/*
TEST THIS WITH THE DEMO ACCOUNT TO CHECK IT WORKS
*/

import React, { useRef, useEffect } from "react";
//import { gql, useQuery, useMutation } from '@apollo/client';
import {AddOrderToUser} from "global/components/PaypalButtons/index";
import API_CALLS from 'API_CALLS/index';
//const NEW_ORDER = API_CALLS.ORDERS.MUTATIONS.create();
  





class PaypalSubscription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };    

  }

  componentDidMount(){
    //console.log(`Paypal loaded`,"\n\n","\n\n");
    var {paypalID, onSuccess=()=>{}, onFail=()=>{}} = this.props;
    window.paypal
      .Buttons({
          style: {
            shape: 'rect',
            color: 'gold',
            layout: 'vertical',
            label: 'paypal'
        },
        createSubscription: (data, actions) => {
          return actions.subscription.create({
            'plan_id': paypalID
          });
        },
        onApprove: async (data, actions) => {
          console.log("PaypalSubscription order", data);
          console.log(`actions`,"\n\n",actions,"\n\n");
                
          AddOrderToUser({orderID:data.orderID, subscriptionID:data.subscriptionID, onSuccess, onFail})
        },
        onError: (err) => {
          console.log(err);
          if(this.props.onFail) this.props.onFail(err);
        },
      })
      .render(this.PaypalRef);
  };


  render(){
    var {paypalID} = this.props;
    console.log(`paypalID`,"\n\n",paypalID,"\n\n");
          
    if(!paypalID) return(<div>CHECKOUT ERROR: 98765</div>);

    return (
      <div ref={e=>this.PaypalRef=e}></div>
    );
  }
}


export default PaypalSubscription;