import React from 'react';
/*
<Container className="">
  <Row className="">
    <Column className="" col="" sm="" md="" lg="" xl="">
      <div className="">
      </div>
    </Column>
  </Row>
</Container>
*/


const Container = (props)=>{
  var {className='', fluid, style={}, id=""} = props;
  return(
    <div id={id} className={`${fluid ? "container-fluid" : "container"} ${className}`} style={style}>
      {props.children}
    </div>
  )
}



const Row = (props)=>{
  var {className='', style={}, id=""} = props;
  return(
    <div id={id} className={`row ${className}`} style={style}>
      {props.children}
    </div>
  )
}


const Column = (props)=>{
  var {className='', col='', sm='', md='', lg='', xl='', style={}, id=""} = props;
  return(
    <div id={id} className={`${(col ? 'col-'+col : 'col')}${(sm ? ' col-sm-'+sm : '')}${(md ? ' col-md-'+md : '')}${(lg ? ' col-lg-'+lg : '')}${(xl ? ' col-xl-'+xl : '')} ${className}`} style={style}>
      <div className="content">
        {props.children}
      </div>
    </div>
  )
}


/*
<Table
  id=""
  style=""
  className=""
  thead={{
    id: "",
    style: "",
    className: "",
    tr: {
      id: "",
      style: "",
      className: "",
      th: [
        {
          id: "",
          style: "",
          className: "",
          value: ""
        }
      ]
    }
  }}
  tbody={{
    id: "",
    style: "",
    className: "",
    tr: [
      {
        id: "",
        style: "",
        className: "",
        th: [
          {
            id: "",
            style: "",
            className: "",
            value: ""
          }
        ]
      }
    ]
  }}
/>
*/
const Table = (props)=>{
  var {
    className='table', 
    style={}, 
    id="", 
    header=[], 
    thead={className:"thead-dark"}, 
    tbody={},
    rows=[],
  } = props;
  return(
    <table 
      id={id}
      style={style}
      className={className}>
      <thead 
        id={thead.id}
        style={thead.style}
        className={thead.className}>
        <tr 
          id={thead.tr.id}
          style={thead.tr.style}
          className={thead.tr.className}>
          {
            thead.tr.th.map((thObj, index)=>{
              return(
                <th id={thObj.id} className={thObj.className} style={thObj.style} key={index}>
                  {thObj.value}
                </th>
              )
            })
          }
        </tr>
      </thead>
      <tbody  
        id={tbody.id}
        style={tbody.style}
        className={tbody.className}>
        {
          tbody.tr.length &&
          tbody.tr.map((trObj, index)=>{
            return(
              <tr id={trObj.id} className={trObj.className} style={trObj.style} key={index}>
                {
                  trObj.th.length &&
                  trObj.th.map((thObj, index)=>{
                    return(
                      <th id={thObj.id} className={thObj.className} style={thObj.style} key={index}>
                        {thObj.value}
                      </th>
                    )
                  })
                }
                
              </tr>
            )
          })
        }
      </tbody>
    </table>
  )
}




const Layout = {
  Container: Container,
  Row: Row,
  Column: Column,
  Table: Table
}

window.LayoutBuilder = Layout;

export default Layout;