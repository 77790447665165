import React from 'react';
import Logo from "global/components/Logo/index";
import Nav from "website/components/Nav/index";
import SocialObj from "SOCIAL.js";
import ESPAÑOL from "./ESPAÑOL";
import ENGLISH from "./ENGLISH";
const CONTENTS = {
  ESPAÑOL,
  ENGLISH
}
const {Container, Row, Column} = window.LayoutBuilder;
const Component = (props) => {
  var SocialList = SocialObj();        
  var today = new Date();
  var phoneString = window.GlobalUtil.getPhoneString(true);
  const CONTENT = window.GlobalUtil.dangerSetGlobal(CONTENTS[window.Session.Language]); 
  const {slogan=""} = window.SiteSettings;
  return (
    <div id="Footer">
      <Container className="footerSection2">
        <Row className="">
          <Column className="footerSlogan" col="12" xs="12" sm="12" md="12" lg="6" xl="7">
            <div className="businessSection">
              <div className="logoImage">
                <Logo />
              </div>
              <div className="slogan">
                {window.GlobalUtil.dangerSet(slogan)}
              </div>
              <div className="copyright d-none d-lg-flex">
                Sonrisas LLC © {today.getFullYear()}.&nbsp;&nbsp; {CONTENT[1]}
              </div>
            </div>
          </Column>
          <Column className="footerNav1" col="" sm="" md="" lg="" xl="">
            <div className="quickLinks">
              <div className="header">
                {CONTENT[2]}
              </div>
              <Nav MenuToShow={window.Session.Language === "ESPAÑOL" ? "EspFooter1" :"Footer1"} />
            </div>
          </Column>
          <Column className="footerNav2" col="" sm="" md="" lg="" xl="">
            <div className="contact">
              <div className="header">
                {CONTENT[3]}
              </div>
              <Nav MenuToShow={window.Session.Language === "ESPAÑOL" ? "EspFooter2" :"Footer2"} />
            </div>
          </Column>
          <Column className="socialColumn" col="" sm="" md="" lg="" xl="">
            <div className="contact">
              <div className="header">{CONTENT[4]}</div>
              <div className="socialLinks">
                {
                  (SocialList && SocialList.length > 0) && 
                  SocialList.map((SocialItem, index)=>{                              
                    if(!SocialItem || !SocialItem.name) return null; 
                    var curURL = "";
                    if(window.SiteSettings && window.SiteSettings.socialLinks){
                      var curSocialObj = window.SiteSettings.socialLinks.filter(obj=>obj.name === SocialItem.name)[0];
                      curURL = ((curSocialObj && curSocialObj.url) ? curSocialObj.url : "");
                    }             
                    return(
                      <div className="socialItem" key={index}>
                        <a href={`http://${SocialItem.url}/${curURL}`} target="_blank">{SocialItem.icon}</a>
                      </div>
                    )
                  })
                }
              </div>
              <div className="phoneFooter">
                <div className="icon"><i className="fas fa-mobile-alt"></i></div>
                <div className="phone"><a className="phoneNumber" href={`tel:${window.GlobalUtil.getPhoneNumber()}`}>{phoneString}</a></div>
              </div>
              <div className="addressFooter">
                <div className="icon"><i className="fas fa-map-pin" /></div>
                <div className="infoContent">
                  {window.GlobalUtil.formatAddress(window.SiteSettings.address)}
                </div>
              </div>
            </div>
          </Column>
          <Column className="d-block d-lg-none text-center" col="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <div className="copyright smallScreenCopyright">
              Sonrisas LLC © {today.getFullYear()}.&nbsp;&nbsp; {CONTENT[1]}
            </div>
          </Column>
        </Row>
      </Container>
      
    </div>
  );
}

export default Component;