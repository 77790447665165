import React from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import {
    BrowserRouter as Router, StaticRouter, Route, NavLink, Link, Redirect, Switch
    // etc.
} from 'react-router-dom';


import "styles/scss/admin/index.scss";
import Logo from 'global/components/Logo/index';
//import API_CALLS from 'API_CALLS/index';
import BlankImage from "global/img/blank/blank_50.png";

import Home from './pages/dashboard/index';
import Aligners from './pages/aligners/index';
import Affiliates from './pages/affiliates/index';
import Blogs from './pages/blogs/index';
import Coms from './pages/coms/index';
import Coupons from './pages/coupons/index';
import Emails from './pages/emails/index';
import Failed from './pages/failed/index';
import Giveaways from './pages/giveaways/index';
import Orders from './pages/orders/index';
import Products from './pages/products/index';
import Referrals from './pages/referrals/index';
import Settings from './pages/settings/index';
import Shipping from './pages/shipping/index';
import Tracking from './pages/tracking/index';
import TreatmentPlans from './pages/treatmentPlans/index';
import Users from './pages/users/index';


//import Footer from './components/Footer';
import Pages from './pages/index'; //ADD NEW PAGES HERE
import TemplateSlideInRight from "global/templates/templateSlideInRight/index";
import TemplateDBNew from "global/templates/templateDBNew/index";
import TemplateDBEdit from "global/templates/templateDBEdit/index";
import TemplateDBPreview from "global/templates/templateDBPreview/index";

import TemplateDBNewObj from "admin/components/DialogNew/index";
import TemplateDBEditObj from "admin/components/DialogEdit/index";
import TemplateDBPreviewObj from "admin/components/DialogPreview/index";
import {GetFullUserInfo, GetSiteSettings, UpdateSiteSettings} from 'global/utils/helper-functions';

import Nav from 'admin/components/Nav/index';
import MobileMenu from 'admin/components/MobileMenu/index';
const PAGES_COMPONENTS = { //ADD ANY NEW PAGES HERE
  Home: Home,
  Aligners: Aligners,
  Affiliates: Affiliates,
  Blogs: Blogs,
  Coms: Coms,
  Coupons: Coupons,
  Emails: Emails,
  Failed: Failed,
  Giveaways: Giveaways,
  Orders: Orders,
  Products: Products,
  Referrals: Referrals,
  Settings: Settings,
  Shipping: Shipping,
  Tracking: Tracking,
  TreatmentPlans: TreatmentPlans,
  Users: Users
};














class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      SlideInRightObj: false,
      TemplateDBObj: {}
    };    
    this.SlideInRightRef = {};
    window.GlobalUtil.ForceUpdateDB = this.ForceUpdateDB;
    this.APIMutationCalledKey = window.GlobalUtil.subscribeEvent("APIMutationCalled", whatMutated=>{ //SHOULD RUN EACH TIME MUTATION HAPPENS AND LETS ME DECIDED WHAT TO UPDATE
      //whatMutated
      //console.log(`APIMutationCalled`,"\n\n","\n\n");
            
      //FOR NOW ON EACH MUTATION WE WILL GET ALL STUFF AGAIN
      window.GlobalUtil.ForceUpdateDB();
    });


    this.SlideInRightObjEventKey = window.GlobalUtil.subscribeEvent("SlideInRightObjEvent", newSlideInRightObj=>{
      this.setState({SlideInRightObj: newSlideInRightObj})
    });


    this.TemplateDBEventKey = window.GlobalUtil.subscribeEvent("TemplateDBEvent", newTemplateDBObj=>{
      var {TemplateDBObj} = this.state;
      if(!TemplateDBObj[newTemplateDBObj.TYPE]) TemplateDBObj[newTemplateDBObj.TYPE] = {};
      TemplateDBObj[newTemplateDBObj.TYPE][window.GlobalUtil.getRandomId()] = newTemplateDBObj;
      this.setState({TemplateDBObj})
    });


    
  }

  componentDidMount(){
    GetSiteSettings({
      forceUpdate: true, 
      //callBack:()=>{}, 
      //triggerEvent:true
    });
    if(document.querySelector("#Website")) document.querySelector("#Website").remove();
  }

  componentWillUnmount(){
    this.APIMutationCalledKey.unsubscribe();
    this.SlideInRightObjEventKey.unsubscribe();
    this.TemplateDBEventKey.unsubscribe();
  }


  

  API_UPDATE_SITE_SETTINGS = (newObj, onSuccess=()=>{}, onFail=()=>{}) => {
    if(navigator.userAgent === 'ReactSnap') return null;
    //if(process.env.NODE_ENV === 'production') return null;
    UpdateSiteSettings({
      newObj: newObj, 
      onSuccess: (cleanObjNew)=>{
        var newClean = window.GlobalUtil.stripOutFromObj(cleanObjNew, "__typename");
        window.SiteSettings = {...window.SiteSettings, ...newClean};
        onSuccess();
        this.ForceUpdateDB();
      },
      onFail: onFail,
      failPathString: "API_UPDATE_SITE_SETTINGS onUpdateSiteSettings FAIL catch"
    });
  }


  ForceUpdateDB=()=>{
    if(!this) return;
    this.setState({hideAll: true},()=>{
      this.setState({hideAll: false})
    })
  }

  render(){
    if(navigator.userAgent === 'ReactSnap') return <div id="Admin"></div>;
    //if(process.env.NODE_ENV === 'production') return null;
    var {hideAll, SlideInRightObj, TemplateDBObj, showSideMenu} = this.state;
    var {data} = this.props;
    const PAGES = Pages();
    if(hideAll) return null;          
    const CleanLinks = PAGES.filter(Page=>Page.Menu.indexOf("Header") !== -1);   
    //window.Session.user.role = "DOCTOR";
    //IF USER IS NOT ADMIN THEN REDIRECT TO USER BEFORE ANYTHING ELSE
    if(!window.Session.user) return (<Redirect to={"/"} />);  //IF NOT LOGGED IN THEN GO TO HOME PAGE
    if(window.Session.user.role !== "ADMIN") return (<Redirect to={"/user"} />); //IF NOT ADMIN THEN GO TO USER PAGE

    return (
      <div id="Admin">
        <div id="AdminHeader">
          {
            window.Session.user &&
            <div className="adminHeaderWrapper">
              <div className="logo">
                <Logo type="light"/>
              </div>
              <div className="otherStuff">
                <div className="mobile-button d-block d-sm-none d-md-none d-lg-none">
                  <div className="mobile-button-box">
                    <i className="fas fa-bars" onClick={()=>this.setState({showSideMenu: true})} />
                  </div>
                </div>
                <div className="userLink d-none d-sm-flex d-md-flex d-lg-flex">
                  <div className="user">
                    <div className="welcome">Welcome,</div>
                    <div className="userName">{window.Session.user.firstName} {window.Session.user.lastName}</div>
                  </div>
                  <div className="userImage">
                    <img src={window.GlobalUtil.deepGetFromString(window.Session.user.userPicture, "0,url", BlankImage)} alt=""/>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
        <div id="AdminContentWrapper">
          <div id="LeftMenu" className={`d-none d-sm-flex d-md-flex d-lg-flex`}>
            <Nav MenuToShow={window.Session.user.role} />
          </div>
          {
            showSideMenu && <MobileMenu onHideSideMenu={()=>this.setState({showSideMenu: false})}/>
          }
          <div id="AdminContent">
            <Switch> 
              {  
                PAGES.map((page, index)=>{
                  const CurComp = PAGES_COMPONENTS[page.Name];
                  if((window.Session.user.role === "DOCTOR") && (page.Name !== "Doctor")) return null; //IF IS DOCTOR THEN DON'T LOAD ANY OTHER PAGES
                  if(page.Name == "Home") return <Route 
                    exact 
                    key={index}
                    path={page.Paths} 
                    render={props=>{
                      window.GlobalUtil.triggerEvent("PageChangedEvent", page);
                      return<CurComp {...props} onUpdateSiteSettings={this.API_UPDATE_SITE_SETTINGS} />
                    }} 
                  />

                  return <Route 
                    key={index}
                    path={page.Paths} 
                    render={props=>{
                      window.GlobalUtil.triggerEvent("PageChangedEvent", page);
                      return<CurComp {...props} onUpdateSiteSettings={this.API_UPDATE_SITE_SETTINGS} />
                    }} 
                  />
                })
              }
              <Route>
                <Redirect to={`/admin`}  />
              </Route>
            </Switch>
          </div>
        </div>


        {
          (SlideInRightObj && SlideInRightObj.children) &&
          <TemplateSlideInRight
            ref={e=>{
              if(!this.SlideInRightRef) this.SlideInRightRef = {};
              this.SlideInRightRef.BASIC=e;
            }}
            title={SlideInRightObj.TITLE}
            isValid={SlideInRightObj.ISVALID}
            onClose={()=>{
              this.setState({SlideInRightObj: false});
            }}
            onSave={()=>{
              if(SlideInRightObj.onSave){
                SlideInRightObj.onSave().then(()=>{
                  this.SlideInRightRef.BASIC.onClose();
                });
              } else this.SlideInRightRef.BASIC.onClose();
            }}
            customButtons={SlideInRightObj.CUSTOMBUTTONS ? ()=>{
              if(this.SlideInRightRef.BASIC) SlideInRightObj.CUSTOMBUTTONS(this.SlideInRightRef.BASIC);
            } : null}>
            {SlideInRightObj.children({
              onChangeValid: (newValid)=>{
                SlideInRightObj.ISVALID = newValid;
                this.setState({SlideInRightObj})
              },
              onSetSubmitButton: (newSaveButton)=>{
                SlideInRightObj.onSave = newSaveButton;
                this.setState({date: new Date()})
              }
            })}
          </TemplateSlideInRight>
        }



        {
          (TemplateDBObj.NEW && Object.keys(TemplateDBObj.NEW).map((key, index)=>{
              var TemplateObj = TemplateDBObj.NEW[key];
              if(!this.TemplateDBNewRef) this.TemplateDBNewRef = {};
              if(!this.SlideInRightRef) this.SlideInRightRef = {};
              return(
                <TemplateSlideInRight
                  key={key}
                  ref={e=>this.SlideInRightRef[key]=e}
                  title={TemplateDBNewObj.title(TemplateObj)}
                  isValid={TemplateObj.ISVALID}
                  onClose={()=>TemplateDBNewObj.onClose(this, key, TemplateObj)}
                  onSave={()=>TemplateDBNewObj.onSave(this, key)}
                  customButtons={TemplateDBNewObj.customButtons(this, key, TemplateObj)}>
                  <TemplateDBNew
                    ref={e=>this.TemplateDBNewRef[key]=e}
                    NAME={TemplateObj.NAME}
                    {...TemplateObj}
                    isValidChange={(newValue)=>{
                      var NewTemplateDBObj = {...TemplateDBObj};
                      NewTemplateDBObj.NEW[key].ISVALID = newValue;
                      this.setState({TemplateDBObj: NewTemplateDBObj})
                    }}
                  />
                </TemplateSlideInRight>
              ) 
            })
          )
        }




        {
          (TemplateDBObj.EDIT &&  Object.keys(TemplateDBObj.EDIT).map((key, index)=>{
              var TemplateObj = TemplateDBObj.EDIT[key];
              if(!this.TemplateDBEditRef) this.TemplateDBEditRef = {};
              if(!this.SlideInRightRef) this.SlideInRightRef = {};
              return(
                <TemplateSlideInRight
                  key={key}
                  ref={e=>this.SlideInRightRef[key]=e}
                  title={TemplateDBEditObj.title(TemplateObj)}
                  isValid={TemplateObj.ISVALID}
                  onClose={()=>TemplateDBEditObj.onClose(this, key, TemplateObj)}
                  onSave={()=>TemplateDBEditObj.onSave(this, key)}
                  customButtons={TemplateDBEditObj.customButtons(this, key, TemplateObj)}>
                  <TemplateDBEdit
                    ref={e=>this.TemplateDBEditRef[key]=e}
                    {...TemplateObj}
                    NAME={TemplateObj.NAME}
                    isValidChange={(newValue)=>{
                      var NewTemplateDBObj = TemplateDBObj;
                      NewTemplateDBObj.EDIT[key].ISVALID = newValue;
                      this.setState({TemplateDBObj: NewTemplateDBObj})
                    }}
                  />
                </TemplateSlideInRight>
              ) 
            })
          )
        }


        {
          (TemplateDBObj.PREVIEW &&  Object.keys(TemplateDBObj.PREVIEW).map((key, index)=>{
              var TemplateObj = TemplateDBObj.PREVIEW[key];
              if(!this.SlideInRightRef) this.SlideInRightRef = {};                    
              return(
                <TemplateSlideInRight
                  key={key}
                  ref={e=>this.SlideInRightRef[key]=e}
                  title={TemplateDBPreviewObj.title(TemplateObj)}
                  isValid={true}
                  onClose={()=>TemplateDBPreviewObj.onClose(this, key, TemplateObj)}
                  onSave={()=>{}}
                  hideSaveButton={!TemplateObj.customButtons ? true : false}
                  customButtons={TemplateObj.customButtons ? TemplateObj.customButtons : null}>
                  <TemplateDBPreview
                    TemplateDBObj={TemplateObj}
                    isValidChange={(newValue)=>{}}
                  />
                </TemplateSlideInRight>
              ) 
            })
          )
        }

        
      </div>
    );
  }
}



export default Index;
      
