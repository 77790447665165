//import React from 'react';
const PAGE = {
  	"ENGLISH": {
		"HEADER": {
			"BASEURL": "https://smiles.club",
			"TITLE": "Smiles Club | Non-toxic clear aligners. Get clear orthodontics at no risk. Money back guarantee.",
			"DESCRIPTION": "Smiles Club has the best clear aligners to fix underbite, crowded teeth, overbite, crooked teeth, and even extra teeth spacing from home with financing.",
			"KEYWORDS": "clear orthodontics, affordable, no risk, clear braces, non-toxic, invisible braces, underbite, overbite, crowded teeth, best aligners, safest aligners, number one clear aligners, invisible aligners, BPA, financial options, safe plastics, discreetly, Braces alternative"
		},
		"CONTENT": {
			"TopMainSection": [
				{
					"type": "div",
					"content": `
						<div class="preHeader">Discover your True Smile</div>
						<h1 class="sectionTitle">Your new Smile is closer than you think. Get your Clear Aligners <strong>NOW!</strong> <strong style="color: #eb008b;">Pay later!</strong></h1>
						<div class="sectionText">Time and flexibility are on your side. With PayPal, pay at a pace that works for you. Up to 24-month payment plans with $0 down.</div>
					`
				}
			],
			"TopMainSectionButtons": [
				{
					"content": `Shop Now`
				},
				{
					"content": `/buy`
				}
			],
			"SloveProblem": [
				{
					"type": "div",
					"content": `
						<div class="preHeader">Quick Questions</div>
						<h2 class="sectionTitle2">What to know about Invisible Alginers?</h2>
						<div class="subHeader">Here are some of the most common aligner questions we get asked.</div>
						<div class="text">
							<ul class="questionAndAnswers">
								<li><span class="answerEmph">Are clear aligners safe?</span> YES! They're even used by Dentist.</li>
								<li><span class="answerEmph">Can clear aligners fix an underbite?</span> YES!</li>
								<li><span class="answerEmph">Can clear aligners fix an crowded teeth?</span> YES!</li>
								<li><span class="answerEmph">Can clear aligners fix an overbite?</span> YES!</li>
								<li><span class="answerEmph">Is there a diffrence between Clear Aligners and Invisible Aligners?</span> NOPE!</li>
								<li><span class="answerEmph">Which clear aligners are the best?</span> OURS ARE! Well, there are many great brands today which provide great aligners. But we only use toxic-free materials, have dental oversight, and HAVE NOT HIDDEN COSTS!</li>
								<li><span class="answerEmph">Which clear aligners cheapest?</span> That's a hard one. Prices are always changing, but BE CAREFUL WITH LOW PRICES! Some companies may use cheap materials, such as BPA plastics, which can have harmful toxins. We make our products in the USA, use the same materials as orthodontists, and work with dental professionals to give the best product for our customers. Please be careful.</li>
							</ul>
						</div>
					`
				}
			],
			"SloveProblemButtons": [
				{
					"content": `Learn More`
				},
				{
					"content": `/getstarted`//comencemos
				},
				{
					"content": `Contact Us`
				}
			],
			"SimpleSteps": [
				{
					"type": "div",
					"content": `
						<div class="preHeader">SIMPLE STEPS</div>
						<h2 class="sectionTitle2">This is how Smiles Club work</h2>
						<div class="subHeader">It is a simple as 1, 2, 3!</div>
					`
				},
				{
					"type": "div",
					"content": `						
						<div class="preHeader2">Step 1</div>
						<h4>Take and Send us Your Impressions</h4>
						<div>We will ship you an impressions’ kit to your home. We will send you easy instructions and a simple video to show you how to create a dental impression. You will send it back to us in a pre-paid return shipping.</div>
					`
				},
				{
					"type": "div",
					"content": `	
						<div class="preHeader2">Step 2</div>
						<h4>Get a Personal Custom Treatment Plan</h4>
						<div>An Orthodontist will design a personalized treatment plan. The plan will be approved by a second, licensed dental professional and we'll make the safest aligner for your whole treatment.</div>
					`
				},
				{
					"type": "div",
					"content": `						
						<div class="preHeader2">Step 3</div>
						<h4>Get your Clear Aligners!</h4>
						<div>We'll send you all your invisible aligners and you can get started! Your full teeth straightening kit will come with each aligner you need do your teeth straightening at home.</div>
					`
				},
				{
					"type": "div",
					"content": `
						<div class="smallCenterText">
							<br/>
							<h3 class="sectionTitle3">Order Now and get a Free Impression Kit!</h3>
							<br/>
							<div>Improve your smile 100% from home with your own braces alternative. It is time that you invest in yourself and discover your True Smile with Smiles Club's aligner therapy treatment.</div>
							<br/>
						</div>
					`
				},
			],
			"SimpleStepsButtons": [
				{
					"content": `Learn More`
				},
				{
					"content": `/getstarted` // /comencemos
				},
				{
					"content": `Order Now!`
				},
				{
					"content": `#BuyNowProduct` // /comencemos
				}
			],
			"Testimonials": [
				{
					"type": "div",
					"content": `
						<div class="preHeader">TESTIMONIALS</div>
						<h3 class="sectionTitle3">See our success stories</h3>
						<div class="text">There are so many true smiles being discovered every day, that it difficult to select a few stories to share.</div>
					`
				}
			],
			"TestimonialsButtons": [
				{
					"content": `See Now`
				},
				{
					"content": `/getstarted` // /comencemos
				}				
			],
			"PricingPlans": [
				{
					"type": "div",
					"content": `
						<div class="preHeader">OUR GUARANTEE TO YOU</div>
						<h2 class="sectionTitle2">Buy your FULL Treatment Plan Now!</h2>
						<div class="subHeader">We'll show you how to take a perfect impression. We'll explain your customized ortho treatment plan. <br/><strong style="font-weight: 500;">AND we’ll give you a FULL refund if you're not a candidate.</strong></div>
					`
				},
				{
					"type": "div",
					"content": `
						<h3 class="sectionTitle3">Get it today! <strong class="spacerLine">|</strong><strong> FOR ONLY $1,949</strong></h3>
						<div class="subHeader2">Once purchased we'll send you a free impression kit to begin your treatment.</div>
						<div class="text">
							<ol>
								<li>Purchase your full treatment plan for $1,949 <strong>INCLUDING a $49.99</strong> impression kit <strong>FOR FREE</strong>.</li>
								<li>Get your At-Home Impression Kit in the mail.</li>
								<li>Send us impressions of your teeth using the included pre-paid return shipping label.</li>
								<li>Our orthodontists personally review your impressions and create a treatment plan offer.</li>
								<li>We'll deliver your custom clear aligners and step-by-step treatment plan to your home.</li>
							</ol>
						</div> 
					`
				}
			],
			"PricingPlansButtons": [
				{
					"content": `Learn More`
				},
				{
					"content": `/pricing` // /precios
				}
			],
			"HaveQuestions": [
				{
					"type": "div",
					"content": `
						<div class="preHeader">FAQ</div>
						<h2 class="sectionTitle2">Still have questions?</h2>`
				},
				{
					//"type": "div",
					"content": `Check out our frequently asked questions or contact us and we'll answer as soon as possible. `
				}
			],
			"HaveQuestionsButtons": [
				{
					"type": "span",
					"content": `FAQ`
				},
				{
					"content": `/faq` // /pmf
				},
				{
					"type": "span",
					"content": `Contact`
				}
			]
		}
	},
	"ESPAÑOL": {
		"HEADER": {
			"BASEURL": "https://sonrisas.club",
			"TITLE": "Smiles Club | Alineadores transparentes no tóxicos. Obtenga una ortodoncia transparente sin riesgo. Garantía de devolución del dinero.",
			"DESCRIPTION": "Smiles Club tiene los mejores alineadores transparentes para corregir la mordida inferior, los dientes apiñados, la sobremordida, los dientes torcidos e incluso el espacio entre dientes adicional desde casa con financiamiento.",
			"KEYWORDS": "ortodoncia transparente, asequible, sin riesgos, frenos transparentes, no tóxico, frenos invisibles, mordida inferior, sobremordida, dientes apiñados, los mejores alineadores, los alineadores más seguros, los alineadores transparentes número uno, los alineadores invisibles, BPA, opciones financieras, plásticos seguros, discretamente, frenos alternativa"
		},
		"CONTENT": {
			"TopMainSection": [
				{
					"type": "div",
					"content": `
						<div class="preHeader">Descubre tu Verdadera Sonrisa</div>
						<h1 class="sectionTitle">Tu nueva Sonrisa está más cerca de lo que piensas. ¡Consigue tus alineadores transparentes <strong>AHORA!</strong> <strong style="color: #eb008b;">¡Paga después!</strong></h1>
						<div class="sectionText">El tiempo y la flexibilidad están de su lado. Con PayPal, pague a un ritmo que funcione para usted. Planes de pago de hasta 24 meses con $0 de pago inicial.</div>
					`
				}
			],
			"TopMainSectionButtons": [
				{
					"content": `Comprar`
				},
				{
					"content": `/buy`
				}
			],
			"SloveProblem": [
				{
					"type": "div",
					"content": `
						<div class="preHeader">Preguntas</div>
						<h2 class="sectionTitle2">¿Qué saber de los Alginers Invisibles?</h2>
						<div class="subHeader">Estas son algunas de las preguntas más comunes que nos hacen sobre los alineadores.</div>
						<div class="text">
							<ul class="questionAndAnswers">
								<li><span class="answerEmph">¿Son seguros los alineadores transparentes?</span> ¡SÍ! Incluso los usa Dentist.</li>
								<li><span class="answerEmph">¿Los alineadores transparentes pueden corregir una mordida inferior?</span> ¡SÍ!</li>
								<li><span class="answerEmph">¿Pueden los alineadores transparentes arreglar los dientes apiñados?</span> ¡SÍ!</li>
								<li><span class="answerEmph">¿Los alineadores transparentes pueden corregir una sobremordida?</span> ¡SÍ!</li>
								<li><span class="answerEmph">¿Hay alguna diferencia entre los alineadores transparentes y los alineadores invisibles?</span> ¡NO!</li>
								<li><span class="answerEmph">¿Qué alineadores transparentes son los mejores?</span><br/>¡LOS NUESTROS SON! Bueno, hoy en día hay muchas marcas excelentes que ofrecen alineadores excelentes. ¡Pero solo usamos materiales libres de tóxicos, tenemos supervisión dental y NO TENEMOS COSTOS OCULTOS!</li>
								<li><span class="answerEmph">¿Qué alineadores transparentes son más baratos?</span><br/>Esa es una pregunta difícil. Los precios siempre están cambiando, pero ¡CUIDADO CON LOS PRECIOS BAJOS! Algunas empresas pueden usar materiales baratos, como plásticos BPA, que pueden tener toxinas dañinas. Fabricamos nuestros productos en los EE. UU., usamos los mismos materiales que los ortodoncistas y trabajamos con profesionales dentales para brindar el mejor producto a nuestros clientes. Tenga cuidado.</li>
							</ul>
						</div>
					`
				}
			],
			"SloveProblemButtons": [
				{
					"content": `Mas Info`
				},
				{
					"content": `/comencemos`
				},
				{
					"content": `Comencemos`
				}
			],
			"SimpleSteps": [
				{
					"type": "div",
					"content": `
						<div class="preHeader">TRES PASOS SENCILLOS</div
						<h2 class="sectionTitle2">Así es como funciona el Smiles Club</h2
						<div class="subHeader">¡Es tan simple como 1, 2, 3!</div
					`
				},
				{
					"type": "div",
					"content": `						
						<div class="preHeader2">Paso 1</div
						<h4>Envíanos tu impresión dental</h4>
						<div>Te enviaremos un kit de impresión dental. Te mostraremos un video para que sepas como tomar la impresión. Tu retornaras la impresión en un sobre de correo pre-pagado.</div>              
					`
				},
				{
					"type": "div",
					"content": `	
						<div class="preHeader2">Paso 2</div
						<h4>Recibe un plan de tratamiento de ortodoncia</h4>
						<div>Un Ortodoncista diseñará un tratamiento personalizado. Este plan será revisado y aprobado por un segundo dentista con licencia en los Estado Unidos.</div>              
					`
				},
				{
					"type": "div",
					"content": `						
						<div class="preHeader2">Paso 3</div
						<h4>Monitorea tu progreso</h4>
						<div>Te ayudaremos a monitorear tu progreso a través del tratamiento.</div>
					`
				},
				{
					"type": "div",
					"content": `
						<div class="smallCenterText">Ordena tu kit de impresión ya. Transforma tu sonrisa 100% desde tu casa. Es hora de que inviertas en ti mismo y descubras tu Verdadera Sonrisa.</div>
					`
				},
			],
			"SimpleStepsButtons": [
				{
					"content": `Comencemos`
				},
				{
					"content": `/comencemos`
				},
				{
					"content": `Somos Mejores`
				},
				{
					"content": `#BuyNowProduct` // /comencemos
				}
			],
			"Testimonials": [
				{
					"type": "div",
					"content": `
						<div class="preHeader">TESTIMONIOS</div>
						<h3 class="sectionTitle3">Mira algunas de las historias de éxito</h3>
						<div class="text">Hay tantas sonrisas verdaderas siendo creadas cada día, que es difícil seleccionar unas pocas historias para compartir.</div>
					`
				}
			],
			"TestimonialsButtons": [
				{
					"content": `Ver Ahora`
				},
				{
					"content": `/comencemos`
				}				
			],
			"PricingPlans": [
				{
					"type": "div",
					"content": `
						<div class="preHeader">NUESTRA GARANTÍA PARA USTED</div>
						<h2 class="sectionTitle2">Compra ahora tu plan de tratamiento COMPLETO!</h2>
						<div class="subHeader">Te mostraremos cómo tomar una impresión perfecta. Te explicaremos tu plan de tratamiento de ortopedia personalizado. <br/><strong style="font-weight: 500;">Y te daremos un reembolso COMPLETO si no es un candidato.</div>
					`
				},
				{
					"type": "div",
					"content": `
						<h3 class="sectionTitle3">¡Ordénalo ya! <strong class="">| POR $1,949</strong></h3>
						<div class="subHeader2">Una vez comprado, le enviaremos un kit de impresión gratuito para comenzar su tratamiento.</div>
						<div class="text">
							<ol>
								<li>Compre su plan de tratamiento completo por $1,949 <strong>INCLUYENDO un kit de impresión de $49.99</strong> <strong>GRATIS</strong></li>
								<li>Recibe tu kit de impresión por el correo.</li>
								<li>Envíanos las impresiones de tus dientes usando el sobre de correo pre-pagado.</li>
								<li>Nuestros Ortodoncistas revisarán tus impresiones y crearán un tratamiento personalizado. Cuando selecciones un plan, te explicaremos el tratamiento en detalle.</li>
								<li>Enviaremos los alineadores trasparentes, así como el plan de tratamiento de ortodoncia a tu casa.</li>
							</ol>
						</div> 
					`
				}
			],
			"PricingPlansButtons": [
				{
					"content": `Ver Mas`
				},
				{
					"content": `/precios`
				}
			],
			"HaveQuestions": [
				{
					"type": "div",
					"content": `
						<div class="preHeader">Preguntas y Respuestas</div>
						<h2 class="sectionTitle2">¿Tienes mas preguntas?</h2>
					`
				},
				{
					//"type": "div",
					"content": `Revisa la lista de preguntas frecuentes o contáctanos y las responderemos tan pronto como sea posible.`
				}
			],
			"HaveQuestionsButtons": [
				{
					"type": "span",
					"content": `Preguntas`
				},
				{
					"content": `/pmf`
				},
				{
					"type": "span",
					"content": `Contáctanos`
				}
			]
		}
	}
};


	
export default PAGE;
