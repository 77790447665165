import 'jquery';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
//import 'popper.min.js';
import 'LayoutBuilder';
import BasicSiteSettings from 'SITE_SETTINGS.json';
import 'styles/animated.min.css';
import 'styles/googlefonts.css';
import 'styles/scss/global/index.scss';

import InitializePrototypes from 'global/utils/prototypes';
import 'global/utils/global-util';
import { setContext } from '@apollo/client/link/context';
import { ApolloClient, InMemoryCache, createHttpLink, ApolloProvider } from '@apollo/client';

import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { hydrate, render } from "react-dom";


import App from 'App';
import * as serviceWorker from 'serviceWorker';

import TagManager from 'react-gtm-module'; //GOOGLE GTM MODULE
import API_CALLS from 'API_CALLS/index';


//IF REACT SNAP IS RUNNING
//if (navigator.userAgent !== 'ReactSnap') addScriptTag
const helmetContext = {};
const tagManagerArgs = {
  gtmId: (process.env.NODE_ENV !== 'development') ? process.env.REACT_APP_GMT_ID_DEV : process.env.REACT_APP_GMT_ID,
  //gtmId: ((process.env.NODE_ENV !== 'development') || (navigator.userAgent !== 'ReactSnap')) ? process.env.REACT_APP_GMT_ID_DEV : process.env.REACT_APP_GMT_ID,
  dataLayerName: 'PageDataLayer',
  events: {
    fillOutForm: 'Filling out Form',
    submitForm: 'Submitted Form',
    loadPage: 'Loaded Page'
  }
}


/*
import TagManager from 'react-gtm-module'; //GOOGLE GTM MODULE
//TRIGGER EVENT
const tagManagerArgs = {
  //gtmId: 'GTM-5TM2ZPK', //I DON'T THINK I NEED THIS
  dataLayer: {
    event: 'EVENT_NAME', //STARTED FORM, SUBMITTED FORM, ETC
    userId: '001',
    userProject: 'project',
    language: window.Session.Language,
    page: page.Name
  },
  //dataLayerName: 'PageDataLayer'
}
TagManager.dataLayer(tagManagerArgs)



EXAMPLE 
const tagManagerArgs = {
  dataLayer: {
    event: 'submitForm',
    //OBJ TO SEND WITH SUBMIT FROM
  }
}
TagManager.dataLayer(tagManagerArgs)

fillOutForm

loadPage
*/

window.DefaultLang = BasicSiteSettings.defaultLang; //"ESPAÑOL"; //CHANGE TO ENGLISH TO CHANGE THE DEFAULT LANDING PAGE TO ENGLISH PAGE
InitializePrototypes();
window.GlobalVar = {
	NumberOfDBConnectRetrys: 6,
	TimeBTDBConnectRetrys: 1000
}

window.GlobalUtil.resetWindowSession();
window.SiteSettings = {
  ...window.GlobalUtil.BasicSiteSettings
};
window.TestSession = false; //true; //THIS WILL LET ME ADD TEST CHECKS TO SEE STUFF FLOW WHILE SKIPPING REAL STEPS. LIKE CHECKOUT WILL SKIP CHECKOUT.
// document.addEventListener('scroll', function(e) {
// 	console.log('scrolling')
// });


TagManager.initialize(tagManagerArgs)
// declare var GlobalUtil;











// Grab the state from a global variable injected into the server-generated HTML
const preloadedState = window.__APOLLO_STORE__;

// Allow the passed state to be garbage-collected
delete window.__APOLLO_STORE__;


const httpLink = createHttpLink({
  uri: ((process.env.NODE_ENV === 'development') ? process.env.REACT_APP_GRAPHQL_LINK_DEV : process.env.REACT_APP_GRAPHQL_LINK),
  //uri: (((process.env.NODE_ENV === 'development') || (navigator.userAgent === 'ReactSnap')) ? process.env.REACT_APP_GRAPHQL_LINK_DEV : process.env.REACT_APP_GRAPHQL_LINK),
});

const authLink = setContext((_, { headers }) => {
  //console.log(`authLink`,"\n\n","\n\n");
        
  // get the authentication token from local storage if it exists
  const token = window.GlobalUtil.LocalStorage.get("token");
  var headersNew = {
    ...headers,
    authorization: token ? `Bearer ${token}` : ""
  }
  if(window.handshaketoken) headersNew.handshaketoken = window.handshaketoken
  // return the headers to the context so httpLink can read them
  return {
    headers: headersNew
  }
});

const client = new ApolloClient({
  initialState: preloadedState,
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});

window.Client = client;

// Tell react-snap how to save state
window.snapSaveState = () => ({
  //"__APOLLO_STORE__": client.store.getState()
  //__APOLLO_STORE__: client.readQuery({
    //query, // you can pass any query you want to prerender here
    // loginWithToken: API_CALLS.USERS.QUERIES.loginWithToken(),
    // siteSettings: API_CALLS.SITE_SETTINGS.QUERIES.single(),
    // products: API_CALLS.PRODUCTS.QUERIES.all()
  //}),
});





const AppWithRouter = (
  <ApolloProvider client={client}>
    <HelmetProvider context={helmetContext}>
      <App />
    </HelmetProvider>
  </ApolloProvider>
);



const rootElement = document.getElementById("Root");

if (rootElement.hasChildNodes()) {
  hydrate(AppWithRouter, rootElement);
} else {
  render(AppWithRouter, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

//import reportWebVitals from './reportWebVitals';

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();


serviceWorker.unregister();
