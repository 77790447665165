import React from 'react';
import EditShipping from './editShipping/index';
import EditUser from './editUser/index';
import API_CALLS from 'API_CALLS/index';
import SectionValue from 'user/normal/components/SectionValue/index';
import ESPAÑOL from "./ESPAÑOL";
import ENGLISH from "./ENGLISH";
import {ESPAÑOL as ESPAÑOLEditUser} from "./editUser/ESPAÑOL";
import {ENGLISH as ENGLISHEditUser} from "./editUser/ENGLISH";
import {ESPAÑOL as ESPAÑOLEditShipping} from "./editShipping/ESPAÑOL";
import {ENGLISH as ENGLISHEditShipping} from "./editShipping/ENGLISH";
import SlideInRight from 'global/components/SlideInRight/index';
//import FormBuilder from 'global/components/FormBuilder/index';
import EditableImage from 'global/components/EditableImage/index';
const CONTENTS = {
  ESPAÑOL,
  ENGLISH
}
const CONTENTS_EDIT_USER = {
  ESPAÑOL: ESPAÑOLEditUser,
  ENGLISH: ENGLISHEditUser
}
const CONTENTS_EDIT_SHIPPING = {
  ESPAÑOL: ESPAÑOLEditShipping,
  ENGLISH: ENGLISHEditShipping
}
const MUTATION_USER_EDIT = API_CALLS.USERS.MUTATIONS.update();
const {Container, Row, Column} = window.LayoutBuilder;





class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSideEditUser: false, 
      updateIsValid: false, 
    };    
    this.checkIfShouldAllowNewProgressImage = this.checkIfShouldAllowNewProgressImage.bind(this);
    this.changeUserSettings = this.changeUserSettings.bind(this);
  }

  componentDidMount(){
    //window.GlobalUtil.consoleLog("MUTATION_USER_EDIT", [MUTATION_USER_EDIT]);
  }

  componentWillUnmount(){
  }

  changeUserSettings(newSettings){
    var currentUser = (window.Session.user ? window.GlobalUtil.stripOutFromObj(window.Session.user, "__typename") : {});
    var newVar = {
      id: currentUser._id,
      settings: newSettings
    }
    this.props.onUpdateUser(newVar)
  }

  // onUpdateUser(newObj){
  //   window.Client.mutate({ //API UPDATE
  //     mutation: MUTATION_USER_EDIT,
  //     variables: newObj
  //   })
  //   .then((obj)=>{
  //     window.ForceUserReload();
  //     if(this) this.setState({date: new Date()})
  //   })
  //   .catch((error)=>{
  //     console.log("error updating user");
  //     console.log(error,"\n\n");  
  //   });
  // }


  onDeactivateUser = () => {
    var currentUser = (window.Session.user ? window.GlobalUtil.stripOutFromObj(window.Session.user, "__typename") : {});
    window.Client.mutate({ //API UPDATE
      mutation: API_CALLS.USERS.MUTATIONS.deactivate(),
      variables: {
        id: currentUser._id
      }
    })
    .then((obj)=>{
      window.GlobalUtil.logout();
    })
    .catch((error)=>{
      console.log("error updating user");
      console.log(error,"\n\n");  
    });
  }

  

  checkIfShouldAllowNewProgressImage(curProgressPictures){
    var allowNewProggressImage = false;
    if(!curProgressPictures || curProgressPictures.length < 2) return true;
    if(curProgressPictures && curProgressPictures.length > 0 && curProgressPictures[curProgressPictures.length-1].createAt){
      var lastImageTime = new Date(Number(curProgressPictures[curProgressPictures.length-1].createAt));
      lastImageTime.setDate(lastImageTime.getDate()+5); //Move forward 1 week
      var todayDateString = new Date().getTime();
      if(todayDateString >= lastImageTime.getTime()) allowNewProggressImage = true;
    }
    return allowNewProggressImage;
  }

  render(){
    var {updateIsValid, showSideEditUser, hideNewImage} = this.state;
    var currentUser = (window.Session.user ? window.GlobalUtil.APICleanObj(window.Session.user) : {});
    currentUser.id = currentUser._id;
    var {userPicture, address={}, email, firstName, lastName, phone, confirmedEmail, settings={}} = currentUser;
    var phoneArray = (phone ? window.GlobalUtil.formatPhone(phone) : false);
    var curProgressPictures = (currentUser.progressPictures ? currentUser.progressPictures.slice() : []);
    var allowNewProggressImage = this.checkIfShouldAllowNewProgressImage(curProgressPictures, false);

    const CONTENT = window.GlobalUtil.dangerSetGlobal(CONTENTS[window.Session.Language]); 
    const confirmDic = {
      "ENGLISH": "Yes",
      "ESPAÑOL": "Si"
    }
    return (
      <div id="UserProfile" className="scrollZone">
        <Container className="" fluid="true">
          <Row className="">
            <Column className="" col="" xs="" sm="" md="" lg="" xl="">
              {CONTENT[0]}
            </Column>
          </Row>
        </Container> 
        <Container className="" fluid="true">
          <Row className="title titleZone">
            <Column className="matchHeight" col="" xs="" sm="" md="" lg="" xl="">
              <div className="component fullHeight overflowRightScroll">
                <div className="itemHeader">
                  <div className="itemHeaderTextZone">
                    <div className="itemHeaderText withIcon">
                      <div className="text">{CONTENT[1]}</div>
                      <EditUser
                        onUpdateUser={this.props.onUpdateUser}
                        CONTENT={window.GlobalUtil.dangerSetGlobal(CONTENTS_EDIT_USER[window.Session.Language])}
                        curObj={{...currentUser, id: currentUser._id}}
                      /> 
                    </div>
                  </div>
                </div>
                <Row className="">
                  <Column className="" col="12" xs="12" sm="12" md="4" lg="3" xl="2">
                    <div className="userImage">
                      <EditableImage
                        images={userPicture}
                        filePath={[
                          "USERS",
                          currentUser._id,
                          "PICTURE"
                        ]}
                        onChangeImage={(newImageObj, callBack)=>{
                          var variables = {
                            id: currentUser._id,
                            userPicture: newImageObj
                          };
                          window.Client.mutate({ //API UPDATE
                            mutation: MUTATION_USER_EDIT,
                            variables: variables
                          })
                          .then((obj)=>{
                            window.ForceUserReload();
                            callBack();
                          })
                          .catch((error)=>{
                            console.log("error saving image to blog");
                            console.log(error,"\n\n", newImageObj);  
                          });
                          
                        }}
                        showDeleteButton={true}
                      />
                    </div>
                  </Column>
                  <Column className="" col="" xs="" sm="" md="" lg="" xl="">
                    <div className="sectionValueZone">
                      <SectionValue
                        section={CONTENT[2]}
                        value={firstName}
                      />
                      <SectionValue
                        section={CONTENT[3]}
                        value={lastName}
                      />
                      <SectionValue
                        section={CONTENT[4]}
                        value={phoneArray && (`(${phoneArray[0]}) ${phoneArray[1]} - ${phoneArray[2]}`)}
                      />
                      <SectionValue
                        section={CONTENT[5]}
                        value={email}
                      />
                      {/* <SectionValue
                        section={CONTENT[6]}
                        value={confirmedEmail ? confirmDic[window.Session.Language] : "No"}
                      /> */}
                    </div>
                  </Column>
                </Row>
              </div>
            </Column>
          </Row>
          <Row className="pictureProgressZone">
            <Column className="matchHeight" col="" xs="" sm="" md="" lg="" xl="">
              <div className="component fullHeight">
                <div className="itemHeader">
                  <div className="itemHeaderTextZone">
                    <div className="itemHeaderText">
                      {CONTENT[7]}
                    </div>
                  </div>
                </div>
                <div className="pictures">
                  {
                    allowNewProggressImage && 
                      <div className="picture">
                        <EditableImage
                          images={[]}
                          disable={!allowNewProggressImage}
                          filePath={[
                            "USERS",
                            currentUser._id,
                            "PROGRESS_IMAGES"
                          ]}
                          onChangeImage={(newImageObj, callBack)=>{
                            if(!allowNewProggressImage) return;
                            var curProgressPictures = (currentUser.progressPictures ? currentUser.progressPictures.slice() : []);
                            var newDateTime = new Date().getTime();
                            curProgressPictures.push({
                              url: newImageObj.url,
                              createAt: `${newDateTime}`,
                              name: newImageObj.name,
                              type: newImageObj.type
                            });
                            console.log(`curProgressPictures`,"\n\n",curProgressPictures,"\n\n");
                                  
                            var variables = {
                              id: currentUser._id,
                              progressPictures: curProgressPictures.slice()
                            };
                            window.Client.mutate({ //API UPDATE
                              mutation: MUTATION_USER_EDIT,
                              variables: variables
                            })
                            .then((obj)=>{
                              window.ForceUserReload();
                              this.setState({hideNewImage: true}, ()=>{
                                this.setState({hideNewImage: false})
                              });
                              //callBack();
                            })
                            .catch((error)=>{
                              console.log("error saving image to blog");
                              console.log(error,"\n\n", newImageObj);  
                            });
                            
                          }}
                          showDeleteButton={true}
                        />
                      </div>
                  }
                  {
                    curProgressPictures.slice().reverse().map((progressImageURL, index)=>{
                      var imageDate = window.GlobalUtil.dateBasic(progressImageURL.createAt, "DAY");
                      return(
                        <div key={index} className="picture text-center">
                          <div className="img"><img src={progressImageURL.url} alt="" /></div>
                          <div className="date">Date: {imageDate}</div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </Column>
          </Row>
          <Row className="shippingZone">
            <Column className="matchHeight" col="" xs="" sm="" md="" lg="" xl="">
              <div className="component fullHeight">
                <div className="itemHeader">
                  <div className="itemHeaderTextZone">
                    <div className="itemHeaderText withIcon">
                      <div className="text">{CONTENT[8]}</div>
                      <EditShipping
                        onUpdateUser={this.props.onUpdateUser}
                        CONTENT={window.GlobalUtil.dangerSetGlobal(CONTENTS_EDIT_SHIPPING[window.Session.Language])}
                        curObj={{id: currentUser._id, address: {...address}}}
                      /> 
                    </div>
                  </div>
                </div>
                <div className="sectionValueZone">
                  <SectionValue
                    section={CONTENT[9]}
                    value={address.street}
                  />
                  <SectionValue
                    section={CONTENT[10]}
                    value={address.street2}
                  />
                  <SectionValue
                    section={CONTENT[11]}
                    value={address.city}
                  />
                  <SectionValue
                    section={CONTENT[12]}
                    value={address.state}
                  />
                  <SectionValue
                    section={CONTENT[13]}
                    value={address.zipcode}
                  />
                  <SectionValue
                    section={CONTENT[14]}
                    value={address.country}
                  />
                </div>
              </div>
            </Column>
          </Row>

          <Row className="mySettingsZone">
            <Column className="matchHeight" col="" xs="" sm="" md="" lg="" xl="">
              <div className="component fullHeight">
                <div className="itemHeader">
                  <div className="itemHeaderTextZone">
                    <div className="itemHeaderText withIcon">
                      {CONTENT[15]}
                    </div>
                  </div>
                </div>
                <Row className="">
                  <Column className="" col="" xs="" sm="" md="" lg="" xl="">
                    <div className="">
                      <SectionValue
                        section={""}
                        value={
                          <a className="valueLink" 
                            style={{"textDecoration": "none", "display": "block"}}
                            href="#"
                            onClick={(e)=>{
                              e.preventDefault();
                              settings.sendOffersEmail = !settings.sendOffersEmail;
                              this.changeUserSettings(settings);
                            }}><i className={`fas fa-${settings.sendOffersEmail ? "check-" : ""}square`} style={{"paddingRight": "10px"}}></i> {CONTENT[16]}</a>
                        }
                      />
                      <SectionValue
                        section={""}
                        value={
                          <a className="valueLink" 
                            style={{"textDecoration": "none", "display": "block"}}
                            href="#"
                            onClick={(e)=>{
                              e.preventDefault();
                              if (window.confirm("Are you sure? ALL YOUR INFOMRATION WILL BE GONE.") == true) {
                                this.onDeactivateUser();
                              } else {
                                console.log("You canceled!");
                              }
                            }}>
                              Delete my Account
                            </a>
                        }
                      />
                    </div>
                  </Column>
                </Row>
              </div>
            </Column>
          </Row>
        </Container>
      </div>
    );
  }
}


export default Index;
      
