import React from 'react';
import User from 'user/normal/index';
import Doctor from 'user/doctor/index';
import Lab from 'user/lab/index';
import Admin from 'admin/index';
import Loading from 'global/components/Loading/index';
import {
    BrowserRouter as Router, StaticRouter, Route, Link, Redirect, Switch
    // etc.
} from 'react-router-dom';
import { gql, useQuery, useMutation } from '@apollo/client';
import {GetFullUserInfo} from 'global/utils/helper-functions';





class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSideMenu: false,
      loading: true
    };    
    this.ClientEventKey = window.GlobalUtil.subscribeEventOnce("ClientEvent", readyToGo=>{
      GetFullUserInfo({
        noCache: true,
        callBackSuccess: this.isLoggedIn,
        callBackFailed: this.redirectPage,
      });
    });
    if(window.Session.user && !window.Session.user.treatmentPlan) window.Session.user.treatmentPlan = {};
  }

  componentDidMount(){
    if(window.Client) GetFullUserInfo({
        noCache: true,
        callBackSuccess: this.isLoggedIn,
        callBackFailed: this.redirectPage
      });
    this.checkLoggedIn();
  }

  componentWillUnmount(){
    if(this.ClientEventKey) this.ClientEventKey.unsubscribe();
  }
      
  redirectPage = ()=>{
    if(navigator.userAgent === 'ReactSnap'){ //IF REACT SNAP THEN DON'T CHECK LOGGED IN
      this.isLoggedIn();
      return true;
    }  
    window.GlobalUtil.consoleLog("BackendWrapper redirectPage Logout", []);
    this.setState({loading: true});
    window.Router.history.push('/login');
  }
    
  checkLoggedIn = ()=>{      
    if(navigator.userAgent === 'ReactSnap'){ //IF REACT SNAP THEN DON'T CHECK LOGGED IN
      this.isLoggedIn();
      return true;
    }    
    // this.redirectTimout = setTimeout(()=>{ //REDIRECT AFTER 6 SECONDS UNLESS THEY CLEAR THIS TIMEOUT
    //   var isLoggedInNow = window.GlobalUtil.getGlobalVariable("isLoggedIn", false);
    //   if(!isLoggedInNow) this.redirectPage();
    // }, (window.GlobalVar.NumberOfDBConnectRetrys*window.GlobalVar.TimeBTDBConnectRetrys));
  }


  isLoggedIn = () => {
    clearTimeout(this.redirectTimout);
    this.setState({loading: false});
  }



  render(){
    var {showSideMenu, loading} = this.state;     
    //return null;
    if(loading) return (
      <Loading
        header={undefined}
        body={undefined}
        floating={true}
        overlay={true}
        inlineStyles={{
          loadingIcon: {
            "maxWidth": "250px"
          }
        }}
      />
    )
    return (
      <Switch>
        <Route path={["/USER", "/user"]} render={props=><User {...this.props}/>} />  
        <Route path={["/LAB", "/lab"]} render={props=><Lab {...this.props}/>} />  
        <Route path={["/doctor"]} render={props=><Doctor {...this.props}/>} />
        <Route path={["/ADMIN", "/admin"]} render={props=><Admin {...this.props}/>} />
      </Switch>
    );
  }
}


export default Index;
      
