const Pages = (wind)=>[
  {
    "PageName":"Inicio",
    "Name":"Inicio",
    "Paths": ["/","/inicio"],
    "Menu": ["SiteMap", "EspHeader", "EspMobile"]
  },

  {
    "PageName":"Comencemos",
    "Name":"Comencemos",
    "Paths": ["/comencemos"],
    "Menu": ["SiteMap", "EspHeader", "EspMobile"]
  },
  
  {
    "PageName":"Restablecer la Contraseña",
    "Name":"RestablecerContra",
    "Paths": ["/restablecerlacontrasena/:token", "/restablecerlacontrasena"],
    "Menu": []//["SiteMap", "Header", "Mobile"]
  },

  {
    "PageName":"Mejores Alineadores",
    "Name":"MejoresAlineadores",
    "Paths": ["/mejoresalineadores"],
    "Menu": ["SiteMap", "EspHeader", "EspMobile"]
  },
  

  {
    "PageName":"Precios",
    "Name":"Precios",
    "Paths": ["/precios"],
    "Menu": ["SiteMap", "EspHeader", "EspMobile"]
  },

  {
    "PageName":"Compra Kit",
    "Name":"CompraKit",
    "Paths": ["/comprakit"],
    "Menu": ["SiteMap"]
  },


  // {
  //   "PageName":"Testimonials",
  //   "Name":"Testimonials",
  //   "Paths": ["/testimonials"],
  //   "Menu": ["None", "Header", "Mobile"]
  // },
  // {
  //   "PageName":"Testimonios",
  //   "Name":"Testimonios",
  //   "Paths": ["/testimonios"],
  //   "Menu": ["None", "EspHeader", "EspMobile"]
  // },




  // {
  //   "PageName":"Store",
  //   "Name":"Store",
  //   "Paths": ["/store"],
  //   "Menu": ["None"]
  // },
  {
    "PageName":"Tienda",
    "Name":"Tienda",
    "Paths": ["/tienda"],
    "Menu": [""]
  },



  // {
  //   "PageName":"Blog",
  //   "Name":"Blog",
  //   "Paths": ["/blog"],
  //   "Menu": ["None"]
  // },
  // {
  //   "PageName":"El Blog",
  //   "Name":"ElBlog",
  //   "Paths": ["/elblog"],
  //   "Menu": ["None"]
  // },




  {
    "PageName":"Acceso",
    "Name":"Acceso",
    "Paths": ["/acceso"],
    "Menu": ["EspMobile"]
  },



  {
    "PageName":"Confirmar Email",
    "Name":"ConfirmarEmail",
    "Paths": ["/confirmaremail"],
    "Menu": ["None"]
  },




  {
    "PageName":"EmailUnsubscribe",
    "Name":"EmailUnsubscribe",
    "Paths": ["/emailUnsubscribe"],
    "Menu": ["None"]
  },
  {
    "PageName":"RedirectPage",
    "Name":"RedirectPage",
    "Paths": ["/rd"],
    "Menu": ["None"]
  },
  // {
  //   "PageName":"SimpleSignup",
  //   "Name":"SimpleSignup",
  //   "Paths": ["/SimpleSignup"],
  //   "Menu": ["None"]
  // },
  {
    "PageName":"Checkout",
    "Name":"Checkout",
    "Paths": ["/checkout"],
    "Menu": ["None"]
  },


  
  
  {
    "PageName":"Kit De Impresion",
    "Name": "KitDeImpresion",
    "Paths": ["/kitDeImpresion"],
    "Menu": ["SiteMap", "EspFooter2", "EspMobile"]
  },




 
  {
    "PageName":"PMF",
    "Name":"PMF",
    "Paths": ["/pmf"],
    "Menu": ["SiteMap", "EspFooter1", "EspMobile"]
  },




  {
    "PageName":"Nosotros",
    "Name":"SobreNosotros",
    "Paths": ["/sobrenosotros"],
    "Menu": ["SiteMap", "EspFooter1", "EspMobile"]
  },


  {
    "PageName":"Contacto",
    "Name":"Contacto",
    "Paths": ["/contacto"],
    "Menu": ["SiteMap", "EspFooter1", "EspMobile"]
  },



  {
    "PageName":"Consentimiento",
    "Name":"Consentimiento",
    "Paths": ["/consentimiento"],
    "Menu": ["SiteMap", "EspFooter2", "EspMobile"]
  },



  {
    "PageName":"Política de Privacidad",
    "Name":"PolíticaDePrivacidad",
    "Paths": ["/privacidad"],
    "Menu": ["SiteMap", "EspFooter2", "EspMobile"]
  },




  {
    "PageName":"Condiciones de Uso",
    "Name":"CondicionesDeUso",
    "Paths": ["/condicionesdeuso"],
    "Menu": ["SiteMap", "EspFooter2", "EspMobile"]
  },



  {
    "PageName":"Garantias",
    "Name":"Garantias",
    "Paths": ["/garantias"],
    "Menu": ["SiteMap", "EspFooter2", "EspMobile"]
  },


  {
    "PageName":"Sorteo",
    "Name":"Alineadores",
    "Paths": ["/regalos"],
    "Menu":  [""]//["SiteMap", "EspFooter1", "EspHeader", "EspMobile"]
  },


  {
    "PageName":"Promocion",
    "Name":"Promocion",
    "Paths": ["/promocion"],
    "Menu":  ["EspHeader", "EspFooter1"]
  },

  {
    "PageName":"PromocionReportes",
    "Name":"PromocionReportes",
    "Paths": ["/promocionReportes"],
    "Menu":  []
  },

  // {
  //   "PageName": "Ganadores",
  //   "Name": "Ganadores",
  //   "Paths": ["/ganadores"],
  //   "Menu":  ["SiteMap", "EspFooter1", "EspMobile"]
  // }
]


export default Pages;