import React from 'react';
import Nav from 'user/normal/components/Nav/index';
//import LogoIcon from 'global/img/logo_icon.svg';
import {NavLink, Link} from 'react-router-dom';
import LogoDark from 'global/img/logo-dark.svg';
const {Container, Row, Column} = window.LayoutBuilder;
const Component = (props) => {
  var today = new Date();
  // var currentUser = (window.Session.user ? window.GlobalUtil.stripOutFromObj(window.Session.user, "__typename") : {});
  // var {userPicture, firstName, lastName} = currentUser;
  // var curUserImage = window.GlobalUtil.deepGetFromString(userPicture, "0,url", LogoIcon);
  return (
    <div id="NavMenu">     
      <NavLink className="siteLogo" to={"/user"}>
        <img src={LogoDark} alt=""/>
      </NavLink>
    	<Nav MenuToShow={(window.Session.Language === "ENGLISH") ? "Header" : "HeaderESP"} />
{/*      <div className="curUser">
        <NavLink className={"welcomeText"} onClick={()=>{}} to={"/user/profile"}>
          {firstName}
        </NavLink>
        <div className="userIcon">
          <NavLink onClick={()=>{}} to={"/user/profile"}>
            <img src={curUserImage} alt=""/>
          </NavLink>
        </div> 
      </div>*/}
    	<div className="menu otherButtons">
    		<div className="menu-item">
          <a className={"nav-link"}  onClick={()=>window.GlobalUtil.logout(true)}>
            <div className="inline-nav-link">
              <div className="icon"><i className="fas fa-sign-out-alt"></i></div>
              <div className="text">Logout</div>
            </div>
          </a>
        </div>
    	</div>
    </div>
  );
}

export default Component;