import React from 'react';
import TagManager from 'react-gtm-module'; //GOOGLE GTM MODULE
class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };   
  }

  componentDidMount(){
    this.onPageLoad();
    if(this.didMount) this.didMount()
  }

  componentWillUnmount(){
    if(this.willUnmount) this.willUnmount()
  }
  
  onPageLoad = ()=>{
    var page = this.props.pageInfo;
    //trafficSrcCookie.getCookie();   
    
    if(navigator.userAgent === 'ReactSnap') return null;
    if(process.env.NODE_ENV !== 'development'){
      const tagManagerArgs = {
        //gtmId: (process.env.NODE_ENV !== 'development') ? process.env.REACT_APP_GMT_ID_DEV : process.env.REACT_APP_GMT_ID,
        dataLayer: {
          userId: '001',
          userProject: 'project',
          language: window.Session.Language,
          page: page.Name
        },
        dataLayerName: 'PageDataLayer'
      }
      TagManager.dataLayer(tagManagerArgs)
    } else {
      return null;
      //console.log(`page`,"\n\n",page,"\n\n");   
      //console.log(`TagManager`,"\n\n",TagManager,"\n\n");   
    }
  }
}


export default Index;
      
