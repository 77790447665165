import React from 'react';
import API_CALLS from 'API_CALLS/index';
import TemplateListBasic from 'global/templates/templateListBasic/index';
//import "styles/scss/lab/components/invoiceList.scss";
import ViewTreatmentPlanRequest from 'user/lab/components/TPFullList/ViewTreatmentPlanRequest/index';
import ESPAÑOL from "./ESPAÑOL";
import ENGLISH from "./ENGLISH";
const CONTENTS = {
  ESPAÑOL,
  ENGLISH
}
const {Table} = window.LayoutBuilder;



class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hideList: false,
      variables: {
        "status": "NOTINACTIVE",
        "sortBy": this.props.field,//"meta.createAt",
        "descending": false,
        "limit": 20,
        "offset": 0,
        "dates":[{
          field: this.props.field,
          gte: `${Date.now()}`
        }],
        "manual": [
          {
            path: "LABORATORY",
            val: window.Session.user._id
          }
        ],
        "include": [
          {
            name: "user"
          }
        ]
      }
    }; 
    var today = new Date();
    this.state.variables.dates[0].gte = `${today.getStartOfMonth().getTime()}`; //START OF MONTH STRING
          
    //window.CurRefetchList
    // window.APIListGetAgain = ()=>{
    //   this.setState({hideList: true}, ()=>{
    //     this.setState({hideList: false})
    //   })
    // };
  }

  componentDidMount(){
  }

  componentWillUnmount(){
  }

  onUpdateFilter = (newVariables)=>{
    this.setState({
      variables: newVariables,
    }, ()=>window[this.props.windowField](true))
  }

  render(){
    var {hideList, variables} = this.state;
    var {TPList=[]} = this.props;
    const CONTENT = window.GlobalUtil.dangerSetGlobal(CONTENTS[window.Session.Language]); 
    return(
      <div className="orderList">
        <div className="template1">
          <TemplateListBasic 
            CurRefetchListVar={this.props.windowField}
            QUERY={API_CALLS.TREATMENT_PLANS.QUERIES.allAndCountListFull()}
            VARIABLES={variables}
            dataField="treatmentPlans"
            onUpdateFilter={this.onUpdateFilter}
            CreateList={(data)=>{
              return(
                <div className="">
                  <OfferListFull
                    CONTENT={CONTENT}
                    fullList={data.treatmentPlans}
                    reloadList={()=>{
                      //window[this.props.windowField](true)
                    }}
                  />
                </div>
              )
            }}
            //changeSort={this.onChangeSort}
          /> 
        </div>
      </div>
    )
  }
}



function OfferListFull(props) {     
  var {fullList=[], CONTENT=[]} = props;
  return (
    <Table
      className="table"
      thead={{
        className:"thead-dark",
        tr: {
          th: [
            {
              //style: {"width": "210px"},
              value: (
                <div className="headerColWrapper ">
                  <div className="headerColText">
                    <div className="text">{CONTENT[0]}</div>
                  </div>
                </div>
              )
            },
            {
              style: {"width": "210px"},
              value: (
                <div className="headerColWrapper ">
                  <div className="headerColText">
                    <div className="text">{CONTENT[1]}</div>
                  </div>
                </div>
              )
            },
            {
              style: {"width": "121px"},
              value: (
                <div className="headerColWrapper ">
                  <div className="headerColText">
                    <div className="text">{CONTENT[2]}</div>
                  </div>
                </div>
              )
            },
            {
              style: {"width": "50px"},
              className: "text-center buttonCategory",
              value: CONTENT[3]
            }
          ]
        }
      }}
      tbody={{
        tr: (
          fullList.length
          ? fullList.map((treatmentPlan, index)=>{
              var completed = new Date(Number(treatmentPlan.HISTORY.LABORATORY.UPLOADED_OFFER));
              return(
                {
                  className: "animated fadeIn",
                  th: [
                    {
                      value: (
                        <div className="rowText">
                          <div className="">{treatmentPlan.user.firstName} {treatmentPlan.user.lastName}</div>
                        </div>
                      )
                    },
                    {
                      value: (
                        <div className="rowText">
                          <div className="value">{treatmentPlan._id}</div>
                        </div>
                      )
                    },
                    {
                      value: (
                        <div className="rowText">
                          <div className="value">{completed.formatDate("n/d/y")}</div>
                        </div>
                      )
                    },
                    {
                      value: (
                        <div className="text-center buttonColumn">
                          <div className="rowText">
                            <ViewTreatmentPlanRequest 
                              curTreatmentPlan={{...treatmentPlan}}
                              curUser={{
                                ...treatmentPlan.user,
                                TPID: treatmentPlan._id
                              }}
                            />
                          </div>
                        </div>
                      )
                    }
                  ] 
                }
              )
            })
          : {
              className: "animated fadeIn",
              th: [
                {
                  value: (
                    <div className="rowText">
                      <div className="">{CONTENT[4]}</div>
                    </div>
                  )
                }
              ] 
            }
        )
      }}
    />
  );
}


export default Index;
