import React from 'react';
import API_CALLS from 'API_CALLS/index';
import TemplatePage from "global/templates/templatePage/index";
import TemplatePreview from "global/templates/templatePreview/index";
import EmailSender from 'admin/components/EmailSender/index';
import { useQuery } from '@apollo/client';
import ActionShipProduct from "./actions/ActionShipProduct";
import DialogProgressLogs from 'admin/components/DialogProgressLogs/index';
import SendKit from "./actions/SendKit";
import SendAligners from "./actions/SendAligners";
import SendProduct from "./actions/SendProduct";


const QUERY = API_CALLS.ORDER_ITEMS.QUERIES.allAndCount();
const QUERY_SINGLE = API_CALLS.ORDER_ITEMS.QUERIES.single();
const QUERY_COUNT = API_CALLS.ORDER_ITEMS.QUERIES.count();
const MUTATION_EDIT = API_CALLS.ORDER_ITEMS.MUTATIONS.update();
const MUTATION_ADD = API_CALLS.ORDER_ITEMS.MUTATIONS.create();
const MUTATION_SHIPPED = API_CALLS.ORDER_ITEMS.MUTATIONS.shipped();
const FORM_FIELDS = API_CALLS.ORDER_ITEMS.FORM_FIELDS;
const QUERY_GET_USERS = API_CALLS.USERS.QUERIES.all(`email`);

const LIST_HEADER_FIELDS = API_CALLS.ORDER_ITEMS.LIST_HEADER_FIELDS;
const PREVIEW = API_CALLS.ORDER_ITEMS.PREVIEW;

const API_CALLS_FULL = {
  QUERY,
  QUERY_SINGLE,
  QUERY_COUNT,
  MUTATION_EDIT,
  MUTATION_ADD,
  FORM_FIELDS,
  QUERY_GET_USERS, 
  MUTATION_SHIPPED,
  PREVIEW
};




const ValidateObject = (newObject, onSuccess, onFail)=>{
  if(newObject._id && !newObject.id) newObject.id = newObject._id;      

        
  Object.keys(newObject).map((key, index)=>{
    if(!newObject[key]) delete newObject[key];
  })  
  onSuccess(newObject);
  //onFail();      
};






function SendEmail(props) {     
  var {UID, OID, whatEmail} = props; 
  const { loading, error, data, refetch } = useQuery(API_CALLS.USERS.QUERIES.single("email preferredLanguage"), {
    variables: {id: UID},
    //fetchPolicy: "no-cache"
  });
  if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;        
  //console.log(`data`,"\n\n",data,"\n\n");
  return (
    <EmailSender
      asButton={true}
      className={`button button1 btn-color4 iconButton`}
      //customLinkText={<span><i className="fas fa-paper-plane"></i> Send Approve Email</span>}
      curObj={{
        whatEmail: whatEmail,
        emailData: {
          whoToEmail: data.user.email,
          whoToCC: "",
          language: (data.user.preferredLanguage ? data.user.preferredLanguage : "ENGLISH"),
          OID: OID,
          UID: UID,
        }
      }}
    />
  );
}


const Preview = (props)=>{        
  var {curObj} = props;       
  function APIReshipOrderItem(curObj, callBack=()=>{}){
    var inputObj = {
      mutation: API_CALLS.ORDER_ITEMS.MUTATIONS.reshipOrderItem(),
      variables: {id: curObj._id}
    }
    window.Client.mutate(inputObj)
    .then((obj) => {
      var {data, loading} = obj;
      window.CurRefetchList(true);
      callBack();
    })
    .catch((error)=>{
      console.log("Failed APIReshipOrderItem", error);
      window.alert("Reship Failed try again later");
      callBack();
    });
  }
        
  return(
    <TemplatePreview
      title="Preview Order Item"
      onClose={props.onClose}
      onSave={props.onSave}
      customButtons={(curRef)=>{
        return(
          <React.Fragment>
            <button className="button button2 btn-color6" onClick={()=>{
              if(curRef) curRef.onClose()
            }}>Close</button>
            <div className="buttonGroup">
              {
                ((curObj.name === "IMPRESSION_KIT") || (curObj.name === "PRODUCT")) && 
                <button className="button button2 btn-color5" onClick={()=>{
                  let text = "Are you sure you want to create this order to be shipped AGAIN to this user?";
                  if (window.confirm(text) == true) {
                    console.log("You pressed OK!");
                    APIReshipOrderItem(curObj, ()=>{
                      if(curRef) curRef.onClose();
                      else window.GlobalUtil.DialogStack.closeTop();
                    });
                  } else {
                    console.log("You canceled!");
                  }
                }}>Ship Again</button>
              }
              <DialogProgressLogs
                ID={curObj._id}
                API_NAME={'ORDER_ITEMS'}
                FIELD={'orderItem'}
                asButton={true}
                className={'button button1 btn-color4'}
                style={{"width":"100px","minWidth":"0"}}
                customLinkText={<span><span>Logs</span>&nbsp;&nbsp;<i className="fas fa-clipboard-list"/></span>}
              />
            </div>
          </React.Fragment>
        )
      }}>
      <PREVIEW
        VARIABLES={{
          id: curObj._id
        }}
      />
    </TemplatePreview>
  )
}


const LIST_HEADER_FIELDS_CUSTOM = (headerText="", whatToFind="") => [
  {
    type: "CUSTOM",
    minWidth: '0',
    width: '0',
    path: headerText,
    name: headerText,
    CUSTOM: val=>{            
      //var sentEmail
      //console.log(`val`,"\n\n",val,"\n\n");
      if(!val.progressLogs || (val.progressLogs.length < 1)) return(<div className="">False</div>)
      var emailSent = val.progressLogs.filter(obj=>obj.category === whatToFind);
      return(<div className="text-center"><strong>{emailSent[0] ? "TRUE" : "FALSE"}</strong></div>)
    }
  }
];



const DIC_FOR_EMAILS = {
  //"TREATMENT_PLAN": "SENT_IMPRESSION_KIT",
  "IMPRESSION_KIT": "SENT_IMPRESSION_KIT",
  "PRODUCT": "SENT_PRODUCTS",
  "ALIGNERS": "SENT_ALIGNERS",
};

const PAGES = () => [
  {
    "id": "SHIPPINGS01",
    "Name":"SendKit",
    "PageName": "Send Kit",
    "Paths": ["/admin/shipping/sendKit"],
    "GlobalStateVar": ["OrderItem,IMPRESSION_KIT"],
    "TITLE": "Send Impression Kit",
    "DATA_FIELD": "orderItems",
    "ActionSlideIn": SendKit,
    "LIST_HEADER_FIELDS": LIST_HEADER_FIELDS,
    "API_CALLS": API_CALLS_FULL,
    "VARIABLES": {
      "status": "NOTINACTIVE",
      "sortBy": "meta.createAt",
      "descending": false,
      "limit": 20,
      "offset": 0,
      "manual": [
        {
          path: "name",
          val: "IMPRESSION_KIT"
        },
        {
          path: "shippedAt",
          val: ""
        }
      ]
    },
    "BUTTONS": [
      {
        buttonText: "Ship",
        category: "Ship",
        className: "btn-color1",
        iconClass: "fas fa-truck",
      }
    ]
  },
  {
    "id": "SHIPPINGS02",
    "Name":"SendImpression",
    "PageName": "Send Aligners",
    "Paths": ["/admin/shipping/sendAligners"],
    "GlobalStateVar": ["OrderItem,ALIGNERS"],
    "TITLE": "Send Aligners",
    "DATA_FIELD": "orderItems",
    "ActionSlideIn": SendAligners,
    "LIST_HEADER_FIELDS": LIST_HEADER_FIELDS,
    "API_CALLS": API_CALLS_FULL,
    "VARIABLES": {
      "status": "NOTINACTIVE",
      "sortBy": "meta.createAt",
      "descending": false,
      "limit": 20,
      "offset": 0,
      "manual": [
        {
          path: "name",
          val: "ALIGNERS"
        },
        {
          path: "shippedAt",
          val: ""
        }
      ]
    },
    "BUTTONS": [
      {
        buttonText: "Ship",
        category: "Ship",
        className: "btn-color2",
        iconClass: "fas fa-truck",
      }
    ]
  },
  {
    "id": "SHIPPINGS03",
    "Name":"SendProduct",
    "PageName": "Send Product",
    "Paths": ["/admin/shipping/sendProduct"],
    "GlobalStateVar": ["OrderItem,PRODUCT"],
    "TITLE": "Send Product",
    "DATA_FIELD": "orderItems",
    "ActionSlideIn": SendProduct,
    "LIST_HEADER_FIELDS": LIST_HEADER_FIELDS,
    "API_CALLS": API_CALLS_FULL,
    "VARIABLES": {
      "status": "NOTINACTIVE",
      "sortBy": "meta.createAt",
      "descending": false,
      "limit": 20,
      "offset": 0,
      "manual": [
        {
          path: "name",
          val: "PRODUCT"
        },
        {
          path: "shippedAt",
          val: ""
        }
      ]
    },
    "BUTTONS": [
      {
        buttonText: "Ship",
        category: "Ship",
        className: "btn-color4",
        iconClass: "fas fa-truck",
      }
    ]
  },
  // {
  //   "Name":"Pending",
  //   "PageName": "Pending",
  //   "Paths": ["/admin/shipping/pending"],
  //   "TITLE": "Pending Products",
  //   "DATA_FIELD": "orderItems",
  //   "ActionSlideIn": ActionShipProduct,
  //   "API_CALLS": API_CALLS_FULL,
  //   "LIST_HEADER_FIELDS": LIST_HEADER_FIELDS,
  //   "VARIABLES": {
  //     "status": "NOTINACTIVE",
  //     "sortBy": "meta.createAt",
  //     "descending": false,
  //     "limit": 20,
  //     "offset": 0
  //   },
  //   "BUTTONS": [
  //     {
  //       buttonText: "Ship",
  //       category: "Ship",
  //       className: "btn-color2",
  //       iconClass: "fas fa-truck",
  //     }
  //   ]
  // },
  {
    "Name":"History",
    "PageName": "History",
    "Paths": ["/admin/shipping/history"],
    "TITLE": "Product History",
    "DATA_FIELD": "orderItems",
    "ActionSlideIn": Preview,
    "API_CALLS": API_CALLS_FULL,
    "LIST_HEADER_FIELDS": [...LIST_HEADER_FIELDS, ...LIST_HEADER_FIELDS_CUSTOM("Sent (EMAIL_SHIPPED)?", "EMAIL_USER")],
    "VARIABLES": {
      "status": "NOTINACTIVE",
      "sortBy": "meta.createAt",
      "descending": false,
      "limit": 20,
      "offset": 0
    },
    "NEWBUTTON": {
      NAME: "ORDER_ITEMS",
      TITLE: "OrderItem",
      FIELD: "orderItem"
    },
    "EDITBUTTON": {
      NAME: "ORDER_ITEMS",
      TITLE: "OrderItem",
      FIELD: "orderItem"
    },
    "DELETEBUTTON": {
      NAME: "ORDER_ITEMS"
    },
    "BUTTONS": [
      {
        width: "0px",
        category: "Email",
        CUSTOMBUTTON: true,
        CUSTOM: (item)=>{
          if(!DIC_FOR_EMAILS[item.name]) return <button className="button button1 btn-color4 iconButton disabled"><i className="fas fa-paper-plane"/></button>;
          return <SendEmail item={item} UID={item.UID} OID={item.OID} whatEmail={DIC_FOR_EMAILS[item.name]}/>;
        }
      },
      {
        width: "110px",
        category: "Preview",
        buttonText: "View",
        className: "btn-color3",
        iconClass: "fas fa-eye",
      }
      // {
      //   width: "110px",
      //   category: "Preview",
      //   buttonText: "View",
      //   className: "btn-color3",
      //   iconClass: "fas fa-eye",
      //   onClick: (obj)=>{
      //     window.GlobalUtil.triggerEvent("TemplateDBEvent", {
      //       "TYPE": "PREVIEW",
      //       "BUTTON_TITLE": "Order",
      //       "TITLE": "Order", //TITLE OF SLIDE OUT
      //       "APINAME": "ORDERS",
      //       "NAME": "Order",
      //       "ID": obj._id
      //     }); 
      //   }
      // }
    ],
    SHOWSELECTBOXES:true
  }
];







const Index = (props) => {
  //var {data} = props;
  var today = new Date();
  return (
     <TemplatePage
      ID={"AdminProducts"}
      REPORTS={
        [
          {
            QUERY: QUERY_COUNT,
            VARIABLES: {
              "status": "NOTINACTIVE",
              "dates": [{
                field: "meta.createAt",
                gte: `${today.getStartOfDay().getTime()}`
              }]
            },
            NAME: "orderItemCount",
            TITLE: "New Orders Today"
          },
          {
            QUERY: QUERY_COUNT,
            VARIABLES: {
              "status": "NOTINACTIVE",
              "dates": [{
                field: "meta.createAt",
                gte: `${today.getStartOfWeek().getTime()}`
              }]
            },
            NAME: "orderItemCount",
            TITLE: "Products this Week"
          },
          {
            QUERY: QUERY_COUNT,
            VARIABLES: {
              "status": "NOTINACTIVE",
              "dates": [{
                field: "meta.createAt",
                gte: `${today.getStartOfMonth().getTime()}`
              }]
            },
            NAME: "orderItemCount",
            TITLE: "Products this Month"
          },
          {
            QUERY: QUERY_COUNT,
            VARIABLES: {
              "status": "NOTINACTIVE",
              "dates": [{
                field: "meta.createAt",
                gte: `${today.getStartOfYear().getTime()}`
              }]
            },
            NAME: "orderItemCount",
            TITLE: "Products this Year"
          }
        ]
      }
      REDIRECT={{
        "path": "/admin/shipping",
        "to": "/admin/shipping/sendKit"
      }}
      PAGES={PAGES()}
      ValidateObject={ValidateObject}
    />
  );
}





export default Index;

export {PAGES};
      

      
