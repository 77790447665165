import React from 'react';
import API_CALLS from 'API_CALLS/index';
import TemplatePage from "global/templates/templatePage/index";
import TemplatePreview from "global/templates/templatePreview/index";
import DialogProgressLogs from 'admin/components/DialogProgressLogs/index';
import ActionMakeAligners from "./actions/ActionMakeAligners";
import UploadAligners from "./actions/UploadAligners";
import ScheduleAligners from "./actions/ScheduleAligners";




const QUERY = API_CALLS.ALIGNER_GROUPS.QUERIES.allAndCount(`
  ID
  AIDS
  TPID
  shippedAt
  scheduledAt
  createdAt
  createdBy
  status
  step
  user{
    firstName
    lastName
    email
  }
  meta{
    createAt
    updateAt
  }
`);
const QUERYALL = API_CALLS.ALIGNER_GROUPS.QUERIES.all();
const QUERY_SINGLE = API_CALLS.ALIGNER_GROUPS.QUERIES.single();
const QUERY_COUNT = API_CALLS.ALIGNER_GROUPS.QUERIES.count();
const MUTATION_EDIT = API_CALLS.ALIGNER_GROUPS.MUTATIONS.update();
const MUTATION_ADD = API_CALLS.ALIGNER_GROUPS.MUTATIONS.create();
const QUERY_GET_USERS = API_CALLS.USERS.QUERIES.all(`email`);


const FORM_FIELDS = API_CALLS.ALIGNER_GROUPS.FORM_FIELDS;
const PREVIEW = API_CALLS.ALIGNER_GROUPS.PREVIEW;




const LIST_HEADER_FIELDS = [
  {
    "path": "treatmentPlan.user.firstName,treatmentPlan.user.lastName",
    "type": "VALUE",
    "name": "User Name"
  },
  {
    "path": "treatmentPlan.user.email",
    "type": "VALUE",
    "name": "Email"
  },
  {
    type: "ID",
    name: "ID/AGID",
    defaultValue: "",
    path: "ID",
    searchable: true
  },
  {
    type: "ID",
    name: "TPID",
    defaultValue: "",
    path: "TPID",
    searchable: true
  },
  {
    type: "VALUE",
    path: "treatmentPlan.UID",
    name: "UID",
    defaultValue: "",
    searchable: true
  },
  {
    type: "DATE",
    name: "scheduledAt",
    defaultValue: "",
    path: "scheduledAt"
  },
  {
    type: "DATE",
    name: "shippedAt",
    defaultValue: "NA",
    path: "shippedAt"
  },
  {
    type: "DATE",
    name: "createdAt",
    defaultValue: "NA",
    path: "createdAt"
  },
  {
    type: "VALUE",
    name: "createdBy",
    defaultValue: "NA",
    path: "createdBy"
  },
  {
    type: "VALUE",
    name: "Status",
    defaultValue: "",
    path: "status"
  }
];


const API_CALLS_FULL = {
  QUERY,
  QUERYALL,
  QUERY_SINGLE,
  QUERY_COUNT,
  MUTATION_EDIT,
  MUTATION_ADD,
  FORM_FIELDS,
  QUERY_GET_USERS
};




const Preview = (props)=>{        
  var {curObj} = props;
  function APIRecreateAligners(curObj, callBack=()=>{}){
    var inputObj = {
      mutation: API_CALLS.ALIGNER_GROUPS.MUTATIONS.recreateAligners(),
      variables: {id: curObj._id}
    }
    window.Client.mutate(inputObj)
    .then((obj) => {
      var {data, loading} = obj;
      window.CurRefetchList(true);
      callBack();
    })
    .catch((error)=>{
      console.log("Failed APIRecreateAligners", error);
      window.alert("Reship Failed try again later");
      callBack();
    });
  }


  return(
    <TemplatePreview
      title="Preview Aligner Group"
      onClose={props.onClose}
      onSave={props.onSave}
      customButtons={(curRef)=>{
        return(
          <React.Fragment>
            <button className="button button2 btn-color6" onClick={()=>{
              if(curRef) curRef.onClose()
            }}>Close</button>
            <div className="buttonGroup">
              <button className="button button2 btn-color5" onClick={()=>{
                let text = "Are you sure you want to create these aligners AGAIN?";
                if (window.confirm(text) == true) {
                  APIRecreateAligners(curObj, ()=>{
                    if(curRef) curRef.onClose();
                    else window.GlobalUtil.DialogStack.closeTop();
                  });
                } else {
                  console.log("You canceled!");
                }
              }}>Create Again</button>
              <DialogProgressLogs
                ID={curObj._id}
                API_NAME={'TREATMENT_PLANS'}
                FIELD={'treatmentPlan'}
                asButton={true}
                className={'button button1 btn-color4'}
                style={{"width":"100px","minWidth":"0"}}
                customLinkText={<span><span>Logs</span>&nbsp;&nbsp;<i className="fas fa-clipboard-list"/></span>}
              />
            </div>
          </React.Fragment>
        )
      }}>
      <PREVIEW
        VARIABLES={{
          id: curObj._id
        }}
      />
    </TemplatePreview>
  )
}






const ValidateObject = (newObject, onSuccess, onFail)=>{
  if(newObject._id && !newObject.id) newObject.id = newObject._id;      
  //console.log("newObject");
  //console.log(newObject,"\n\n");
        
  Object.keys(newObject).map((key, index)=>{
    if(!newObject[key]) delete newObject[key];
  })  
  onSuccess(newObject);
  //onFail();      
};





const PAGES = () => [
  {
    "id": "ALIGNER_GROUPS01",
    "Name": "Upload",
    "PageName": "Upload",
    "Paths": ["/admin/aligners/upload"],
    "GlobalStateVar": ["TreatmentPlan,UPLOAD_ALIGNERS"],
    "TITLE": "Aligners to Upload",
    "DATA_FIELD": "treatmentPlans",
    "ActionSlideIn": UploadAligners,
    "LIST_HEADER_FIELDS": API_CALLS.TREATMENT_PLANS.LIST_HEADER_FIELDS,
    "API_CALLS": {
      ...API_CALLS_FULL,
      QUERY: API_CALLS.TREATMENT_PLANS.QUERIES.allAndCountListFull()
    },
    "VARIABLES": {
      "status": "NOTINACTIVE",
      "sortBy": "meta.createAt",
      "descending": false,
      "limit": 20,
      "offset": 0,
      "manual": [
        {
          path: "step",
          val: "UPLOAD_ALIGNERS"
        }
      ],
      "include": [
        {
          name: "user"
        }
      ]
    },
    "BUTTONS": [
      {
        buttonText: "Upload",
        category: "Action",
        className: "btn-color1",
        iconClass: "fas fa-tooth",
      }
    ]
  },
  {
    "id": "ALIGNER_GROUPS02",
    "Name": "Schedule",
    "PageName": "Schedule",
    "Paths": ["/admin/aligners/schedule"],
    "GlobalStateVar": ["TreatmentPlan,SCHEDULE_ALIGNERS"],
    "TITLE": "Aligners to Schedule",
    "DATA_FIELD": "treatmentPlans",
    "ActionSlideIn": ScheduleAligners,
    "LIST_HEADER_FIELDS": API_CALLS.TREATMENT_PLANS.LIST_HEADER_FIELDS,
    "API_CALLS": {
      ...API_CALLS_FULL,
      QUERY: API_CALLS.TREATMENT_PLANS.QUERIES.allAndCountListFull()
    },
    "VARIABLES": {
      "status": "NOTINACTIVE",
      "sortBy": "meta.createAt",
      "descending": false,
      "limit": 20,
      "offset": 0,
      "manual": [
        {
          path: "step",
          val: "SCHEDULE_ALIGNERS"
        }
      ],
      "include": [
        {
          name: "user"
        }
      ]
    },
    "BUTTONS": [
      {
        buttonText: "Schedule",
        category: "Action",
        className: "btn-color4",
        iconClass: "fas fa-calendar-alt",
      }
    ]
  },
  {
    "id": "ALIGNER_GROUPS03",
    "Name": "Make",
    "PageName": "Make",
    "Paths": ["/admin/aligners/make"],
    "GlobalStateVar": ["AlignerGroup,MAKE"],
    "TITLE": "Aligners to Make",
    "DATA_FIELD": "alignerGroups",
    "ActionSlideIn": ActionMakeAligners,
    "LIST_HEADER_FIELDS": API_CALLS.ALIGNER_GROUPS.LIST_HEADER_FIELDS,
    "API_CALLS": {
      ...API_CALLS_FULL
    },
    "VARIABLES": {
      "status": "ACTIVE",
      "sortBy": "scheduledAt",
      "descending": true,
      "dates":[{
        field: "scheduledAt",
        lte: `${Date.now()}`
      }],
      "limit": 20,
      "offset": 0,
      "manual": [
        {
          path: "step",
          val: "MAKE"
        }
      ],
      "include": [
        {
          name: "user"
        }
      ]
    },
    "BUTTONS": [
      {
        buttonText: "Create",
        category: "Action",
        className: "btn-color2",
        iconClass: "fas fa-hammer",
      }
    ]
  },
  {
    "id": "ALIGNER_GROUPS04",
    "Name": "Pending",
    "PageName": "Pending",
    "Paths": ["/admin/aligners/pending"],
    //"GlobalStateVar": ["AlignerGroup,MAKE"],
    "TITLE": "Aligners Not Due Yet",
    "DATA_FIELD": "alignerGroups",
    "ActionSlideIn": ActionMakeAligners,
    "LIST_HEADER_FIELDS": API_CALLS.ALIGNER_GROUPS.LIST_HEADER_FIELDS,
    "API_CALLS": API_CALLS_FULL,
    "VARIABLES": {
      "status": "NOTINACTIVE",
      "sortBy": "scheduledAt",
      "descending": true,
      "dates":[{
        field: "scheduledAt",
        gte: `${Date.now()}`
      }],
      "limit": 20,
      "offset": 0,
      "include": [
        {
          name: "user"
        }
      ]
    },
    "BUTTONS": [
      {
        buttonText: "Create",
        category: "Action",
        className: "btn-color2",
        iconClass: "fas fa-hammer",
      }
    ]
  },
  {
    "id": "ALIGNER_GROUPS05",
    "Name":"Shipping",
    "PageName": "Shipping",
    "Paths": ["/admin/aligners/shipping"],
    //"GlobalStateVar": ["AlignerGroup,FINISHED"],
    "TITLE": "Aligners in Shipping",
    "DATA_FIELD": "alignerGroups",
    "ActionSlideIn": Preview,
    "API_CALLS": API_CALLS_FULL,
    "LIST_HEADER_FIELDS": API_CALLS.ALIGNER_GROUPS.LIST_HEADER_FIELDS,
    "VARIABLES": {
      "status": "NOTINACTIVE",
      "sortBy": "meta.createAt",
      "descending": false,
      "limit": 20,
      "offset": 0,
      "manual": [
        {
          path: "step",
          listValues: JSON.stringify(["SHIP"])
        }
      ],
      "include": [
        {
          name: "treatmentPlan",
          "include": [
            {
              name: "user"
            }
          ]
        }
      ]
    },
    // "NEWBUTTON": {
    //   NAME: "ALIGNER_GROUPS",
    //   TITLE: "Aligner Group",
    //   FIELD: "alignerGroup"
    // },
    "BUTTONS": [
      {       
        category: "Edit",
        width: "40px",
        buttonText: "",//"Edit",
        className: "button2 btn-color3",
        iconClass: "fas fa-edit",
        onClick: (obj)=>{
          window.GlobalUtil.triggerEvent("TemplateDBEvent", {
            NAME: "ALIGNER_GROUPS",
            TITLE: "Aligner Group",
            TYPE: "EDIT",
            ID: obj._id,
            FIELD: "alignerGroup"
          });
        }
      },
      {
        category: "Preview",
        buttonText: "View",
        className: "btn-color3",
        iconClass: "fas fa-eye",
      }
      // {
      //   width: "110px",
      //   category: "Preview",
      //   buttonText: "View",
      //   className: "btn-color3",
      //   iconClass: "fas fa-eye",
      //   onClick: (obj)=>{                
      //     window.GlobalUtil.triggerEvent("TemplateDBEvent", {
      //       "TYPE": "PREVIEW",
      //       "BUTTON_TITLE": "Aligner Group",
      //       "TITLE": "Aligner Group", //TITLE OF SLIDE OUT
      //       "APINAME": "ALIGNER_GROUPS",
      //       "NAME": "Aligner Group",
      //       "ID": obj._id
      //     }); 
      //   }
      // }
    ],
    SHOWSELECTBOXES:true
  },
  {
    "id": "ALIGNER_GROUPS06",
    "Name":"History",
    "PageName": "History",
    "Paths": ["/admin/aligners/history"],
    //"GlobalStateVar": ["AlignerGroup,FINISHED"],
    "TITLE": "Aligners History",
    "DATA_FIELD": "alignerGroups",
    "ActionSlideIn": Preview,
    "API_CALLS": API_CALLS_FULL,
    "LIST_HEADER_FIELDS": API_CALLS.ALIGNER_GROUPS.LIST_HEADER_FIELDS,
    "VARIABLES": {
      "status": "NOTINACTIVE",
      "sortBy": "meta.createAt",
      "descending": false,
      "limit": 20,
      "offset": 0,
      "manual": [
        {
          path: "step",
          listValues: JSON.stringify(["FINISHED"])
        }
      ],
      "include": [
        {
          name: "treatmentPlan",
          "include": [
            {
              name: "user"
            }
          ]
        }
      ]
    },
    // "NEWBUTTON": {
    //   NAME: "ALIGNER_GROUPS",
    //   TITLE: "Aligner Group",
    //   FIELD: "alignerGroup"
    // },
    "BUTTONS": [
      {       
        category: "Edit",
        width: "40px",
        buttonText: "",//"Edit",
        className: "button2 btn-color3",
        iconClass: "fas fa-edit",
        onClick: (curObj)=>{
          window.GlobalUtil.triggerEvent("TemplateDBEvent", {
            NAME: "ALIGNER_GROUPS",
            TITLE: "Aligner",
            TYPE: "EDIT",
            ID: curObj._id,
            FIELD: "alignerGroup"
          });
        }
      },
      {
        category: "Preview",
        buttonText: "View",
        className: "btn-color3",
        iconClass: "fas fa-eye",
      }
      // {
      //   width: "110px",
      //   category: "Preview",
      //   buttonText: "View",
      //   className: "btn-color3",
      //   iconClass: "fas fa-eye",
      //   onClick: (obj)=>{
      //     window.GlobalUtil.triggerEvent("TemplateDBEvent", {
      //       "TYPE": "PREVIEW",
      //       "BUTTON_TITLE": "Aligner Group",
      //       "TITLE": "Aligner Group", //TITLE OF SLIDE OUT
      //       "APINAME": "ALIGNER_GROUPS",
      //       "NAME": "Aligner Group",
      //       "ID": obj._id
      //     }); 
      //   }
      // }
    ],
    SHOWSELECTBOXES:true
  }
];






const Index = (props) => {
  //var {data} = props;
  var today = new Date();
  return (
     <TemplatePage
      ID={"AdminAligners"}
      REPORTS={
        [
          {
            QUERY: QUERY_COUNT,
            VARIABLES: {
              "status": "NOTINACTIVE",
              "dates": [{
                field: "meta.createAt",
                gte: `${today.getStartOfDay().getTime()}`
              }]
            },
            NAME: "alignerGroupCount",
            TITLE: "New Aligners Today"
          },
          {
            QUERY: QUERY_COUNT,
            VARIABLES: {
              "status": "NOTINACTIVE",
              "dates": [{
                field: "meta.createAt",
                gte: `${today.getStartOfWeek().getTime()}`
              }]
            },
            NAME: "alignerGroupCount",
            TITLE: "Aligners this Week"
          },
          {
            QUERY: QUERY_COUNT,
            VARIABLES: {
              "status": "NOTINACTIVE",
              "dates": [{
                field: "meta.createAt",
                gte: `${today.getStartOfMonth().getTime()}`
              }]
            },
            NAME: "alignerGroupCount",
            TITLE: "Aligners this Month"
          },
          {
            QUERY: QUERY_COUNT,
            VARIABLES: {
              "status": "NOTINACTIVE",
              "dates": [{
                field: "meta.createAt",
                gte: `${today.getStartOfYear().getTime()}`
              }]
            },
            NAME: "alignerGroupCount",
            TITLE: "Aligners this Year"
          }
        ]
      }
      REDIRECT={{
        "path": "/admin/aligners",
        "to": "/admin/aligners/upload"
      }}
      PAGES={PAGES()}
      ValidateObject={ValidateObject}
    />
  );
}





export default Index;

export {PAGES};   





