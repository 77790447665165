import React, { useState, useRef } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import FormBuilder from 'global/components/FormBuilder/index';


const {Container, Row, Column} = window.LayoutBuilder;


/*

SORTBY (sortby)
ASC/DEC (descending)
SEARCH (search)


FILTER BY DATE (dates)
TOGGLE ON/OFF
WHAT DATE FIELD?
BEFORE WHAT DATE/TIME
AFTER WHAT DATE/TIME


FILTER BY SHOW DELETED FILES (status)


status
sortBy
descending
search
dates

*/

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterVariables: {}
    };   
    this.TemplateListStateKey = window.GlobalUtil.State.subscribe("templateList", (newState)=>{ //THIS SETS AND LISTENS TO SITE STATE
      this.setState({"filterVariables": newState.filterVariables});
    });
  }

  componentDidMount(){
    if(window.GlobalUtil.State.get("templateList")) this.onChange(window.GlobalUtil.State.get("templateList").filterVariables);
  }

  componentWillUnmount(){
    this.TemplateListStateKey.unsubscribe();
  }


  onChange = (newValues)=>{
    var isValid = true;                   
    window.GlobalUtil.State.set("templateList", { //THIS WILL TRIGGER THE SET STATE ABOVE
      "filterVariables": newValues
    });
  }

  //dates
  render(){
    var {filterVariables} = this.state;          
    return (
      <button 
        className="sideButton button button2" 
        onClick={(e)=>{
          e.preventDefault();
          window.GlobalUtil.triggerEvent("SlideInRightEvent", {
            TITLE: "Filter By",
            closeButtonText: "Close",
            saveButtonText: "Save",
            customClass: "frontEndSlideInRight shortSlideInRight newOrderDialog",
            width: "1200px",
            onClose: ()=>{
              window.CurRefetchList(true);
            },
            onSave: async ()=>{
              return;
              //this should run onClose after onSave is one
            },
            children: (propsChildren)=>{                                      
              return(
                <div id="TemplateAdd" className="backendPageTemplateComponent">
                  <FormBuilder
                    disableKeySubmit={true}
                    initialValues={filterVariables}
                    listOfFields={FORM_FIELDS(window.GlobalUtil.State.get("templateList").listHeaderFields)}
                    onSubmit={()=>{}}
                    onChange={this.onChange}
                    onValidate={(isValid)=>{                    
                    }}
                  />
                </div>
              )
            }
          });
        }}><span>Filter List </span> <i className="fas fa-filter" />
      </button>
    );
  }
}


/*
  dates{
    gte:String,
    lt:String,
    field: String,
    bool:Boolean
  }
*/
const FORM_FIELDS = (FIELDS)=>{
  var sortFields = FIELDS.map((FIELD)=>{
      return({
        "title": FIELD.name,
        "value": FIELD.path
      })
    })
  return([
    {
      title: <div className="sectionDividerBasic"><span className="sectionDividerText">Date Filtering</span><span className="sectionDividerBorder"></span></div>,
      type: "sectionDivider",
      name: "name",
      required: false,
      defaultValue: "",
      defaultValuePath: ""
    },
    {
      title: <div className="formTitle"><span className="">Filter By Date</span></div>,
      subTitle: <div className="formSubTitle"><span className="">Turn ON to filter by date. Turn off to stop filtering.</span></div>,
      type: "toggle",
      name: "fbd,active",
      borderBottom: false,
      defaultValue: false,
      defaultValuePath: "fbd,active",
    },
    {
      title: <div className="formTitle"><span className="">Date Filter Options</span></div>,
      subTitle: <div className="formSubTitle">Use these settings to filter by date</div>,
      type: "conditional",
      condition: (curObj)=>{            
        if(curObj.fbd && curObj.fbd.active && curObj.fbd.active !== "false") return true;
        return false;
      },
      listOfFields: [
        {
          title: <div className="formTitle"><span className="">Which Date</span></div>,
          subTitle: <div className="formSubTitle"><span className="">Which date should be filter by?</span></div>,
          type: "select",
          name: "fbd,field",
          options: [
            {
              "value": "",
              "title": "Choose one",
              "disabled": true
            },
            {
              "title": "Create At",
              "value": "meta.createAt"
            },
            {
              "title": "Update At",
              "value": "meta.updateAt"
            }
          ],
          required: true,
          defaultValue: "",
          defaultValuePath: "fbd,field",
          errorMessage: "Status is required"
        },
        {
          title: <div className="formTitle"><span className="">After When</span></div>,
          subTitle: <div className="formSubTitle">All items will be after this time. <small>Leave Blank to ignore</small></div>,
          type: "datePicker",
          name: "fbd,gt",
          showTimeSelect: true,
          zIndex: 2,
          required: false,
          defaultValue: undefined,
          defaultValuePath: "fbd,gt"
        },
        {
          title: <div className="formTitle"><span className="">Include after when date?</span></div>,
          subTitle: <div className="formSubTitle">Should we include the exact after when date? Yes=ON</div>,
          type: "toggle",
          name: "fbd,gte",
          zIndex: 2,
          required: false,
          defaultValue: false,
          defaultValuePath: "fbd,gte"
        },
        {
          title: <div className="formTitle"><span className="">Before When</span></div>,
          subTitle: <div className="formSubTitle">All items will be before this time. <small>Leave Blank to ignore</small></div>,
          type: "datePicker",
          name: "fbd,lt",
          showTimeSelect: true,
          required: false,
          defaultValue: undefined,
          defaultValuePath: "fbd,lt"
        },
        {
          title: <div className="formTitle"><span className="">Include before when date?</span></div>,
          subTitle: <div className="formSubTitle">Should we include the exact before when date? Yes=ON</div>,
          type: "toggle",
          name: "fbd,lte",
          zIndex: 2,
          required: false,
          defaultValue: false,
          defaultValuePath: "fbd,lte"
        },
      ]
    },
    {
      title: <div className="formTitle"><span className="">Filter by Status</span></div>,
      subTitle: <div className="formSubTitle"><span className="">What status does the item have?</span></div>,
      type: "select",
      name: "status",
      options: [
        {
          "value": "",
          "title": "Choose one",
          "disabled": true
        },
        {
          "title": "Active",
          "value": "ACTIVE"
        },
        {
          "title": "Inactive",
          "value": "INACTIVE"
        },
        {
          "title": "Show ALL",
          "value": "ALL"
        }
      ],
      required: false,
      defaultValue: "ACTIVE",
      defaultValuePath: "status"
    },
    {
      title: <div className="formTitle"><span className="">Limit Per Page</span></div>,
      subTitle: <div className="formSubTitle">Number to find per each page</div>,
      type: "number",
      name: "limit",
      max: 100,
      min: 5,
      required: false,
      defaultValue: 20,
      defaultValuePath: "limit"
    },
    {
      title: <div className="sectionDividerBasic"><span className="sectionDividerText">Sorting</span><span className="sectionDividerBorder"></span></div>,
      type: "sectionDivider",
      name: "name",
      required: false,
      defaultValue: "",
      defaultValuePath: ""
    },
    {
      title: <div className="formTitle"><span className="">What to Sort by and How</span></div>,
      subTitle: <div className="formSubTitle">Choose here or just click on the column header to sort by that column</div>,
      type: "conditional",
      condition: (curObj)=>{            
        return true;
      },
      listOfFields: [
        {
          title: <div className="formTitle"><span className="">Sort By</span></div>,
          subTitle: <div className="formSubTitle"><span className="">One must always be selected</span></div>,
          type: "select",
          name: "sortBy",
          options: [
            {
              "value": "",
              "title": "Choose one",
              "disabled": true
            },
            ...sortFields,
          ],
          required: true,
          defaultValue: "",
          defaultValuePath: "sortBy",
          errorMessage: "Status is required"
        },
        {
          title: <hr/>,
          type: "sectionDivider",
          name: "name",
          required: false,
          defaultValue: "",
          defaultValuePath: ""
        },
        {
          title: <div className="formTitle"><span className="">Ascending or Descending</span></div>,
          subTitle: <div className="formSubTitle"><span className="">One must always be selected</span></div>,
          type: "radio",
          name: "descending",
          isBool: true,
          options: [
            {
              "value": false,
              "title": "Descending"
            },
            {
              "value": true,
              "title": "Ascending"
            },
          ],
          required: true,
          defaultValue: "",
          defaultValuePath: "descending",
          errorMessage: "Status is required"
        }
      ]
    },
    // {
    //   title: <div className="formTitle"><span className="">Name</span></div>,
    //   subTitle: <div className="formSubTitle"><span className="">Users Name</span></div>,
    //   type: "text",
    //   name: "name",
    //   defaultValue: "",
    //   defaultValuePath: "name",
    //   errorMessage: "Each product needs a name"
    // },
    // {
    //   title: <div className="formTitle"><span className="">Phone</span></div>,
    //   subTitle: <div className="formSubTitle"><span className="">Users Phone Number.</span></div>,
    //   type: "phone",
    //   name: "phone",
    //   defaultValue: "",
    //   defaultValuePath: "phone",
    // },
    // {
    //   title: <div className="formTitle"><span className="">Email</span></div>,
    //   subTitle: <div className="formSubTitle"><span className="">Contact Email Address.</span></div>,
    //   type: "email",
    //   name: "email",
    //   defaultValue: "",
    //   defaultValuePath: "email",
    // },
    // {
    //   title: <div className="formTitle"><span className="">Notes</span></div>,
    //   subTitle: <div className="formSubTitle"><span className="">Any Action you've taken should go here.</span></div>,
    //   type: "textArea",
    //   name: "notes",
    //   rows: 6,
    //   defaultValue: "",
    //   defaultValuePath: "notes",
    // },
    // {
    //   title: <div className="formTitle"><span className="">Message</span></div>,
    //   subTitle: <div className="formSubTitle"><span className="">Message that shows up for this product.</span></div>,
    //   type: "textArea",
    //   name: "message",
    //   rows: 6,
    //   defaultValue: "",
    //   defaultValuePath: "message",
    // },
    // {
    //   title: <div className="formTitle"><span className="">Status</span></div>,
    //   subTitle: <div className="formSubTitle"><span className="">Choose a Status for your Product. Active means it'll go live.</span></div>,
    //   type: "select",
    //   name: "status",
    //   options: [
    //     {
    //       "value": "",
    //       "title": "Choose one",
    //       "disabled": true
    //     },
    //     {
    //       "value": "ACTIVE",
    //       "title": "ACTIVE"
    //     },
    //     {
    //       "value": "PENDING",
    //       "title": "PENDING"
    //     },
    //     {
    //       "value": "INACTIVE",
    //       "title": "INACTIVE"
    //     }
    //   ],
    //   required: true,
    //   defaultValue: "ACTIVE",
    //   defaultValuePath: "status",
    //   errorMessage: "Status is required"
    // }
  ])
};


export default Index;
      

