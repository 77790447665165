//import React from 'react';
const PAGE = {
  	"ENGLISH": {
		"CONTENT": {
			"SidePayment": [
				{
					//NUM 0
					"content": "No Orders Found"
				},
				{
					//NUM 1
					"content": "Please checkout our amazing offers."
				},
				{
					//NUM 2
					"type": "div",
					"content": "Login"
				},
				{
					//NUM 3
					"type": "div",
					"content": "Shipping"
				},
				{
					//NUM 4
					"type": "div",
					"content": "Checkout"
				},
				{
					//NUM 5
					"content": "Shipping Address"
				},
				{
					//NUM 6
					"content": "Save" //BUTTON TEXT
				},
				{
					//NUM 7
					"content": "Checkout"
				},
				{
					//NUM 8
					"type": "div",
					"content": '<div class="text">Buy purchasing I give full consent as agree to in the <a href="/consent" target="_blank">consent page.</a></div>'
				}
			],
			"SidePaymentButtons": [
				{	
					"link": '/', // '/inicio'
					"text": "Go Home"
				},
				{	
					"link": '/getstarted', // '/comencemos'
					"text": "Get Started"
				}
			],
			"LoginSignup": [
				{
					//NUM 11
					"content": 'Welcome'
				},
				{
					//NUM 12
					"content": 'You are all signed in and ready to checkout.'
				},
				{
					//NUM 12
					"content": 'Logout'
				}
			],
			"SignUpFormOrLoginButton": [
				{
					//NUM 
					"content": `Continue with Google`
				},
				{
					//NUM 
					"content": `Continue with Facebook`
				},
				{
					//NUM 
					"content": `OR`
				},
				{
					//NUM 
					"content": `Sign Up`
				},
				{
					//NUM 
					"type": "p",
					"content": `Email or Password failed`
				}
			],
			"CheckoutPageComponent": [
				{
					//NUM 0
					"content": "I'm sorry but it looks like we are having payment problems. Please try again later."
				},
				{
					//NUM 1
					"content": 'Congratulations'
				},
				{
					//NUM 2
					"content": 'Close'
				},
				{
					//NUM 3
					"type": "div",
					"content": `
						<div class="text1">Thank You</div>
						<div class="text2">You've successfully placed your order</div>
					`
				},
				{
					//NUM 4
					"content": `Your order number is`
				},
				{
					//NUM 5
					"type": "div",
					"content": `
						<br/>You can login to your account any time to check on your order history.
						<br/>Thank you for your purchase and have a good day.
					`
				},
				{
					//NUM 6
					"content": `Error: Oh No!`
				},
				{
					//NUM 7
					"content": `Go Home`
				},
				{
					//NUM 8
					"content": `Submit`
				},
				{
					//NUM 9
					"type": "div",
					"content":`
						<div class="BasicDialogContent">
							<div class="text1">Error: Oh No!</div>
							<div class="text2">Something went wrong with paypal</div>
							<div class="text3">Paypal may be experiancing technical problems please wait a few minutes and try again with them. Thank you!</div>
						</div>
					`
				}
			]
		}
	},
	"ESPAÑOL": {
		"CONTENT": {
			"SidePayment": [
				{
					//NUM 0
					"content": "No se encontraron pedidos"
				},
				{
					//NUM 1
					"content": "Consulte nuestras increíbles ofertas."
				},
				{
					//NUM 2
					"type": "div",
					"content": "Iniciar sesión"
				},
				{
					//NUM 3
					"type": "div",
					"content": "Envío"
				},
				{
					//NUM 4
					"type": "div",
					"content": "Pagar"
				},
				{
					//NUM 5
					"content": "Dirección de envío"
				},
				{
					//NUM 6
					"content": "Guardar" //TEXTO DEL BOTÓN
				},
				{
					//NUM 7
					"content": "Pagar"
				},
				{
					//NUM 8
					"type": "div",
					"content": '<div class="text">Comprar compra Doy pleno consentimiento como acepto en la <a href="/consent" target="_blank">página de consentimiento.</a></div>'
				}
			],
			"SidePaymentButtons": [
				{	
					"link": '/inicio',
					"text": "Vete a casa"
				},
				{	
					"link": '/comencemos',
					"text": "Empezar"
				}
			],
			"LoginSignup": [
				{
					//NUM 11
					"content": 'Bienvenido'
				},
				{
					//NUM 12
					"content": 'Todos han iniciado sesión y están listos para pagar.'
				},
				{
					//NUM 12
					"content": 'Cerrar sesión'
				}
			],
			"SignUpFormOrLoginButton": [
				{
					//NUM 
					"content": `Continuar con Google`
				},
				{
					//NUM 
					"content": `Continuar con Facebook`
				},
				{
					//NUM 
					"content": `O`
				},
				{
					//NUM 
					"content": `Inscribirse`
				},
				{
					//NUM 
					"type": "p",
					"content": `El correo electrónico o la contraseña fallaron`
				}
			],
			"CheckoutPageComponent": [
				{
					//NUM 0
					"content": "Lo siento, pero parece que estamos teniendo problemas con el pago. Por favor, inténtelo de nuevo más tarde."
				},
				{
					//NUM 1
					"content": 'Felicidades'
				},
				{
					//NUM 2
					"content": 'Cerca'
				},
				{
					//NUM 3
					"type": "div",
					"content": `
						<div class="text1">Gracias</div>
						<div class="text2">Ha realizado correctamente su pedido</div>
					`
				},
				{
					//NUM 4
					"content": `Tu numero de orden es`
				},
				{
					//NUM 5
					"type": "div",
					"content": `
						<br/>Puede iniciar sesión en su cuenta en cualquier momento para verificar su historial de pedidos.
						<br/>Gracias por su compra y que tenga un buen día.
					`
				},
				{
					//NUM 6
					"content": `Error: ¡Oh, no!`
				},
				{
					//NUM 7
					"content": `Ir a casa`
				},
				{
					//NUM 8
					"content": `Enviar`
				},
				{
					//NUM 9
					"type": "div",
					"content":`
						<div class="BasicDialogContent">
							<div class="text1">Error: ¡Ay, no!</div>
							<div class="text2">algo salió mal con paypal</div>
							<div class="text3">Paypal puede estar experimentando problemas técnicos, espere unos minutos y vuelva a intentarlo. ¡Gracias!</div>
						</div>
					`
				}
			]
		}
	}
};


	
export default PAGE;


