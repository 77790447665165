import React, { useState, useRef } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import "styles/scss/lab/components/TPOfferList.scss";
import ReactTooltip from 'react-tooltip';
import Loading from 'global/components/Loading/index';
const {Container, Row, Column} = window.LayoutBuilder;
///Users/danielezaldivar/Sites/sonrisas/src/styles/admin/pages/templateList.scss


class ListComp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showNextButton: false,
      showPrevButton: false,
      currentPage: 1,
      totalPages: 1,
    };  
    (
      (this.props.CurRefetchListVar) 
      ? window[this.props.CurRefetchListVar] = this.APIGetList 
      : window.CurRefetchList = this.APIGetList //RUN THIS FUNCTION TO RELOAD THE LIST WITHOUT CACHE
    )
  } 

  componentDidMount(){
    this.APIGetList(true);
  }

  componentWillUnmount(){
  }


  onChangeSort = (SortPath) => {
    var {VARIABLES={}} = this.props;
    if(VARIABLES.sortBy === SortPath){
      VARIABLES.descending = !VARIABLES.descending;
    } else {
      VARIABLES.sortBy = SortPath;
      VARIABLES.descending = false;
    }
    this.props.onUpdateFilter(VARIABLES); //this auto calls window.CurRefetchList which is APIGetList()
  }

  cleanFilterVar = (filterVariables) => {
    const deleteIfMissing = ["status","sortBy","limit","offset","fbd,field","fbd,field","fbd,gt","fbd,gte","fbd,lt","fbd,lte"];
    const typeBoolean = ["descending"];
    const typeNumber = ["limit", "offset"];
    typeBoolean.map((pathToValue, index)=>{
      var curVal = window.GlobalUtil.deepGetFromString(filterVariables, pathToValue, "false");
      if(typeof curVal === "string") window.GlobalUtil.deepSetFromString(filterVariables, pathToValue, (curVal === "false" ? false : true));
    });
    typeNumber.map((pathToValue, index)=>{
      var curVal = window.GlobalUtil.deepGetFromString(filterVariables, pathToValue, "false");
      window.GlobalUtil.deepSetFromString(filterVariables, pathToValue, Number(curVal));
    });
    deleteIfMissing.map((pathToValue, index)=>{
      var curVal = window.GlobalUtil.deepGetFromString(filterVariables, pathToValue, undefined);
      if(curVal===undefined){
        var pathListArray = pathToValue.split(",");
        if(pathListArray.length === 1){ //REMOVE FROM BASE OBJECT IF AT BASE
          delete filterVariables[pathToValue];
        } else { //DEEP REMOVE FIELD FROM OBJ
          delete filterVariables[pathToValue]; //DELETE ANY WRONG LONG VALUES LIKE "fbd,active" 
          var lastPathValue = pathListArray.slice(-1).join(",");
          var pathWithoutLastValue = pathListArray.slice(0,-1).join(",");
          var tempObj = window.GlobalUtil.deepGetFromString(filterVariables, pathWithoutLastValue, {});
          delete tempObj[lastPathValue];
          window.GlobalUtil.deepSetFromString(filterVariables, pathWithoutLastValue, tempObj);
        }
      }
    });
    // if(!filterVariables.fbd.active){
    //   delete filterVariables.dates;
    // }
    delete filterVariables.fbd;
    delete filterVariables["fbd,active"];
    return filterVariables;
  }



  APIGetList = (noCache) => {
    if(!this) return;
    this.setState({loading: true})
    var cleanFilter = this.cleanFilterVar({...this.props.VARIABLES});
    //console.log(`cleanFilter`,"\n\n",cleanFilter,"\n\n");  
    var inputObj = {
      query: this.props.QUERY,
      variables: this.cleanFilterVar({...this.props.VARIABLES})
    }
    //console.log(`inputObj`,"\n\n",inputObj,"\n\n");
          
    if(noCache) inputObj.fetchPolicy = "no-cache";
    window.Client.query(inputObj)
    .then((obj) => {
      var {data, loading} = obj;
      //console.log("SUCCESS LIST API");    
      console.log(`data`,"\n\n",data,"\n\n");
      data = window.GlobalUtil.stripOutFromObj(data, "__typename");       
      if(this && this.state) this.setState({data: data, loading: false}, this.getNavigationVar)
    })
    .catch((error)=>{
      console.log(`inputObj`,"\n\n",inputObj,"\n\n");
      if(this) this.setState({loading: false})
      console.log("API GET LIST catch", error);
    });
  }


  getNavigationVar = () => {
    var {data} = this.state;
    var {dataField, VARIABLES} = this.props;
    var CountName = (dataField ? (dataField.slice(0, -1)+"Count") : null);
          
    if(!data || !dataField || !data[CountName]) return; //IF FOUND NOTHING THEN NO NAVIGATION
    window.TotalCount = data[CountName];
    var maxItems = Number(data[CountName]);
    if(!maxItems) return; //IF FOUND NOTHING THEN NO NAVIGATION
    var limit = Number(VARIABLES.limit);
    var offset = Number(VARIABLES.offset);
    var {hasNextPage, hasPrevPage, totalPages, currentPage} = window.GlobalUtil.paginationCalculator(maxItems, limit, offset);
          
    //window.GlobalUtil.consoleLog("maxItems, limit, offset", [maxItems, limit, offset]);
    this.setState({
      showPrevButton: hasPrevPage,
      showNextButton: hasNextPage,
      currentPage: currentPage,
      totalPages: totalPages,
    })
    
  }

  navigateTo = (direction) => {
    var {VARIABLES} = this.props;
    if(direction == 'next'){
      VARIABLES.offset = Number(VARIABLES.offset)+Number(VARIABLES.limit);
    } else {
      VARIABLES.offset = Number(VARIABLES.offset)-Number(VARIABLES.limit);
    }
    this.props.onUpdateFilter(VARIABLES);
  }

  render(){
    var {data, showNextButton, showPrevButton, currentPage, totalPages, loading} = this.state;
    return(
      <div id="TPOfferList" className="templateList">
        {
          loading          
          ? <Loading 
              header={"Updating"}
              body={"Please stand by"}
              inlineStyles={{
                "loadingIcon": {
                  "maxWidth": "100px",
                  "paddingBottom": "20px"
                },
                "loadingHeader": {
                  "marginBottom": "5px",
                  "fontSize": "40px"
                },
                "loadingBody": {
                  "marginBottom": "0px",
                  "fontSize": "20px"
                }
              }}
              floating={true}
              overlay={true}
            />
          : data 
            ? this.props.CreateList(data)
            : null
        }
        {
          (totalPages > 1) && 
          <Container className="navigationList" fluid="true">
            <Row className="">
              <Column className="navLeft" col="" xs="" sm="" md="" lg="" xl="">
                <div className="navButtons prevButton">
                    <button className={`button button2 ${!showPrevButton ? "disabled" : ""}`} disabled={!showPrevButton} onClick={()=>{
                      if(showPrevButton) this.navigateTo("back");
                    }}><i className="fas fa-chevron-left"></i> Back</button>
                </div>
              </Column>
              <Column className="curNumber" col="" xs="" sm="" md="" lg="" xl="">
                <div className="navigationTracker">
                  <div className="">Page {currentPage} of {totalPages}</div>
                </div>
              </Column>
              <Column className="navRight" col="" xs="" sm="" md="" lg="" xl="">
                <div className="navButtons nextButton">
                  <button className={`button button2 ${!showNextButton ? "disabled" : ""}`} disabled={!showNextButton} onClick={()=>{
                    if(showNextButton) this.navigateTo("next");
                  }}>Next <i className="fas fa-chevron-right"></i></button>
                </div>
              </Column>
            </Row>
          </Container>
        }
      </div>
    )
  }
}


export default ListComp;
      

