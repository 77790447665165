import React from 'react';
import API_CALLS from 'API_CALLS/index';
import PAGES from 'website/pages/pages_FULL.js';

const GetCurrentPage = () => {
  const PAGES_LIST = PAGES(window.Session.Language);
  var pathname = window.location.pathname.toLowerCase();
  if(pathname === "/"){ //IT'S THE HOME PAGE STOP HERE
    return PAGES_LIST.filter(obj=>obj.PageName === "Home")[0]; //THIS SHOULD BE HOME OBJECT 
  }
  var currentPage = {};
  for(var pageObj of PAGES_LIST){
    for(var pathExact of pageObj.Paths){
      if(pathname === pathExact) currentPage = pageObj; //If perfect match stop here
    }
  }
  if(!currentPage || !currentPage.PageName){
    var pathNameArray = pathname.split("/").slice(1).map(obj=>`${obj[0].toUpperCase()}${obj.slice(1)}`); //WE REMOVE THE FIRST VALUE BECAUSE IT'LL BE ""
    currentPage = {
      "PageName": pathNameArray.join(" -> "),
      "Name": pathNameArray.slice(-1)[0],
      "Paths": [pathname]
    };
  }
  return currentPage;
}


const GetBasicUserInfo = ({noCache=false, noGlobalUtilLogin=false, callBackSuccess=()=>{}, callBackFailed=()=>{}}) => {
  window.GlobalUtil.consoleLog(`GetBasicUserInfo`);
  if(navigator.userAgent === 'ReactSnap') return true; //DONT RUN FOR REACT SNAP
  window.Client.query({
    query: API_CALLS.USERS.QUERIES.me(),
    fetchPolicy: (noCache ? "no-cache" : "cache")
  })
  .then(({data={}}) => {
    var me = window.GlobalUtil.APICleanObj(data.me);  
    var loginObj = {token: window.Session.token, user: me};          
    if(!noGlobalUtilLogin) window.GlobalUtil.login(loginObj, true);
    callBackSuccess(loginObj);
  })
  .catch((error)=>{
    window.GlobalUtil.consoleLog("GetBasicUserInfo error", error);
    //console.dir("GetBasicUserInfo error", error);
    window.GlobalUtil.logout(false);
    callBackFailed();
  })
}



const GetFullUserInfo = ({noCache=false, callBackSuccess=()=>{}, callBackFailed=()=>{}}) => {
  window.GlobalUtil.consoleLog(`GetFullUserInfo`);
  if(navigator.userAgent === 'ReactSnap') return true; //DONT RUN FOR REACT SNAP
  window.Client.query({
    query: API_CALLS.USERS.QUERIES.userFull(),
    fetchPolicy: (noCache ? "no-cache" : "cache")
  })
  .then(({data={}}) => {          
    var userFull = window.GlobalUtil.APICleanObj(data.userFull);                     
    var loginObj = {token: window.Session.token, user: userFull};                
    window.GlobalUtil.login(loginObj, true);
    callBackSuccess(loginObj);
  })
  .catch((error)=>{
    console.dir("GetFullUserInfo error", error);
    console.log('error', error);
    
    //window.GlobalUtil.logout(false);
    callBackFailed();
  })
}


const UpdateUserInfo = async (VARIABLES) => {
  //ONLY RUN ONCE CLIENT IS READY
  if(window.Client){
    if(navigator.userAgent === 'ReactSnap') return null;           
    var variables = window.GlobalUtil.APISubmitObjCleaner(API_CALLS.USERS.CLEAN_FIELDS, VARIABLES);          
    var newUser = await window.Client.mutate({
        mutation: API_CALLS.USERS.MUTATIONS.update(),
        variables: variables
      })
      .then(result => {              
        return window.GlobalUtil.APICleanObj(result.data.updateUser);
      })
      .catch((error)=>{
        console.log("UpdateUserInfo FAIL catch", error);
        throw error;
      });
    return newUser;
  }
  return null;
}



//IF USER IS LOGGED IN CHECK TO SEE IF ANY NEW GLOBAL VAR EXIST FOR THIS USER. IF THEY DO ADD THEN TO THE USER.
const UpdateUserWithAnyGlobalVars = async (curObj={}) => {
  window.GlobalUtil.consoleLog(`UpdateUserWithAnyGlobalVars`);
  //ONLY RUN ONCE CLIENT IS READY
  if(window.Client){
    if(navigator.userAgent === 'ReactSnap') return null; 
    if(!window.Session || !window.Session.user || !window.Session.user._id) return null; 
    var newUserVarObj = window.GlobalUtil.getGlobalVariable("NEW_USER_VAR", {});
    curObj = {id: window.Session.user._id, ...curObj};

    //IF USER VAR ALREADY EXIST THEN SKIP THEM, ELSE ADD THEM
    if(!window.Session.user.website) curObj.website = window.SiteSettings.siteNameAllCaps;
    
    if(Object.keys(curObj).length > 1) { //CHECK HERE TO NOT UPDATE THE USER IF NOTHING TO UPDATE
      var newUser = await UpdateUserInfo(curObj); //THIS IS ASYNC BUT CAN BE IGNORED
      await window.GlobalUtil.deleteGlobalVariable("NEW_USER_VAR"); //DELETE THE USER GLOBAL VARS  
      window.GlobalUtil.consoleLog(newUser);
      console.log(`newUser`,"\n\n",newUser,"\n\n");
            
      return newUser;
    } else {
      window.GlobalUtil.consoleLog(`UpdateUserWithAnyGlobalVars DIDN'T UPDATE ANYTHING`);
    }
  }
  window.GlobalUtil.consoleLog(null);
  return null;
}






const GetSiteSettingsFunction = ({forceUpdate=false, callBack=()=>{}, triggerEvent=true}) => {        
  var SiteSettings = window.GlobalUtil.LocalStorage.get("SiteSettings");
  // const CreateLogoObj = (cleanObj)=>{
  //   cleanObj.logosObj = {};
  //   cleanObj.logos.map((logoObj, index)=>{
  //     cleanObj.logosObj[logoObj.name] = logoObj;
  //   })
  //   return cleanObj;
  // }         

  if(SiteSettings && !forceUpdate){
    var cleanObj = JSON.parse(SiteSettings);
    if(triggerEvent) window.GlobalUtil.triggerEvent("SiteSettingsEvent", cleanObj);
    callBack(cleanObj);
  } else {
    if(window.SiteSettingsLoading) return;
    window.SiteSettingsLoading = true;
    window.Client.query({
      query: API_CALLS.SITE_SETTINGS.QUERIES.single(),
      variables: {id: "604d1cee8015f32ea407590d"},
      fetchPolicy: (!forceUpdate ? "cache" : "no-cache")
    })
    .then(result => {
      window.SiteSettingsLoading = false;
      var cleanObj = window.GlobalUtil.APICleanObj(result.data.siteSetting);                            
      //cleanObj = CreateLogoObj(cleanObj); //CREATE LOGO OBJ TO FOR EASE OF LOGO GET
      var ExpDate = new Date();
      ExpDate.addTimeFrame(1,"DAY");          
      window.GlobalUtil.LocalStorage.set("SiteSettings", JSON.stringify(cleanObj), ExpDate); 
      if(triggerEvent) window.GlobalUtil.triggerEvent("SiteSettingsEvent", cleanObj);  
      callBack(cleanObj);
    })
    .catch((error)=>{
      window.SiteSettingsLoading = false;
      window.SiteSettings = {
        ...window.GlobalUtil.BasicSiteSettings
      };
      if(triggerEvent) window.GlobalUtil.triggerEvent("SiteSettingsEvent", window.SiteSettings); 
      console.log("GetSiteSettings failed", error);
    })//END OF catch
  }
}



const GetSiteSettings = ({forceUpdate=false, callBack=()=>{}, triggerEvent=true}) => {
  //ONLY RUN ONCE CLIENT IS READY
  if(window.Client) GetSiteSettingsFunction({forceUpdate, callBack, triggerEvent})
  else{
    window.GlobalUtil.subscribeEventOnce("ClientEvent", readyToGo=>{
      GetSiteSettingsFunction({forceUpdate, callBack, triggerEvent})
    });
  }
}




const UpdateSiteSettings = ({newObj={}, onSuccess=()=>{}, onFail=()=>{}, failPathString="",}) => {
  //ONLY RUN ONCE CLIENT IS READY
  if(window.Client){
    if(navigator.userAgent === 'ReactSnap') return null;
    var cleanObj = {...newObj, id: window.SiteSettings._id};   
    cleanObj = window.GlobalUtil.stripOutFromObj(cleanObj, "__typename");   
    window.Client.mutate({
      mutation: API_CALLS.SITE_SETTINGS.MUTATIONS.update(),
      variables: cleanObj
    })
    .then(result => {
      GetSiteSettings({
        forceUpdate: true, 
        callBack: onSuccess, 
        //triggerEvent: true
      });
    })
    .catch((error)=>{
      console.log(failPathString ? failPathString : "helper-functions -> UpdateSiteSettings -> API_CALLS.SITE_SETTINGS.MUTATIONS.update FAIL catch", error);
      onFail();
    })
  }
  else return;
}





const LoginWith3rdPary = ({token, type, autoCreate=false, callBackSuccess, callBackFailed}) => {
  if(navigator.userAgent === 'ReactSnap') return true; //DONT RUN FOR REACT SNAP
  window.Client.query({
    query: API_CALLS.USERS.QUERIES.login3rdParty(),
    variables: {
      token,
      type,
      autoCreate: autoCreate,
      website: window.SiteSettings.siteNameAllCaps
    },
    fetchPolicy: "no-cache"
  })
  .then(({data={}}) => {          
    console.log(`data`,"\n\n",data,"\n\n");
    var loginObj = window.GlobalUtil.APICleanObj(data.login3rdParty);              
    //console.log(`loginObj`,"\n\n",loginObj,"\n\n");
    if(callBackSuccess) callBackSuccess(loginObj);
    else window.GlobalUtil.login(loginObj);
  })
  .catch((error)=>{
    console.dir("LoginWith3rdPary error", error);
    if(callBackFailed) callBackFailed();
  })
}





const GetCouponFromCode = ({VARIABLES}) => {
  if(navigator.userAgent === 'ReactSnap') return true; //DONT RUN FOR REACT SNAP
  return window.Client.query({
    query: API_CALLS.COUPONS.QUERIES.couponFinder(),
    variables: VARIABLES
  })
  .then((obj) => {
    return window.GlobalUtil.deepGetFromString(obj, "data,couponFinder", null);
  })
  .catch((error)=>{
    console.log("on Find Coupon catch", error);
  });
}




export {
  GetBasicUserInfo,
  GetFullUserInfo,
  UpdateUserInfo,
  UpdateUserWithAnyGlobalVars,
  GetSiteSettings,
  UpdateSiteSettings,
  LoginWith3rdPary,
  GetCouponFromCode,
  GetCurrentPage
}



