import React from 'react';
import API_CALLS from 'API_CALLS/index';
import TemplatePage from "global/templates/templatePage/index";
import ActionPreview from "./actions/ActionPreview";
import DialogNotesList from 'admin/components/DialogNotesList/index';

const QUERY = API_CALLS.GIVEAWAYS.QUERIES.allAndCount();
const QUERY_SINGLE = API_CALLS.GIVEAWAYS.QUERIES.single();
const QUERY_COUNT = API_CALLS.GIVEAWAYS.QUERIES.count();
const MUTATION_EDIT = API_CALLS.GIVEAWAYS.MUTATIONS.update();
const MUTATION_ADD = API_CALLS.GIVEAWAYS.MUTATIONS.create();
const FORM_FIELDS = API_CALLS.GIVEAWAYS.FORM_FIELDS;
const QUERY_GET_USERS = API_CALLS.USERS.QUERIES.all(`email`);
const LIST_HEADER_FIELDS = API_CALLS.GIVEAWAYS.LIST_HEADER_FIELDS;

const PREVIEW = API_CALLS.GIVEAWAYS.PREVIEW;

const API_CALLS_FULL = {
  QUERY,
  QUERY_SINGLE,
  QUERY_COUNT,
  MUTATION_EDIT,
  MUTATION_ADD,
  FORM_FIELDS,
  QUERY_GET_USERS,
  PREVIEW
};






const ValidateObject = (newObject, onSuccess, onFail)=>{
  if(newObject._id && !newObject.id) newObject.id = newObject._id;      
  //console.log("newObject");
  //console.log(newObject,"\n\n");
        
  Object.keys(newObject).map((key, index)=>{
    if(!newObject[key]) delete newObject[key];
  })  
  onSuccess(newObject);
  //onFail();      
};




const NotesListPreivewContestants = ()=>{
  return(
    {
      width: "110px",
      category: "Preview",
      buttonText: "View",
      className: "btn-color3",
      iconClass: "fas fa-eye",
      onClick: (obj)=>{
        window.GlobalUtil.triggerEvent("TemplateDBEvent", {
          "TYPE": "PREVIEW",
          "BUTTON_TITLE": "Contestant",
          "TITLE": "Giveaway Contestant", //TITLE OF SLIDE OUT
          "APINAME": "GIVEAWAY_CONTS",
          "NAME": "Giveaway_Cont",
          "ID": obj._id,
          "customButtons": (curRef)=>{                    
            return(
              <React.Fragment>
                <button className="button button2 btn-color6" onClick={()=>{
                  if(curRef) curRef.onClose()
                }}>Close</button>
                <DialogNotesList
                  ID={obj._id}
                  API_NAME='GIVEAWAY_CONTS'
                  FIELD='giveawayCont'
                  asButton={true}
                  className={'button button1 btn-color4'}
                  style={{"width":"100px","minWidth":"0"}}
                  customLinkText={<span><span>Logs</span>&nbsp;&nbsp;<i className="fas fa-clipboard-list"/></span>}
                  onUpdated={()=>{
                    if(window.CurRefetchList) window.CurRefetchList();
                    if(window.CurRefetchEdit) window.CurRefetchEdit();
                  }}
                  pathToNotesList={"aboutWinnings,notesList"}
                  defaultCategoriesProps={[
                    {
                      "value": "SENT_EMAIL",
                      "title": "SENT_EMAIL"
                    },
                    {
                      "value": "PHONE_CALL",
                      "title": "PHONE_CALL"
                    },
                    {
                      "value": "TEXT_MESSAGE",
                      "title": "TEXT_MESSAGE"
                    },
                    {
                      "value": "IN_PERSON",
                      "title": "IN_PERSON"
                    },
                    {
                      "value": "OTHER",
                      "title": "OTHER"
                    }
                  ]}
                />
              </React.Fragment>
            )
          }
        }); 
      }
    }
  )
}


const NotesListPreivewGiveaways = ()=>{
  return(
    {
      width: "110px",
      category: "Preview",
      buttonText: "View",
      className: "btn-color3",
      iconClass: "fas fa-eye",
      onClick: (obj)=>{
        window.GlobalUtil.triggerEvent("TemplateDBEvent", {
          "TYPE": "PREVIEW",
          "BUTTON_TITLE": "Giveaway",
          "TITLE": "Giveaway", //TITLE OF SLIDE OUT
          "APINAME": "GIVEAWAYS",
          "NAME": "Giveaway",
          "ID": obj._id,
          "customButtons": (curRef)=>{                    
            return(
              <React.Fragment>
                <button className="button button2 btn-color6" onClick={()=>{
                  if(curRef) curRef.onClose()
                }}>Close</button>
                <DialogNotesList
                  ID={obj._id}
                  API_NAME='GIVEAWAYS'
                  FIELD='giveaway'
                  asButton={true}
                  className={'button button1 btn-color4'}
                  style={{"width":"100px","minWidth":"0"}}
                  customLinkText={<span><span>Logs</span>&nbsp;&nbsp;<i className="fas fa-clipboard-list"/></span>}
                  onUpdated={()=>{
                    if(window.CurRefetchList) window.CurRefetchList();
                    if(window.CurRefetchEdit) window.CurRefetchEdit();
                  }}
                  pathToNotesList={"notesList"}
                  defaultCategoriesProps={[
                    {
                      "value": "ACTION_TAKEN",
                      "title": "ACTION_TAKEN"
                    },
                    {
                      "value": "UPDATED",
                      "title": "UPDATED"
                    },
                    {
                      "value": "MEMO_FOR_LATER",
                      "title": "MEMO_FOR_LATER"
                    },
                    {
                      "value": "TODO",
                      "title": "TODO"
                    },
                    {
                      "value": "OTHER",
                      "title": "OTHER"
                    }
                  ]}
                />
              </React.Fragment>
            )
          }
        }); 
      }
    }
  )
}



const GIVEAWAY_PAGES = () => [
  {
    "id": "GIVEAWAYSEVENTS01",
    "Name":"ActiveEvents",
    "PageName": "Active",
    "Paths": ["/admin/giveaways/events/active"],
    "TITLE": "Active Events",
    "DATA_FIELD": "giveaways",
    "GlobalStateVar": ["giveawaysCountActive"],
    "ActionSlideIn": ActionPreview,
    "API_CALLS": API_CALLS_FULL,
    "LIST_HEADER_FIELDS": LIST_HEADER_FIELDS,
    "VARIABLES": {
      "active": true,
      "sortBy": "meta.updateAt",
      "descending": false,
      "limit": 20,
      "offset": 0,
      "dates": [
        {
          "field": "startAt",
          "lte": `${Date.now()}`
        },
        {
          "field": "endAt",
          "gte": `${Date.now()}`
        }
      ]
    },
    "NEWBUTTON": {
      NAME: "GIVEAWAYS",
      TITLE: "Giveaway",
      FIELD: "giveaway"
    },
    "EDITBUTTON": {
      NAME: "GIVEAWAYS",
      TITLE: "Giveaway",
      FIELD: "giveaway"
    },
    "DELETEBUTTON": {
      NAME: "GIVEAWAYS"
    },
    "CLONEBUTTON": {
      NAME: "GIVEAWAYS",
      CONFIRMMESSAGE: "Are you sure you want to clone this? If so it be cloned into PENDING.",
      ONSUCCESS: ()=>{
        window.FlashMenuItem("/admin/giveaways/events/pending");
      }
    },
    "BUTTONS": [
      NotesListPreivewGiveaways()
    ],
    SHOWSELECTBOXES:true
  },
  {
    "id": "GIVEAWAYSEVENTS02",
    "Name":"pendingEvents",
    "PageName": "Pending",
    "Paths": ["/admin/giveaways/events/pending"],
    "TITLE": "Pending Events",
    "DATA_FIELD": "giveaways",
    "GlobalStateVar": ["giveawaysCountPending"],
    "ActionSlideIn": ActionPreview,
    "API_CALLS": API_CALLS_FULL,
    "LIST_HEADER_FIELDS": LIST_HEADER_FIELDS,
    "VARIABLES": {
      "active": true,
      "sortBy": "meta.updateAt",
      "descending": false,
      "limit": 20,
      "offset": 0,
      "dates": [
        {
          "field": "startAt",
          "gte": `${Date.now()}`
        }
      ],
    },
    "NEWBUTTON": {
      NAME: "GIVEAWAYS",
      TITLE: "Giveaway",
      FIELD: "giveaway"
    },
    "EDITBUTTON": {
      NAME: "GIVEAWAYS",
      TITLE: "Giveaway",
      FIELD: "giveaway"
    },
    "DELETEBUTTON": {
      NAME: "GIVEAWAYS"
    },
    "CLONEBUTTON": {
      NAME: "GIVEAWAYS",
      CONFIRMMESSAGE: "Are you sure you want to clone this? If so it be cloned into PENDING.",
      ONSUCCESS: ()=>{
        window.FlashMenuItem("/admin/giveaways/events/pending");
      }
    },
    "BUTTONS": [
      NotesListPreivewGiveaways()
    ],
    SHOWSELECTBOXES:true
  },
  {
    "id": "GIVEAWAYSEVENTS03",
    "Name":"pendingEvents",
    "PageName": "Expired",
    "Paths": ["/admin/giveaways/events/expired"],
    "TITLE": "Expired Events",
    "DATA_FIELD": "giveaways",
    "GlobalStateVar": ["giveawaysCountExpired"],
    "ActionSlideIn": ActionPreview,
    "API_CALLS": API_CALLS_FULL,
    "LIST_HEADER_FIELDS": LIST_HEADER_FIELDS,
    "VARIABLES": {
      "active": true,
      "sortBy": "meta.updateAt",
      "descending": false,
      "limit": 20,
      "offset": 0,
      "dates": [
        // {
        //   "field": "startAt",
        //   "lte": `${Date.now()}`
        // },
        {
          "field": "endAt",
          "lte": `${Date.now()}`
        }
      ],
    },
    "NEWBUTTON": {
      NAME: "GIVEAWAYS",
      TITLE: "Giveaway",
      FIELD: "giveaway"
    },
    "EDITBUTTON": {
      NAME: "GIVEAWAYS",
      TITLE: "Giveaway",
      FIELD: "giveaway"
    },
    "DELETEBUTTON": {
      NAME: "GIVEAWAYS"
    },
    "CLONEBUTTON": {
      NAME: "GIVEAWAYS",
      CONFIRMMESSAGE: "Are you sure you want to clone this? If so it be cloned into PENDING.",
      ONSUCCESS: ()=>{
        window.FlashMenuItem("/admin/giveaways/events/expired");
      }
    },
    "BUTTONS": [
      NotesListPreivewGiveaways()
    ],
    SHOWSELECTBOXES:true
  }
];


const CONTESTANT_PAGES = () => [
  {
    "id": "GIVEAWAYS_CONTESTANTS01",
    "Name": "Contestants",
    "PageName": "All",
    "Paths": ["/admin/giveaways/contestants/active"],
    "TITLE": "Giveaway Contestants",
    "DATA_FIELD": "giveawayConts",
    "GlobalStateVar": ["giveawayConsCountActive"],
    "ActionSlideIn": ActionPreview,
    "API_CALLS": {
      QUERY: API_CALLS.GIVEAWAY_CONTS.QUERIES.allAndCount(),
      QUERY_SINGLE: API_CALLS.GIVEAWAY_CONTS.QUERIES.single(),
      QUERY_COUNT: API_CALLS.GIVEAWAY_CONTS.QUERIES.count(),
      MUTATION_EDIT: API_CALLS.GIVEAWAY_CONTS.MUTATIONS.update(),
      MUTATION_ADD: API_CALLS.GIVEAWAY_CONTS.MUTATIONS.create(),
      FORM_FIELDS: API_CALLS.GIVEAWAY_CONTS.FORM_FIELDS,
      QUERY_GET_USERS: API_CALLS.USERS.QUERIES.all(`email`),
      PREVIEW: API_CALLS.GIVEAWAY_CONTS.PREVIEW
    },
    "LIST_HEADER_FIELDS": API_CALLS.GIVEAWAY_CONTS.LIST_HEADER_FIELDS,
    "VARIABLES": {
      "status": "ACTIVE",
      "sortBy": "meta.createAt",
      "descending": false,
      "limit": 20,
      "offset": 0,
      "include": [
        {
          name: "giveaway"
        }
      ]
    },
    "NEWBUTTON": {
      NAME: "GIVEAWAY_CONTS",
      TITLE: "Contestant",
      FIELD: "giveawayCont"
    },
    "EDITBUTTON": {
      NAME: "GIVEAWAY_CONTS",
      TITLE: "Contestant",
      FIELD: "giveawayCont"
    },
    "INACTIVEBUTTON": {
      NAME: "GIVEAWAY_CONTS"
    },
    "CLONEBUTTON": {
      NAME: "GIVEAWAY_CONTS",
      CONFIRMMESSAGE: "Are you sure you want to clone this? If so it be cloned into PENDING.",
      ONSUCCESS: ()=>{
        window.FlashMenuItem("/admin/giveaways/contestants");
      }
    },
    "BUTTONS": [
      NotesListPreivewContestants()
    ],
    SHOWSELECTBOXES:true
  },
  {
    "id": "GIVEAWAYS_CONTESTANTS02",
    "Name":"Contestants",
    "PageName": "Winners",
    "Paths": ["/admin/giveaways/contestants/winners"],
    "TITLE": "Giveaway Winners",
    "DATA_FIELD": "giveawayConts",
    "GlobalStateVar": ["giveawayConsCountWinners"],
    "ActionSlideIn": ActionPreview,
    "API_CALLS": {
      QUERY: API_CALLS.GIVEAWAY_CONTS.QUERIES.allAndCount(),
      QUERY_SINGLE: API_CALLS.GIVEAWAY_CONTS.QUERIES.single(),
      QUERY_COUNT: API_CALLS.GIVEAWAY_CONTS.QUERIES.count(),
      MUTATION_EDIT: API_CALLS.GIVEAWAY_CONTS.MUTATIONS.update(),
      MUTATION_ADD: API_CALLS.GIVEAWAY_CONTS.MUTATIONS.create(),
      FORM_FIELDS: API_CALLS.GIVEAWAY_CONTS.FORM_FIELDS,
      QUERY_GET_USERS: API_CALLS.USERS.QUERIES.all(`email`),
      PREVIEW: API_CALLS.GIVEAWAY_CONTS.PREVIEW
    },
    "LIST_HEADER_FIELDS": API_CALLS.GIVEAWAY_CONTS.LIST_HEADER_FIELDS,
    "VARIABLES": {
      "status": "ACTIVE",
      "sortBy": "meta.createAt",
      "descending": false,
      "limit": 20,
      "offset": 0,
      "manual": [
        {
          path: "won",
          val: "true"
        }
      ],
      "include": [
        {
          name: "giveaway"
        }
      ]
    },
    "NEWBUTTON": {
      NAME: "GIVEAWAY_CONTS",
      TITLE: "Contestant",
      FIELD: "giveawayCont"
    },
    "EDITBUTTON": {
      NAME: "GIVEAWAY_CONTS",
      TITLE: "Contestant",
      FIELD: "giveawayCont",

    },
    "INACTIVEBUTTON": {
      NAME: "GIVEAWAY_CONTS"
    },
    "CLONEBUTTON": {
      NAME: "GIVEAWAY_CONTS",
      CONFIRMMESSAGE: "Are you sure you want to clone this? If so it be cloned into PENDING.",
      ONSUCCESS: ()=>{
        window.FlashMenuItem("/admin/giveaways/contestants");
      }
    },
    "BUTTONS": [
      NotesListPreivewContestants()
    ],
    SHOWSELECTBOXES:true
  },
  {
    "id": "GIVEAWAYS_CONTESTANTS03",
    "Name":"Contestants",
    "PageName": "History",
    "Paths": ["/admin/giveaways/contestants/history"],
    "TITLE": "Giveaway History",
    "DATA_FIELD": "giveawayConts",
    "GlobalStateVar": ["giveawayConsCountHistory"],
    "ActionSlideIn": ActionPreview,
    "API_CALLS": {
      QUERY: API_CALLS.GIVEAWAY_CONTS.QUERIES.allAndCount(),
      QUERY_SINGLE: API_CALLS.GIVEAWAY_CONTS.QUERIES.single(),
      QUERY_COUNT: API_CALLS.GIVEAWAY_CONTS.QUERIES.count(),
      MUTATION_EDIT: API_CALLS.GIVEAWAY_CONTS.MUTATIONS.update(),
      MUTATION_ADD: API_CALLS.GIVEAWAY_CONTS.MUTATIONS.create(),
      FORM_FIELDS: API_CALLS.GIVEAWAY_CONTS.FORM_FIELDS,
      QUERY_GET_USERS: API_CALLS.USERS.QUERIES.all(`email`),
      PREVIEW: API_CALLS.GIVEAWAY_CONTS.PREVIEW
    },
    "LIST_HEADER_FIELDS": API_CALLS.GIVEAWAY_CONTS.LIST_HEADER_FIELDS,
    "VARIABLES": {
      "status": "INACTIVE",
      "sortBy": "meta.createAt",
      "descending": false,
      "limit": 20,
      "offset": 0,
      "manual": [],
      "include": [
        {
          name: "giveaway"
        }
      ]
    },
    "NEWBUTTON": {
      NAME: "GIVEAWAY_CONTS",
      TITLE: "Contestant",
      FIELD: "giveawayCont"
    },
    "EDITBUTTON": {
      NAME: "GIVEAWAY_CONTS",
      TITLE: "Contestant",
      FIELD: "giveawayCont",

    },
    "DELETEBUTTON": { //THIS ONE ACTUALY DELETES THE ITEM INSTEAD OF JUST HIDING IT
      NAME: "GIVEAWAY_CONTS"
    },
    "CLONEBUTTON": {
      NAME: "GIVEAWAY_CONTS",
      CONFIRMMESSAGE: "Are you sure you want to clone this? If so it be cloned into PENDING.",
      ONSUCCESS: ()=>{
        window.FlashMenuItem("/admin/giveaways/contestants");
      }
    },
    "BUTTONS": [
      NotesListPreivewContestants()
    ],
    SHOWSELECTBOXES:true
  }
];

const PAGES = () => [
  {
    "id": "GIVEAWAYS01",
    "Name": "Events",
    "PageName": "Events",
    "Paths": ["/admin/giveaways/events"],
    "SubPaths": GIVEAWAY_PAGES()
  },
  {
    "id": "GIVEAWAYS02",
    "Name":"Contestants",
    "PageName": "Contestants",
    "Paths": ["/admin/giveaways/contestants"],
    "SubPaths": CONTESTANT_PAGES()
  }
];






const Index = (props) => {
  //var {data} = props;
  var today = new Date();
  return (
     <TemplatePage
      ID={"AdminGiveaway"}
      REPORTS={
        [
          {
            QUERY: API_CALLS.GIVEAWAY_CONTS.QUERIES.count(),
            VARIABLES: {
              "status": "NOTINACTIVE",
              "dates": [{
                field: "meta.createAt",
                gte: `${today.getStartOfDay().getTime()}`
              }]
            },
            NAME: "giveawayContCount",
            TITLE: "New Contestants Today"
          },
          {
            QUERY: API_CALLS.GIVEAWAY_CONTS.QUERIES.count(),
            VARIABLES: {
              "status": "NOTINACTIVE",
              "dates": [{
                field: "meta.createAt",
                gte: `${today.getStartOfWeek().getTime()}`
              }]
            },
            NAME: "giveawayContCount",
            TITLE: "Contestants this Week"
          },
          {
            QUERY: API_CALLS.GIVEAWAY_CONTS.QUERIES.count(),
            VARIABLES: {
              "status": "NOTINACTIVE",
              "dates": [{
                field: "meta.createAt",
                gte: `${today.getStartOfMonth().getTime()}`
              }]
            },
            NAME: "giveawayContCount",
            TITLE: "Contestants this Month"
          },
          {
            QUERY: API_CALLS.GIVEAWAY_CONTS.QUERIES.count(),
            VARIABLES: {
              "status": "NOTINACTIVE",
              "dates": [{
                field: "meta.createAt",
                gte: `${today.getStartOfYear().getTime()}`
              }]
            },
            NAME: "giveawayContCount",
            TITLE: "Contestants this Year"
          }
        ]
      }
      REDIRECT_ARRAY={[
        {
          "path": "/admin/giveaways/contestants",
          "to": "/admin/giveaways/contestants/active"
        },
        {
          "path": "/admin/giveaways/events",
          "to": "/admin/giveaways/events/active"
        },
        {
          "path": "/admin/giveaways",
          "to": "/admin/giveaways/events/active"
        }
      ]}
      PAGES={PAGES()}
      ValidateObject={ValidateObject}
    />
  );
}





export default Index;

export {PAGES};   

      
