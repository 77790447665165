import React, { useState, useRef } from 'react';
import API_CALLS from 'API_CALLS/index';
import EditCode from './editCode/index';
import Loading from 'global/components/Loading/index';
//const {Container, Row, Column} = window.LayoutBuilder;


class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      coupons: undefined, 
      loading: false
    };    
  }

  componentDidMount(){
    //window.GlobalUtil.consoleLog("MUTATION_USER_EDIT", [MUTATION_USER_EDIT]);
    this.APIGetCoupons();
  }

  componentWillUnmount(){
    if(this.DelayWithAbort) this.DelayWithAbort.abort()
  }


  APIGetCoupons = (noCache=false) => {
    this.setState({loading: true});
    window.Client.query({ //API UPDATE
      query: API_CALLS.COUPONS.QUERIES.couponFinderUser(),
      fetchPolicy: "no-cache",
      variables: {
        UID: this.props.currentUser._id
      }
    })
    .then(async ({data})=>{
      //console.log('data.couponFinderUser', data.couponFinderUser);
      this.DelayWithAbort = window.GlobalUtil.delayWithAbort(400, ()=>{
        this.setState({
          coupons: ((!data.couponFinderUser || !data.couponFinderUser.length) ? null : data.couponFinderUser),
          loading: false
        });
      })
    })
    .catch((error)=>{
      this.setState({
        loading: false
      });
      console.log("error APIGetCoupons getting coupons");
      console.log(error,"\n\n");  
    });
  }





  render(){
    var {coupons, loading} = this.state;
    var {CONTENT} = this.props;
    return (
      <div id="PromoCodes" className={`component fullHeight overflowRightScroll tableComponent ${loading ? "loading" : ""}`}>
        {
          loading
          ? <Loading 
              floating={false}
              header={CONTENT.PromoCodesLoading[0]}
              body={CONTENT.PromoCodesLoading[1]}
            />
          : null
        }
        <div className="itemHeader">
          <div className="itemHeaderTextZone">
            <div className="itemHeaderText withIcon itemHeaderTextCombo">
              <div className="icon"><i className="fas fa-tags"></i></div>
              <div className="text">{CONTENT.PromoCodes[0]}</div>
            </div>
          </div>
        </div>
        <div className="componentSummary">
          {CONTENT.PromoCodes[1]}
        </div>
        <div id="PromoCodeTable" className="tableWrapper">
          {
            loading
            ? null
            : coupons
              ? <table className='table'>
                  <thead>
                    <tr>
                      <th>{CONTENT.PromoCodeTable[0]}</th>
                      <th>{CONTENT.PromoCodeTable[1]}</th>
                      <th>{CONTENT.PromoCodeTable[2]}</th>
                      <th>{CONTENT.PromoCodeTable[3]}</th>
                      <th className="buttonSectionHeader">{CONTENT.PromoCodeTable[4]}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      coupons
                      ? coupons.map((coupon, index)=>{
                          if(!coupon || !coupon.PROMO) return null;
                          return(
                            <tr key={index}>
                              <td>
                                <span>{(coupon.PROMO ? coupon.PROMO.CODE : "")}</span>
                              </td>
                              <td><span className="">{coupon.name}</span></td>
                              <td><span className="">{coupon.summary}</span></td>
                              <td><span className="">{window.GlobalUtil.convertToMoney(coupon.commission)}</span></td>
                              <td className="buttonSection">
                                <EditCode
                                  onUpdate={()=>this.APIGetCoupons(true)}
                                  CONTENT={CONTENT}
                                  curObj={{
                                    UID: this.props.currentUser._id,
                                    CPID: coupon._id,
                                    CODE: coupon.PROMO.CODE
                                  }}
                                  currentCoupon={coupon}
                                />
                                {
                                  //<i className="fas fa-pencil-alt" />
                                }
                              </td>
                            </tr>
                          )
                        })
                      : null
                    }
                  </tbody>
                </table>
              : <p className="nothingFoundMessage">{CONTENT.PromoCodeTable[5]}</p>
          }
        </div>
      </div>
    );
  }
}


export default Index;
      
