import React from 'react';
import ACTIVITYS from './ACTIVITYS/index';
import ALIGNER_GROUPS from './ALIGNER_GROUPS/index';
import ALIGNERS from './ALIGNERS/index';
import BLOGS from './BLOGS/index';
import COMS from './COMS/index';
import COMMISSIONS from './COMMISSIONS/index';
import COMMISSION_CHECKS from './COMMISSION_CHECKS/index';
import COUPONS from './COUPONS/index';
import EMAILS from './EMAILS/index';
import FAILEDS from './FAILEDS/index';
import GIVEAWAYS from './GIVEAWAYS/index';
import GIVEAWAY_CONTS from './GIVEAWAY_CONTS/index';
import ORDER_ITEMS from './ORDER_ITEMS/index';
import ORDERS from './ORDERS/index';
import PRODUCTS from './PRODUCTS/index';
import REFERRALS from './REFERRALS/index';
import REPORTS from './REPORTS/index';
import S3 from './S3/index';
import SITE_SETTINGS from './SITE_SETTINGS/index';
import TRACKING from './TRACKING/index';
import TREATMENT_PLANS from './TREATMENT_PLANS/index';
import USERS from './USERS/index';

const API_CALLS = {
  ACTIVITYS: ACTIVITYS,
  ALIGNER_GROUPS: ALIGNER_GROUPS,
  ALIGNERS: ALIGNERS,
  BLOGS: BLOGS,
  COMS: COMS,
  COMMISSIONS: COMMISSIONS,
  COMMISSION_CHECKS: COMMISSION_CHECKS,
  COUPONS: COUPONS,
  EMAILS: EMAILS,
  FAILEDS: FAILEDS,
  GIVEAWAYS: GIVEAWAYS,
  GIVEAWAY_CONTS: GIVEAWAY_CONTS,
  ORDER_ITEMS: ORDER_ITEMS,
  ORDERS: ORDERS,
  PRODUCTS: PRODUCTS,
  REFERRALS: REFERRALS,
  REPORTS: REPORTS,
  S3: S3,
  SITE_SETTINGS: SITE_SETTINGS,
  TRACKING: TRACKING,
  TREATMENT_PLANS: TREATMENT_PLANS,
  USERS: USERS
}


export default API_CALLS;