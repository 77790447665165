import React from 'react';
import "styles/scss/lab/pages/dashboard.scss";
import { gql, useQuery, useMutation } from '@apollo/client';
import API_CALLS from 'API_CALLS/index';
import InvoiceList from 'user/lab/components/InvoiceList/index';
import ESPAÑOL from "./ESPAÑOL";
import ENGLISH from "./ENGLISH";

const CONTENTS = {
  ESPAÑOL,
  ENGLISH
}
const MUTATION_USER_EDIT = API_CALLS.USERS.MUTATIONS.update();
const {Container, Row, Column} = window.LayoutBuilder;


class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }; 
  }

  componentDidMount(){
  }

  componentWillUnmount(){
  }


  render(){
    var {showSideMenu} = this.state;
    const CONTENT = window.GlobalUtil.dangerSetGlobal(CONTENTS[window.Session.Language]); 
    //console.log(`CONTENT`,"\n\n",CONTENT,"\n\n");
          
    const confirmDic = {
      "ENGLISH": "Yes",
      "ESPAÑOL": "Si"
    }
    return (
      <div id="LabHome" className="scrollZone">
        <div className="">
          <Container className="" fluid="true">
            <Row className="">
              <Column className="" col="" xs="" sm="" md="" lg="" xl="">
                <div className="headerButton">
                  <h1 className="pageHeader"><i className="fas fa-calendar-alt"></i> {CONTENT[0]}</h1>
                </div>
              </Column>
            </Row>
          </Container> 
          <Container className="" fluid="true">
            <Row className="">
              <Column className="matchHeight" col="" xs="" sm="" md="12" lg="12" xl="12">
                <div className="component fullHeight overflowRightScroll">
                  <div className="itemHeader d-flex">
                    <div className="itemHeaderTextZone">
                      {CONTENT[1]}
                    </div>
                  </div>
                  <InvoiceList 
                    field={"HISTORY.LABORATORY.UPLOADED_OFFER"}
                    windowField={"CurRefetchListVar3"}
                  />
                </div>
              </Column>
            </Row>


            <Row className="">
              <Column className="matchHeight" col="" xs="" sm="" md="12" lg="12" xl="12">
                <div className="component fullHeight overflowRightScroll">
                  <div className="itemHeader d-flex">
                    <div className="itemHeaderTextZone">
                      {CONTENT[2]}
                    </div>
                  </div>
                  <InvoiceList 
                    field={"HISTORY.LABORATORY.UPLOADED_FULL"}
                    windowField={"CurRefetchListVar4"}
                  />
                </div>
              </Column>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}



















export default Index;