import React from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import API_CALLS from 'API_CALLS/index';

const APIGetHandshakeToken = ()=>{     
  if(navigator.userAgent === 'ReactSnap') return window.GlobalUtil.delay(10); //IF REACT-SNAP IS JUST VIEWING THE PAGE THEN DO NOTHING
  return window.Client.query({
    query: API_CALLS.SITE_SETTINGS.QUERIES.getHandShakeToken(),
    variables: {},
    fetchPolicy: "no-cache"
  })    
  .then(({data={}}) => {
    const {getHandShakeToken} = data;
    window.handshaketoken = getHandShakeToken;        
    return getHandShakeToken;
  })
  .catch((error)=>{
    console.dir("API_CALLS.SITE_SETTINGS.QUERIES.getHandShakeToken error", error);
  })
}


//THIS ON CAN WILL FORCE A SAVE FOR ANY TRACKING THING WE WANT TO KEEP.
const APISaveForTracking = (variables={})=>{
  if(navigator.userAgent === 'ReactSnap') return window.GlobalUtil.delay(10); //IF REACT-SNAP IS JUST VIEWING THE PAGE THEN DO NOTHING
  var variables = {
    status: "ACTIVE",
    language: window.Session.Language,
    params: (window.Session.urlParams ? JSON.stringify(window.Session.urlParams).toUpperCase() : ""),
    //page: "HOME",
    //type: "PAGE",
    ...variables 
  };
  variables = window.GlobalUtil.APICleanObj(variables);      
  //console.log(`APISaveForTracking`,"\n\n","\n\n");
        
  return APIGetHandshakeToken().then(()=>{
    window.Client.mutate({
      mutation: API_CALLS.TRACKING.MUTATIONS.create(),
      variables: variables
    })
    .then(({data={}}) => {
      const {tracking} = data;
      window.handshaketoken = undefined;    
      window.GlobalUtil.consoleLog("APISaveForTracking -> SUCCESS");
      return tracking; 
    })

    .catch((error)=>{
      console.dir("APISaveForTracking error", error);
    })
  });
}


//THIS ON CAN BE RUN ON ANY PAGE AND WILL ONLY RUN ONCE, FOR NEW USERS.
const APISaveForTrackingUnique = async (variables={})=>{  //{page: "HOME",type: "PAGE"}
  //return;
  window.GlobalUtil.consoleLog("APISaveForTrackingUnique", variables);
  if(navigator.userAgent === 'ReactSnap') return;
  var PageAlreadySubmitted = await window.GlobalUtil.LocalStorage.get("setPageAsViewed");
  if(!PageAlreadySubmitted) PageAlreadySubmitted = false; //IF PAGE HAS NOT BEEN LOADED MARK IT;

  if(PageAlreadySubmitted) return false;
  if(window.Session && window.Session.user && window.Session.user._id) { //IF HAVE A LOGIN THEN DON'T TRACK. DON'T TRACK EXISTING USERS.
    window.GlobalUtil.LocalStorage.set("setPageAsViewed", true);
    return false; 
  }

  await APISaveForTracking(variables)
    .then(()=>{
      window.GlobalUtil.LocalStorage.set("setPageAsViewed", true);
      window.handshaketoken = undefined;     
    });
  return true;
}

export default APISaveForTracking;
export {
  APIGetHandshakeToken,
  APISaveForTrackingUnique
};


