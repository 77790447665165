import React from 'react';
//import { gql, useQuery, useMutation } from '@apollo/client';
import FormBuilder from "global/components/FormBuilder/index";
import Loading from 'global/components/Loading/index';
import API_CALLS from 'API_CALLS/index';
import MUTATION_FIELDS_DEFAULT from "API_CALLS/MUTATION_FIELDS_DEFAULT";
///Users/danielezaldivar/Sites/sonrisas/src/styles/admin/components/AdminProgressLogs.scss
///Users/danielezaldivar/Sites/sonrisas/src/global/components/FormBuilder/comp-notesList.js
///Users/danielezaldivar/Sites/sonrisas/src/global/components/EditNote/index.js
/*
  <DialogProgressLogs
    ID={""}
    API_NAME={'TREATMENT_PLANS'}
    FIELD={'treatmentplan'}
    asButton={'false'}
    className={''}
    style={{}}
    customLinkText={''}
  />
*/

const Index = (props) => {        
  if(props.asButton) return(
      <button className={props.className} style={props.style ? props.style : {}} onClick={(e)=>{              
        e.preventDefault();
        window.GlobalUtil.triggerEvent("SlideInRightEvent", {
          TITLE: <span><i className="fas fa-clipboard-list" style={{"padding": "0 10px 0 10px"}}/> Notes List</span>,
          customClass: "frontEndSlideInRight mediumSlideInRight",
          width: " ",
          noFooter: true,
          children: (childrenProps)=>{                                      
            return(
              <EditLogs {...{...props, ...childrenProps}}/>
            )
          }
        });
      }}>
        {props.customLinkText ? props.customLinkText : <i className="fas fa-clipboard-list"></i>}
      </button>
  );


  return(
    <div className="resetPWButton">
      <a href="#" className={props.className} onClick={(e)=>{
        e.preventDefault();
        window.GlobalUtil.triggerEvent("SlideInRightEvent", {
          TITLE: <span><i className="fas fa-clipboard-list" style={{"padding": "0 10px 0 10px"}}/> Notes List</span>,
          customClass: "frontEndSlideInRight mediumSlideInRight",
          width: " ",
          noFooter: true,
          children: (childrenProps)=>{                                      
            return(
              <EditLogs {...{...props, ...childrenProps}}/>
            )
          }
        });
      }}>
        {props.customLinkText ? props.customLinkText : <i className="fas fa-clipboard-list"></i>}
      </a>
    </div>
  )
}






class EditLogs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      curStatus: false,
      status: 'loading',
    };    
  }

  componentDidMount(){
    this.APIGetNotes();
  }

  componentWillUnmount(){
  }

  onFormChange = newObj => {
    if(newObj.whatEmail && (this.state.curObj.whatEmail != newObj.whatEmail)){
      this.setState({
        curObj: newObj,
        status: 'noForm'
      }, ()=>{
        this.setState({status: 'start'});
      });
    } else {
      this.setState({
        curObj: newObj
      });
    }
  }

  APIGetNotes = () => {  
    return window.Client.query({
      query: API_CALLS[this.props.API_NAME].QUERIES.single(),
      variables: {
        id: this.props.ID
      },
      fetchPolicy: "no-cache"
    }).then(obj=>{
      //console.log(`APIGetNotes`,"\n\n",obj,"\n\n");
      var curObj = window.GlobalUtil.stripOutFromObj(obj.data[this.props.FIELD], "__typename");
      this.setState({
        curObj: {
          ...curObj,
          id: curObj._id
        },
        status: 'start'
      });
    }).catch(fail=>{
      this.setState({
        status: 'failed'
      });
      console.log(`API GET LOGS FAILED`,"\n\n",fail,"\n\n");
    });
  }


  APIUpdateNotes = (newObj) => {            
    var newVariables = {
      id: newObj._id
    };
    var pathArray = this.props.pathToNotesList.split(",");
    if(pathArray.length === 1) {
      newVariables[this.props.pathToNotesList] = newObj[this.props.pathToNotesList];
    } else {            
      newVariables[pathArray[0]] = window.GlobalUtil.deepGetFromString(newObj, pathArray[0], {});
      var curNotesList = window.GlobalUtil.deepGetFromString(newObj, this.props.pathToNotesList, []);
      window.GlobalUtil.deepSetFromString(newVariables, this.props.pathToNotesList, curNotesList);
      //newVariables[pathArray[0]] = newObj[this.props.pathToNotesList];
    }
    //console.log(`APIUpdateNotes newObj`,"\n\n",newObj,"\n\n");
    //console.log(`newVariables`,"\n\n",newVariables,"\n\n");
    return window.Client.mutate({
      mutation: API_CALLS[this.props.API_NAME].MUTATIONS.update(),
      variables: newVariables
    });
  }


  onSave = () => {
    var {curObj} = this.state;
    this.setState({status: 'loading'});
    const newVar = JSON.parse(JSON.stringify(curObj));
    this.APIUpdateNotes(newVar)
      .then((result)=>{
        window.GlobalUtil.delay(500).then(()=>{ //ADD A 2 SEC DELAY FOR MORE USER FRIENDLY
          var sendEmail = result.data.sendEmail;
          if(this) this.setState({status: 'success'});
          if(this.props.onUpdated) this.props.onUpdated(); //THIS RUNS TO RELOAD THE CURRENT SLIDE OUT DATA
          if(this.props.onSuccess) this.props.onSuccess(true); //UPDATE THE DATABASE PAGE AREA 
          window.GlobalUtil.DialogStack.closeTop();
        });
      })
      .catch((error)=>{
        if(this) this.setState({status: 'failed', errorMessage: error.toString()});
        console.log(`error`,"\n\n",error,"\n\n");    
        if(this.props.onUpdated) this.props.onUpdated();
        window.GlobalUtil.DialogStack.closeTop();
      });
        
  }

  render(){
    var {status, curObj, curStatus, errorMessage} = this.state;            
    console.log(`this.props`,"\n\n",this.props,"\n\n");
              
    return (
      <div id="AdminProgressLogs" className=''>
        {
          status === 'failed' && 
          <div className="body">
            <div className="startText">
              API FAILED!
            </div>
          </div>
        }
        {
          status === 'loading' && 
          <Loading
            header={"Loading"}
            body={"Loading Logs"}
            floating={false}
            overlay={false}
            inlineStyles={{
              LoadingScreen: {
                "minHeight": "190px",
                //"border": "1px solid rgb(221, 221, 221)",
                "borderRadius": "10px",
                "position": "relative",
                "paddingTop": "50px"
              },
              loadingIcon: {
                "maxWidth": "80px"
              },
              loadingHeader: {
                "fontSize":"18px",
                "marginBottom":"0",
                "paddingTop":"20px"
              }
            }}
          />
        }
        {
          status === 'start' && 
          <React.Fragment>
            <div className="body">
              <div className="startText">
                {/*<div className="text">
                  <i className="fas fa-envelope-open-text"></i> Fill out to send email
                </div>
                <br/>*/}
                <FormBuilder
                  ref={e => this.formRef = e}
                  initialValues={curObj}
                  listOfFields={[
                    {
                      title: <div className="formTitle"><span className="">Notes List</span></div>,
                      subTitle: <div className="formSubTitle"><span className="">Please list off every action taken. From contacting the user, to sending emails, to contacting doctors and more.</span></div>,
                      type: "notesList",
                      name: this.props.pathToNotesList,
                      defaultCategoriesProps: 
                        this.props.defaultCategoriesProps
                        ? this.props.defaultCategoriesProps
                        : [
                            {
                              "value": "UPLOAD_FILES",
                              "title": "UPLOAD_FILES"
                            },
                            {
                              "value": "EMAIL_UPLOAD",
                              "title": "EMAIL_UPLOAD"
                            },
                            {
                              "value": "EMAIL_APPROVE",
                              "title": "EMAIL_APPROVE"
                            },
                            {
                              "value": "EMAIL_USER",
                              "title": "EMAIL_USER"
                            },
                            {
                              "value": "EMAIL_REQUEST_FULL",
                              "title": "EMAIL_REQUEST_FULL"
                            },
                            {
                              "value": "SCHEDULED_ALIGNERS",
                              "title": "SCHEDULED_ALIGNERS"
                            },
                            {
                              "value": "CREATED_ALIGNERS",
                              "title": "CREATED_ALIGNERS"
                            },
                            {
                              "value": "SHIPPED_PRODUCT",
                              "title": "SHIPPED_PRODUCT"
                            },
                            {
                              "value": "OTHER",
                              "title": "OTHER"
                            }
                          ]
                      ,
                      defaultValue: "",
                      required: false,
                      defaultValuePath: "progressLogs",
                      errorMessage: "This is required"
                    }
                  ]}
                  onSubmit={this.onSave}
                  onChange={this.onFormChange}
                  onValidate={(isValid)=>{
                    this.setState({curStatus: isValid});
                  }}
                />
              </div>
            </div>
            <div className="footer">
              <div className="buttonGroup split">
                <button className="button button2 btn-color6" onClick={()=>{
                  if(this.props.onSuccess) this.props.onSuccess(false)
                }}>Close</button>
                <button className={`button button1 ${(curStatus) ? '' : 'disabled'}`} onClick={this.onSave} disabled={!curStatus}>Save Logs&nbsp;&nbsp;<i className="fas fa-save" style={{"padding": "0 10px 0 0 "}}/></button>
              </div>
            </div>
          </React.Fragment>
        }
      </div>
    );
  }
}


export default Index;