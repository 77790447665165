import React from 'react';
//import {NavLink, Link} from 'react-router-dom';

//import BannerLong from 'global/img/banner/bannerLong1.jpg';
import BannerLong1500 from 'global/img/banner/bannerLong1500.jpg';
import BannerLong1200 from 'global/img/banner/bannerLong1200.jpg';
import BannerLong940 from 'global/img/banner/bannerLong940.jpg';
import BannerLong700 from 'global/img/banner/bannerLong700.jpg';
import BannerLong485 from 'global/img/banner/bannerLong485.jpg';

import Logo from "global/components/Logo/index";
import CONTENTS from "./CONTENTS";
const {Container, Row, Column} = window.LayoutBuilder;


class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      swipeDirection: ''
    };    
  }

  componentDidMount(){
    if(this.compareWrapperRef) {
      var scrollWidth = this.compareWrapperRef.scrollWidth-this.compareWrapperRef.offsetWidth;
      this.compareWrapperRef.addEventListener("scroll", this.trackScrolling);         
      if(scrollWidth > 4)  this.setState({swipeDirection: 'firstLoop'})
    } 
  }

  componentWillUnmount(){
    this.compareWrapperRef.removeEventListener("scroll", this.trackScrolling);
    //clearTimeout(this.TimeoutRef);
  }

  trackScrolling = () => {
    const maxRight = (this.compareWrapperRef.scrollWidth-this.compareWrapperRef.offsetWidth) - 10;
    const maxLeft = 30;
    //clearTimeout(this.TimeoutRef);
    //this.TimeoutRef = setTimeout(()=>{
      const scrollLength = this.compareWrapperRef.scrollLeft;            
      if(scrollLength >= maxRight) this.setState({swipeDirection: "swipeLeft"});
      else if(scrollLength <= maxLeft) this.setState({swipeDirection: "swipeRight"});
      else this.setState({swipeDirection: ""});
    //}, 100)
  }

  render(){
    var {swipeDirection} = this.state;
          
    var Language = window.Session.Language;
    const CONTENT = window.GlobalUtil.dangerSetGlobalObjList(CONTENTS[window.Session.Language].CONTENT); 
    return (
      <div id="UsVsThem" className=" backgroundSVG5">
        <Container>
          <Row className="">
            <Column className="text-center" col="" xs="" sm="" md="" lg="" xl="">
              <div className="">
                {CONTENT.Compare[0]}
                <br/>     
                <div className="">
                  <img 
                    src={BannerLong1500} 
                    alt="Woman holding clear and traditional braces."
                    srcSet={`
                      ${BannerLong485} 485w,
                      ${BannerLong700} 700w,
                      ${BannerLong940} 940w,
                      ${BannerLong1200} 1200w,
                      ${BannerLong1500} 1500w
                    `}
                    sizes={`
                      (max-width: 767px) 485px, 
                      (max-width: 991px) 700px, 
                      (max-width: 1199px) 940px, 
                      (max-width: 1399px) 1200px, 
                      100vw
                    `}
                  />
                </div>
                <br/> 
              </div>
            </Column>
          </Row>
          <Row className="">
            <Column className="contentCenter">
              <div className="usVsThemCompare">
                <div className="sections">
                  <div className="sectionRow first"></div>
                  {
                    CONTENT.Compare[3].map((SubArray, index)=>{
                      return(
                        <div key={index} className="sectionRow">{SubArray[0]}</div>
                      )
                    })
                  }
                </div>
                <div className="compare" ref={e=>this.compareWrapperRef = e}>
                  <div className={`handNotification ${swipeDirection}`}>
                    <i className='fas fa-hand-pointer' />
                    <i className='far fa-hand-pointer' />
                  </div>
                  <div className="compareColumn active">
                    <div className="sectionRow first"><Logo/></div>
                    {
                      CONTENT.Compare[3].map((SubArray, index)=>{
                        return(
                          <div key={index} className="sectionRow">{SubArray[1]}</div>
                        )
                      })
                    }
                    {/*<div className="sectionRow"><div className="checkBox"><i className="fas fa-check"></i></div></div>*/}
                  </div>
                  <div className="compareColumn">
                    <div className="sectionRow first"><div className="titleText">{CONTENT.Compare[1]}</div></div>
                    {
                      CONTENT.Compare[3].map((SubArray, index)=>{
                        return(
                          <div key={index} className="sectionRow">{SubArray[2]}</div>
                        )
                      })
                    }
                    {/*<div className="sectionRow"><div className="checkBox"><i className="fas fa-check"></i></div></div>*/}
                  </div>
                  <div className="compareColumn">
                    <div className="sectionRow first"><div className="titleText">{CONTENT.Compare[2]}</div></div>
                    {
                      CONTENT.Compare[3].map((SubArray, index)=>{
                        return(
                          <div key={index} className="sectionRow">{SubArray[3]}</div>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
            </Column>
          </Row>
        </Container>
      </div>
    );
  }
}


export default Index;
      