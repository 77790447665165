import React from 'react';
import 'styles/scss/website/pages/faq.scss';
import {Helmet} from "react-helmet-async";
import TemplatePage from 'website/templates/templatePage/index.js';
import Accordion from 'global/components/Accordion/index';
import CONTENTS from "./CONTENTS";
const {Container, Row, Column} = window.LayoutBuilder;


class Index extends TemplatePage {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     ...this.state
  //   };    
  // }

  didMount(){
  }

  willUnmount(){
  }

  render(){
    //var {showSideMenu} = this.state;
    const CONTENT = window.GlobalUtil.dangerSetGlobalObjList(CONTENTS[window.Session.Language].CONTENT); 
    const HEADER = CONTENTS[window.Session.Language].HEADER; 
    return (
      <div className="contentPage" id="PageFAQ">
        <Helmet>
            <meta charSet="utf-8" />
            <title>{HEADER.TITLE}</title>
            <meta name="description" content={HEADER.DESCRIPTION}/>
            <link  href={`${this.props.location.pathname}`} />
            <link rel="canonical" href={`${HEADER.BASEURL}${this.props.location.pathname}`} />
            <meta name="keywords" content={HEADER.KEYWORDS} />

            {"<!-- Google / Search Engine Tags -->"}
            <meta itemprop="name" content={HEADER.TITLE} />
            <meta itemprop="description" content={HEADER.DESCRIPTION}/>
            <meta itemprop="image" content="https://Smiles.Club/SocialImage_500.jpg" />

            {/*<!-- Facebook Meta Tags -->*/}
            <meta property="og:url" content={`${HEADER.BASEURL}${this.props.location.pathname}`} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={HEADER.TITLE} />
            <meta property="og:description" content={HEADER.DESCRIPTION}/>
            <meta property="og:image" content="https://Smiles.Club/SocialImage_500.jpg" />

            {/*<!-- Twitter Meta Tags -->*/}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={HEADER.TITLE} />
            <meta name="twitter:description" content={HEADER.DESCRIPTION}/>
            <meta name="twitter:image" content="https://Smiles.Club/SocialImage_500.jpg" />
        </Helmet>
        <div className="backgroundSVG5">
          <Container className="">
            {
              (window.Session.Language === "ENGLISH")
              ? <Row className="title">
                  <Column className="" col="" xs="" sm="" md="" lg="" xl="">
                    <div className="">
                      <IframeVideo />
                    </div>
                    <br/>
                  </Column>
                  <Column className="contentCenter" col="12" xs="12" sm="12" md="12" lg="6" xl="6">
                    <div className="">
                      {CONTENT.Section1[0]}
                    </div>
                  </Column>
                </Row>
              : <Row className="">
                  <Column className="" col="" xs="" sm="" md="" lg="" xl="">
                    <div className="">
                      {CONTENT.Section1[0]}
                    </div>
                  </Column>
                </Row>
            }
          </Container>
        </div>
        <div className="">  
          <Container className="">
            <Row className="">
              <Column className="" col="" xs="" sm="" md="" lg="" xl="">
                <div className="">
                  <Accordion
                    fields={CONTENT.Accordion}
                  />
                </div>
                <br/>
                <br/>
              </Column>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}




class IframeVideo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true, 
      width: 0,
      height: 0
    };    
  }
  
  componentDidMount(){
    var width = this.containerSize.parentElement.offsetWidth;
    this.setState({
      loading: false,
      width: width,
      height: (width * (480/853))
    });
  }


  render(){
    const {loading, width, height} = this.state;
    if(loading) return(
      <div ref={e=> this.containerSize = e}></div>
    )
    return(
      <iframe
        width="100%"
        height={height}
        src={"https://www.youtube.com/embed/mIh3c1joHxs"}
        frameBorder="0"
        //allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    )
  }
}

export default Index;
      