import React from 'react';
import API_CALLS from 'API_CALLS/index';
import FormBuilder from "global/components/FormBuilder/index";
import Loading from 'global/components/Loading/index';


//?CODE=VAL-PAC-001 SUMMERDEAL
class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      refCode: this.props.refCode,
      curStatus: true,
      statusMessage: undefined
    };    
  }

  componentWillUnmount(){
    if(this.DelayPointer) this.DelayPointer.abort(); //IF WENT BACK BEFORE FOUND THEN CANCEL ANY API SEARCH
  }

  setCoupon = (couponObj={})=>{         
    var newOrder = window.GlobalUtil.State.get("newOrder") 
    newOrder.coupon = window.GlobalUtil.APICleanObj(couponObj)
    window.GlobalUtil.State.set("newOrder", newOrder);
    window.GlobalUtil.triggerEvent("changedCoupon", couponObj);
  }

  onCheckReferral = () => {
    var {refCode} = this.state;    
    this.setState({ loading: true})
    this.DelayPointer = window.GlobalUtil.delayWithAbort(1000, ()=>{
      window.Client.query({
        query: API_CALLS.COUPONS.QUERIES.couponFinder(),
        variables: {
          "code": refCode
        }
      })
      .then(async ({data={}}) => {              
        var statusMessage = "success";
        console.log('data', data);
        //console.log(`data`,"\n\n",data,"\n\n");
        if(!data.couponFinder || !data.couponFinder._id) {
          statusMessage = "failed";
        }
        var coupon = ((statusMessage !== "failed") ? window.GlobalUtil.APICleanObj(data.couponFinder) : undefined);              
        window.GlobalUtil.consoleLog(`WEBSITE -> PAGE -> BENEFITS -> ReferralCodeSearch -> onCheckReferral() -> SUCCESS`,coupon);
              
        if(coupon) {
          this.props.onSuccess();                
          await this.setCoupon(coupon);
        }

        this.setState({
          refCode: "",
          loading: false,
          coupon: coupon,
          statusMessage: statusMessage
        }); 
      })
      .catch((error)=>{
        let errorMessage = error.message;
        window.GlobalUtil.consoleLog(`WEBSITE -> PAGE -> BENEFITS -> ReferralCodeSearch -> onCheckReferral() -> ERROR`,error);
        console.log("onSubmitLogin errorMessage");
        console.dir(errorMessage,"\n\n");
        console.dir(error,"\n\n");
        //if(errorMessage === "Unexpected error value: \"CODE NOT FOUND\"")
        this.setState({
          refCode: "",
          loading: false,
          statusMessage: "failed"
        }); 
      })
    })
  }

  render(){
    var {curStatus, loading, statusMessage, coupon, refCode} = this.state;     
    const {CONTENT} = this.props;     
    
    if(loading) return (
      <div id="ReferralCodeSearch">
        <Loading
          header={CONTENT[0]}
          body={CONTENT[1]}
          floating={false}
          overlay={true}
          inlineStyles={{
            loadingIcon: {
              "maxWidth": "150px"
            }
          }}
        />
      </div>
    )
    if(statusMessage === "failed"){
      return(
        <div id="ReferralCodeSearch">
          <div className="messageBox">
            <div className="messageText animated fadeIn message">
              <h2>{CONTENT[2]}</h2>
              <h3 className="color5">{CONTENT[3]}</h3>
              <div className="messageTextDesc">{CONTENT[4]}</div>
              <button className={`button button1 buttonFull`} onClick={()=>{
                this.setState({statusMessage: undefined})
              }}>{CONTENT[5]}</button>
            </div>
          </div>
          </div>
      )
    }
    if(statusMessage === "success"){
      return(
        <div id="ReferralCodeSearch">
          <div className="messageBox">
            <div className="messageText animated fadeIn message">
              <h2>{CONTENT[6]}</h2>
              <h3 className="color1">{CONTENT[7]}</h3>
              <div className="messageTextDesc">{(coupon && coupon.description) ? window.GlobalUtil.dangerSet(coupon.description) : null}</div>
              <div className="actionDiv">{CONTENT[8]}</div>
              <div className="downArrow" onClick={()=>{
                if(document.querySelector("#WhatsNextHeader")) window.scroll(0, document.querySelector("#WhatsNextHeader").offsetTop-document.querySelector("#Header").offsetHeight);
              }}><i className="fas fa-angle-down"></i></div>
            </div>
          </div>  
        </div>
      )
    }
    return(
      <div id="ReferralCodeSearch">
        <FormBuilder
          //ref={formRef}
          initialValues={{refCode: refCode}}
          listOfFields={[
            {
              //title: <div className="formTitle"><span className="">{(window.Session.Language === "ESPAÑOL") ? "Codigo" : "Code"}</span></div>,
              type: "text",
              name: "refCode",
              placeholder: CONTENT[9],
              required: true,
              formatInput: (inputValue)=>{
                if(!inputValue) return "";
                inputValue = inputValue.toUpperCase();
                //inputValue = inputValue.split("-");
                //inputValue = inputValue.join("");
                // if(!inputValue) return "";
                // if(inputValue.length > 9) inputValue = inputValue.slice(0,9);
                // if(inputValue.length > 6){
                //   inputValue = `${inputValue.slice(0,3)}-${inputValue.slice(3,6)}-${inputValue.slice(6)}`
                // } else if(inputValue.length > 3){
                //   inputValue = `${inputValue.slice(0,3)}-${inputValue.slice(3,6)}`
                // } 
                return inputValue;
              }
            }
          ]}
          onSubmit={e=>{
            if(e && e.preventDefault) e.preventDefault()
            this.onCheckReferral()
          }}
          onChange={newObj => this.setState({refCode: newObj.refCode})}
          onValidate={(isValid)=>{this.setState({curStatus: isValid})}}
        />
        <button className={`button button1 buttonFull ${!curStatus ? "disabled" : ""}`} onClick={this.onCheckReferral} disabled={!curStatus}>{CONTENT[10]}</button>
      </div>
    )
  }
}


export default Index;