import React, { useState, useRef } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import TemplateAction from './templateAction';
import API_CALLS from 'API_CALLS/index';
import ImageUtils from "global/utils/image-utils";
const IMAGE_UTILS =  new ImageUtils();
const {Container, Row, Column} = window.LayoutBuilder;
const MUTATION_EDIT = API_CALLS.TREATMENT_PLANS.MUTATIONS.update();
/*

//DUMP ALL STL'S TO PLAN->alignerFilesTemp
//THEN IN THE NEXT SchedualAligners we will decided what goes where and what does not

*/






const FORM_FIELDS = (obj)=>{
  return [
    {
      title: <div className="formTitle"><span className="">All Aligner STL Files</span></div>,
      type: "fileUploadList",
      name: "alignerFilesTemp",
      maxNumber: 1,
      required: false,
      defaultValue: "",
      defaultValuePath: "alignerFilesTemp",
      maxWidth: "150px",
      rename: true,
      filePath: [
        "USERS",
        obj.UID,
        "TREATMENT_PLANS",
        obj.id,
        "ALIGNERS"
      ],
      onChange: (newFileList, callBack=()=>{})=>IMAGE_UTILS.onChangeFile(
        newFileList, 
        obj.id,
        "alignerFilesTemp", 
        MUTATION_EDIT,
        window.CurObj,
        ()=>{
          window.APIGetAgain();
          callBack();
        })
    }
  ]
};

class Index extends TemplateAction {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state
    };       
  }


  validate = (dontFinish=false) => {
    var curObj = {...this.curObj};
    Object.keys(curObj).map((key, index)=>{
      if((curObj[key]  === undefined) || (curObj[key]  === "")) delete curObj[key] 
    })
    curObj = window.GlobalUtil.stripOutFromObj(curObj, "__typename")
    //console.log("Saved","\n\n",curObj,"\n\n");
    /*
      SHOULD HAVE
        _id
        UID
        status
        offerRequest: {
          chiefComplaint String
          notes String
          createAt Date
        }
    */
    //return false;
    this.APIUpdate(curObj, dontFinish);
    //this.APICompleteTask(curObj);
  }

  APIGet = () => {         
    var inputObj = {
      query: API_CALLS.COMMISSIONS.QUERIES.commissionsByUser(),
      variables: {
        id: this.props.curObj.user.UID
      },
      fetchPolicy: "no-cache"
    }
    window.Client.query(inputObj)
    .then((obj) => {
      var {data, loading} = obj;     
      console.log('data', data);
      var commissionsByUser = window.GlobalUtil.stripOutFromObj(data.commissionsByUser, "__typename");
      // this.curObj = {
      //   ...commissionsByUser
      // };
      // window.CurObj = {...this.curObj};
      //console.log("window.CurObj","\n\n",window.CurObj,"\n\n");
      if(!this) return;
      this.setState({
        commissionsByUser: commissionsByUser,
        hideForm: true
      }, ()=>{
        this.setState({hideForm: false})
      })
    })
    .catch((error)=>{
      console.log("query plan failed", error);
    });
  }


  


  APIUpdate = (newObj, dontFinish) => {
    if(!dontFinish){
      newObj.rejectStepNotes = "";//THIS SHOULD BE REMOVED OR ADDED EVERY STEP
      newObj.changeStep = 1;//MOVE TO NEXT STEP WHEN DONE
      window.GlobalUtil.deepSetFromString(newObj, "HISTORY,LABORATORY,UPLOADED_FULL", `${Date.now()}`);
    }
    var inputObj = {
      mutation: MUTATION_EDIT,
      variables: newObj
    }
    window.Client.mutate(inputObj)
    .then((obj) => {
      var {data, loading} = obj;
      if(!dontFinish) {
        window.CurRefetchList(true);
        this.onClose(this.CurRef);
      }
    })
    .catch((error)=>{
      console.log("onCloneCatch catch", error);
      this.onClose(this.CurRef);
    });
  }



  render(){
    var {showSlideIn, isValid, commissionsByUser, hideForm, isLoading} = this.state;              
    return (
      this.SlideInRight(
        "View Commissions Owed", 
        (curRef)=>{
          this.CurRef = curRef;
          return(
            <React.Fragment>
              <button className="button button2 btn-color6" onClick={()=>{
                if(curRef) curRef.onClose()
              }}>Close</button>
              <div className="buttonGroup">
                <button className={`button button1 ${(isValid && !isLoading) ? "" : "disabled"}`} onClick={()=>{ 
                  this.validate();
                }}>Mark Paid <i className="fas fa-tooth"></i></button>
              </div>
            </React.Fragment>
          )
        }, 
        ()=>{
          return(
            <div id="ActionPreviewUserCommission">
              <div className="PreviewForm">
                {
                  commissionsByUser 
                  ? <Row>
                      <Column 
                        className={"borderColor5"}
                        style={{"paddingBottom":"0px"}}>
                        <div className="previewFormFieldTitles2">
                          <div className="previewFormFieldTitle">Commissions By User</div>
                          <div>User: {commissionsByUser.user.firstName} {commissionsByUser.user.lastName}</div>
                          <div>ID: {commissionsByUser.user.UID}</div>
                          <div>Earned: {window.GlobalUtil.convertToMoney(commissionsByUser.EARNED)}</div>
                          <br/>
                          <br/>
                          <div className="previewFormFieldTitle">Commissions:</div>
                          <div className="tableWrapper">
                            <table className="table commissions">
                              <thead className="thead">
                                <tr>
                                  <th>
                                    <div className={`headerCol`}>
                                      Earned
                                    </div>
                                  </th>
                                  <th>
                                    <div className={`headerCol`}>
                                      Date
                                    </div>
                                  </th>
                                  <th>
                                    <div className={`headerCol`}>
                                      Coupon Code
                                    </div>
                                  </th>
                                  <th>
                                    <div className={`headerCol`}>
                                      Coupon Name
                                    </div>
                                  </th>
                                  <th>
                                    <div className={`headerCol`}>
                                      Commission ID
                                    </div>
                                  </th>
                                  <th>
                                    <div className={`headerCol`}>
                                      Coupon ID
                                    </div>
                                  </th>
                                  <th>
                                    <div className={`headerCol`}>
                                      Order ID
                                    </div>
                                  </th>
                                  <th>
                                    <div className={`headerCol`}>
                                      Order Item ID
                                    </div>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                              {
                                commissionsByUser && commissionsByUser.commissions.map((obj, index)=>{    
                                  return(
                                    <tr key={index} className={`commission`}>
                                      <td>
                                        <div className="tableCol">
                                          {window.GlobalUtil.convertToMoney(obj.EARNED)}
                                        </div>
                                      </td>
                                      <td>
                                        <div className="tableCol">
                                          {window.GlobalUtil.dateBasic(obj.APPROVED_DATE, "DAY")}
                                        </div>
                                      </td>
                                      <td>
                                        <div className="tableCol">
                                          {obj.CODE}
                                        </div>
                                      </td>
                                      <td>
                                        <div className="tableCol">
                                          {obj.coupon.name}
                                        </div>
                                      </td>
                                      <td>
                                        <div className="tableCol">
                                          {obj.ID}
                                        </div>
                                      </td>
                                      <td>
                                        <div className="tableCol">
                                          {obj.CPID}
                                        </div>
                                      </td>
                                      <td>
                                        <div className="tableCol">
                                          {obj.OID}
                                        </div>
                                      </td>
                                      <td>
                                        <div className="tableCol">
                                          {obj.OIID}
                                        </div>
                                      </td>
                                    </tr>
                                  )
                                })
                              }
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </Column>
                    </Row>
                  :null
                }
                
                <Row>
                  <Column 
                    className={"borderColor5"}
                    style={{"paddingBottom":"0px"}}>
                  <div className="previewFormFieldTitles2">
                    <div className="previewFormFieldTitle">Needed Input</div>
                    <div className="previewFormFieldSubTitle">What to add to mark this as paid</div>
                  </div>
                  </Column>
                </Row>
                <Row>
                  <Column 
                    className={""}
                    style={{"paddingBottom":"0px"}}>
                      <ul className="">
                        <li>CMIDS</li>
                        <li>UID</li>
                        <li>total</li>
                        <li>checkNum</li>
                        <li>voided</li>
                        <li>paid</li>
                        <li>paidDate</li>
                        <li>mailedDate</li>
                        <li>status</li>
                        <li>address</li>
                      </ul>
                  </Column>
                </Row>
              </div>
              {/* {
                (this.curObj && this.curObj.id) && 
                this.FormBuilderZone(FORM_FIELDS)
              } */}
            </div>
          )
        }
      )
    );
  }
}




export default Index;