import React from 'react';
import API_CALLS from 'API_CALLS/index';
import TemplatePage from "global/templates/templatePage/index";
import TemplatePreview from "global/templates/templatePreview/index";
import DialogProgressLogs from 'admin/components/DialogProgressLogs/index';
import ActionPreviewUserCommission from "./actions/ActionPreviewUserCommission";
//import UploadAligners from "./actions/UploadAligners";
//import ScheduleAligners from "./actions/ScheduleAligners";


/*
□ CREATE QUERY TO GET ALL AFFILIATES WHO WE OWE MONEY TOO AND HOW MUCH
□ ON HERE OR ADMIN SIDE PULL THAT API
□ CREATE A WAY TO SUBMIT A CHECK FOR ALL THOSE COMMISSIONS WE WANT TO PAY
□ ON THE USER SIDE
  □ PULL COMMISSION API FROM NEW COMMISSION QUERY
  □ PULL CHECKS FROM NEW COMMISSION CHECK API

□ CREATE BUTTON TO EASILY CREATE NEW AFFILIATE AND CHOSE 1+ COUPONS FOR THAT USER AT ONCE

□ TEST AGAIN AND IF ALL WORKS PUSH TO LIVE AND UPDATE DB TOO

□ CREATE FORM_FIELDS FOR COMMISSION API
□ CREATE FROM_FIELDS FOR COMMISSION CHECK API


LATER:
  (THIS MUST BE DONE AFTER I UPLOAD EVERYTHING AND UPDATE THE DATABASE OR WE WONT BE ABLE TO ACCESS THAT INFO TO CREATE THE COMMISSIONS FORM THE ORDER_ITEMS)
  □ REMOVE PROMO FROM ORDER ITEM
    □ ALLOW IT TO TAKE PROMO BUT NO LONGER HAVE IT AS A SAVED OBJECT

TODO TEST BEFORE LAUNCH:
  □ ON BUY CREATE COMMISSION
  □ SHOW COMMISSION ON ADMIN SIDE AS PENDING
  □ ON APPROVE SHOW COMMISSION TO USER SIDE
  □ ON PAID COMMISSION:
    □ ADD USER COMMISSION CHECK
    □ SHOW COMMISSION CHECK ON ADMIN SIDE
  □ DB BUTTON TO CREATE COMMISSION FOR EACH ORDER ITEM
*/



const QUERY = API_CALLS.ALIGNER_GROUPS.QUERIES.allAndCount(`
  ID
  AIDS
  TPID
  shippedAt
  scheduledAt
  createdAt
  createdBy
  status
  step
  user{
    firstName 
    lastName
    email
  }
  meta{
    createAt
    updateAt
  }
`);
const QUERYALL = API_CALLS.ALIGNER_GROUPS.QUERIES.all();
const QUERY_SINGLE = API_CALLS.ALIGNER_GROUPS.QUERIES.single();
const QUERY_COUNT = API_CALLS.ALIGNER_GROUPS.QUERIES.count();
const MUTATION_EDIT = API_CALLS.ALIGNER_GROUPS.MUTATIONS.update();
const MUTATION_ADD = API_CALLS.ALIGNER_GROUPS.MUTATIONS.create();
const QUERY_GET_USERS = API_CALLS.USERS.QUERIES.all(`email`);


const FORM_FIELDS = API_CALLS.ALIGNER_GROUPS.FORM_FIELDS;
const PREVIEW = API_CALLS.ALIGNER_GROUPS.PREVIEW;




const LIST_HEADER_FIELDS = [
  {
    "path": "treatmentPlan.user.firstName,treatmentPlan.user.lastName",
    "type": "VALUE",
    "name": "User Name"
  },
  {
    "path": "treatmentPlan.user.email",
    "type": "VALUE",
    "name": "Email"
  },
  {
    type: "ID",
    name: "ID/AGID",
    defaultValue: "",
    path: "ID",
    searchable: true
  },
  {
    type: "ID",
    name: "TPID",
    defaultValue: "",
    path: "TPID",
    searchable: true
  },
  {
    type: "VALUE",
    path: "treatmentPlan.UID",
    name: "UID",
    defaultValue: "",
    searchable: true
  },
  {
    type: "DATE",
    name: "scheduledAt",
    defaultValue: "",
    path: "scheduledAt"
  },
  {
    type: "DATE",
    name: "shippedAt",
    defaultValue: "NA",
    path: "shippedAt"
  },
  {
    type: "DATE",
    name: "createdAt",
    defaultValue: "NA",
    path: "createdAt"
  },
  {
    type: "VALUE",
    name: "createdBy",
    defaultValue: "NA",
    path: "createdBy"
  },
  {
    type: "VALUE",
    name: "Status",
    defaultValue: "",
    path: "status"
  }
];


const API_CALLS_FULL = {
  QUERY,
  QUERYALL,
  QUERY_SINGLE,
  QUERY_COUNT,
  MUTATION_EDIT,
  MUTATION_ADD,
  FORM_FIELDS,
  QUERY_GET_USERS
};




const Preview = (props)=>{        
  var {curObj} = props;
  function APIRecreateAligners(curObj, callBack=()=>{}){
    var inputObj = {
      mutation: API_CALLS.ALIGNER_GROUPS.MUTATIONS.recreateAligners(),
      variables: {id: curObj._id}
    }
    window.Client.mutate(inputObj)
    .then((obj) => {
      var {data, loading} = obj;
      window.CurRefetchList(true);
      callBack();
    })
    .catch((error)=>{
      console.log("Failed APIRecreateAligners", error);
      window.alert("Reship Failed try again later");
      callBack();
    });
  }


  return(
    <TemplatePreview
      title="Preview Affiliates Group"
      onClose={props.onClose}
      onSave={props.onSave}
      customButtons={(curRef)=>{
        return(
          <React.Fragment>
            <button className="button button2 btn-color6" onClick={()=>{
              if(curRef) curRef.onClose()
            }}>Close</button>
            <div className="buttonGroup">
              <button className="button button2 btn-color5" onClick={()=>{
                let text = "Are you sure you want to create these aligners AGAIN?";
                if (window.confirm(text) == true) {
                  APIRecreateAligners(curObj, ()=>{
                    if(curRef) curRef.onClose();
                    else window.GlobalUtil.DialogStack.closeTop();
                  });
                } else {
                  console.log("You canceled!");
                }
              }}>Create Again</button>
              <DialogProgressLogs
                ID={curObj._id}
                API_NAME={'TREATMENT_PLANS'}
                FIELD={'treatmentPlan'}
                asButton={true}
                className={'button button1 btn-color4'}
                style={{"width":"100px","minWidth":"0"}}
                customLinkText={<span><span>Logs</span>&nbsp;&nbsp;<i className="fas fa-clipboard-list"/></span>}
              />
            </div>
          </React.Fragment>
        )
      }}>
      <PREVIEW
        VARIABLES={{
          id: curObj._id
        }}
      />
    </TemplatePreview>
  )
}






const ValidateObject = (newObject, onSuccess, onFail)=>{
  if(newObject._id && !newObject.id) newObject.id = newObject._id;      
  //console.log("newObject");
  //console.log(newObject,"\n\n");
        
  Object.keys(newObject).map((key, index)=>{
    if(!newObject[key]) delete newObject[key];
  })  
  onSuccess(newObject);
  //onFail();      
};





const AFFILIATE_COMMISSION_PAGES = () => [
  {
    "id": "AFFILIATES_COMMISSION_GROUPS01",
    "Name": "All",
    "PageName": "All",
    "Paths": ["/admin/affiliates/commissions/all"],
    "GlobalStateVar": ["Commissions,ALL"],
    "TITLE": "All Commissions We Haven't Paid Yet",
    "DATA_FIELD": "commissions",
    //"ActionSlideIn": UploadAligners,
    "LIST_HEADER_FIELDS": API_CALLS.COMMISSIONS.LIST_HEADER_FIELDS,
    "API_CALLS": {
      QUERY: API_CALLS.COMMISSIONS.QUERIES.allAndCount(),
      QUERY_SINGLE: API_CALLS.COMMISSIONS.QUERIES.single(),
      QUERY_COUNT: API_CALLS.COMMISSIONS.QUERIES.count(),
      MUTATION_EDIT: API_CALLS.COMMISSIONS.MUTATIONS.update(),
      MUTATION_ADD: API_CALLS.COMMISSIONS.MUTATIONS.create(),
      QUERY_GET_USERS: API_CALLS.USERS.QUERIES.all(`user`),
      FORM_FIELDS: API_CALLS.COMMISSIONS.FORM_FIELDS,
      PREVIEW: API_CALLS.COMMISSIONS.PREVIEW
    },
    "VARIABLES": {
      "sortBy": "meta.createAt",
      "descending": false,
      "limit": 20,
      "offset": 0,
      "manual": [
        // { //IF WE ADD THIS THEN THEY'LL ONLY SHOW THOSE THAT HAVE BEEN APPROVED, NOT THE PENDING ONES TOO. 
        //   path: "APPROVED",
        //   val: "TRUE"
        // },
        {
          path: "ADMIN_PAID", //DON'T SHOW THOSE THAT HAVE BEEN PAID
          not: true
        }
      ]
    },
    "BUTTONS": [
      {
        width: "110px",
        category: "Preview",
        buttonText: "View",
        className: "btn-color3",
        iconClass: "fas fa-eye",
        onClick: (obj)=>{
          window.GlobalUtil.triggerEvent("TemplateDBEvent", {
            "TYPE": "PREVIEW",
            "BUTTON_TITLE": "Commission",
            "TITLE": "Commission", //TITLE OF SLIDE OUT
            "APINAME": "COMMISSIONS",
            "NAME": "Commission",
            "ID": obj._id
          }); 
        }
      }
    ]
  },
  {
    "id": "AFFILIATES_COMMISSION_GROUPS02",
    "Name": "By User",
    "PageName": "By User",
    "Paths": ["/admin/affiliates/commissions/by_user"],
    "GlobalStateVar": ["Commissions,USERS"],
    "TITLE": "Unpaid Commissions By Influencer",
    "DATA_FIELD": "commissionsByUsers",
    "ActionSlideIn": ActionPreviewUserCommission,
    "LIST_HEADER_FIELDS": [
      {
        path: "user.UID",
        type: "ID",
        name: "UID",
        searchable: true
      },
      {
        "path": "user.firstName,user.lastName",
        "type": "VALUE",
        "name": "User Name",
        searchable: true
      },
      {
        "path": "user.email",
        "type": "VALUE",
        "name": "Email",
        searchable: true
      },
      {
        path: "EARNED",
        type: "MONEY",
        name: "Earned",
        searchable: true
      },
      {
        path: "commissionsCount",
        type: "VALUE",
        name: "commissionsCount",
        searchable: true
      }
    ],
    "API_CALLS": {
      QUERY: API_CALLS.COMMISSIONS.QUERIES.commissionsByUsers(),
      QUERY_SINGLE: API_CALLS.COMMISSIONS.QUERIES.commissionsByUser(),
      QUERY_COUNT: API_CALLS.COMMISSIONS.QUERIES.count(),
      MUTATION_EDIT: API_CALLS.COMMISSIONS.MUTATIONS.update(),
      MUTATION_ADD: API_CALLS.COMMISSIONS.MUTATIONS.create(),
      QUERY_GET_USERS: API_CALLS.USERS.QUERIES.all(`user`),
      FORM_FIELDS: API_CALLS.COMMISSIONS.FORM_FIELDS,
      PREVIEW: API_CALLS.COMMISSIONS.PREVIEW
    },
    "VARIABLES": {
      "sortBy": "meta.createAt",
      "descending": false,
      "limit": 20,
      "offset": 0,
      "manual": [
        { //IF WE ADD THIS THEN THEY'LL ONLY SHOW THOSE THAT HAVE BEEN APPROVED, NOT THE PENDING ONES TOO. 
          path: "APPROVED",
          val: "TRUE"
        },
        {
          path: "ADMIN_PAID", //DON'T SHOW THOSE THAT HAVE BEEN PAID
          not: true
        }
      ]
    },
    "BUTTONS": [
      {
        buttonText: "Request",
        category: "Request",
        className: "btn-color1",
        iconClass: "fas fa-envelope",
      }
      // {
      //   width: "0px",
      //   category: "View",
      //   CUSTOMBUTTON: true,
      //   CUSTOM: (item)=><ViewCommissions UID={item.user.UID}/>
      // },
      // {
      //   width: "110px",
      //   category: "Preview",
      //   buttonText: "View",
      //   className: "btn-color3",
      //   iconClass: "fas fa-eye",
      //   onClick: (obj)=>{
      //     window.GlobalUtil.triggerEvent("TemplateDBEvent", {
      //       "TYPE": "PREVIEW",
      //       "BUTTON_TITLE": "Commission",
      //       "TITLE": "Commission to Pay", //TITLE OF SLIDE OUT
      //       "APINAME": "COMMISSIONS",
      //       "NAME": "Commission",
      //       "ID": obj.user.UID
      //     }); 
      //   }
      // }
    ]
  },
  {
    "id": "AFFILIATES_COMMISSION_GROUPS03",
    "Name": "Paid",
    "PageName": "Paid",
    "Paths": ["/admin/affiliates/commissions/paid"],
    "GlobalStateVar": ["CommissionsChecks,PAID"],
    "TITLE": "Checks We've Written for Commissions",
    "DATA_FIELD": "commissionChecks",
    //"ActionSlideIn": UploadAligners,
    "LIST_HEADER_FIELDS": API_CALLS.COMMISSION_CHECKS.LIST_HEADER_FIELDS,
    "API_CALLS": {
      QUERY: API_CALLS.COMMISSION_CHECKS.QUERIES.allAndCount(),
      QUERY_SINGLE: API_CALLS.COMMISSION_CHECKS.QUERIES.single(),
      QUERY_COUNT: API_CALLS.COMMISSION_CHECKS.QUERIES.count(),
      MUTATION_EDIT: API_CALLS.COMMISSION_CHECKS.MUTATIONS.update(),
      MUTATION_ADD: API_CALLS.COMMISSION_CHECKS.MUTATIONS.create(),
      QUERY_GET_USERS: API_CALLS.USERS.QUERIES.all(`user`),
      FORM_FIELDS: API_CALLS.COMMISSION_CHECKS.FORM_FIELDS,
      PREVIEW: API_CALLS.COMMISSION_CHECKS.PREVIEW
    },
    "VARIABLES": {
      "sortBy": "paidDate",
      "descending": false,
      "limit": 20,
      "offset": 0,
      "manual": [
        {
          path: "paid", //DON'T SHOW THOSE THAT HAVE BEEN PAID
          not: false
        }
      ]
    },
    "BUTTONS": [
      {
        width: "110px",
        category: "Preview",
        buttonText: "View",
        className: "btn-color3",
        iconClass: "fas fa-eye",
        onClick: (obj)=>{
          window.GlobalUtil.triggerEvent("TemplateDBEvent", {
            "TYPE": "PREVIEW",
            "BUTTON_TITLE": "Commission Check",
            "TITLE": "Commission Check", //TITLE OF SLIDE OUT
            "APINAME": "COMMISSION_CHECKS",
            "NAME": "Commission Check",
            "ID": obj._id
          }); 
        }
      }
    ]
  },
  /*
      I SHOULD CREATE A NEW API CALL ON THE SERVER SIDE TO GET 
      -EVERY PENDING ORDER ITEM I NEED TO PAY
      -GROUP THEM BY USER
      -GET EACH OF THOSE USERS INFO
      -RETURN AN ARRAY OF USERS
        {
          IMAGE
          NAME
          ID
          ORDER ITEMS COUNT
          ORDER ITEMS[
            {
              FULL ORDER ITEM
            }
          ]
        }

  {
    "id": "AFFILIATES_GROUPS03",
    "Name": "Conversions",
    "PageName": "Conversions",
    "Paths": ["/admin/affiliates/conversions"],
    "GlobalStateVar": ["Affiliates,CONVERSIONS"], // PAID
    "TITLE": "List of Affiliates that have made sales whom we have yet to pay",
    "DATA_FIELD": "users",
    //"ActionSlideIn": UploadAligners,
    "LIST_HEADER_FIELDS": [
        {
          path: "userPicture.0.url",
          type: "IMAGE",
          name: "Image",
          defaultValue: "NONE"
        },
        {
          path: "ID",
          type: "ID",
          name: "ID",
          searchable: true
        },
        {
          path: "firstName",
          type: "VALUE",
          name: "FirstName",
          searchable: true
        },
        {
          path: "lastName",
          type: "VALUE",
          name: "LastName",
          searchable: true
        },
        {
          path: "email",
          type: "EMAIL",
          name: "Email",
          searchable: true
        },
        {
          path: "phone",
          type: "PHONE",
          name: "Phone",
          searchable: true,
          minWidth: "120px"
        },
        {
          path: "conversions",
          type: "VALUE",
          name: "Conversions",
          searchable: true
        },
        {
          path: "meta.createAt",
          type: "DATE",
          name: "Create At",
          defaultValue: "1606607634571"
        }
      ],
    "API_CALLS": {
      // QUERY: API_CALLS.USERS.QUERIES.allAndCount(),
      // QUERY_SINGLE: API_CALLS.USERS.QUERIES.single(),
      // QUERY_COUNT: API_CALLS.USERS.QUERIES.count(),
      // MUTATION_EDIT: API_CALLS.USERS.MUTATIONS.update(),
      // MUTATION_ADD: API_CALLS.USERS.MUTATIONS.create(),
      // QUERY_GET_USERS: API_CALLS.USERS.QUERIES.all(`user`),
      // FORM_FIELDS: API_CALLS.USERS.FORM_FIELDS,
      // PREVIEW: API_CALLS.USERS.PREVIEW
    },
    "VARIABLES": {
      "status": "NOTINACTIVE",
      "sortBy": "meta.createAt",
      "descending": false,
      "limit": 20,
      "offset": 0,
      "manual": [
        {
          path: "AFFILIATE.isAffiliate",
          val: "true"
        },
        {
          path: "AFFILIATE.codes.0",
          not: false
        }
      ]
    },

    //  I SHOULD CREATE A NEW API CALL ON THE SERVER SIDE TO
    // -TAKE LIST OF ORDER ITEM ID'S
    // -TAKE CHECK NUMBER
    // -TAKE CHECK DATE
    // -UPDATE ALL ORDER ITEMS MENTIONED WITH NEW INFO
    
    //   PROMO
    //   -ADMIN_PAID: true
    //   -ADMIN_PAID_DATE
    //   -ADMIN_CHECK_NUMBER
    
    "BUTTONS": [
      {
        width: "110px",
        category: "Preview",
        buttonText: "View",
        className: "btn-color3",
        iconClass: "fas fa-eye",
        onClick: (obj)=>{
          window.GlobalUtil.triggerEvent("TemplateDBEvent", {
            "TYPE": "PREVIEW",
            "BUTTON_TITLE": "User",
            "TITLE": "User", //TITLE OF SLIDE OUT
            "APINAME": "USERS",
            "NAME": "User",
            "ID": obj._id
          }); 
        }
      }
    ]
  }
  */
 /*
      I SHOULD CREATE A NEW API CALL ON THE SERVER SIDE TO GET 
      -EACH CHECK WRITTEN
      -SORT THEM BY ADMIN_PAID_DATE
      -OR SORT THEM BY ADMIN_CHECK_NUMBER
      -GROUP THEM BY USER
      -RETURN AN ARRAY OF
        {
          CHECK NUMBER
          TOTAL PAID
          DAY PAID
          USER ID
          ~USER OBJ
          ORDER ITEMS
            ID
            EARNED
            CODE
            DATE APPROVED
            COUPON{
              OPTIONAL FOR LIST VIEW WITHOUT THIS
            }
        }

  {
    "id": "AFFILIATES_GROUPS04",
    "Name": "Paid",
    "PageName": "Paid",
    "Paths": ["/admin/affiliates/paid"],
    "GlobalStateVar": ["Affiliates,PAID"],
    "TITLE": "List of each check sent to each affiliate and it's info",
    "DATA_FIELD": "users",
    //"ActionSlideIn": UploadAligners,
    "LIST_HEADER_FIELDS": [
        {
          path: "ID",
          type: "ID",
          name: "ID",
          searchable: true
        },
        {
          path: "checkNumber",
          type: "VALUE",
          name: "Check Number",
          searchable: true
        },
        {
          path: "UID",
          type: "VALUE",
          name: "User",
          searchable: true
        },
        {
          path: "email",
          type: "EMAIL",
          name: "Email",
          searchable: true
        },
        {
          path: "phone",
          type: "PHONE",
          name: "Phone",
          searchable: true,
          minWidth: "120px"
        },
        {
          path: "total",
          type: "VALUE",
          name: "Total",
          searchable: true
        },
        {
          path: "meta.createAt",
          type: "DATE",
          name: "Create At",
          defaultValue: "1606607634571"
        }
      ],
    "API_CALLS": {
      // QUERY: API_CALLS.USERS.QUERIES.allAndCount(),
      // QUERY_SINGLE: API_CALLS.USERS.QUERIES.single(),
      // QUERY_COUNT: API_CALLS.USERS.QUERIES.count(),
      // MUTATION_EDIT: API_CALLS.USERS.MUTATIONS.update(),
      // MUTATION_ADD: API_CALLS.USERS.MUTATIONS.create(),
      // QUERY_GET_USERS: API_CALLS.USERS.QUERIES.all(`user`),
      // FORM_FIELDS: API_CALLS.USERS.FORM_FIELDS,
      // PREVIEW: API_CALLS.USERS.PREVIEW
    },
    "VARIABLES": {
      "status": "NOTINACTIVE",
      "sortBy": "meta.createAt",
      "descending": false,
      "limit": 20,
      "offset": 0,
      "manual": [
        {
          path: "AFFILIATE.isAffiliate",
          val: "true"
        },
        {
          path: "AFFILIATE.codes.0",
          not: false
        }
      ]
    },
    "BUTTONS": [
      {
        width: "110px",
        category: "Preview",
        buttonText: "View",
        className: "btn-color3",
        iconClass: "fas fa-eye",
        onClick: (obj)=>{
          window.GlobalUtil.triggerEvent("TemplateDBEvent", {
            "TYPE": "PREVIEW",
            "BUTTON_TITLE": "User",
            "TITLE": "User", //TITLE OF SLIDE OUT
            "APINAME": "USERS",
            "NAME": "User",
            "ID": obj._id
          }); 
        }
      }
    ]
  }
  */
];

const AFFILIATE_PAGES = () => [
  {
    "id": "AFFILIATES_GROUPS01",
    "Name": "All",
    "PageName": "All",
    "Paths": ["/admin/affiliates/influencers/all"],
    "GlobalStateVar": ["Affiliates,ALL"],
    "TITLE": "All Affiliate Users",
    "DATA_FIELD": "users",
    //"ActionSlideIn": UploadAligners,
    "LIST_HEADER_FIELDS": API_CALLS.USERS.LIST_HEADER_FIELDS,
    "API_CALLS": {
      QUERY: API_CALLS.USERS.QUERIES.allAndCount(),
      QUERY_SINGLE: API_CALLS.USERS.QUERIES.single(),
      QUERY_COUNT: API_CALLS.USERS.QUERIES.count(),
      MUTATION_EDIT: API_CALLS.USERS.MUTATIONS.update(),
      MUTATION_ADD: API_CALLS.USERS.MUTATIONS.create(),
      QUERY_GET_USERS: API_CALLS.USERS.QUERIES.all(`user`),
      FORM_FIELDS: API_CALLS.USERS.FORM_FIELDS,
      PREVIEW: API_CALLS.USERS.PREVIEW
    },
    "VARIABLES": {
      "status": "NOTINACTIVE",
      "sortBy": "meta.createAt",
      "descending": false,
      "limit": 20,
      "offset": 0,
      "manual": [
        {
          path: "AFFILIATE.isAffiliate",
          val: "true"
        },
        {
          path: "AFFILIATE.codes.0",
          not: true
        }
      ]
    },
    "BUTTONS": [
      {
        width: "110px",
        category: "Preview",
        buttonText: "View",
        className: "btn-color3",
        iconClass: "fas fa-eye",
        onClick: (obj)=>{
          window.GlobalUtil.triggerEvent("TemplateDBEvent", {
            "TYPE": "PREVIEW",
            "BUTTON_TITLE": "User",
            "TITLE": "User", //TITLE OF SLIDE OUT
            "APINAME": "USERS",
            "NAME": "User",
            "ID": obj._id
          }); 
        }
      }
    ]
  },
  {
    "id": "AFFILIATES_GROUPS02",
    "Name": "Requested",
    "PageName": "Requested",
    "Paths": ["/admin/affiliates/influencers/requested"],
    "GlobalStateVar": ["Affiliates,REQUEST"],
    "TITLE": "New Affiliate Requests",
    "DATA_FIELD": "users",
    //"ActionSlideIn": UploadAligners,
    "LIST_HEADER_FIELDS": API_CALLS.USERS.LIST_HEADER_FIELDS,
    "API_CALLS": {
      QUERY: API_CALLS.USERS.QUERIES.allAndCount(),
      QUERY_SINGLE: API_CALLS.USERS.QUERIES.single(),
      QUERY_COUNT: API_CALLS.USERS.QUERIES.count(),
      MUTATION_EDIT: API_CALLS.USERS.MUTATIONS.update(),
      MUTATION_ADD: API_CALLS.USERS.MUTATIONS.create(),
      QUERY_GET_USERS: API_CALLS.USERS.QUERIES.all(`user`),
      FORM_FIELDS: API_CALLS.USERS.FORM_FIELDS,
      PREVIEW: API_CALLS.USERS.PREVIEW
    },
    "VARIABLES": {
      "status": "NOTINACTIVE",
      "sortBy": "meta.createAt",
      "descending": false,
      "limit": 20,
      "offset": 0,
      "manual": [
        {
          path: "AFFILIATE.isAffiliate",
          val: "true"
        },
        {
          path: "AFFILIATE.codes.0",
          not: false
        }
      ]
    },
    "BUTTONS": [
      {
        width: "110px",
        category: "Preview",
        buttonText: "View",
        className: "btn-color3",
        iconClass: "fas fa-eye",
        onClick: (obj)=>{
          window.GlobalUtil.triggerEvent("TemplateDBEvent", {
            "TYPE": "PREVIEW",
            "BUTTON_TITLE": "User",
            "TITLE": "User", //TITLE OF SLIDE OUT
            "APINAME": "USERS",
            "NAME": "User",
            "ID": obj._id
          }); 
        }
      }
    ]
  }
];


const PAGES = () => [
  {
    "id": "AFFIL01",
    "Name": "Commissions",
    "PageName": "Commissions",
    "Paths": ["/admin/affiliates/commissions"],
    "SubPaths": AFFILIATE_COMMISSION_PAGES()
  },
  {
    "id": "AFFIL02",
    "Name":"Influencers",
    "PageName": "Influencers",
    "Paths": ["/admin/affiliates/influencers"],
    "SubPaths": AFFILIATE_PAGES()
  }
];


const Index = (props) => {
  //var {data} = props;
  var today = new Date();
  return (
     <TemplatePage
      ID={"AdminAligners"}
      REPORTS={
        [
          {
            QUERY: QUERY_COUNT,
            VARIABLES: {
              "status": "NOTINACTIVE",
              "dates": [{
                field: "meta.createAt",
                gte: `${today.getStartOfDay().getTime()}`
              }]
            },
            NAME: "alignerGroupCount",
            TITLE: "New Affiliates Today"
          },
          {
            QUERY: QUERY_COUNT,
            VARIABLES: {
              "status": "NOTINACTIVE",
              "dates": [{
                field: "meta.createAt",
                gte: `${today.getStartOfWeek().getTime()}`
              }]
            },
            NAME: "alignerGroupCount",
            TITLE: "Affiliates this Week"
          },
          {
            QUERY: QUERY_COUNT,
            VARIABLES: {
              "status": "NOTINACTIVE",
              "dates": [{
                field: "meta.createAt",
                gte: `${today.getStartOfMonth().getTime()}`
              }]
            },
            NAME: "alignerGroupCount",
            TITLE: "Affiliates this Month"
          },
          {
            QUERY: QUERY_COUNT,
            VARIABLES: {
              "status": "NOTINACTIVE",
              "dates": [{
                field: "meta.createAt",
                gte: `${today.getStartOfYear().getTime()}`
              }]
            },
            NAME: "alignerGroupCount",
            TITLE: "Affiliates this Year"
          }
        ]
      }
      REDIRECT_ARRAY={[
        {
          "path": "/admin/affiliates/commissions",
          "to": "/admin/affiliates/commissions/all"
        },
        {
          "path": "/admin/affiliates/influencers",
          "to": "/admin/affiliates/influencers/all"
        },
        {
          "path": "/admin/affiliates",
          "to": "/admin/affiliates/commissions/all"
        }
      ]}
      PAGES={PAGES()}
      ValidateObject={ValidateObject}
    />
  );
}





export default Index;

export {PAGES};   





