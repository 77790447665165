import React from 'react';
import TemplatePage from 'website/templates/templatePage/index.js';
import Loading from 'global/components/Loading/index';
const {Container, Row, Column} = window.LayoutBuilder;
/*

GET REDIRECT LOCATION
CHECK IF USER EXIST
  IF NOT THEN HAVE THEM LOGIN
ONCE USER EXIST FORWARD TO REDIRECT PAGE

http://localhost:3000/rd/?REDIR=/admin/doctor/aligners/upload/60c514a9bceae3b9da5e6578
johnny@fantastic4.com
123qwe
*/

class Index extends TemplatePage {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      //loading: true
    };    
    this.RouterEventKey = window.GlobalUtil.subscribeEvent("RouterEvent", newRouter=>{           
      this.getRedirectLocation();
    });
  }

  didMount(){
    if(window.Router) this.getRedirectLocation(); //IF ROUTER EXIST THEN LOADED THEN RUN THE FOLLOWING, ELSE IT WILL AUTO RUN WHEN IT DOES EXIST
  }

  willUnmount(){
    this.RouterEventKey.unsubscribe();
  }

  getRedirectLocation = () => {
    var queryString = window.location.search;
    var urlParams = new URLSearchParams(queryString);
    var REDIR = urlParams.get('REDIR');
    //console.log("REDIR","\n\n",REDIR,"\n\n");
    //console.log(`REDIR`,"\n\n",REDIR,"\n\n");
    if(REDIR){
      if(REDIR.slice(0,1) !== "/") REDIR = "/"+REDIR;
      window.GlobalUtil.setGlobalVariable("REDIR", REDIR);
      this.getUser(REDIR)
    } else {
      window.Router.history.push(((window.Session.Language === "ENGLISH") ? "/login" : "/acceso"));
    }
  }


  getUser = (REDIR) => { //IF USER DOES NOT EXIST REDIRECT TO LOGIN PAGE AND WHEN IT DOES LOGIN THEN IT WILL GO TO THE NEW REDIRECT
    if(window.Session.user) window.Router.history.push(REDIR);
    else window.Router.history.push(((window.Session.Language === "ENGLISH") ? "/login" : "/acceso"));
  }


  render(){
    //var {loading} = this.state;
    return (
      <div className="contentPage" id="PageLogin">
        <div id="TopMainSection" className="containerSection backgroundImage3">
          <Container className="">
            <Row className="">
              <Column className="" col="" xs="" sm="" md="" lg="" xl="">
                <div className="">
                  <Loading 
                    //floating="true"
                    header={'Redirecting'}
                    body={''}
                    inlineStyles={{
                      "LoadingScreen": {
                        "paddingTop": "100px"
                      }
                    }}
                  />
                </div>
              </Column>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}


export default Index;

      
