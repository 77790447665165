import React from 'react';
import {Helmet} from "react-helmet-async";
import 'styles/scss/website/pages/login.scss';
import TemplatePage from 'website/templates/templatePage/index.js';
import Main1 from 'global/img/main_pngs/main1.png';
import Main1_500 from 'global/img/main_pngs/main1_500.png';
import Main1_400 from 'global/img/main_pngs/main1_400.png';
import Main1_250 from 'global/img/main_pngs/main1_250.png';
import Login from 'global/components/Login/index';
import {UpdateUserWithAnyGlobalVars} from 'global/utils/helper-functions';
//import SignUp from 'global/components/SignUp/index';
import CONTENTS from "./CONTENTS";
const {Container, Row, Column} = window.LayoutBuilder;
class Index extends TemplatePage {
  // constructor(props) {
  //   super(props);
  //   //this.state = {...this.state
  //   //};    
  // }

  didMount(){
    document.querySelector("#Content").classList.add("noMinHeight")
  }

  willUnmount(){
    document.querySelector("#Content").classList.remove("noMinHeight")
  }

  render(){
    //var {showSideMenu} = this.state;
    const CONTENT = window.GlobalUtil.dangerSetGlobalObjList(CONTENTS[window.Session.Language].CONTENT); 
    const HEADER = CONTENTS[window.Session.Language].HEADER; 
    return (
      <div className="contentPage" id="PageLogin">
         <Helmet>
            <meta charSet="utf-8" />
            <title>{HEADER.TITLE}</title>
            <meta name="description" content={HEADER.DESCRIPTION}/>
            <link  href={`${this.props.location.pathname}`} />
            <link rel="canonical" href={`${HEADER.BASEURL}${this.props.location.pathname}`} />
            <meta name="keywords" content={HEADER.KEYWORDS} />

            {"<!-- Google / Search Engine Tags -->"}
            <meta itemprop="name" content={HEADER.TITLE} />
            <meta itemprop="description" content={HEADER.DESCRIPTION}/>
            <meta itemprop="image" content="https://Smiles.Club/SocialImage_500.jpg" />

            {/*<!-- Facebook Meta Tags -->*/}
            <meta property="og:url" content={`${HEADER.BASEURL}${this.props.location.pathname}`} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={HEADER.TITLE} />
            <meta property="og:description" content={HEADER.DESCRIPTION}/>
            <meta property="og:image" content="https://Smiles.Club/SocialImage_500.jpg" />

            {/*<!-- Twitter Meta Tags -->*/}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={HEADER.TITLE} />
            <meta name="twitter:description" content={HEADER.DESCRIPTION}/>
            <meta name="twitter:image" content="https://Smiles.Club/SocialImage_500.jpg" />


             {/*<!-- <meta name="robots" content="noindex" /> This will prevent indexing -->*/}
        </Helmet>
        <div id="TopMainSection" className="containerSection ">
          <Container className="">
            <Row className="">
              <Column className="" col="12" xs="12" sm="12" md="5" lg="5" xl="5">
                <div className="bigPicture">
                  <img 
                    src={Main1_500} 
                    alt="doctor"
                    srcSet={`
                      ${Main1_250} 250w,
                      ${Main1_400} 400w,
                      ${Main1_500} 500w,
                      ${Main1} 800w
                    `}
                    sizes="(max-width: 500px) 250px, (max-width: 767px) 400px, (max-width: 990px) 500px, 100vw"
                  />
                </div>
              </Column>
              <Column className="" col="12" xs="12" sm="12" md="7" lg="7" xl="7">
                <div className="" id="LoginHeader">
                  {CONTENT.LoginHeader[0]}
                  <br/>
                  <div className="" style={{"maxWidth": ""}}>
                    <Login 
                      id=""
                      className=""
                      emailOrPasswordWrongMessage={CONTENT.Login[0]}
                      onFail={()=>{
                        //window.GlobalUtil.logout();
                      }}
                      onSuccess={async (login)=>{
                        //LOGIN BUT DONT CHANGE PAGE TO GET TOKEN AND USER OBJ CORRECT
                        window.GlobalUtil.login(login, true) 
                          .then(async (login)=>{
                            //UPDATE USER OBJ WITH ANY GLOBAL VAR THEN RETURN THE CORRECT THING
                            var updatedUser = await UpdateUserWithAnyGlobalVars(); 
                            if(updatedUser && updatedUser._id){
                              login.user = {...updatedUser};
                              window.GlobalUtil.login(login);
                            } else {
                              window.GlobalUtil.changePageAfterLogin(); //IF NO CHANGE THEN JUST REDIRECT THE PAGE
                            }
                          });

                      }}
                      buttonText={CONTENT.Login[1]}
                    />
                  </div>
                </div>
              </Column>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}


export default Index;

      
