import React from 'react';
//import { gql, useQuery, useMutation } from '@apollo/client';
import USERS from "../USERS/index";
import ORDERS from "../ORDERS/index";
import API_CALLS from 'API_CALLS/index';

const FORM_FIELDS = (obj={}) => {
  const QUERY_GET_USERS = USERS.QUERIES.all(`email`);
  const QUERY_GET_ORDERS = ORDERS.QUERIES.all(`_id`);
  return [
    {
      title: <div className="formTitle"><span className="">UID</span></div>,
      subTitle: <div className="formSubTitle"><span className="">Must have a user attached to every task</span></div>,
      type: "selectAPI",
      onMount: (callBack) => {
        window.Client.query({
          query: QUERY_GET_USERS
        })
        .then(result => {
          var Options = result.data.users.map((user, index)=>{
            return({
              "value": user._id,
              "title": user.email
            })
          });
          callBack("options", [{
            "value": "",
            "title": "Choose one",
            "disabled": true
          }, ...Options])
          // console.log("Options");
          // console.log(Options,"\n\n");
                
        });
      },
      name: "UID",
      required: true,
      defaultValue: "",
      defaultValuePath: "UID",
      errorMessage: "User ID is required"
    },
    {
      title: <div className="formTitle"><span className="">Order ID</span></div>,
      subTitle: <div className="formSubTitle"><span className="">Must be attacked to an order.</span></div>,
      type: "selectAPI",
      onMount: (callBack) => {
        window.Client.query({
          query: QUERY_GET_ORDERS
        })
        .then(result => {
          var Options = result.data.orders.map((order, index)=>{
            return({
              "value": order._id,
              "title": order._id
            })
          });
          callBack("options", [{
            "value": "",
            "title": "Choose one",
            "disabled": true
          }, ...Options])
          // console.log("Options");
          // console.log(Options,"\n\n");
                
        });
      },
      name: "OID",
      required: true,
      defaultValue: "",
      defaultValuePath: "OID",
      errorMessage: "ORDER ID is required"
    },
    {
      title: <div className="formTitle"><span className="">Name</span></div>,
      subTitle: <div className="formSubTitle"><span className="">Name this order item.</span></div>,
      type: "text",
      name: "name",
      required: true,
      defaultValue: "",
      defaultValuePath: "name",
      errorMessage: "Name is required"
    },
    {
      title: <div className="formTitle"><span className="">Ship</span></div>,
      subTitle: <div className="formSubTitle"><span className="">Does this item need shipping?</span></div>,
      type: "toggle",
      name: "ship",
      required: true,
      defaultValue: "",
      defaultValuePath: "ship",
      errorMessage: "Ship is required"
    },
    {
      title: <div className="formTitle"><span className="">Shipped At</span></div>,
      subTitle: <div className="formSubTitle"><span className="">If we've already shipped it then this should have a value.</span></div>,
      type: "datePicker",
      name: "shippedAt",
      required: true,
      defaultValue: "",
      defaultValuePath: "shippedAt",
      errorMessage: "ShippedAt is required"
    },
    {
      title: <div className="formTitle"><span className="">Status</span></div>,
      subTitle: <div className="formSubTitle"><span className="">The status of the task</span></div>,
      type: "select",
      name: "status",
      options: [
        {
          "value": "",
          "title": "Choose one",
          "disabled": true
        },
        {
          "value": "ACTIVE",
          "title": "ACTIVE"
        },
        {
          "value": "PENDING",
          "title": "PENDING"
        },
        {
          "value": "INACTIVE",
          "title": "INACTIVE"
        },
        {
          "value": "FINISHED",
          "title": "FINISHED"
        }
      ],
      required: false,
      defaultValue: "ACTIVE",
      defaultValuePath: "status"
    },
    {
      title: <div className="formTitle"><span className="">Tracking Number</span></div>,
      subTitle: <div className="formSubTitle"><span className="">The number for the user to track their shipment</span></div>,
      type: "text",
      name: "trackingNumber",
      required: false,
      defaultValue: "",
      errorMessage: "Name is required"
    },
    {
      title: <div className="formTitle"><span className="">Tracking Company</span></div>,
      subTitle: <div className="formSubTitle"><span className="">The name of the company who we used to ship the package.</span></div>,
      type: "selectAPIWithAdd",
      onMount: (callBack) => {
        window.Client.query({
          query: API_CALLS.ORDER_ITEMS.QUERIES.distinct(),
          variables: {
            field: "trackingCompany" //FIELD TO GET ALL OPTIONS
          },
          fetchPolicy: "no-cache"
        })
        .then(result => {
          //console.log("result.data","\n\n",result.data,"\n\n");
          var fullOptionList = result.data.orderItemDistinct.map((DisOption, index)=>{
            return({
              "value": DisOption,
              "title": DisOption
            })
          });
          var DefaultValues = [
            {
              "value": "",
              "title": "Choose one",
              "disabled": true
            }
          ];
          const uniqueArray = window.GlobalUtil.arrayOfObjectsOnlyUnique([...DefaultValues, ...fullOptionList], "value");
          window.GlobalUtil.consoleLog("uniqueArray", [uniqueArray]);
          callBack("options", uniqueArray)
        });
      },
      name: "trackingCompany",
      required: false,
      defaultValue: "",
      defaultValuePath: "trackingCompany",
      errorMessage: "This is required"
    }
  ]
};






export default FORM_FIELDS;