import React from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';

import MUTATIONS from "./MUTATIONS";
import QUERIES from "./QUERIES";
import FORM_FIELDS from "./FORM_FIELDS";
import FORM_FIELDS_NEW from "./FORM_FIELDS_NEW";
import {DEFAULT_QUERY_INPUT_VAR, DEFAULT_QUERY_INPUT} from "../QUERY_DEFAULTS";
import PROMO_PAYMENT_PREVIEW from "./PROMO_PAYMENT_PREVIEW";

const DEFAULT_MUTATION_FIELDS = `
  ID
  meta{
    createAt
    updateAt
  }
`;


const DEFAULT_QUERY_FIELDS = DEFAULT_MUTATION_FIELDS+``;



const DEFAULT_MUTATION_INPUT_VAR = ` 

`;


const DEFAULT_MUTATION_INPUT = ` 
`;


const CLEAN_FIELDS = { 
}

const REPORTS = {
  MUTATIONS: MUTATIONS,
  QUERIES: QUERIES,
  PROMO_PAYMENT_PREVIEW: PROMO_PAYMENT_PREVIEW,
  FORM_FIELDS: FORM_FIELDS,
  FORM_FIELDS_NEW: FORM_FIELDS_NEW,
  CLEAN_FIELDS: CLEAN_FIELDS
}

export default REPORTS;
export {
  DEFAULT_MUTATION_FIELDS,
  DEFAULT_QUERY_FIELDS,
  DEFAULT_QUERY_INPUT_VAR,
  DEFAULT_QUERY_INPUT,
  DEFAULT_MUTATION_INPUT_VAR,
  DEFAULT_MUTATION_INPUT
};