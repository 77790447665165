import React from 'react';
import 'styles/scss/website/pages/best.scss';
import {Helmet} from "react-helmet-async";
import HeaderImage from 'website/components/HeaderImage/index';
import TemplatePage from 'website/templates/templatePage/index.js';
import {NavLink, Link} from 'react-router-dom';
import HIW_Image1 from 'global/img/HIW_Image/HIW_Image1.jpg';
import HIW_Image2 from 'global/img/HIW_Image/HIW_Image2.jpg';
import Main1_500 from 'global/img/squareGirlAligner_500.jpg';
import TreatmentPlanBar from 'website/components/TreatmentPlanBar/index';

import Compare from "./compare";
import CONTENTS from "./CONTENTS";
const {Container, Row, Column} = window.LayoutBuilder;


class Index extends TemplatePage {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
    };    
  }

  didMount(){
  }

  willUnmount(){
    //clearTimeout(this.TimeoutRef);
  }


  render(){
    var {swipeDirection} = this.state;
    var Language = window.Session.Language;
    Language = (this.props.match.url === "/bestaligners") ? "ENGLISH" : "ESPAÑOL";
    const CONTENT = window.GlobalUtil.dangerSetGlobalObjList(CONTENTS[window.Session.Language].CONTENT); 
    const HEADER = CONTENTS[window.Session.Language].HEADER; 
    return (
      <div className="contentPage" id="PageBestAligners">
        <Helmet>
            <meta charSet="utf-8" />
            <title>{HEADER.TITLE}</title>
            <meta name="description" content={HEADER.DESCRIPTION}/>
            <link  href={`${this.props.location.pathname}`} />
            <link rel="canonical" href={`${HEADER.BASEURL}${this.props.location.pathname}`} />
            <meta name="keywords" content={HEADER.KEYWORDS} />

            {"<!-- Google / Search Engine Tags -->"}
            <meta itemprop="name" content={HEADER.TITLE} />
            <meta itemprop="description" content={HEADER.DESCRIPTION}/>
            <meta itemprop="image" content="https://Smiles.Club/SocialImage_500.jpg" />

            {/*<!-- Facebook Meta Tags -->*/}
            <meta property="og:url" content={`${HEADER.BASEURL}${this.props.location.pathname}`} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={HEADER.TITLE} />
            <meta property="og:description" content={HEADER.DESCRIPTION}/>
            <meta property="og:image" content="https://Smiles.Club/SocialImage_500.jpg" />

            {/*<!-- Twitter Meta Tags -->*/}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={HEADER.TITLE} />
            <meta name="twitter:description" content={HEADER.DESCRIPTION}/>
            <meta name="twitter:image" content="https://Smiles.Club/SocialImage_500.jpg" />
        </Helmet>
        <div id="TopMainSection" className="containerSection backgroundSVG1">
          <Container className="">
            <Row className="">
              <Column className="" col="" xs="" sm="" md="" lg="" xl="">
                <div className="bigPicture">
                  <HeaderImage
                    img={Main1_500}
                    alt={CONTENT.TopMainSectionImage1[0].alt}
                    right={true}
                  />
                </div>
              </Column>
              <Column className="topTextZone contentCenter" col="12" xs="12" sm="12" md="12" lg="8" xl="8">
                <div className="">
                  {CONTENT.TopMainSection[0]}
                </div>
              </Column>
            </Row>
          </Container>
        </div>


        <Compare/>

        <div id="FullSteps" className=" backgroundSVG3">
          <Container className="">
            <Row className="">
              <Column className="" col="" xs="" sm="" md="" lg="" xl="">
                <div className="text-center">
                  {CONTENT.FullSteps[0]}
                  <br/>
                </div>
              </Column>
            </Row>
            <Row className="text-center">
              <Column className="" col="12" xs="12" sm="12" md="12" lg="6" xl="6">
                <div className="">
                  <div className="bottomImages">
                    <img src={HIW_Image1} alt={CONTENT.FullStepsImages[0].alt}/>
                  </div>
                  <br/>
                  {CONTENT.FullSteps[1]}
                  <div className="buttonGroup center">
                    <NavLink className={"button button1"} style={{"margin": "20px 5px 0px"}}  exact to={CONTENT.FullStepsButtons[2]}>{CONTENT.FullStepsButtons[0]}</NavLink>
                  </div>
                  <div className="d-flex d-lg-none">
                    <br/>
                    <br/>
                  </div>
                </div>
              </Column>
              <Column className="" col="12" xs="12" sm="12" md="12" lg="6" xl="6">
                <div className="">
                  <div className="bottomImages">
                    <img src={HIW_Image2} alt={CONTENT.FullStepsImages[1].alt}/>
                  </div>
                  <br/>
                  {CONTENT.FullSteps[2]}
                  <div className="buttonGroup center">
                    <NavLink className={"button button2"} style={{"margin": "20px 5px 0px"}}  exact to={CONTENT.FullStepsButtons[2]}>{CONTENT.FullStepsButtons[1]}</NavLink>
                  </div>
                </div>
              </Column>
            </Row>
          </Container>
        </div>
        <TreatmentPlanBar/>
      </div>
    );
  }
}


export default Index;
      