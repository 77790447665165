import React, { useState, useRef } from 'react';
import FileDropZone from 'global/components/FileDropZone/index';
import {ExtenderClass, TITLE, SUBTITLE, INVALID_CLASS_CHECK} from "./helperClasses";
//const {Container, Row, Column} = window.LayoutBuilder;

class FileUploadList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileList: this.props.value ? (Array.isArray(this.props.value) ? this.props.value : [this.props.value]) : []
    }
    //this.extraCheck = (newValue)=>{return((!newValue || !newValue.name || !newValue.url) ? false : true)}
    this.extraCheck = (newValue)=>{
      return((!newValue || newValue.length === 0) ? false : true)
    }
    this.Validate = this.Validate.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount(){
    this.Validate(this.props.value);
  }


  Validate(newValue){
    var {field={}} = this.props;
    var {required, name=""} = field;
    if(!required){
      this.props.onChangeValid(name, true);
      return;
    }

    var valid = true;
    if(!newValue) valid = false;
    if(valid && this.extraCheck) valid = this.extraCheck(newValue)
    this.props.onChangeValid(name, valid);
  }


  onChange(newFileList, callBack=()=>{}){      
    this.props.field.onChange(newFileList, ()=>{
      this.setState({fileList: newFileList});
      callBack();
    });
    
  }

  render(){
    var {fileList=[]} = this.state;          
    var {field={}, id, value="", onChange, onTouch, meta} = this.props;
    var {
      name="", 
      required, 
      title, 
      options=[], 
      maxFiles, 
      maxWidth, 
      placeHolderImage, 
      filePath, 
      hideDeleteButtons, 
      rename, 
      functionOnDrop=()=>{}, 
      functionOnDropDone=()=>{}, 
      hideBlanks=false,
      showCopyURL=false,
      showDownload=false,
      showDownloadAllButton=false,
      DONT_DELETE_FROM_AMAZON, 
      currentFilesText, //THIS IS TEXT YOU CAN USE TO REPLACE THE "CURRENT FILES" TEXT BEFORE THE LIST
      CustomFilePreview  //THIS IS A FUNCTION TO RETURN A PREIVEW OF THE FILE. EXAMPLE: FILE=>{return <div><img src={FILE.URL}/></div>}
    } = field;
    return(
      <div className={`fileUploads ${INVALID_CLASS_CHECK(meta, field.required, "error", "")}`}>
        {TITLE(field.title, field.required, field.requiredMessage)}
        {SUBTITLE(field.subTitle)}
        <div className="">
          <FileDropZone
            fileList={fileList}
            filePath={filePath}
            maxFiles={maxFiles}
            currentFilesText={currentFilesText}
            CustomFilePreview={CustomFilePreview}
            rename={rename}
            hideBlanks={hideBlanks}
            showCopyURL={showCopyURL}
            showDownload={showDownload}
            showDownloadAllButton={showDownloadAllButton}
            DONT_DELETE_FROM_AMAZON={DONT_DELETE_FROM_AMAZON} //IF THIS IS TRUE THEN IT WILL NOT DELETE FROM AMAZON ONLY OUR LINKS TO THEM
            onChange={this.onChange}
            functionOnDrop={field.functionOnDrop}
            functionOnDropDone={field.functionOnDropDone}
            hideDeleteButtons={hideDeleteButtons}
          />
        </div>
        {INVALID_CLASS_CHECK(meta, field.required, (field.errorMessage && <small className="form-text red-text errorMessage">{field.errorMessage}</small>), null)}
      </div>
    )
  }
}




const STYLES = {
  
}

export default FileUploadList;