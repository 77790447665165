import React from 'react';
import API_CALLS from 'API_CALLS/index';
import TemplatePage from "global/templates/templatePage/index";
import ActionPreview from "./actions/ActionPreview";



const QUERY = API_CALLS.FAILEDS.QUERIES.allAndCount();
const QUERY_SINGLE = API_CALLS.FAILEDS.QUERIES.single();
const QUERY_COUNT = API_CALLS.FAILEDS.QUERIES.count();
const FORM_FIELDS = API_CALLS.FAILEDS.FORM_FIELDS;
const LIST_HEADER_FIELDS = API_CALLS.FAILEDS.LIST_HEADER_FIELDS;

const PREVIEW = API_CALLS.FAILEDS.PREVIEW;

const API_CALLS_FULL = {
  QUERY,
  QUERY_SINGLE,
  QUERY_COUNT,
  FORM_FIELDS,
  PREVIEW
};






const ValidateObject = (newObject, onSuccess, onFail)=>{
  if(newObject._id && !newObject.id) newObject.id = newObject._id;      
  //console.log("newObject");
  //console.log(newObject,"\n\n");
        
  Object.keys(newObject).map((key, index)=>{
    if(!newObject[key]) delete newObject[key];
  })  
  onSuccess(newObject);
  //onFail();      
};








const PAGES = () => [
  {
    "id": "FAILEDS01",
    "Name":"Active",
    "PageName": "Active",
    "Paths": ["/admin/configure/failed/active"],
    "TITLE": "Failed Alerts",
    "DATA_FIELD": "faileds",
    "GlobalStateVar": ["failedCountActive"],
    "ActionSlideIn": ActionPreview,
    "API_CALLS": API_CALLS_FULL,
    "LIST_HEADER_FIELDS": LIST_HEADER_FIELDS,
    "VARIABLES": {
      "status": "ACTIVE",
      "sortBy": "meta.createAt",
      "descending": false,
      "limit": 20,
      "offset": 0
    },
    "DELETEBUTTON": {
      NAME: "FAILEDS"
    },
    "BUTTONS": [
      {
        width: "110px",
        category: "Finished",
        buttonText: "Finished",
        className: "btn-color1",
        iconClass: "fas fa-clipboard-check",
        onClick: (obj)=>{                
          window.Client.mutate({ //API UPDATE
            mutation: API_CALLS.FAILEDS.MUTATIONS.complete(),
            variables: {
              id: obj._id
            }
          })
          .then((obj)=>{
            window.CurRefetchList(true);
          })
          .catch((error)=>{
            console.log("error updating user");
          });
        }
      },
      {
        width: "110px",
        category: "Preview",
        buttonText: "View",
        className: "btn-color3",
        iconClass: "fas fa-eye",
        onClick: (obj)=>{
          window.GlobalUtil.triggerEvent("TemplateDBEvent", {
            "TYPE": "PREVIEW",
            "BUTTON_TITLE": "Failed",
            "TITLE": "Failed", //TITLE OF SLIDE OUT
            "APINAME": "FAILEDS",
            "NAME": "Failed",
            "ID": obj._id
          }); 
        }
      }
    ],
    SHOWSELECTBOXES:true
  },
  {
    "id": "FAILEDS02",
    "Name":"History",
    "PageName": "History",
    "Paths": ["/admin/configure/failed/history"],
    "TITLE": "Failed Alert History",
    "DATA_FIELD": "faileds",
    "ActionSlideIn": ActionPreview,
    "API_CALLS": API_CALLS_FULL,
    "LIST_HEADER_FIELDS": LIST_HEADER_FIELDS,
    "VARIABLES": {
      "status": "INACTIVE",
      "sortBy": "meta.createAt",
      "descending": false,
      "limit": 20,
      "offset": 0
    },
    // "EDITBUTTON": {
    //   NAME: "FAILEDS",
    //   TITLE: "Failed",
    //   FIELD: "failed"
    // },
    "DELETEBUTTON": {
      NAME: "FAILEDS",
      REMOVE: true
    },
    "BUTTONS": [
      {
        width: "110px",
        category: "Preview",
        buttonText: "View",
        className: "btn-color3",
        iconClass: "fas fa-eye",
        onClick: (obj)=>{
          window.GlobalUtil.triggerEvent("TemplateDBEvent", {
            "TYPE": "PREVIEW",
            "BUTTON_TITLE": "Failed",
            "TITLE": "Failed", //TITLE OF SLIDE OUT
            "APINAME": "FAILEDS",
            "NAME": "Failed",
            "ID": obj._id
          }); 
        }
      }
    ],
    SHOWSELECTBOXES:true
  }
];








const Index = (props) => {
  //var {data} = props;
  var today = new Date();
  return (
     <TemplatePage
      ID={"AdminFailed"}
      REPORTS={
        [
          {
            QUERY: QUERY_COUNT,
            VARIABLES: {
              "status": "NOTINACTIVE",
              "dates": [{
                field: "meta.createAt",
                gte: `${today.getStartOfDay().getTime()}`
              }]
            },
            NAME: "failedCount",
            TITLE: "New Failed Today"
          },
          {
            QUERY: QUERY_COUNT,
            VARIABLES: {
              "status": "NOTINACTIVE",
              "dates": [{
                field: "meta.createAt",
                gte: `${today.getStartOfWeek().getTime()}`
              }]
            },
            NAME: "failedCount",
            TITLE: "Failed this Week"
          },
          {
            QUERY: QUERY_COUNT,
            VARIABLES: {
              "status": "NOTINACTIVE",
              "dates": [{
                field: "meta.createAt",
                gte: `${today.getStartOfMonth().getTime()}`
              }]
            },
            NAME: "failedCount",
            TITLE: "Failed this Month"
          },
          {
            QUERY: QUERY_COUNT,
            VARIABLES: {
              "status": "NOTINACTIVE",
              "dates": [{
                field: "meta.createAt",
                gte: `${today.getStartOfYear().getTime()}`
              }]
            },
            NAME: "failedCount",
            TITLE: "Failed this Year"
          }
        ]
      }
      REDIRECT={{
        "path": "/admin/configure/failed",
        "to": "/admin/configure/failed/active"
      }}
      PAGES={PAGES()}
      ValidateObject={ValidateObject}
    />
  );
}





export default Index;

export {PAGES};   

      
