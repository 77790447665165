//import React from 'react';
const PAGE = {
  	"ENGLISH": {
		"HEADER": {
			"BASEURL": "https://smiles.club",
			"TITLE": "Smiles Club | Clear aligners financial options and cost for invisible braces.",
			"DESCRIPTION": "Smiles Club provides PayPal pay later financial orthodontics options. See our clear aligner prices and discounts.",
			"KEYWORDS": "Teeth straightening at home, affordable, clear braces, clearbraces, clear-braces, invisible braces, best aligners, PayPal pay later, PayPal, discount aligners, safest aligners, number one clear aligners, invisible aligners, safe plastics, discreetly, clearaligners alternative"
		},
		"CONTENT": {
			"TopMainSection": [
				{
					"type": "div",
					"content": `
						<div class="preHeader">Price and Plans</div>
						<h1 class="sectionTitle">Custom treatment plan at less than half the price.</h1>
						<div class="">An Orthodontist will design a customized treatment plan and will show you some videos about the transformation of your smile along the treatment. You will decide on a payment option and your clear aligners will be fabricated and delivered to you. </div>
					`
				}
			],
			"TopMainSectionImages": [
				{
					"alt": "A woman's smile pulling out her clear aligners."
				}
			],
			"HowToPay": [
				{
					"type": "div",
					"content": `
						<div class="preHeader">PAYMENT OPTIONS</div>
						<h2 class="sectionTitle2">Two easy ways to pay!</h2>
						<div class="subHeader">We offer the same or higher quality treatment you will get in a regular dental office at less than half the price. Our clear aligners are far less expensive than traditional braces. So say goodbye to financial barriers and embrace the possibilities of achieving your dream smile through our partnership with PayPal Pay Later! </div>
					`
				}
			],
			"PaymentOptions": [
				{
					"type": "div",
					"content": `
						<div class="bigIcon"><i class="fas fa-cash-register" style="color: #0070e0;"></i></div>
						<div class="typeTitle">Single Payment</div>
						<div class="paymentAmmount" style="color: #0070e0;">$2,199</div>
						<div class="summary">Pay upfront using your FSA or HSA card, your bank account, PayPal cards, or other simply pay with PayPal. Lock this amazing deal today and save more by paying all at once! Contact us if you have any questions or for more details.</div>
					`
				},
				{
					"type": "div",
					"content": `
						<div class="bigIcon"><i class="fab fa-paypal" style="color: #003087;"></i></div>
						<div class="typeTitle">SMILE NOW, Pay Later!</div>
						<div class="paymentAmmount" style="color: #0070e0;">PayPal Payments</div>
						<div class="summary">Smiles Club is integrated with PayPal's "Buy now, pay later" program. You can choose to split the cost into 4 bi-weekly payments or a 6, 12, or 24 month payment plan with $0 down.*</div>
					`
				},
				{
					"type": "span",
					"content": `<span class="responsiveSmall">*Visit PayPal's official website at www.paypal.com, to learn more about their "Buy now, pay later" program.</span>`
				}
			],
			"PaymentOptionsButtons": [
				{
					"type": "span",
					"content": `Buy Now`
				},
				{
					"type": "span",
					"content": `Buy Now`
				}
			],
			"PaymentTypes": [
				{
					"type": "div",
					"content": `
						<div class="preHeader">PAYMENT TYPES</div>
						<h2 class="sectionTitle2">We accept </h2>
						<div class="subHeader">Regular credit cards, Paypal, FSA, HSA, Bank Accounts ACH, Bank wires and Checks.</div>
					`
				}
			],
			"PaymentTypesButton": [
				{
					"type": "span",
					"content": `Get Started`
				}
			],
			"PaymentTypesImages": [
				{
					"alt": "Amex payment Icon"
				},
				{
					"alt": "Paypal payment Icon"
				},
				{
					"alt": "Paypal Credit Card payment Icon"
				},
				{
					"alt": "Dinner payment Icon"
				},
				{
					"alt": "Discover payment Icon"
				},
				{
					"alt": "Visa payment Icon"
				},
				{
					"alt": "Mastercard payment Icon"
				}
			]
		}
	},
	"ESPAÑOL": {
		"HEADER": {
			"BASEURL": "https://sonrisas.club",
			"TITLE": "Sonrisas Club | Alineadores transparentes de materiales de primera calidad sin toxinas dañinas",
			"DESCRIPTION": "Sonrisas Club proporciona opciones de ortodoncia financiera de pago posterior de PayPal.",
			"KEYWORDS": "Alisado de dientes en casa, asequible, frenostransparentes, frenos transparentes, frenos-transparentes, frenos invisibles, los mejores alineadores, pago posterior de PayPal, PayPal, alineadores con descuento, los alineadores más seguros, los alineadores transparentes número uno, los alineadores-invisibles, los plásticos seguros, discretamente, la alternativa de los alineadores transparentes"
		},
		"CONTENT": {
			"TopMainSection": [
				{
					"type": "div",
					"content": `
						<div class="preHeader">PRECIO Y PLANES</div>
						<h1 class="sectionTitle">Plan de tratamiento personalizado a menos de la mitad del precio.</h1>
						<div class="">Un Ortodoncista diseñará un plan de tratamiento personalizado y te mostrará algunos videos sobre la transformación de tu sonrisa a lo largo del tratamiento. Tu decidirás una opción de pago y tus alineadores transparentes serán fabricados y enviados a tu domicilio.</div>`
				}
			],
			"TopMainSectionImages": [
				{
					"alt": "La sonrisa de una mujer sacándose los alineadores transparentes."
				}
			],
			"HowToPay": [
				{
					"type": "div",
					"content": `
						<div class="preHeader">OPCIONES DE PAGO</div>
						<h2 class="sectionTitle2">¡Dos formas sencillas de pagar!</h2>
						<div class="subHeader">Ofrecemos el mismo tratamiento o mejor del que obtendrás en un consultorio dental regular a menos de la mitad del precio.</div>
					`
				}
			],
			"PaymentOptions": [
				{
					"type": "div",
					"content": `
						<div class="bigIcon"><i class="fas fa-cash-register" style="color: #0070e0;"></i></div>
						<div class="typeTitle">Pago único</div>
						<div class="paymentAmmount" style="color: #0070e0;">$2,199</div>
						<div class="summary">¡Paga por adelantado, usando tu tarjeta FSA o HSA, o tu cuenta bancaria, Paypal, etc. ¡Cierre esta increíble oferta hoy! Póngase en contacto con nosotros si tiene alguna pregunta o para obtener más detalles.</div>
					`
				},
				{
					"type": "div",
					"content": `
						<div class="bigIcon"><i class="fab fa-paypal" style="color: #003087;"></i></div>
						<div class="typeTitle">¡SONRÍE AHORA, paga después!</div>
						<div class="paymentAmmount" style="color: #0070e0;">Pagos de PayPal</div>
						<div class="summary">Smiles Club está integrado con el programa "Buy now, pay later" de PayPal. Puede elegir dividir el costo en 4 pagos quincenales o un plan de pago de 6, 12 o 24 meses con $0 de pago inicial.*</div>
					`
				},
				{
					"type": "span",
					"content": `<span class="responsiveSmall">*Visite el sitio web oficial de PayPal en www.paypal.com para obtener más información sobre su programa "Buy now, pay later".</span>`
				}
			],
			"PaymentOptionsButtons": [
				{
					"type": "span",
					"content": `Comprar`
				},
				{
					"type": "span",
					"content": `Comprar`
				}
			],
			"PaymentTypes": [
				{
					"type": "div",
					"content": `
						<div class="preHeader">FORMAS DE PAGO</div>
						<h2 class="sectionTitle2">Aceptamos</h2>
						<div class="subHeader">Tarjetas de crédito regulares, Paypal, FSA, HSA, cuentas bancarias ACH, transferencias bancarias y cheques.</div>
					`
				}
			],
			"PaymentTypesButton": [
				{
					"type": "span",
					"content": `Contáctanos`
				}
			],
			"PaymentTypesImages": [
				{
					"alt": "El symbolo de Amex"
				},
				{
					"alt": "El symbolo de Paypal"
				},
				{
					"alt": "El symbolo de Paypal trajeta"
				},
				{
					"alt": "El symbolo de Dinner"
				},
				{
					"alt": "El symbolo de Discover"
				},
				{
					"alt": "El symbolo de Visa"
				},
				{
					"alt": "El symbolo de Mastercard"
				}
			]
		}
	}
}


	
export default PAGE;
