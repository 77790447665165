import React from 'react';
import EditShipping from "./editShipping/index";
import GiveawayImage1 from 'global/img/giveaway1/prizeImage_4_1x1_500.jpg';
//import Step4 from 'global/img/infoVectors/Step4.jpg';
//<div className="image" style={{"maxWidth": "300px", "margin":"auto"}}><img src={Step4} alt=""/></div>
const {Container, Row, Column} = window.LayoutBuilder;




const Index = (props) => {
  var {currentUser, APISendAffiliateRequest, CONTENT} = props;
  const ISAFFILIATE = (currentUser.AFFILIATE && currentUser.AFFILIATE.isAffiliate);
  return (
    <div id="JoinAffiliatesComponent" className="overflowRightScroll">
        <Container className="" fluid="true">
          <Row className="" id="AffiliatesWhy">
            <Column className="matchHeight" col="" xs="" sm="" md="" lg="" xl="">
              <div className="affiliatesWhy">
                <div className="personImage">
                  <img 
                    className="stepImages" 
                    id="StepImages3"
                    src={GiveawayImage1} 
                    alt={"Person happy about affiliate"}
                  />
                </div>
                <div className="infoGroups">
                  <div className="infoTitle">
                    {CONTENT.AffiliatesWhy[0]}
                  </div>
                  <div className="infoGroup">
                    <div className="icon"><i className="fas fa-check-square"></i></div>
                    <div className="text">
                      <div className="infoGroupTitle">{CONTENT.AffiliatesWhy[1]}</div> 
                      <div className="infoGroupText">{CONTENT.AffiliatesWhy[2]}</div> 
                    </div>
                  </div>
                  <div className="infoGroup">
                    <div className="icon"><i className="fas fa-check-square"></i></div>
                    <div className="text">
                      <div className="infoGroupTitle">{CONTENT.AffiliatesWhy[3]}</div> 
                      <div className="infoGroupText">{CONTENT.AffiliatesWhy[4]}</div> 
                    </div>
                  </div>
                  <div className="infoGroup">
                    <div className="icon"><i className="fas fa-check-square"></i></div>
                    <div className="text">
                      <div className="infoGroupTitle">{CONTENT.AffiliatesWhy[5]}</div> 
                      <div className="infoGroupText">{CONTENT.AffiliatesWhy[6]}</div> 
                    </div>
                  </div>
                  <div className="infoGroup">
                    <div className="icon"><i className="fas fa-check-square"></i></div>
                    <div className="text">
                      <div className="infoGroupTitle">{CONTENT.AffiliatesWhy[7]}</div> 
                      <div className="infoGroupText">{CONTENT.AffiliatesWhy[8]}</div> 
                    </div>
                  </div>
                </div>
              </div>
            </Column>
          </Row>
        </Container>
        <Container className="" fluid="true">
          <Row className="" id="JoinAffiliates">
            <Column className="matchHeight" col="" xs="" sm="" md="" lg="" xl="">
              <div className="component fullHeight overflowRightScroll">
                <div className="joinAffiliatesWrapper">
                  <div className="joinAffiliatesText">
                    <div className="title">
                      {CONTENT.JoinAffiliates[0]}
                    </div>
                    {CONTENT.JoinAffiliates[1]}
                    <div className="info">{CONTENT.JoinAffiliates[2]}</div>
                    {
                      ISAFFILIATE
                      ? <p className="requestSent">{CONTENT.JoinAffiliates[3]}</p>
                      : <EditShipping
                          currentUser={currentUser}
                          CONTENT={CONTENT}
                          onSubmit={APISendAffiliateRequest}
                          onUpdateUser={props.onUpdateUser}
                        />
                    }
                  </div>
                </div>
              </div>
            </Column>
          </Row>
        </Container>
        {/* <Container className="" fluid="true">
          <Row className="" id="AffiliatesInfo">
            <Column className="matchHeight" col="" xs="" sm="" md="" lg="" xl="">
              <div className="component fullHeight overflowRightScroll">
                <div className="itemHeader">
                  <div className="itemHeaderTextZone">
                    <div className="itemHeaderText">
                      Here's how our affiliate programs works:
                    </div>
                  </div>
                </div>
                <div className="">
                  <div className="">
                    <div className="">Sign-up: </div>
                    <div className="">Request becoming an affiliate by pressing the button above. Once you're approved as an official affiliate you'll receive a few unique affiliate ID's or CODES. These CODES will be linked to several coupons and commissions. They will be used to track any sales you make and commissions you earn.</div>
                  </div>
                  <div className="">
                    <div className="">Promotion: </div>
                    <div className="">Affiliates can use their codes to promote the sales of products through various means, such as on websites, blogs, social media, email marketing, videos, or other online channels. Each unique CODE ties each sale to the affiliate who owns that code.</div>
                  </div>
                  <div className="">
                    <div className="">Tracking: </div>
                    <div className="">When a customer purchase using one of your codes, and that sale is approved, the tracking system will record that sale and display it here. In this affiliates tab you can track your approved affiliate sales and how much commission you've earned.</div>
                  </div>
                  <div className="">
                    <div className="">Commission: </div>
                    <div className="">Affiliates earn a commission for each approved sale. Because customers may not qualify for a treatment, affiliates won't earn a commission until that customer has been approved. If they're not approved, we will refund the money to the customer. The commission amount varies for each CODE used. The greater the discount on the customers the lower the commission earned, and vice versa. Any commissions shown in the backend tracking will already be approved and be owed to our affiliate.</div>
                  </div>
                  <div className="">
                    <div className="">Payment: </div>
                    <div className="">
                      Affiliates commissions are paid on a regular basis at the start of every month. A check will the total ammount of commission earned the previous month will be sent through the mail to the address provided by the user in their profile. 
                      Our affiliates will be able to track when the check was sent, how much was on the check, and even the check number. If a check does not reach you, after a week, please contact us and we'll void that check and reissue a new one.</div>
                  </div>
                </div>
              </div>
            </Column>
          </Row>
        </Container> */}
    </div>
  );
}


export default Index;
      