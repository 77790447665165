import React from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import API_CALLS from 'API_CALLS/index';
import {GetSiteSettings} from 'global/utils/helper-functions';
import BasicSiteSettings from 'SITE_SETTINGS.json';
import {QRCodeSVG} from 'qrcode.react';


export default class GlobalUtil {
	constructor() {
		this.events = {};
    	this.eventsOnce = {};
		this.globalVariables = {};
		this.State = new State();
		this.LocalStorage = new LocalStorage();
		this.DialogStack = new DialogStack();
		this.BasicSiteSettings = BasicSiteSettings;//END OF BASIC_SITE_SETTINGS
		//this.Vars = new Vars(this);
		//TODO: REPLACE ALL window.SiteSettings with this and link any updates too
		//this.State.set("SiteSettings", {...BasicSiteSettings});//SET SITE SETTINGS STATE
		//GET window.GlobalUtil.State.get("SiteSettings")
		//SET window.GlobalUtil.State.set("SiteSettings", {"whatToChange": "newValue"});
	}

	compairTimes(time1, time2=new Date(), trueTime){
		function millisToMinutesAndSeconds(millis) {
			if(millis < 0) millis = millis * -1;
						
			var minutes = Math.floor(millis / 60000);
			var seconds = ((millis % 60000) / 1000).toFixed(0);
			var results = `${minutes}.${seconds}`;
			return(Number(results));
		}

		var time1InSec = new Date(time1).getTime();
		var time2InSec = new Date(time2).getTime();
    if(trueTime) return(time1InSec-time2InSec);
		return(millisToMinutesAndSeconds(time1InSec-time2InSec))
	}



	getFirstOfMonth(customDate){
		var today = new Date();
		if(customDate) today = new Date(customDate);
		return today.getStartOfMonth().getTime();
	}

	getFirstOfLastMonth(){
		var today = new Date();
		var endDate = new Date(`${today.getMonth()+1}/1/${1900+today.getYear() }`);
		var startDate = new Date(endDate);
		startDate = new Date(startDate.setMonth(startDate.getMonth()-1));
		return startDate.getTime();
	}
	
	getTimeNow(){
		return new Date().getTime();
	}
	
	convertToDate(dateString, formatString){
		return new Date(Number(dateString)).formatDate(formatString);
	}


	getWeekNumber(customDate){
		var today = new Date();
		if(customDate) today = new Date(customDate);
		var weekNumber = today.getWeek();
		return Number(`${today.formatDate("Y")}${(weekNumber.toString().length < 2 ? 0 : '')}${weekNumber}`)
	}

	camelCaseToCapitalSpace(start){
		var result = start.replace( /([A-Z])/g, " $1" );
		result = result.split(' ');
		result = result.map(word=>{
			return (word.charAt(0).toUpperCase() + word.slice(1))
		})
		return result.join(" ");
	}


	apiFail(id, error){
		this.consoleLog("API FAILED "+id, error);
	}

	weekNumberToDate(weekNumber) { //works with this.getWeekNumber();
		if(!weekNumber) return;
		var w = Number(weekNumber.toString().slice(4,6));
		var y = Number(weekNumber.toString().slice(0,4));
		var d = (1 + (w - 1) * 7); // 1st of January + 7 days for each week
		return new Date(y, 0, d);
	}

	capitalizeFirst = (string) => {
    if(string.sentenceCase) return string.sentenceCase(); //IF MY PROTOPYTE EXIST THEN USE IT, ELSE BROOT IT
		return string.charAt(0).toUpperCase() + string.substr(1).toLowerCase();
	}

	capitalizeFirstOfEachWord = (string) => {
    if(string.capitalize) return string.capitalize(); //IF MY PROTOPYTE EXIST THEN USE IT, ELSE BROOT IT
		if (typeof(string) === typeof('string'))
		{
			var array = string.split(' ');
			var capitalizedArray = array.map(element => this.capitalizeFirst(element));
			return capitalizedArray.join(' ');
		}  
		else return '';
	}


	inputToBool = (input) =>
	{
		if (input === 'true') return true;
		else if (input === true) return true;
		else if (input === '1') return true;
		else if (input === 1) return true;
		else return false;
	}

	arrayOfObjectsOnlyUnique = (currentArray, valueToCompair)=>{
		if(!valueToCompair) return;
		return currentArray.filter((obj, index) => {
			const value = this.deepGetFromString(obj, valueToCompair, null);
			return index === currentArray.findIndex(obj => {
				const value2 = this.deepGetFromString(obj, valueToCompair, null);
				return value2 === value;
			});
		});
	}

	arrayOnlyUnique(currentArray, superExact=true) {
		function onlyUnique(value, index, self) {
			if(!superExact && !isNaN(value)) value = Number(value);
			return self.indexOf(value) === index;
		}
		return currentArray.filter(onlyUnique);
	}

	//TAKE AN ARRAY OF OBJECTS AND GROUP THEM BY A CERTAIN VALUE THEY ALL HAVE. GROUPING ALL THOSE WHO HAVE THE SAME VALUE TOGETHER.
	groupArrayOfObj = ({objArray, pathToGroupBy, returnAsObj=false}) => {
		if(!objArray) return;
		if(!pathToGroupBy) return;
		var newObj = {};
		for(var objSingle of objArray){            
			var valueInPlace = this.deepGetFromString(objSingle, pathToGroupBy, null);			
			if(!valueInPlace) return console.log("FAIL: GlobalUtil -> groupArrayOfObj path not found in obj. " + pathToGroupBy);
			else {
				if(!newObj[valueInPlace]) newObj[valueInPlace] = [objSingle];
				else {
					newObj[valueInPlace].push(objSingle);
				}
			}
		}
		if(returnAsObj) return newObj;
		return Object.keys(newObj).map(key=>newObj[key]);

	}

	htmlTextStripper = (html) => {
		let tmp = document.createElement("DIV");
		tmp.innerHTML = html;
		return tmp.textContent || tmp.innerText || "";
	}

	getImageUrl = ({sitefile, desiredSize, boxSize="600x600"}) => {
		let currentURL = `https://via.placeholder.com/${boxSize ? boxSize : `600x600`}?text=No+Picture+Found`;//'/global/assets/images/unavailable.png';
		if(!sitefile || !sitefile.url) return [currentURL];
		if(!sitefile.image_urls || Number(sitefile.images_resized) !== 1 || !desiredSize || desiredSize.length == 0) return [sitefile.url];
		let arrayOfImages =  desiredSize.map((size, index)=>{
			let imageUrl = sitefile.image_urls[size] ? sitefile.image_urls[size] : sitefile.url;
			return imageUrl;
		})
		return arrayOfImages
	}

	convertToMoney = (newNumb, freeValue) => {
		if (freeValue && Number(newNumb) == 0) return freeValue;
		if(!Number.prototype.format) Number.prototype.format = function(n, x, s, c) {
		let re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
				num = this.toFixed(Math.max(0, ~~n));

			return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
		};
		return `$${(Number(newNumb)).format(2)}`;
	}

	numberWithCommas = (x, decNumb) => {
		var numberOfDec = ((decNumb) ? decNumb : 2);
		var parts = x.toString().split(".");
		parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		if(parts[1] || decNumb) parts[1] = Number(`.${((parts[1]) ? parts[1] : 0)}`).toFixed(numberOfDec).split('.')[1];
		return parts.join(".");
	}

	//Check to see if on mobile ios device using safari
	iOSMobileSafariChecker = () => {
		var ua = window.navigator.userAgent;
		var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
		var webkit = !!ua.match(/WebKit/i);
		var iOSSafari = iOS && webkit && !ua.match(/CriOS/i); //IF THIS IS TRUE THEN YES IT IS A STUPID APPLE DEVICE
		return(iOSSafari) //Return bool
	}

	delay = (duration) => new Promise(resolve => setTimeout(resolve, duration))

	delayWithAbort = (duration, thenFunction)=>{ //ABORT IF SOMETHING CHANGED BEFORE THE TIMEOUT
		const timoutAborter = setTimeout(thenFunction, duration)
		return {
			abort: ()=>{clearTimeout(timoutAborter)}
		}
	}

	retryLoop = (functionToTry=()=>{}, numberOfTrys=1, delayTime=1000, onFailedAll=()=>{})=>{
		var RandomID = this.getRandomId();
		window[RandomID] = true;
		const Abort = (RandomID) =>{
			window[RandomID] = undefined;
			delete window[RandomID];
		}
		const onAbort = () =>{
			if(!window[RandomID]){
				//this.consoleLog("ABORTING","\n\n","\n\n");
				return true
			} else {
				return false;
			}	
		}

		const retryLoopInner = async ()=>{
			var functionToTryResults = await functionToTry(numberOfTrys);
			numberOfTrys = numberOfTrys-1;
			if(!functionToTryResults){
				if(numberOfTrys > 0){
					await this.delay(delayTime).then(async ()=>{
						if(onAbort()) return;
						return await retryLoopInner(functionToTry, numberOfTrys, delayTime, onFailedAll);
					}); 
				} else {
					if(onAbort()) return;
					Abort(RandomID); //IF IT DID NOT WORK THEN REMOVE THE RANDOM ID
					await onFailedAll(); //IF TIRED EVERYTHING BUT STILL DIDNT WORK
				}
			} else { //IF IT WORKED THEN REMOVE THE RANDOM ID
				Abort(RandomID);
			}
		}
		retryLoopInner();
		return {
			abort: ()=>{Abort(RandomID)}
		}
	}
	
	decycle(obj, stack = []) { //ALLOWS STRINGIFY OF CLASS
		if (!obj || typeof obj !== 'object')
				return obj;
		
		if (stack.includes(obj))
				return null;

		let s = stack.concat([obj]);

		return Array.isArray(obj)
				? obj.map(x => this.decycle(x, s))
				: Object.fromEntries(
						Object.entries(obj)
								.map(([k, v]) => [k, this.decycle(v, s)]));
	}

	// purpose
	//   copy to Object
	// args
	//   key (unique identifier)
	//   value (object)
	// returns
	//   (none)
	copyObject = (obj) => {
		return(JSON.parse(JSON.stringify(obj)))
	}

	// purpose
	//   paste an object
	// args
	//   key (unique identifier)
	//   value (object)
	// returns
	//   (none)
	pasteObject = (key) => {
		return this.clipboard[key];
	}


	stripOutFromObj = (obj, whatToRemove) => {
		//REMOVE WHAT I DON'T WANT
		var ObjStr = JSON.stringify(obj);  //IF STARTS WITH COMMA THEN REMOVE IT WITH THAT STARTING COMMA
		var searchRegExp = new RegExp('(\,\"'+whatToRemove+'[\"\:]+)([a-zA-Z]+)(\")', 'gi');
		var replaceWith = '';

		var ObjStr = ObjStr.replace(searchRegExp, replaceWith); //IF STILL EXIST BUT ONLY ENDS WITH A COMMA (MAYBE FIRST ITEM) THEN REMOVE IT WITH THAT ENDING COMMA
		var searchRegExp2 = new RegExp('(\"'+whatToRemove+'[\"\:]+)([a-zA-Z]+)(\"\,)', 'gi');
		var replaceWith2 = '';
		ObjStr = ObjStr.replace(searchRegExp2, replaceWith2);

		var searchRegExp3 = new RegExp('(\"'+whatToRemove+'[\"\:]+)([a-zA-Z]+)(\")', 'gi'); //IF STILL EXIST THEN JUST REMOVE IT
		var replaceWith3 = '';
		ObjStr = ObjStr.replace(searchRegExp3, replaceWith3);

		//this.consoleLog("ObjStr", [ObjStr]);
		var newObj = {};
		try {
			// for IOS (You may think this code is not necessary but seems to be the only way ios would work)
			newObj = JSON.parse(ObjStr)
			return(newObj)
		} catch (err) {
			this.consoleLog('Oops, unable to stripOutFromObj');
			this.consoleLog("ObjStr", ObjStr)
			return
		}		
	} 

	// purpose
	//   copy text to clipboard
	//   (there may be some problems with IE 10 and below)
	// args
	//   stringToCopy
	// returns
	//   (none)
	copyToClipboard = (string) => {
		var textArea = document.createElement("textarea");
		textArea.value = string;
		document.body.appendChild(textArea);
		textArea.select();
		try {
			// for IOS (You may think this code is not necessary but seems to be the only way ios would work)
			if (this.iOSMobileSafariChecker())
			{
				var el = textArea;
				var editable = el.contentEditable;
				var readOnly = el.readOnly;
				el.contentEditable = true;
				el.readOnly = false;
				var range = document.createRange();
				range.selectNodeContents(el);
				var sel = window.getSelection();
				sel.removeAllRanges();
				sel.addRange(range);
				el.setSelectionRange(0, 999999);
				el.contentEditable = editable;
				el.readOnly = readOnly;
			}
			// End for IOS

			var successful = document.execCommand('copy');
			//if(window.toastr) window.toastr.success("Copied to Clipboard")
		} catch (err) {
			this.consoleLog('Oops, unable to copy');
		}
		document.body.removeChild(textArea);
	}


  clearSelection(){
   if (window.getSelection) {window.getSelection().removeAllRanges();}
   else if (document.selection) {document.selection.empty();}
  }

  formatAddress = (addressObj={})=>{
    if(!addressObj.street) return "";
    return `${addressObj.street}${addressObj.street2 ? " "+addressObj.street2 : ""}, ${addressObj.city}, ${addressObj.state}, ${addressObj.zipcode}, ${addressObj.country}`;
  }

	ulify = (string='', character_limit) =>
	{
		string = `${string}`;
		if (string == undefined) return '';
		return (string.length <= character_limit) ? string : string.substring(0, character_limit) + "...";
	}

	getArrayFromDictionary = (dictionary) => {
		var array = [];
		Object.keys(dictionary).map(function(element)
		{
			array.push(dictionary[element]);
		});
		return array;
	}

	getRandomId = () => {
		var FirstRandomLetter = Math.random().toString(36).replace(/[^A-Za-z]+/g, '').substr(0, 1); //without this they could have an random id that starts with a number.
		return FirstRandomLetter+Math.random().toString(36).slice(2);
	}

	getRandomIdList = (howMany=1) => {
		return new Array(howMany).fill(1).map(cur=>this.getRandomId());
	}

	getRandomNumber = (min, max) =>
	{
		return Math.floor(Math.random() * (max - min + 1) + min);
	}
  
	deepGetFromString = (obj, key, defaultReturn, delimiter = ',') => {
		if(!key) return defaultReturn;
		return this.deepGetFromArray(obj, key.split(','), defaultReturn);
	}

	deepGetFromArray = (obj, array, defaultReturn) => {
    if(!obj || (typeof(obj) !== "object")) return defaultReturn;
		var compareObject = obj;
		for (var i = 0; i < array.length; i++)
		{
			if (compareObject[array[i]] === undefined || compareObject[array[i]] === null) return defaultReturn;
			compareObject = compareObject[array[i]];
		}
		return compareObject;
	}

	deepSetFromString = (obj, key, newValue) => {
		if(key){
			if(key.indexOf(".") !== -1) return this.deepSetFromArray(obj, key.split("."), newValue); //IF I USED . INSTEAD OF , THEN USE THIS TO SPLIT IT.
			else return this.deepSetFromArray(obj, key.split(","), newValue);
		} else return obj;
	}

	deepSetFromArray = (obj, array, newValue) => {
		var foundSoFar;
		var remainingProps = array.slice(1);
		var currentPositionName = array[0];
		if(Number(currentPositionName) || (Number(currentPositionName) === 0)) currentPositionName = Number(currentPositionName); //IF CURRENT POSITION IS A NUMBER THAN MAKE IT A NUMBER
			
		if (obj[currentPositionName] === undefined || obj[currentPositionName] === null) {
			if(array[1] && (Number(array[1]) || (Number(array[1]) === 0))) { //IF THE NEXT ONE IS A NUMBER THAN THIS ONE SHOULD BE AN ARRAY.
				obj[currentPositionName] = [];
			}
			else{
				obj[currentPositionName] = {};
			}
		}
		foundSoFar = obj[currentPositionName];

		if (remainingProps.length === 0) {
			return obj[currentPositionName] = newValue;
		}

		return this.deepSetFromArray(foundSoFar, remainingProps, newValue);
	}

	isEquivalent = (a, b) => {
		let aProps = Object.getOwnPropertyNames(a);
		let bProps = Object.getOwnPropertyNames(b);

		if (aProps.length != bProps.length) {
			return false;
		}
		for (let i = 0; i < aProps.length; i++) {
			let propName = aProps[i];
			if (a[propName] !== b[propName]) {
				return false;
			}
		}
		if (JSON.stringify(a) !== JSON.stringify(b)) {
			return false;
		}

		return true;
	}


	// whether or not it successfully set config value
	triggerEvent(eventKey, args){
		if (this.events[eventKey] !== undefined) {
			Object.keys(this.events[eventKey]).forEach(key => {
				if(typeof this.events[eventKey][key] === 'function') this.events[eventKey][key](args);
			})
		}

    if (this.eventsOnce[eventKey] !== undefined) {
      Object.keys(this.eventsOnce[eventKey]).forEach(key => {
        if(typeof this.eventsOnce[eventKey][key] === 'function') this.eventsOnce[eventKey][key](args);
        delete this.eventsOnce[eventKey][key]; //DELETE ONCE RAN ONCE
      })
    }
	}

	subscribeEvent(eventKey, method)
	{
		if (this.events[eventKey] === undefined) this.events[eventKey] = {};
		let randomKey = this.getRandomId();
		this.events[eventKey][randomKey] = method;
		return {
      methodKey: randomKey,
      unsubscribe: ()=>{
        this.unsubscribeEvent(eventKey, randomKey)
      }
    };
	}


	subscribeEventOnce(eventKey, method)
	{
		if (this.eventsOnce[eventKey] === undefined) this.eventsOnce[eventKey] = {};
		let randomKey = this.getRandomId();
		this.eventsOnce[eventKey][randomKey] = method;
		return {
		methodKey: randomKey,
		unsubscribe: ()=>{
			this.unsubscribeEvent(eventKey, randomKey)
		}
		};
	}


	unsubscribeEvent(eventKey, methodKey){
		if (this.events[eventKey] !== undefined && this.events[eventKey][methodKey] !== undefined) delete this.events[eventKey][methodKey];
    	else if (this.eventsOnce[eventKey] !== undefined && this.eventsOnce[eventKey][methodKey] !== undefined) delete this.eventsOnce[eventKey][methodKey];
	}


	runAPIOnceWhenReady(method){
		if(window.Client && window.Client.query){
			method()
			return {
				unsubscribe: ()=>{}
			}
		} else {
			return this.subscribeEventOnce("ClientEvent", obj=>method(obj))
		}
	}


	padZeros = (string, number_of_zeros) =>
	{
		string = String(string);
		while(string.length < number_of_zeros) string = '0' + string;
		return string;
	}

	isChild(parent, child, checkIgnores = true) {

		// fix for unmounting elements
		if (this.isEmpty(child.parentElement) && child.tagName != 'HTML') return true;

		// first check ignores
		if (checkIgnores) {
			let foundParent = false;
			this.ignoreComponents.forEach(component => 
			{
				if (!foundParent && this.isChild(component, child, false)) foundParent = true;
			})

			Object.keys(this.ignoreComponentQueries).map(key => {
				let elements = document.querySelectorAll(this.ignoreComponentQueries[key]);
				elements.forEach(element => 
				{
					if (!foundParent && this.isChild(element, child, false)) foundParent = true;
				})
			})


			if (foundParent) return foundParent;
		}

		// parent = javascript element
		// child = javascript element
		if (!parent) return false;
		if (parent == child) return true;
		if (parent && child && parent.classList == child.classList) return true;
		else if (child.parentElement) return this.isChild(parent, child.parentElement, false);
		
		else return false;
	}


	setGlobalVariable(path, value) {
		this.deepSetFromString(this.globalVariables, path, value);
	}

	getGlobalVariable(path, defaultValue) {
    	return this.deepGetFromString(this.globalVariables, path, defaultValue);
	} 

	deleteGlobalVariable(path) {
		this.deepSetFromString(this.globalVariables, path, null);
	} 

	addNumberSuffix(i) {
		let j = i % 10, k = i % 100;
			if (j == 1 && k != 11) return i + "st";
			if (j == 2 && k != 12) return i + "nd";
			if (j == 3 && k != 13) return i + "rd";
			return i + "th";
	}

	compareTwoObjects(a, b)
	{

		let foundInconsistancy = false;

		Object.keys(a).forEach(x => {
			if (a[x] != b[x]) foundInconsistancy = true;
		})

		Object.keys(b).forEach(x => {
			if (a[x] != b[x]) foundInconsistancy = true;
		})

		return !foundInconsistancy
	}


	scrollTo(hash){
		if(!hash) return;
		var element = document.getElementById(`${hash}`);
		if(element) window.scrollTo(0, element.offsetTop);    	
	}


	formatPhoneClean(input){
		input = input.replace(/\D/g,'');
		input = input.slice(0,10);
		return(input)
	}


	formatPhone(input){
    var cleaned = ('' + input).replace(/\D/g, '');
    var match = [cleaned.slice(0,3), cleaned.slice(3,6), cleaned.slice(6,10)]; //8009990000 => ['800','999','0000']     
    if(cleaned.length === 11) match = [cleaned.slice(0,1), cleaned.slice(1,4), cleaned.slice(4,7), cleaned.slice(7,11)]; //18009990000 => ['1','800','999','0000']
    return match;
  }


  getPhoneNumber(){
    var basePhone = (window.Session.urlParams.phone ? window.Session.urlParams.phone : this.BasicSiteSettings.phone);
    if(!basePhone){
      this.consoleLog(`Base Phone Failed. SiteSettings`, [this.BasicSiteSettings, `Session.urlParams`, window.Session.urlParams]);
      basePhone = this.BasicSiteSettings.phone;  
    }
    return basePhone;
  }


  getPhoneString(clean=true){
    var basePhone = this.getPhoneNumber();
    if(!basePhone) return null;
    basePhone = this.formatPhone(basePhone);
    if(!clean) return `${basePhone.join("")}`;
    if(basePhone.length === 4) return`${basePhone[0]} (${basePhone[1]}) ${basePhone[2]} - ${basePhone[3]}`;
    return `(${basePhone[0]}) ${basePhone[1]} - ${basePhone[2]}`;
  }
  

	IDArrayToObj(IDArray=[]){
		var NewObj = {};
		IDArray.map((str, index)=>{
      if(!str || !str.match(/\d+/)) return null;
      else {
  			var firstNumb = str.match(/\d+/).index
  			if(!NewObj[str.slice(0, firstNumb)]) NewObj[str.slice(0, firstNumb)] = str.slice(firstNumb);
  			else {
  				if(Array.isArray(NewObj[str.slice(0, firstNumb)])) NewObj[str.slice(0, firstNumb)].push(str.slice(firstNumb))
  				else NewObj[str.slice(0, firstNumb)] = [NewObj[str.slice(0, firstNumb)], str.slice(firstNumb)];
  			}
      }
		})	
		return NewObj;
	}

	dateSeconds(dt=Date.now()){ //THESE WILL BE THE STANDARDS I USE IN MY DATABASE
		var date = new Date(dt);
		return(date.getTime())
	}

	dateString(dt=Date.now()){ //THESE WILL BE THE STANDARDS I USE IN MY DATABASE
		var date = new Date(dt);
		return(date.toString())
	}

	dateBasic(dt, kind="DAY"){ //THESE WILL BE THE STANDARDS I USE IN MY DATABASE
		if(!dt) return dt;
		if(isNaN(dt)) return dt;
		var date = new Date(Number(dt));
		return(date.formatTickSizeLabel(kind))
	}


	dangerSet(newValue, type="div", className="", id=""){
		function createMarkup(newValue) {
			return {
				 __html: newValue    };
		};
		var TagName = type;
		return(<TagName dangerouslySetInnerHTML={createMarkup(newValue)} className={className} id={id} /> ) 
	}

  dangerSetGlobal(obj=[]){
    var PathArray = obj.slice();
	var newPathArray = [];
	newPathArray = PathArray.map((CONTENT, index)=>{
		if(CONTENT.content === undefined) return CONTENT;
		if(CONTENT.type === undefined) return CONTENT.content;
		return this.dangerSet(CONTENT.content, CONTENT.type, CONTENT.className, CONTENT.id);
	})
    return(newPathArray) 
  }

  dangerSetGlobalObjList(obj={}){
	  var PathArray = {};
	  if(Array.isArray(obj)) PathArray = this.dangerSetGlobal(obj);
	  else {
		Object.keys(obj).map((key, index)=>{
			PathArray[key] = this.dangerSetGlobal(obj[key]);
		})
	  }
    return(PathArray) 
  }


  async getPageParams(){
    var changedValues = false;
    var queryString = window.location.search;
    if(queryString){ //IF NEW PARAMS THEN UPDATE
      changedValues = true;
      var urlParamsClass = new URLSearchParams(queryString);
      var urlParamsString = this.LocalStorage.get("urlParams");
      var urlParams = (urlParamsString ? JSON.parse(urlParamsString) : {});
      urlParamsClass.forEach((value, key)=>{
        if(key === "REDIR") return;
        urlParams[key] = value.toUpperCase();
      })
      if(urlParamsClass.get("reset")){ //IF /?reset=true RESET ALL PARAMS
        urlParams = {};
      }
      var ExpDate = new Date();
      ExpDate.addTimeFrame(1,"WEEK");    
      this.LocalStorage.set("urlParams", JSON.stringify(urlParams), ExpDate); //UPDATE LOCAL SORAGE ONLY IF THEIR ARE NEW PARAMS
      window.Session.urlParams = urlParams;
    }
    return changedValues;
  }

  
  
  resetWindowSession(){
    var urlParams = this.LocalStorage.get("urlParams");
    window.Session = {
      "urlParams": (urlParams ? JSON.parse(urlParams) : {}),
      "token": this.LocalStorage.get("token"), //localStorage.getItem('token'),
      "user": {
        "email": this.LocalStorage.get("email") //localStorage.getItem('email')
      },
      "Language": window.DefaultLang
    }; 
  }


	logout=(redirect=true)=>{
		//if(process.env.NODE_ENV === 'production') return true;
		if(navigator.userAgent === 'ReactSnap') return true; //IF WE ARE DOING THE REACT SNAP PRELOAD THEN SKIP THIS    
			this.consoleLog("GlobalUtil logout");
			var redirectPage = ((window.Session.Language === "ENGLISH") ? "/login" : "/acceso");
			var curPath = this.deepGetFromString(window, "Router,history,location,pathname", false);
			if(redirect && curPath){ //DONT REDIRECT IF ALREADY IN LOGIN SCREEN
			this.consoleLog(`Global logout redirect, curPath`, redirect,curPath);
			if((curPath.search("/admin") > -1) || (curPath.search("/user") > -1)){ //If we are going to the user page or the admin page. Else DON'T REDIRECT
				if(window.Router && window.Router.history){ 
					window.Router.history.push(redirectPage); //IF ADMIN GO TO ADMIN
				}
				else {
					document.location.href=redirectPage;
				}
			}
		}
		this.LocalStorage.remove("token");
		this.LocalStorage.remove("email");
		this.resetWindowSession();
		this.setGlobalVariable("isLoggedIn", false);
		this.triggerEvent("LoginEvent", {});
    	//CLEAN OUT ANY EXTRA SITE SETTINGS
		window.SiteSettings = {
			...this.BasicSiteSettings
		};
		window.GlobalUtil.triggerEvent("SiteSettingsEvent", this.BasicSiteSettings); 
		//window.Client.clearStore();
	}



    changePageAfterLogin = ()=>{
      var REDIR = this.getGlobalVariable("REDIR", null);
      if(REDIR){
        window.Router.history.push(REDIR);
        this.deleteGlobalVariable("REDIR");
      } else {
        if(window.Session && window.Session.user && (window.Session.user.role === "ADMIN")) window.Router.history.push('/admin'); //IF ADMIN GO TO ADMIN
        else window.Router.history.push('/user'); //IF USER GO TO USER
      }
    }

	login = async (login, dontChangePage) => {  
    this.consoleLog("GlobalUtil login");       
	//if(process.env.NODE_ENV === 'production') return true;
    if(navigator.userAgent === 'ReactSnap') return true; //IF WE ARE DOING THE REACT SNAP PRELOAD THEN SKIP THIS     

    this.State.set("Session", login);
		window.Session = {...window.Session, user: {...(window.Session.user ? window.Session.user : {}), ...login.user}, token: (login.token ? login.token : window.Session.token)};          
		if(window.Session.user){
		window.Session.user.id = window.Session.user._id;
		if(!window.Session.user.treatmentPlan) window.Session.user.treatmentPlan = {};
		}  
		if(login.token) this.LocalStorage.set("token", login.token); 
			this.LocalStorage.set("email", login.user.email);		    	
			this.setGlobalVariable("isLoggedIn", true);
			this.triggerEvent("LoginEvent", login);

			if(!dontChangePage){
			this.changePageAfterLogin();
		}
		return login;
	}






	//window.GlobalUtil.setCartToLocalStorage(newOrder)
	setCartToLocalStorage = (newOrder) => {          
		if(!newOrder) return;
		var ExpDate = new Date();
		ExpDate.addTimeFrame(1,"WEEK");		    	
		this.LocalStorage.set("newOrder", JSON.stringify(newOrder), ExpDate);
	}

	//window.GlobalUtil.getCartFromLocalStorage()
	getCartFromLocalStorage = () => {    	
		var newOrder = this.LocalStorage.get("newOrder");
		if(!newOrder) return null;
		return JSON.parse(newOrder);
	}

	//window.GlobalUtil.clearLocalStorageCart()
	clearLocalStorageCart = () => {
		this.LocalStorage.remove("newOrder");
	}


	//window.GlobalUtil.consoleLog(NAME, CONTENT, EXCEPTION)
	consoleLog = (NAME, CONTENT, EXCEPTION)=>{ //CONSOLE LOG STUFF WHILE IN PRODUCTIONS BUT NOT IN LIVE VERSION SO NO ACCIDENTAL CONSOLE LOGS SHORTCUT "con"+tab
		if(!EXCEPTION && process.env.NODE_ENV != 'development') return;
		console.log("DEV LOG: ", NAME,"\n");
		if(CONTENT){
			if(Array.isArray(CONTENT)) CONTENT.map((item, index)=>{
				console.log("DEV LOG: ", item,"\n");
			})
			else console.log("DEV LOG: ", CONTENT,"\n\n");
		}
          
	}

	//window.GlobalUtil.consoleDir(NAME, CONTENT, EXCEPTION)
	consoleDir = (NAME, CONTENT, EXCEPTION)=>{ //CONSOLE LOG STUFF WHILE IN PRODUCTIONS BUT NOT IN LIVE
		if(!EXCEPTION && process.env.NODE_ENV != 'development') return;
		console.log(NAME,"\n");
    if(CONTENT){
  		if(Array.isArray(CONTENT)) CONTENT.map((item, index)=>{
  			console.dir(item,"\n");
  		})
      else console.dir(`CONTENT`,"\n\n",CONTENT,"\n\n");
    }
          
	}

  //window.GlobalUtil.QRCodeMaker("https://smiles.club/benefits/?CODE=VAL-PAC-001", "NEW_QR")
  QRCodeMaker = (URL, NEW_QRCODE_NAME="NEW_QR_CODE") => {
    this.triggerEvent("SlideInRightEvent", {
      TITLE: <span>New QR Code</span>,
      TITLE: "New QR CODE",
      closeButtonText: "Cancel",
      saveButtonText: "",
      hideSaveButton: true,
      customClass: "frontEndSlideInRight shortSlideInRight newOrderDialog",
      width: "1200px",
      children: (childrenProps)=>{
        function downloadSVG(className) {
          var svg = document.querySelector(className);
          svg.children[0].setAttribute("xmlns","http://www.w3.org/2000/svg");
          svg.children[0].setAttribute("xmlnsXlink","http://www.w3.org/1999/xlink");
          const blob = new Blob([svg.innerHTML.toString()]);
          const element = document.createElement("a");
          element.download = `${NEW_QRCODE_NAME}.svg`;
          element.href = window.URL.createObjectURL(blob);
          element.click();
          element.remove();
        }                                      
        return(
          <div className="">
            <div className="d-flex"  style={{"margin": "0 -10px"}}>
              <div className="customSVG" style={{"padding": "10px"}}>
                <QRCodeSVG 
                  value={URL} 
                  size="400"
                  level={"M"}
                  includeMargin={false}
                />
              </div>
              <div className="customSVG2" style={{"padding": "10px"}}>
                <QRCodeSVG 
                  value={URL} 
                  imageSettings={{
                    src: "https://Smiles.club/icon-100.png",
                    x: undefined,
                    y: undefined,
                    height: 50,
                    width: 50,
                    excavate: true,
                  }}
                  level={"M"}
                  includeMargin={false}
                  size="400"
                />
              </div>
            </div>
            <br/>
            <br/>
            <h2 className="">URL: {URL}</h2>
            <div className="buttonGroup">
              <button className="button button1" onClick={()=>downloadSVG(".customSVG")}>Download</button>
              <button className="button button1 btn-color5" onClick={()=>downloadSVG(".customSVG2")}>Download</button>
            </div>
          </div>
        )
      }
    });
  }




  		setCoupon = async (couponObj={}) =>{ 
			this.consoleLog("GLOBAL-UTIL.JS -> window.GlobalUtil.setCoupon -> RAN");
			var newOrder = this.State.get("newOrder")     
			newOrder.coupon = this.APICleanObj(couponObj)
			await this.State.set("newOrder", newOrder);
			await this.triggerEvent("changedCoupon", couponObj);
			return true;
		}

		removeCoupon = async () => {
			this.consoleLog("GLOBAL-UTIL.JS -> window.GlobalUtil.removeCoupon -> RAN");
			await this.setCoupon();
			return true;
		}

        //-THIS INTERPRETS COUPONS TO DISCOUNT--------------------------------------------------------------------------------------------------------------------------
        couponDis = ({coupon={}, product})=>{
			//IF THE COUPON DOES NOT APPLY TO THIS PRODUCT THEN DON'T APPLY THE COUPON TO THE PRODUCT
			if(coupon.appliesOnlyTo && (coupon.appliesOnlyTo !== "ALL")){ //RUN THIS CHECK ONLY WHEN COUPON NEEDS TO APPLY TO ONLY CERTAIN THINGS
				if(coupon.appliesOnlyTo !== product.category) return product;
			}
			
          var cost = (Number(product.cost)-Number(product.discount));		  
          var couponDiscount = (coupon.discount ? Number(coupon.discount) : 0);
          if(coupon.type === "FIXED_PRICE"){
			  product.couponDiscount = cost-couponDiscount;
			  product.couponCost = couponDiscount; //THIS IS THE NEW PRODUCT COST AFTER THE COUPON IS APPLIED
          }
          if(coupon.type === "PERCENT_OFF"){
			product.couponDiscount = (cost*(couponDiscount/100));
			product.couponCost = (cost-product.couponDiscount); //THIS IS THE NEW PRODUCT COST AFTER THE COUPON IS APPLIED
		  }
          if(coupon.type === "MONEY_OFF"){
			  product.couponDiscount = couponDiscount;
			  if(cost<couponDiscount) product.couponDiscount = cost;//CANNOT BE MORE OF A DISCOUNT THAN THE COST OF THE PRODUCT
			  product.couponCost = (cost-product.couponDiscount);
		  }

          if(product.couponDiscount) product.couponDiscount = Number(Number(product.couponDiscount).toFixed(2))
          if(product.couponCost) product.couponCost = Number(Number(product.couponCost).toFixed(2))
		  //console.log('couponDis product', product);
		  
          return product; //ROUND TO 2 DECIMAL PLACES
        }
        //--THIS ADDS COUPON DISCOUNT TO PRODUCTS--------------------------------------------------------------------------------------------------------------------------
        productCouponDisCal = (products, coupon)=>{
          var discount = 0;
          if(products) products = products.map((product, index)=>{
            //delete product.couponDiscount;                  
            if(coupon) {
				if((coupon.appliesOnlyTo === product.category) || (coupon.appliesOnlyTo === "ALL")){ //IF THIS COUPON APPLIES TO THIS PRODUCT CATEGORY
					product = this.couponDis({coupon, product}); //ELSE GET THE PRICE OFF       
				}
            }                    
            return product;
          })
          return products;
        }

        productCostCal = (product)=>{			
			var quantity = (product.quantity ? Number(product.quantity) : 1);
			var discount = Number(product.discount);
			if(product.couponDiscount !== undefined) discount = (discount + Number(product.couponDiscount));
			var cost = Number(product.cost);
			discount = Number(Number(discount).toFixed(2));
			cost = Number(Number(cost).toFixed(2));                
			return {
				discount, 
				cost, 
				originalCost: Number(product.cost), 
				quantity
			};
        }

        productTotalCostCal = (products=[])=>{
			var coupon = this.State.get("newOrder").coupon;
			var price = 0;
			var productDiscount = 0;
			for(var product of products){
				var tempProduct = (coupon ? this.couponDis({coupon, product}) : product);	
				var {discount, cost, quantity} = this.productCostCal(tempProduct);
				price+=(cost*quantity);
				productDiscount+=(discount*quantity);
			}
			var subtotal = Number(Number(price-productDiscount).toFixed(2));
			productDiscount = Number(Number(productDiscount).toFixed(2));                
			return {subtotal, cost:price, productDiscount};
        }

	/*
    ● ADD ANY DISCOUNTS TO ANY PRODUCTS
    ● CALCULATE THE COST AND DISCOUNT OF ALL THE PRODUCTS
    ● CHECK GLOBAL DISCOUNTS
    ● CALCULATES EVERYTHING ELSE
    ● RETURNS IT AS OBJ
  */
	checkoutCalculator = (products, coupon)=>{
		if(!products || !products.length) return({
			subtotal: 0,
			discount: 0,
			productDiscount: 0,
			subtotalMinusDis: 0,
			tax: 0,
			shipping: 0,
			setupFee: 0,
			total: 0,
			productCount: 0,
			orderSummary: 0
		});	
		var curProducts = this.productCouponDisCal(products.slice(), coupon); //GET CURRENT PRODUCTS WITH ANY COUPONS APPLIED TO THEM
		var taxLocation = 0;
		var {subtotal, productDiscount} = this.productTotalCostCal(curProducts); //GET COST OF ALL PRODUCTS
		var discount = (coupon && (coupon.appliesOnlyTo === "ALL")) ? this.couponDis({coupon: coupon, product: products[0]}).couponDiscount : 0; //IF A COUPON FOR ALL EXIST THEN REMOVE THAT MUCH FROM SUBTOTAL

		var subtotalMinusDis = Math.max(subtotal-discount, 0);
		var tax = subtotalMinusDis*taxLocation;
		var shipping = 0;//products.length < 1 ? 0 : products.reduce((cur, newVal)=>{return(cur+Number(newVal.shipping ? newVal.shipping : 0))},0);
		var total  = subtotalMinusDis+tax+shipping;
		var orderSummary = products.map(order=>{
			return(
				"("+order.quantity+") "+order.name
			)
		}).join(", ");
		var productCount = ((!products.length) ? 0 : products.reduce((cur,odr)=>{
			return(cur+Number(odr.quantity));
		},0));
		//THIS ONLY WORKS BECAUSE WE CAN ONLY HAVE 1 PRODUCT IN THE CART WITH SUBSCRIPTION

		return({
			subtotal,
			discount,
			productDiscount,
			subtotalMinusDis,
			tax,
			shipping,
			setupFee: 0,
			total,
			productCount,
			orderSummary
		})
	}

	checkoutCalculatorNew = (products, coupon)=>{
		if(!products || !products.length) return({
			subtotal: 0, //FULL PRICE OF ALL PRODUCTS ADDED TOGETHER
			discount: 0,
			tax: 0,
			shipping: 0,
			subtotalMinusDis: 0,
			total: 0
		});
		var tax = 0,
			shipping = 0,
			subtotal = 0,
			discount = 0,
			subtotalMinusDis = 0,
			total = 0;		

		products.map(product=>{
			var tempProduct = this.couponDis({coupon, product:{...product}});
			var cost = (Number(tempProduct.cost));
			var quantity = Number(tempProduct.quantity);		
			Number(tempProduct.discount)
			subtotal = (subtotal + (cost*quantity))
			discount = (discount + (((tempProduct.couponDiscount ? Number(tempProduct.couponDiscount) : 0) + Number(tempProduct.discount))*quantity));
		});		
		discount = (subtotal<discount) ? subtotal : discount;
		subtotalMinusDis = (subtotal-discount); //TOTAL WITH ALL DISCOUNTS ADDED
		total = (subtotalMinusDis+tax+shipping); //TOTAL OF SUBTOTAL + TAX + SHIPPING - DISCOUNTS
		
		return({
			subtotal, //FULL PRICE OF ALL PRODUCTS ADDED TOGETHER
			discount,
			tax,
			shipping,
			subtotalMinusDis,
			total
		})
	}

  /*
    values {
      onlyOne //TRUE IF YOU DON'T WANT TO ALLOW MULTIPLE OF THE SAME PRODUCT
      replaceAll //TRUE IF YOU WANT TO REPLACE ALL PRODUCTS WITH CURRENT ADDED PRODUCT
      product //CURRENT PRODUCT TO ADD TO CART
    }
  */
	addToCart = (values={}, callBack=()=>{})=>{ //IF replaceAll IS TRUE THEN IT WILL REMOVE ALL PRODUCTS EXEPT THIS ONE
    var {product={}, replaceAll=false, onlyOne=false} = values;
    //if(replaceAll) this.State.clear("newOrder");
    product = this.APICleanObj(product);//CLEAN OBJ JUST IN CASE          
    if(!product) return;
		product = {...product, id: product._id};
		var currentOrder = this.State.get("newOrder");
    if(!currentOrder || replaceAll) currentOrder = {};

    //if(product.category === "IMPRESSION_KITS") currentOrder.hasImpressionKit = true;
    //if(product.category === "TREATMENT_PLANS") currentOrder.hasTreatmentPlan = true;
          
		if(!currentOrder.products || replaceAll){
			product.quantity = 1;
			currentOrder.products = [product];
		} else {
			var productExist = false;
			currentOrder.products = currentOrder.products.map((productObj, index)=>{
				if(productObj.id === product.id){ //IF WE FOUND THAT PRODUCT ALREADY AND WE CAN HAVE MULTIPLE THEN ADD IT AGAIN. ELSE SKIP
					productExist = true;
					if(!onlyOne) productObj.quantity = Number(productObj.quantity)+1;
				}
				return productObj;
			})
			if(!productExist){
				product.quantity = 1;
				currentOrder.products.push(product);
			} 
		}

    currentOrder.products = this.productCouponDisCal(currentOrder.products.slice(), currentOrder.coupon); //GET CURRENT PRODUCTS WITH ANY COUPONS APPLIED TO THEM
          
    //this.setCartToLocalStorage(currentOrder);      //THIS IS DONE IN THE APP FILE
		this.State.set("newOrder", {...currentOrder});
		callBack();
	}


	addToCartWithRedirect = (product, replaceAll=false, callBack=()=>{})=>{
    if(!product) return;
		this.addToCart({product, replaceAll}, ()=>{
			window.Router.history.push('/checkout');
			callBack();
		})
	}

	//var {hasNextPage, hasPrevPage, totalPages, currentPage} = this.paginationCalculator(totalItems, limit, offset);
	paginationCalculator = (totalItems, limit, offset)=>{
		offset = Number(offset);
		totalItems = Number(totalItems);
		limit = Number(limit);

		var totalPages = Math.ceil(totalItems/limit);
		var currentPage = (Math.floor(offset/limit)+1);
		var hasNextPage = ((currentPage >= totalPages) ? false : true);
		var hasPrevPage = ((currentPage > 1) ? true : false);
		return {
			hasNextPage, 
			hasPrevPage,
			totalPages,
			currentPage
		}
	}


  APICleanObj = (curObj)=>{
    return this.stripOutFromObj(JSON.parse(JSON.stringify(curObj)), "__typename")
  }
  
  //API HELPERS--------------------------------------------------------------------------------------------------------------
  //NEEDS THE "CLEAN_FIELDS" THAT SHOULD BE FOUND IN THE API_CALLS FOR EACH API (API_CALLS.[API_NAME].CLEAN_FIELDS)
  APISubmitObjCleaner = (CLEAN_FIELDS, SUBMIT_OBJ)=>{
    var CopyOfSubObj = {...this.APICleanObj(SUBMIT_OBJ)};

    //CLEAN OUT BLANK
    Object.keys(CopyOfSubObj).map((key, index)=>{
      if(CopyOfSubObj[key] === undefined) delete CopyOfSubObj[key];
      //if(CopyOfSubObj[key] === null) CopyOfSubObj[key] = "";
      //if(Array.isArray(CopyOfSubObj[key]) && CopyOfSubObj[key].length < 1) CopyOfSubObj[key] = "";
    })
          
    const CheckType = (path, curValue, CopyOfSubObj)=>{
      if(typeof curValue === "string"){
        var curValueArray = curValue.split();
        if(curValueArray[0] === "string"){
          var curObjValue = this.deepGetFromString(CopyOfSubObj, path, undefined);
          if(curObjValue === undefined) return CopyOfSubObj;
          var TransformDic = {
            "uppercase": (curObjValue) => curObjValue.toUpperCase(),
            "lowercase": (curObjValue) => curObjValue.toLowerCase(),
            "sentenceCase": (curObjValue) => curObjValue.sentenceCase(),
            "capitalize": (curObjValue) => curObjValue.capitalize() //capitalize each first letter of each word.
          };
          if(curValueArray[1] && TransformDic[curValueArray[1]]) curObjValue = TransformDic[curValueArray[1]](curObjValue);
          this.deepSetFromString(CopyOfSubObj, path, `${curObjValue}`)
        }

        if(curValueArray[0] === "number"){
          var curObjValue = this.deepGetFromString(CopyOfSubObj, path, undefined);
          if(curObjValue === undefined) return CopyOfSubObj;
          this.deepSetFromString(CopyOfSubObj, path, Number(curObjValue))
        }

        if(curValueArray[0] === "boolean"){ //IF UNDEFINED THEN DON'T DO ANYTHING, ELSE TRANSLATE TO BOOL
          var curObjValue = this.deepGetFromString(CopyOfSubObj, path, undefined);
          if(curObjValue === undefined) return CopyOfSubObj;
          this.deepSetFromString(CopyOfSubObj, path, this.inputToBool(curObjValue))
        }
      } //END OF TYPE STRING


      if(typeof curValue === "object"){ //THIS COULD BE ARRAY OR REAL OBJECT
        if(Array.isArray(curValue)){ //IF ARRAY THEN FOR EACH ITEM IN ARRAY RUN THIS FUNCTION AGAIN
          curValue.map((curVal, index)=>{
            CopyOfSubObj = CheckType(path+','+index, curVal, CopyOfSubObj);
          })
          //console.log(`CopyOfSubObj`,"\n\n",CopyOfSubObj,"\n\n");
          //console.log(`curValue`,"\n\n",curValue,"\n\n");
                
          //if()  
        }
        else { //IF IS AN OBJECT THEN CHECK FOR EACH ITEM IN OBJECT
          Object.keys(curValue).map((innerKey, index)=>{
            CopyOfSubObj = CheckType(`${path},${innerKey}`,curValue[innerKey], CopyOfSubObj);
          });
        }
      }

      return CopyOfSubObj;
    }

    Object.keys(CLEAN_FIELDS).map((key, index)=>{
      var curValue = CLEAN_FIELDS[key];          
      CopyOfSubObj = CheckType(key, curValue, CopyOfSubObj)
    })
    

    return CopyOfSubObj;
  }


	// dateStringSimple(dt=Date.now()){
	// 	var date = new Date(dt);
	// 	return(newDate.toLocaleDateString(undefined, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }));
	// }

	states(){
		return({
			"AL": "Alabama",
			"AK": "Alaska",
			"AZ": "Arizona",
			"AR": "Arkansas",
			"CA": "California",
			"CO": "Colorado",
			"CT": "Connecticut",
			"DE": "Delaware",
			"DC": "District Of Columbia",
			"FL": "Florida",
			"GA": "Georgia",
			"HI": "Hawaii",
			"ID": "Idaho",
			"IL": "Illinois",
			"IN": "Indiana",
			"IA": "Iowa",
			"KS": "Kansas",
			"KY": "Kentucky",
			"LA": "Louisiana",
			"ME": "Maine",
			"MD": "Maryland",
			"MA": "Massachusetts",
			"MI": "Michigan",
			"MN": "Minnesota",
			"MS": "Mississippi",
			"MO": "Missouri",
			"MT": "Montana",
			"NE": "Nebraska",
			"NV": "Nevada",
			"NH": "New Hampshire",
			"NJ": "New Jersey",
			"NM": "New Mexico",
			"NY": "New York",
			"NC": "North Carolina",
			"ND": "North Dakota",
			"OH": "Ohio",
			"OK": "Oklahoma",
			"OR": "Oregon",
			"PA": "Pennsylvania",
			"PR": "Puerto Rico",
			"RI": "Rhode Island",
			"SC": "South Carolina",
			"SD": "South Dakota",
			"TN": "Tennessee",
			"TX": "Texas",
			"UT": "Utah",
			"VT": "Vermont",
			"VI": "Virgin Islands",
			"VA": "Virginia",
			"WA": "Washington",
			"WV": "West Virginia",
			"WI": "Wisconsin",
			"WY": "Wyoming"
		})
	}

}



// class Vars { //THIS CANNOT BE SUBSCRIBED AND JUST HOLDS GLOBAL VALUES FOR THE SITE
//   constructor(GlblUtl) {
//     this.variables = {};                  
//   }

//   set(path, newVal) {          
//     GlblUtl.deepSetFromString(this.variables, path, newVal);
//     if (!this.variables[key]) this.variables[key] = {};
//     this.variables[key] = {...this.variables[key], ...state};     
//   }

//   get(path, defaultValue) {
//     return GlblUtl.deepGetFromString(this.variables, path, newVal);
//   }

//   clear(path) {
//     GlblUtl.deepSetFromString(this.variables, path, und);
//   } 

// }





class State { //THIS CAN BE SUBSCRIBED AND THINGS CAN BE UPDATED
  constructor() {
    this.states = {};
    this.statesChangeCallbacks = {}
    this.subscribeKeyIndex = 0;
  }

  set(key, state) {          
    if (!this.states[key]) this.states[key] = {};
    this.states[key] = {...this.states[key], ...state};
    if (this.statesChangeCallbacks[key]){
      Object.keys(this.statesChangeCallbacks[key]).forEach(x => this.statesChangeCallbacks[key][x](this.states[key]));
    }        
  }

  get(key) {
    return this.states[key] ? this.states[key] : undefined;
  }

  clear(key) {
    delete this.states[key];
    delete this.statesChangeCallbacks[key];
  }

  subscribe(key, callback) {
    let subscribeKey = this.subscribeKeyIndex++;
    if (!this.statesChangeCallbacks[key]) this.statesChangeCallbacks[key] = {};
    this.statesChangeCallbacks[key][subscribeKey] = callback;
    return {
      methodKey: subscribeKey,
      unsubscribe: ()=>{
        this.unsubscribe(key, subscribeKey);
      }
    };
  }

  unsubscribe(key, subscribeKey) {
    if (this.statesChangeCallbacks[key]) delete this.statesChangeCallbacks[key][subscribeKey];
  }
}





class LocalStorage { //THIS GET'S SAVED ON BROWSER.
	constructor(){
		this.states = {};
		this.get = this.get.bind(this);
		this.checkAllExpired = this.checkAllExpired.bind(this);
		this.checkAllExpired();
	}

	checkAllExpired(){
		Object.keys(window.localStorage).map((key, index)=>{
			if(key.search('_experation') == -1) this.checkExpired(key);
		})
	}

	checkExpired(key){
		if(!window.localStorage.getItem(`${key}_experation`)) return;
		//Check each item in local storage and check experation date
		//if it's expired then remove it
		var today = new Date();
		var lastupdate = new Date(Number(window.localStorage.getItem(`${key}_experation`)));
		if(lastupdate === "invalid date") return;   	
					
		if(lastupdate.getTime() < today.getTime()){
				window.localStorage.removeItem(key);
				window.localStorage.removeItem(`${key}_experation`);
			}
	}

	set(key, object, experationDate) {
		if(!experationDate){ //IF NO DATE IS GIVEN THEN DEFAULT 1 MONTH FROM TODAY
			var experationDate = new Date();
			experationDate.setMonth(experationDate.getMonth()+1);
		}
		window.localStorage.setItem(key, JSON.stringify(object));
		window.localStorage.setItem(`${key}_experation`, JSON.stringify(experationDate.getTime()));
	}

	get(key) {
		this.checkExpired(key);
		var item = window.localStorage.getItem(key);
		if(!item) return;
		try {
			item = JSON.parse(item)
		} catch (e) {
			console.log(item)
		}
		return item;
	}

	remove(key) {
		window.localStorage.removeItem(key);
			window.localStorage.removeItem(`${key}_experation`);
	}
}





class DialogStack {
  constructor() {
    this.dialogStack = []
  }

  getRandomId = () => {
    var FirstRandomLetter = Math.random().toString(36).replace(/[^A-Za-z]+/g, '').substr(0, 1); //without this they could have an random id that starts with a number.
    return FirstRandomLetter+Math.random().toString(36).slice(2);
  }


  closeTop = () => {
    var StackCOPY = this.dialogStack.pop(); //REMOVE LAST ONE
    StackCOPY.close();
  }


  closeByID = (id, dontCloseTop) => {
    var StackCOPY = this.dialogStack.slice();
    var CloseAllAfter = false; //ONCE WE'VE CLOSED ONE ALL THE ONES ABOVE IT SHOULD ALSO CLOSE
    StackCOPY.map((object, index)=>{
      if((object.id === id) || CloseAllAfter){
        object.close();
        this.dialogStack.splice(index,1); //REMOVE FROM STACK
        if(!dontCloseTop) CloseAllAfter = true;
      }
    })
  }

  closeAll = () => { //CLOSE ALL
    this.closeByID(this.dialogStack[0].id);
  }


  add = (newCloseFunction) => {
    if(!newCloseFunction) return;
    var randomKey = this.getRandomId();
    this.dialogStack.push({
      id: randomKey,
      close: newCloseFunction
    });
    return {
      id: randomKey,
      close: ()=>{
        this.remove(randomKey)
      }
    };
  }


  remove = (id) => {
    var StackCOPY = this.dialogStack.slice();
    StackCOPY.map((object, index)=>{
      if(object.id === id){
        this.dialogStack.splice(index,1); //REMOVE FROM STACK
      }
    })
  }

  

}

window.GlobalUtil = new GlobalUtil();
