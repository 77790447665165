import React from 'react';
//import "styles/scss/user/index.scss";
import 'styles/scss/user/index.scss';

import Dashboard from './pages/dashboard/index';
import Help from './pages/help/index';
import Profile from './pages/profile/index';
import Billing from './pages/billing/index';
import TreatmentPlan from './pages/treatmentPlan/index';
import Affiliate from './pages/affiliate/index';


import MobileMenu from './components/MobileMenu';
import {
    BrowserRouter as Router, StaticRouter, Route, Link, Redirect, Switch
    // etc.
} from 'react-router-dom';

import PAGES from './pages/pages.json'; //ADD NEW PAGES HERE
import { gql, useQuery, useMutation } from '@apollo/client';
import API_CALLS from 'API_CALLS/index';
import Loading from 'global/components/Loading/index';
import Header from 'user/normal/components/Header/index';
import NavMenu from 'user/normal/components/NavMenu/index';
import {GetFullUserInfo} from 'global/utils/helper-functions';

const MUTATION_USER_EDIT = API_CALLS.USERS.MUTATIONS.update();


const PAGES_COMPONENTS = { //ADD ANY NEW PAGES HERE
  Dashboard: Dashboard,
  Billing: Billing,
  Help: Help,
  Profile: Profile,
  TreatmentPlan: TreatmentPlan,
  Affiliate: Affiliate,

  Inicio: Dashboard,
  Facturacion: Billing,
  Tratamiento: TreatmentPlan,
  Perfil: Profile,
  Afiliado: Affiliate,
  Ayuda: Help
};


//window.Session.user.type === "ONLINE"
//GET TASK USER_PURCHASE_TREATMENT_PLAN IF AVALIBAL

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMobileMenu: false,
    };     
  }

  componentDidMount(){
    window.ForceUserReload = ()=>GetFullUserInfo({
      noCache: true,
      //callBackSuccess: ()=>{},
      //callBackFailed: ()=>{}
    });
    if(document.querySelector("#Website")) document.querySelector("#Website").remove();
    //console.log(`componentDidMount`,"\n\n","\n\n");

    // if(navigator.userAgent === 'ReactSnap'){
    //   //Array.from(document.querySelector("#Root").children).map(child=>child.remove())
    //   //Array.from(document.querySelector("#Root").children).map(child=>document.querySelector("#Root").removeChild(child))
    // }
  }

  componentWillUnmount(){
  }  
  

  onUpdateUser = (newObj, callBack=()=>{}) => {
    window.Client.mutate({ //API UPDATE
      mutation: MUTATION_USER_EDIT,
      variables: newObj
    })
    .then(async (obj)=>{
      await callBack();
      await GetFullUserInfo({
        noCache: true,
        //callBackSuccess: ()=>{},
        //callBackFailed: ()=>{}
      });
      if(this) this.setState({date: new Date()});
      return true;
    })
    .catch((error)=>{
      console.log("error updating user");
      console.log(error,"\n\n");  
      callBack();
    });
  }



  render(){
    var {showMobileMenu} = this.state;
    var isLoggedIn = window.GlobalUtil.getGlobalVariable("isLoggedIn", false);
    if(navigator.userAgent === 'ReactSnap') return null;
    if(window.Session && window.Session.user){
      if(window.Session.user.role === "DOCTOR") return (<Redirect to={"/doctor"} />);
      if(window.Session.user.role === "LABORATORY") return (<Redirect to={"/lab"} />)
    }
    return (
      <div id="UserWrapper" className="animated fadeIn">
        {
          (!isLoggedIn || !window.Session.user)
          ? null
          : <div id="User">
              {
                showMobileMenu && <MobileMenu onHideSideMenu={()=>this.setState({showMobileMenu: false})} onUpdateUser={this.onUpdateUser}/>
              }
              <div id="UserContent">
                <NavMenu />
                <div id="Content">
                  <Header onShowMobileMenu={()=>this.setState({showMobileMenu: true})} onUpdateUser={this.onUpdateUser}/>
                  <Switch>
                    {
                      PAGES[window.Session.Language].map((page, index)=>{
                        const CurComp = PAGES_COMPONENTS[page.Name];
                        if(page.Name == "Home" || page.Name == "Inicio" || page.Name == "Dashboard") return <Route 
                          exact 
                          key={index}
                          path={page.Paths} 
                          render={props=>{
                            window.GlobalUtil.triggerEvent("PageChangedEvent", page);
                            return(
                              <CurComp  
                                {...props}
                                onUpdateUser={this.onUpdateUser}
                              />
                            )
                          }} 
                        />

                        return <Route 
                          key={index}
                          path={page.Paths} 
                          render={props=>{
                            window.GlobalUtil.triggerEvent("PageChangedEvent", page);
                            return(
                              <CurComp  
                                {...props}
                                onUpdateUser={this.onUpdateUser}
                              />
                            )
                          }} 
                        />
                      })
                    }
                    <Route>
                      <Redirect to={`/user`}  />
                    </Route>
                  </Switch>
                </div>
              </div>
            </div>
        }
      </div>
    );
  }
}


export default Index;