import React from 'react';
import TemplatePage from 'website/templates/templatePage/index.js';
import 'styles/scss/website/pages/privacy.scss';
import ESPAÑOL from "./ESPAÑOL";
import ENGLISH from "./ENGLISH";
const CONTENTS = {
  ESPAÑOL,
  ENGLISH
}
const {Container, Row, Column} = window.LayoutBuilder;
class Index extends TemplatePage {
  // constructor(props) {
  //   super(props);
  //   //this.state = {...this.state,
  //   //};    
  // }

  didMount(){
  }

  willUnmount(){
  }

  render(){
    //var {showSideMenu} = this.state;
    const CONTENT = window.GlobalUtil.dangerSetGlobal(CONTENTS[window.Session.Language]); 
    return (
      <div className="contentPage" id="PagePrivacyPolicy"> 
        <Container className="">
          <Row className="title">
            <Column className="" col="" xs="" sm="" md="" lg="" xl="">
              <div className="">
                {CONTENT[0]}
              </div>
            </Column>
          </Row>
          <Row className="title">
            <Column className="" col="" xs="" sm="" md="" lg="" xl="">
              {CONTENT[1]}
            </Column>
          </Row>
        </Container>
      </div>
    );
  }
}


export default Index;
      