import React, { useState, useRef } from 'react';
//import { gql, useQuery, useMutation } from '@apollo/client';
//import $ from 'jquery';
import { ReactSortable } from "react-sortablejs";
//import API_CALLS from 'API_CALLS/index';
import FormBuilder from 'global/components/FormBuilder/index';
import {UpdateSiteSettings} from 'global/utils/helper-functions';


const {Container, Row, Column} = window.LayoutBuilder;


/*

SORTBY (sortby)
ASC/DEC (descending)
SEARCH (search)


FILTER BY DATE (dates)
TOGGLE ON/OFF
WHAT DATE FIELD?
BEFORE WHAT DATE/TIME
AFTER WHAT DATE/TIME


FILTER BY SHOW DELETED FILES (status)


status
sortBy
descending
search
dates

*/

class Index extends React.Component {
  constructor(props) {
    super(props);
  }
  render(){
    return (
      <button 
        className="sideButton button editButton" 
        onClick={(e)=>{
          e.preventDefault();
          window.GlobalUtil.triggerEvent("SlideInRightEvent", {
            TITLE: "Table Settings",
            closeButtonText: "Close",
            saveButtonText: "Save",
            customClass: "frontEndSlideInRight shortSlideInRight newOrderDialog",
            width: "1200px",
            onClose: ()=>{
              window.CurRefetchList(true);
            },
            onSave: async ()=>{
              if(this.TableSettingsRef) return await this.TableSettingsRef.onSave();
              return;
              //this should run onClose after onSave is one
            },
            children: (propsChildren)=>{                                      
              return(
                <TableSettings ref={e=>this.TableSettingsRef=e}/>
              )
            }
          });
        }}><i className="fas fa-edit" />
      </button>
    );
  }
}




class TableSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultList: [],
      hideList: false,
      PAGEID: window.GlobalUtil.State.get("templateList").PAGEID
    };   
  }

  componentDidMount(){
    this.createDefaultList();
  }

  componentWillUnmount(){

  }


  createDefaultList = () => {
    var {PAGEID} = this.state;
    var {LIST_HEADER_FIELDS} = this.props;          
    var defaultList = [];
    var tableSettingsObj = (window.SiteSettings.tableSettings ? JSON.parse(window.SiteSettings.tableSettings) : {});
    var CurTableSettings = (tableSettingsObj[PAGEID] ? tableSettingsObj[PAGEID] : []); //GET ANY SAVED HEADER SETTINGS FOR THIS PAGE

    var newListObj = window.GlobalUtil.State.get("templateList").listHeaderFields.slice(); //GET ALL HEADERS AND GIVE THEM ALL UNIQUE ID'S
          
    newListObj = newListObj.map((object, index)=>{
      object.id = window.GlobalUtil.getRandomId();
      return object;
    });
                      

    CurTableSettings.map((activePath, index)=>{//ADD ALL HEADERS IN THE ORDER THEY ARE SAVED TO THE LIST AND SET THEM AS ACTIVE
      var newItem = newListObj.filter(obj=>(obj.path === activePath))[0];
      if(newItem){
        newListObj = newListObj.filter(obj=>(obj.path != activePath)); //REMOVE CURRENT
        newItem.active = true;
        defaultList.push(newItem); //ADD TO SORTED
      }
    })


    newListObj = newListObj.map((OBJ, index)=>{ //DEACTVATE ANY THAT DID NOT SHOW UP
      OBJ.active = false;
      return OBJ;
    })

    defaultList = [...defaultList, ...newListObj];
          
    this.setState({
      hideList: true,
      defaultList: defaultList
    }, ()=>{
      this.setState({hideList: false})
    })
  }


  onSave = (callBack=()=>{}) => {
    var {PAGEID} = this.state;
    var newListActive = this.state.defaultList.filter(obj=>obj.active);
    var listOfHeaders = newListActive.map(obj=>obj.path);

    var tableSettingsObj = (window.SiteSettings.tableSettings ? JSON.parse(window.SiteSettings.tableSettings) : {});
    tableSettingsObj[PAGEID] = listOfHeaders;
    var tableSettings = JSON.stringify(tableSettingsObj)

    var cleanObj = window.GlobalUtil.stripOutFromObj({
      id: window.SiteSettings._id,
      tableSettings: tableSettings
    }, "__typename");
          
    return UpdateSiteSettings({
      newObj: cleanObj, 
      onSuccess: (cleanObjNew)=>{
        var newClean = window.GlobalUtil.stripOutFromObj(cleanObjNew, "__typename");
        window.SiteSettings = newClean;
        callBack();
      },
      onFail: ()=>{},
      failPathString: "tableSettings -> helper-functions -> UpdateSiteSettings -> API_CALLS.SITE_SETTINGS.MUTATIONS.update FAIL catch"
    });
  }




  //dates
  render(){
    var {defaultList, hideList, PAGEID} = this.state;
    var {LIST_HEADER_FIELDS} = this.props;
    var tableSettingsObj = (window.SiteSettings.tableSettings ? JSON.parse(window.SiteSettings.tableSettings) : {});
    var CurTableSettings = tableSettingsObj[PAGEID];          
    if(hideList) return null;
    return (
      <div id="TemplateAdd" className="backendPageTemplateComponent">
        <DefaultList
          defaultList={defaultList}
          onSetList={(newDefaultList) => {
            this.setState({
              defaultList: newDefaultList
            });
          }}
        />
      </div>
    );
  }
}













class DefaultList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };       
  }

  componentDidMount(){
  }

  componentWillUnmount(){
  }



  render(){ 
    var {} = this.state;
    var {defaultList} = this.props;                         
    return (
      <div id="TableSettingsSortable" className="SortableListGroups">
        <h4 className="headerBasic">Table Header Fields</h4>
        <div className="subHeaderBasic">Toggle on all those to show, and change order by dragging them.</div>
        <ReactSortable 
          list={defaultList} 
          group='shared'
          className="SortableItemContainer"
          ghostClass='blue-background-class'
          animation={150}
          handle='.my-handle'
          setList={this.props.onSetList}>
          {
            defaultList && 
            defaultList.map((item) => {
              return(
                <div key={item.id} className="SortableItem withHandle">
                  <i className="fas fa-grip-lines my-handle"></i>
                  <FormBuilder
                    disableKeySubmit={true}
                    initialValues={item}
                    listOfFields={FORM_FIELDS(item)}
                    onSubmit={()=>{}}
                    onChange={(newObj)=>{
                      //console.log(`newObj`,"\n\n",newObj,"\n\n");
                      defaultList = defaultList.map((object, index)=>{
                        if(object.id === newObj.id) return newObj;
                        return object;
                      })     
                      this.props.onSetList(defaultList);
                    }}
                    onValidate={(isValid)=>{}}
                  />
                </div>
              )
            })
          }
        </ReactSortable>
      </div>
    );
  }
}




const FORM_FIELDS = (FIELDS)=>{
  return([
    {
      title: <div className="formTitle"><span className="">{FIELDS.name}</span></div>,
      type: "toggle",
      name: "active",
      borderBottom: false,
      defaultValue: false,
      defaultValuePath: "active",
    }
  ])
};


export default Index;
      

