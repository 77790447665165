import React, { useState, useRef } from 'react';
import API_CALLS from 'API_CALLS/index';
import FormBuilder from 'global/components/FormBuilder/index';
import ImageUtils from "global/utils/image-utils";
const MUTATION_EDIT = API_CALLS.TREATMENT_PLANS.MUTATIONS.update();
const {Container, Row, Column} = window.LayoutBuilder;
const IMAGE_UTILS =  new ImageUtils();

const FormMouthPicture = (props) => {
  var {CONTENT, placeHolderImage, placeHolderText, index, onChangeImage, curObj} = props;
  return (
    <div className="formMouthPicture">
      <FormBuilder
        disableKeySubmit={true}
        initialValues={curObj}
        listOfFields={FORM_FIELDS({
          placeHolderImage, 
          placeHolderText,
          index,
          onChangeImage,
          CONTENT, 
          obj: curObj, 
        })}
        onSubmit={()=>{}}
        onChange={newObj => {}}
        onValidate={(isValid)=>{ 
          //setCurStatus(isValid);
          //onCheckValid(isValid);
        }}
      />
    </div>
  );
}

const FORM_FIELDS = ({CONTENT, obj, placeHolderImage, placeHolderText, index, onChangeImage})=>{
  return(
    [ //defaultValuePath used to set default value form CONTENTect
      {
        title: <div className="formTitle"><span className="">{CONTENT.title}</span></div>,
        subTitle: <div className="formSubTitle"><span className="">{CONTENT.desc}</span></div>,
        type: "editableImage",
        name: `offerRequest,mouthPictures,${index}`,
        maxNumber: 1,
        required: false,
        defaultValue: "",
        defaultValuePath: `offerRequest,mouthPictures,${index}`,
        maxWidth: "150px",
        filePath: [
          "USERS",
          obj.id,
          "PICTURE"
        ],
        placeHolderImage: placeHolderImage,
        placeHolderText: placeHolderText,
        onChangeImage: onChangeImage
      }
    ]
  )
};
export default FormMouthPicture;
      




