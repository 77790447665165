import React from 'react';
//import { gql, useQuery, useMutation } from '@apollo/client';
import USERS from "../USERS/index";
import MUTATIONS from "./MUTATIONS";
import QUERIES from "./QUERIES";
import ImageUtils from "global/utils/image-utils";
const IMAGE_UTILS =  new ImageUtils();



const FORM_FIELDS = (obj)=>{
  //const QUERY_GET_USERS = USERS.QUERIES.all(`email`);
  const QUERY_GET_DISTINCT = QUERIES.distinct();
  return [ //defaultValuePath used to set default value form object
    {
      title: <div className="formTitle"><span className="">ID</span></div>,
      subTitle: <div className="formSubTitle"><span className="">ID CANT BE CHANGED</span></div>,
      type: "text",
      name: "id",
      required: false,
      editable: false,
      defaultValue: "",
      defaultValuePath: "_id",
      hide: true
    },
    {
      title: <div className="formTitle"><span className="">Language</span></div>,
      subTitle: <div className="formSubTitle"><span className="">What sort of communication was it?</span></div>,
      type: "selectAPIWithAdd",
      onMount: (callBack) => {
        window.Client.query({
          query: QUERY_GET_DISTINCT,
          variables: {
            field: "type" //FIELD TO GET ALL OPTIONS
          },
          fetchPolicy: "no-cache"
        })
        .then(result => {
          ////console.log("result.data","\n\n",result.data,"\n\n");
          var fullOptionList = result.data.trackingDistinct.map((DisOption, index)=>{
            return({
              "value": DisOption,
              "title": DisOption
            })
          });
          var DefaultValues = [
            {
              "value": "",
              "title": "Choose one",
              "disabled": true
            },
            {
              "value": "ENGLISH",
              "title": "ENGLISH"
            },
            {
              "value": "ESPAÑOL",
              "title": "ESPAÑOL"
            }
          ];
          const uniqueArray = window.GlobalUtil.arrayOfObjectsOnlyUnique([...DefaultValues, ...fullOptionList], "value");
          callBack("options", uniqueArray)
        });
      },
      name: "language",
      required: true,
      defaultValue: "",
      defaultValuePath: "language",
      errorMessage: "This is required"
    },
    {
      title: <div className="formTitle"><span className="">Type</span></div>,
      subTitle: <div className="formSubTitle"><span className="">What the Task Does</span></div>,
      type: "selectAPIWithAdd",
      onMount: (callBack) => {
        window.Client.query({
          query: QUERY_GET_DISTINCT,
          variables: {
            field: "type" //FIELD TO GET ALL OPTIONS
          },
          fetchPolicy: "no-cache"
        })
        .then(result => {
          //console.log("result.data","\n\n",result.data,"\n\n");
          var fullOptionList = result.data.trackingDistinct.map((DisOption, index)=>{
            return({
              "value": DisOption,
              "title": DisOption
            })
          });
          var DefaultValues = [
            {
              "value": "",
              "title": "Choose one",
              "disabled": true
            },
            {
              "value": "PAGE",
              "title": "PAGE"
            }
          ];
          const uniqueArray = window.GlobalUtil.arrayOfObjectsOnlyUnique([...DefaultValues, ...fullOptionList], "value");
          //window.GlobalUtil.consoleLog("uniqueArray", [uniqueArray]);
          callBack("options", uniqueArray)
        });
      },
      name: "type",
      required: false,
      defaultValue: "",
      defaultValuePath: "type",
      errorMessage: "This is required"
    },

    {
      title: <div className="formTitle"><span className="">Subscription Options</span></div>,
      subTitle: <div className="formSubTitle">Each Subscription Product Needs These Fields</div>,
      type: "conditional",
      condition: (curObj)=>{            
        if(curObj.type && curObj.type === "PAGE") return true;
        return false;
      },
      listOfFields: [
        {
          title: <div className="formTitle"><span className="">Page</span></div>,
          subTitle: <div className="formSubTitle"><span className="">What page are we tracking</span></div>,
          type: "selectAPIWithAdd",
          onMount: (callBack) => {
            window.Client.query({
              query: QUERY_GET_DISTINCT,
              variables: {
                field: "page" //FIELD TO GET ALL OPTIONS
              },
              fetchPolicy: "no-cache"
            })
            .then(result => {
              //console.log("result.data","\n\n",result.data,"\n\n");
              var fullOptionList = result.data.trackingDistinct.map((DisOption, index)=>{
                return({
                  "value": DisOption,
                  "title": DisOption
                })
              });
              var DefaultValues = [
                {
                  "value": "",
                  "title": "Choose one",
                  "disabled": true
                }
              ];
              const uniqueArray = window.GlobalUtil.arrayOfObjectsOnlyUnique([...DefaultValues, ...fullOptionList], "value");
              //window.GlobalUtil.consoleLog("uniqueArray", [uniqueArray]);
              callBack("options", uniqueArray)
            });
          },
          name: "page",
          required: false,
          defaultValue: "",
          defaultValuePath: "page",
          errorMessage: "This is required"
        },
        {
          title: <div className="formTitle"><span className="">Page Params</span></div>,
          subTitle: <div className="formSubTitle"><span className="">List of Params the page had in it's header. Key value pairs</span></div>,
          type: "keyValueList", //"keyValueList",
          name: "params",
          required: false,
          defaultValue: "",
          upparcase: true,
          defaultValuePath: "params",
          errorMessage: "This is required"
        }
      ]
    },
    {
      title: <div className="formTitle"><span className="">Status</span></div>,
      subTitle: <div className="formSubTitle"><span className="">The status of the task</span></div>,
      type: "select",
      name: "status",
      options: [
        {
          "value": "",
          "title": "Choose one",
          "disabled": true
        },
        {
          "value": "ACTIVE",
          "title": "ACTIVE"
        },
        {
          "value": "PENDING",
          "title": "PENDING"
        },
        {
          "value": "INACTIVE",
          "title": "INACTIVE"
        }
      ],
      required: false,
      defaultValue: "ACTIVE",
      defaultValuePath: "status"
    }
  ]
};






export default FORM_FIELDS;