import React from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';

import MUTATIONS from "./MUTATIONS";
import QUERIES from "./QUERIES";
import FORM_FIELDS from "./FORM_FIELDS";
import FORM_FIELDS_NEW from "./FORM_FIELDS_NEW";
import PREVIEW_FORM_TEMPLATES from "../PREVIEW_FORM_TEMPLATES";
import {DEFAULT_QUERY_INPUT_VAR, DEFAULT_QUERY_INPUT} from "../QUERY_DEFAULTS";
import PREVIEW from "./PREVIEW";
import MUTATION_FIELDS_DEFAULT from "../MUTATION_FIELDS_DEFAULT";
import CLEAN_FIELDS_DEFAULT from "../CLEAN_FIELDS_DEFAULT";
const DEFAULT_MUTATION_FIELDS = `
  ID
  UID
  OID
  AGID
  name
  itemsStringArray
  progressLogs{
    ${MUTATION_FIELDS_DEFAULT.NOTESLIST}
  }
  PROMO{
    CODE
    UID
    CPID
    DATE
    EARNED
    APPROVED
    ADMIN_PAID
    ADMIN_PAID_DATE
    ADMIN_CHECK_NUMBER
  }
  ship 
  shippedAt 
  trackingNumber 
  trackingCompany 
  status 
  consent 
  meta{
    ${MUTATION_FIELDS_DEFAULT.META}
  }
  active
  product{
    ID
    ref
    type
    name
    description
    subscription{
      setupFee
      billingCycleCharge
      paypalID
      billingCycle
    }
    cost
    shipping
    discount
    quantity
    language
    website
    category
  }
`;


const DEFAULT_QUERY_FIELDS = DEFAULT_MUTATION_FIELDS+``;



const DEFAULT_MUTATION_INPUT_VAR = ` 
  $UID: String,
  $OID: String,
  $AGID: String,
  $name: String,
  $itemsStringArray: String,
  $progressLogs: [NotesListItemInput], 
  $ship: Boolean,
  $shippedAt: String,
  $trackingNumber: String,
  $trackingCompany: String,
  $PROMO: PROMOInput,
  $status: String,
  $sendEmail: Boolean,
  $product: OrderItemProductInput,
  $active: Boolean
`;


const DEFAULT_MUTATION_INPUT = ` 
  UID: $UID,
  OID: $OID,
  AGID: $AGID,
  name: $name,
  trackingNumber: $trackingNumber,
  trackingCompany: $trackingCompany,
  itemsStringArray: $itemsStringArray,
  progressLogs: $progressLogs,
  ship: $ship,
  shippedAt: $shippedAt,
  PROMO: $PROMO,
  status: $status,
  sendEmail: $sendEmail,
  product: $product,
  active: $active
`;

const CLEAN_FIELDS = { 
  "id": "string",
  "UID": "string",
  "OID": "string",
  "AGID": "string",
  "name": "string",
  "itemsStringArray": "string",
  "ship": "boolean",
  "sendEmail": "boolean",
  "PROMO": {
    "CPID": "string",
    "CODE": "string",
    "UID": "string",
    "DATE": "string",
    "EARNED": "string",
    "APPROVED": "string",
    "ADMIN_PAID": "boolean",
    "ADMIN_PAID_DATE": "string",
    "ADMIN_CHECK_NUMBER": "string",
  },
  "product": {
    "ID": "string",
    "ref": "string",
    "type": "string",
    "name": "string",
    "description": "string",
    "subscription": {
      "setupFee": "number",
      "billingCycleCharge": "number",
      "paypalID": "string",
      "billingCycle": "string",
    },
    "cost": "string",
    "shipping": "string",
    "discount": "string",
    "quantity": "string",
    "language": "string",
    "website": "string",
    "category": "string",
  },
  "trackingNumber": "string",
  "trackingCompany": "string",
  "shippedAt": "string",
  "progressLogs": [
    CLEAN_FIELDS_DEFAULT.NOTESLIST
  ], 
  "status": "string",
  "meta": CLEAN_FIELDS_DEFAULT.MET
}

const LIST_HEADER_FIELDS = [
  {
    path: "ID",
    type: "ID",
    name: "ID",
    searchable: true
  },
  {
    type: "ID",
    name: "UID",
    defaultValue: "",
    path: "UID",
    searchable: true
  },
  {
    type: "ID",
    name: "OID",
    defaultValue: "",
    path: "OID",
    searchable: true
  },
  {
    type: "VALUE",
    name: "Name",
    defaultValue: "ACTIVE",
    path: "name",
    searchable: true
  },
  {
    type: "CUSTOM",
    name: "# of Items",
    path: "itemsStringArray", //THIS IS NEEDED TO ALLOW TABLE SETTINGS TO SAVE IT
    CUSTOM: val=>{            
      var ItemsArray = (val.itemsStringArray ? JSON.parse(val.itemsStringArray) : []);
      return(<strong>{ItemsArray.length}</strong>)
      //var sentEmail
      //console.log(`val`,"\n\n",val,"\n\n");
      // var itemsArray = val.items.map(item=>item.type)
      // if(!itemsArray) return(<div className="">NONE</div>)
      // return(<div className="text-center">{itemsArray.join(",")}</div>)
    },
    "sortable": false
  },
  {
    type: "VALUE",
    name: "Ship",
    defaultValue: "FALSE",
    path: "ship",
    searchable: true
  },
  {
    type: "DATE",
    name: "Shipped At",
    defaultValue: "NULL",
    path: "shippedAt"
  },
  {
    type: "VALUE",
    name: "Status",
    defaultValue: "ACTIVE",
    path: "status",
    searchable: true
  },
  {
    path: "meta.createAt",
    type: "DATE",
    name: "Create At",
    defaultValue: "1606607634571"
  },
  {
    path: "meta.updateAt",
    type: "DATE",
    name: "Last Update",
    defaultValue: "1606607634571"
  }
];




const DEFAULT_PREVIEW_FORM = () => [
  {
    "TYPE": "ROW",
    "FIELDS": [
      {
        "TYPE": "COLUMN",
        "FIELDS": [
          {
            "PATH": "name",
            "TYPE": "STRING",
            "NAME": "Name"
          },
          {
            "PATH": "_id",
            "TYPE": "STRING",
            "NAME": "ID"
          },
          {
            "PATH": "UID",
            "TYPE": "STRING",
            "PREVIEW_LINK": {
              "TYPE": "PREVIEW_BUTTON",
              "TITLE": "User", //TITLE OF SLIDE OUT
              "APINAME": "USERS",
              "ID_PATH": "UID",
              "EDITABLE": true
            },
            "NAME":"USER ID"
          },
          {
            "PATH": "OID",
            "TYPE": "STRING",
            "PREVIEW_LINK": {
              "TYPE": "PREVIEW_BUTTON",
              "TITLE": "Order", //TITLE OF SLIDE OUT
              "APINAME": "ORDERS",
              "ID_PATH": "OID",
              "EDITABLE": true
            },
            "NAME":"ORDER ID"
          },
          {
            "PATH": "AGID",
            "TYPE": "STRING",
            "CONDITION": (obj)=>(obj.AGID ? true : false),
            "PREVIEW_LINK": {
              "TYPE": "PREVIEW_BUTTON",
              "TITLE": "Aligner Group", //TITLE OF SLIDE OUT
              "APINAME": "ALIGNER_GROUPS",
              "ID_PATH": "AGID",
              "EDITABLE": true
            },
            "NAME":"ALIGNER GROUP ID"
          },
          {
            "PATH": "status",
            "TYPE": "STRING",
            "NAME": "Status"
          },
          {
            "PATH": "ship",
            "TYPE": "BOOLEAN",
            "NAME": "Ship",
            "DEFAULT": "FALSE"
          },
          {
            "PATH": "shippedAt",
            "TYPE": "DATE",
            "NAME": "ShippedAt",
            "DEFAULT": "FALSE"
          },
          {
            "PATH": "trackingNumber",
            "TYPE": "STRING",
            "NAME": "Tracking Number"
          },
          {
            "PATH": "trackingCompany",
            "TYPE": "STRING",
            "NAME": "Tracking Company"
          },
          {
            "PATH": "PROMO,CODE",
            "TYPE": "STRING",
            "NAME": "Promo Code"
          }
        ]
      },
      {
        "TYPE": "COLUMN",
        "FIELDS": [
          {
            "PATH": "PROMO,UID",
            "TYPE": "STRING",
            "NAME": "Promo User ID"
          },
          {
            "PATH": "PROMO,CPID",
            "TYPE": "STRING",
            "NAME": "Promo Coupon ID"
          },
          {
            "PATH": "PROMO,DATE",
            "TYPE": "DATE",
            "NAME": "Promo Date"
          },
          {
            "PATH": "PROMO,EARNED",
            "TYPE": "STRING",
            "NAME": "Promo Earned"
          },
          {
            "PATH": "PROMO,APPROVED",
            "TYPE": "STRING",
            "NAME": "Promo Approved"
          },
          {
            "PATH": "PROMO,ADMIN_PAID",
            "TYPE": "BOOLEAN",
            "NAME": "Has admin Paid?"
          },
          {
            "PATH": "PROMO,ADMIN_PAID_DATE",
            "TYPE": "DATE",
            "NAME": "When was this Promo Paid?"
          },
          {
            "PATH": "PROMO,ADMIN_CHECK_NUMBER",
            "TYPE": "STRING",
            "NAME": "What was the Check Number?"
          }
        ]
      }
    ]
  },
  {
    "TYPE": "ROW",
    "FIELDS": [
      {
        "TYPE": "COLUMN",
        "FIELDS": [
          {
            "PATH": "product,ID",
            "TYPE": "STRING",
            "NAME": "ID"
          },
          {
            "PATH": "product,ref",
            "TYPE": "STRING",
            "NAME": "Ref"
          },
          {
            "PATH": "product,type",
            "TYPE": "STRING",
            "NAME": "Type"
          },
          {
            "PATH": "product,name",
            "TYPE": "STRING",
            "NAME": "Name"
          },
          {
            "PATH": "product,description",
            "TYPE": "STRING",
            "NAME": "Description"
          },
          {
            "PATH": "product,cost",
            "TYPE": "STRING",
            "NAME": "Cost"
          }
        ]
      },
      {
        "TYPE": "COLUMN",
        "FIELDS": [
          {
            "PATH": "product,shipping",
            "TYPE": "STRING",
            "NAME": "Shipping"
          },
          {
            "PATH": "product,discount",
            "TYPE": "STRING",
            "NAME": "Discount"
          },
          {
            "PATH": "product,quantity",
            "TYPE": "STRING",
            "NAME": "Quantity"
          },
          {
            "PATH": "product,language",
            "TYPE": "STRING",
            "NAME": "Language"
          },
          {
            "PATH": "product,website",
            "TYPE": "STRING",
            "NAME": "Website"
          },
          {
            "PATH": "product,category",
            "TYPE": "STRING",
            "NAME": "Category"
          }
        ]
      }
    ]
  },
  {
    "TYPE": "ROW",
    "FIELDS": [
      {
        "TYPE": "COLUMN",
        "TITLE":"Order Item Items",
        "CONDITION": (obj)=>(obj.itemsStringArray ? true : false),
        "FIELDS": [
          {
            "PATH": "itemsStringArray",
            "TYPE": "ARRAY_CUSTOM",
            "PARSE": true,
            "CUSTOM": (obj)=>{
              var imageUrl = (obj.images ? obj.images[0].url : (obj.img ? obj.img : ""))
              return(
                <div className="d-flex">
                  {
                    imageUrl && 
                    <div className="image" style={{maxWidth: "200px"}}>
                      <img src={imageUrl} alt="" style={{maxWidth: "100%"}}/>
                    </div>
                  }
                  <div className="" style={{padding: "0 0 0 10px"}}>
                    <div className="">
                      <div className="d-flex">
                        <div style={{"paddingRight":"10px","fontWeight":"600"}}>Name: </div>
                        <div>{obj.name}</div>
                      </div>
                    </div>
                    <div className="">
                      <div className="d-flex">
                        <div style={{"paddingRight":"10px","fontWeight":"600"}}>Id: </div>
                        <div>{obj.id}</div>
                      </div>
                    </div>
                    <div className="">
                      <div className="d-flex">
                        <div style={{"paddingRight":"10px","fontWeight":"600"}}>Quantity: </div>
                        <div>{obj.quantity}</div>
                      </div>
                    </div>
                    <div className="">
                      <div className="d-flex">
                        <div style={{"paddingRight":"10px","fontWeight":"600"}}>Ref: </div>
                        <div>{obj.ref}</div>
                      </div>
                    </div>
                    <div className="">
                      <div className="d-flex">
                        <div style={{"paddingRight":"10px","fontWeight":"600"}}>Category: </div>
                        <div>{obj.category}</div>
                      </div>
                    </div>
                    <div className="">
                      <div className="d-flex">
                        <div style={{"paddingRight":"10px","fontWeight":"600"}}>Type: </div>
                        <div>{obj.type}</div>
                      </div>
                    </div>
                    <div className="">
                      <div className="d-flex">
                        <div style={{"paddingRight":"10px","fontWeight":"600"}}>Cost With Discount: </div>
                        <div>{obj.costWithDiscount}</div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }
          }
        ]
      }
    ]
  },
  PREVIEW_FORM_TEMPLATES.META()
];

const FINAL_MOD_NEW = (curCleanObj)=>{
  delete curCleanObj.ID;  if(!curCleanObj.progressLogs || (curCleanObj.progressLogs.length < 1)) delete curCleanObj.progressLogs;   
  if(curCleanObj.itemsStringArray && (typeof curCleanObj.itemsStringArray !== "string")) curCleanObj.itemsStringArray = JSON.stringify(curCleanObj.itemsStringArray);
  //console.log(`FINAL_MOD_NEW`,"\n\n",curCleanObj,"\n\n");
  //if(!curCleanObj.files) delete curCleanObj.files;
  return curCleanObj;
}

const FINAL_MOD_EDIT = (curCleanObj)=>{
  delete curCleanObj.ID;  if(!curCleanObj.progressLogs || (curCleanObj.progressLogs.length < 1)) delete curCleanObj.progressLogs;   
  if(curCleanObj.itemsStringArray && (typeof curCleanObj.itemsStringArray !== "string")) curCleanObj.itemsStringArray = JSON.stringify(curCleanObj.itemsStringArray);
  if(!curCleanObj.shippedAt) curCleanObj.shippedAt = '';
  
  //console.log(`FINAL_MOD_EDIT`,"\n\n",curCleanObj,"\n\n");
        
  //if(!curCleanObj.files) delete curCleanObj.files;
  return curCleanObj;
}

const ORDER_ITEMS = {
  MUTATIONS: MUTATIONS,
  QUERIES: QUERIES,
  FORM_FIELDS: FORM_FIELDS,
  FORM_FIELDS_NEW: FORM_FIELDS_NEW,
  LIST_HEADER_FIELDS: LIST_HEADER_FIELDS,
  PREVIEW: PREVIEW,
  CLEAN_FIELDS: CLEAN_FIELDS,
  FINAL_MOD_NEW: FINAL_MOD_NEW,
  FINAL_MOD_EDIT: FINAL_MOD_EDIT
}

const DEFAULT_FIELDS_ORDER_ITEMS = {
  DEFAULT_MUTATION_FIELDS,
  DEFAULT_QUERY_FIELDS,
  DEFAULT_QUERY_INPUT_VAR,
  DEFAULT_QUERY_INPUT,
  DEFAULT_MUTATION_INPUT_VAR,
  DEFAULT_MUTATION_INPUT
}

export default ORDER_ITEMS;
export {
  DEFAULT_MUTATION_FIELDS,
  DEFAULT_QUERY_FIELDS,
  DEFAULT_QUERY_INPUT_VAR,
  DEFAULT_QUERY_INPUT,
  DEFAULT_MUTATION_INPUT_VAR,
  DEFAULT_MUTATION_INPUT,
  DEFAULT_FIELDS_ORDER_ITEMS,
  DEFAULT_PREVIEW_FORM
};