import React from 'react';
import "styles/scss/lab/components/header.scss";
import {NavLink, Link} from 'react-router-dom';
import LogoIcon from 'global/img/logo_icon.svg';
import LogoDark from 'global/img/logo-dark.svg';
import CartButton from "website/components/CartButton/index";
const {Container, Row, Column} = window.LayoutBuilder;


export default class Component extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  resetNav(){
  }

  render(){
    var currentUser = (window.Session.user ? window.GlobalUtil.stripOutFromObj(window.Session.user, "__typename") : {});
    var {userPicture, firstName, lastName} = currentUser;
    var curUserImage = window.GlobalUtil.deepGetFromString(userPicture, "0,url", LogoIcon);
    return (
      <div id="Header">
        <Container className="" fluid="true">
          <Row className="">
            <Column className="mobileHeader" col="" sm="" sm="" md="" lg="" xl="">
              <NavLink className="siteLogo" to={"/user"}>
                <img src={LogoDark} alt=""/>
              </NavLink>
              {/*<CartButton />*/}
              <div className="mobileButtonBox">
                <i className="fas fa-bars" onClick={this.props.onShowMobileMenu} />
              </div>
            </Column>
            <Column className="mainHeader" col="" sm="" sm="" md="" lg="" xl="">
              <div className="info d-flex">
                {/*<CartButton />*/}
                <NavLink className={"welcomeText"} onClick={()=>{}} to={"/user/profile"}>
                  {firstName} {lastName}
                </NavLink>
                <div className="doctorIcon">
                  <NavLink onClick={()=>{}} to={"/user/profile"}>
                    <img src={curUserImage} alt=""/>
                  </NavLink>
                </div> 
              </div>
            </Column>
          </Row>
        </Container>
      </div>
    );
  }
}




