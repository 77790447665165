import React, { useState, useRef } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import TemplateAction from './templateAction';
import DialogProgressLogs from 'admin/components/DialogProgressLogs/index';
import API_CALLS from 'API_CALLS/index';
import ImageUtils from "global/utils/image-utils";
import {DEFAULT_MUTATION_FIELDS as DEFAULT_MUTATION_FIELDS_ALIGNER_GROUPS} from "API_CALLS/ALIGNER_GROUPS/index";
import {DEFAULT_MUTATION_FIELDS as DEFAULT_MUTATION_FIELDS_ALIGNERS} from "API_CALLS/ALIGNERS/index";
import MUTATION_FIELDS_DEFAULT from "API_CALLS/MUTATION_FIELDS_DEFAULT";
import DownloadAllButton from 'global/components/DownloadAllButton';


const IMAGE_UTILS =  new ImageUtils();
const {Container, Row, Column} = window.LayoutBuilder;






class Index extends TemplateAction {
  constructor(props) {
    super(props);
    this.state = { 
      ...this.state
    };       
    this.validate = this.validate.bind(this);
    this.APIGet = this.APIGet.bind(this);
    this.APIUpdate = this.APIUpdate.bind(this);
  }


  validate(){
    this.APIUpdate()
  }


  APIGet(){     
    //console.log(`this.props.curObj`,"\n\n",this.props.curObj,"\n\n");    
    var inputObj = {
      query: API_CALLS.ALIGNER_GROUPS.QUERIES.all(`
        ${DEFAULT_MUTATION_FIELDS_ALIGNER_GROUPS} 
        aligners{
          _id
          ${DEFAULT_MUTATION_FIELDS_ALIGNERS}
        }
        treatmentPlan{
          _id
          UID
          user{
            _id
            firstName
            lastName
            email
          }
        }
      `),
      variables: {
        "status": "ACTIVE",
        "manual": [
          {
            path: "_id",
            val: this.props.curObj._id
          }
        ],
        "include": [
          {
            name: "aligners"
          },
          {
            name: "treatmentPlan",
            include: [
              {
                name: "user"
              },
            ]
          }
        ]
      },
      fetchPolicy: "no-cache"
    }
    window.Client.query(inputObj)
    .then((obj) => {
      var {data, loading} = obj;                 
      this.curObj = {
        ...window.GlobalUtil.stripOutFromObj(data.alignerGroups[0], "__typename"), 
        id: data.alignerGroups[0]._id
      };
      window.CurObj = {...this.curObj};
      console.log("window.CurObj","\n\n",window.CurObj,"\n\n");
      if(!this) return;
      this.setState({AlignerGroup: this.curObj})
    })
    .catch((error)=>{
      console.log("query alignerGroup failed", error);
    });
  }


  

  APIUpdate(variables){
    var inputObj = {
      mutation: API_CALLS.ALIGNER_GROUPS.MUTATIONS.alignerGroupCreateOrders(),
      variables: {
        id: this.curObj.id
      }
    }          
    return window.Client.mutate(inputObj)
    .then((obj) => {
      var {data, loading} = obj;
      window.CurRefetchList(true);
      this.onClose(this.CurRef)
    })
    .catch((error)=>{
      console.log("ActionMakeAligners onAPIUpdate catch", error);
      this.onClose(this.CurRef);
    });
  }



  render(){
    var {showSlideIn, isValid, AlignerGroup={}, hideForm, isLoading} = this.state;          
    var {curObj={}} = this.props;          

    var alignersSTLs = this.curObj.aligners && this.curObj.aligners.map((aligner, index)=>{
      var curUser = window.GlobalUtil.deepGetFromString(this.curObj, "treatmentPlan,user", "");
      var userName = (curUser ? `${curUser.firstName}_${curUser.lastName}_` : "");
      var curAlignerFile = window.GlobalUtil.deepGetFromString(aligner, "files,0", "");
      return {
        url: curAlignerFile.url,
        title: `${userName}${curAlignerFile.name}`,
        name: `${userName}${curAlignerFile.name}`
      }
    });          
    return (
      this.SlideInRight(
        "Create Aligners", 
        (curRef)=>{
          this.CurRef = curRef;
          return(
            <React.Fragment>
              <button className="button button2 btn-color6" onClick={()=>{
                if(curRef) curRef.onClose()
              }}>Close</button>
              <div className="buttonGroup">
                <button className={`button button1`} onClick={()=>{ 
                  var returned = this.validate();
                }}>Created All <i className="fas fa-hammer"></i></button>
                <DialogProgressLogs
                  ID={this.props.curObj._id}
                  API_NAME={'ALIGNER_GROUPS'}
                  FIELD={'alignerGroup'}
                  asButton={true}
                  className={'button button1 btn-color4'}
                  style={{"width":"100px","minWidth":"0"}}
                  customLinkText={<span><span>Logs</span>&nbsp;&nbsp;<i className="fas fa-clipboard-list"/></span>}
                />
              </div>
            </React.Fragment>
          )
        }, 
        ()=>{
          return(
            <div className="">
              <div className="PreviewForm">
                {
                  (AlignerGroup && AlignerGroup.treatmentPlan && AlignerGroup.treatmentPlan.user) &&
                  this.previewUser(AlignerGroup.treatmentPlan.user)
                }
                {
                  (AlignerGroup && AlignerGroup.treatmentPlan) &&
                  this.previewPlan(AlignerGroup.treatmentPlan)
                }
                <Row>
                  <Column 
                    className={"borderColor5"}
                    style={{"paddingBottom":"0px"}}>
                  <div className="previewFormFieldTitles2">
                    <div className="previewFormFieldTitle">Aligners to Create</div>
                    <div className="previewFormFieldSubTitle">Create and label the following aligners together</div>
                  </div>
                  </Column>
                </Row>
                <Row className="downloadLinksList">
                  {
                    (AlignerGroup && AlignerGroup.aligners && (AlignerGroup.aligners.length > 0)) &&
                    AlignerGroup.aligners.map((aligner, index)=>{
                      return(
                          <DownloadLinks
                            key={index}
                            aligner={aligner}
                          />
                          
                      )
                    })
                  }
                  <Column 
                    sm={12}
                    className={""}
                    style={{"paddingBottom":"0px"}}>
                      <DownloadAllButton 
                        files={alignersSTLs}
                      />
                  </Column>
                </Row>
                {
                  AlignerGroup._id &&
                  <Steps
                    ID={AlignerGroup._id}
                    progressLogs={AlignerGroup.progressLogs}
                  />
                }
              </div>
            </div>
          )
        }
      )
    );
  }
}



class DownloadLinks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clicked: false
    };       
  }

  render(){
    var {clicked} = this.state;
    var {aligner} = this.props;
    //console.log(`aligner`,"\n\n",aligner,"\n\n");
          
    return(
      <Column 
        sm={12}
        className={""}
        style={{"paddingBottom":"0px"}}>
        <div className="downloadLink">
          <div className="downloadLinkTextWrapper">
            <div className="downloadLinkText"><strong>Aligner ID: </strong> {aligner._id}</div>
            <div className="downloadLinkText"><strong>File Name: </strong> {window.GlobalUtil.deepGetFromString(aligner, "files,0,name", "")}</div>
          </div>
          <div className="downloadLinkButtons">
            <a className={`button button1 ${clicked ? "downloaded" : "" }`} onClick={()=>{
              this.setState({clicked: true})
            }} href={window.GlobalUtil.deepGetFromString(aligner, "files,0,url", "")} download target="_blank">Download</a>
          </div>
        </div>
      </Column>
    )
  }
}


var STEPS_LOGS = {
  "step1": {
    title: "STEP 3D PRINTING - MAKE ALIGNERS", //NOTE TITLE TO SUMMARIZE THE NOTE
    note: `Aligners have been 3d printed.`,
  },
  "step2": {
    title: "STEP WRAP PLASIC - MAKE ALIGNERS", //NOTE TITLE TO SUMMARIZE THE NOTE
    note: `Aligners have been wrapped arround the 3d model.`,
  },
  "step3": {
    title: "STEP CUT OUT ALIGNERS - MAKE ALIGNERS", //NOTE TITLE TO SUMMARIZE THE NOTE
    note: `Aligners have been cut out of block.`,
  },
  "step4": {
    title: "STEP POLISH AND CLEAN UP - MAKE ALIGNERS", //NOTE TITLE TO SUMMARIZE THE NOTE
    note: `Aligners have been polished and cleaned.`,
  },
  "step5": {
    title: "STEP LABEL AND FINISH - MAKE ALIGNERS", //NOTE TITLE TO SUMMARIZE THE NOTE
    note: `Aligners have been labeled and moved to be shipped to the user.`,
  }
}

class Steps extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      progressLogs: this.props.progressLogs
    };       
  }

  componentDidMount(){
    //this.APIGetLogs();
  }



  APIUpdateLogs = (stepName) => {  
    var logObj = {
      ...STEPS_LOGS[stepName],
      category: "SYSTEM_LOG",
      createAt: `${Date.now()}`,
      updateAt: `${Date.now()}`,
      status: "COMPLETE"
    }
    var {progressLogs=[]} = this.state;
    progressLogs = [logObj, ...progressLogs];

    return window.Client.mutate({
      mutation: API_CALLS.ALIGNER_GROUPS.MUTATIONS.update(),
      variables: {
        id: this.props.ID,
        progressLogs: progressLogs
      }
    }).then(()=>{
      this.setState({progressLogs});
    }).catch(error=>{
      console.log(`error`,"\n\n",error,"\n\n");
            
    });
  }

  getSelected = (progressLogs) => {
    var selected = {
      ...STEPS_LOGS
    }
    if(progressLogs && progressLogs.length > 0){
      progressLogs.map((log, index)=>{
        if(log.category === "SYSTEM_LOG"){
          Object.keys(selected).map((stepName, index)=>{
            var stepObj = selected[stepName];
            if(stepObj.title === log.title) selected[stepName].active = true;
          });
          
        }
      })
    }
    return selected;
  }

  render(){
    var {progressLogs} = this.state;
    //var {progressLogs} = this.props;
    var selected = this.getSelected(progressLogs);
    console.log(`progressLogs`,"\n\n",progressLogs,"\n\n");
    console.log(`selected`,"\n\n",selected,"\n\n");
          
    return(
      <Row className="alignerMakeSteps">
        <Column 
          sm={12}
          className={""}>
          <div className="title">
            Aligner Making Steps
          </div>
          <div className="alignerMakeStep" 
            onClick={()=>{
              if(!selected.step1.active) this.APIUpdateLogs("step1");
            }}>
            <div className="stepName">Step 1 - Print 3d</div>
            <div className="checkButton">
              {
                selected.step1.active
                ? <i className="fas fa-check-square"></i>
                : <i className="far fa-square"></i>
              }
            </div>
          </div>
          <div className="alignerMakeStep" 
            onClick={()=>{
              if(!selected.step2.active) this.APIUpdateLogs("step2");
            }}>
            <div className="stepName">Step 2 - Wrap Plasic Over 3d Model</div>
            <div className="checkButton">
              {
                selected.step2.active
                ? <i className="fas fa-check-square"></i>
                : <i className="far fa-square"></i>
              }
            </div>
          </div>
          <div className="alignerMakeStep" 
            onClick={()=>{
              if(!selected.step3.active) this.APIUpdateLogs("step3");
            }}>
            <div className="stepName">Step 3 - Cut out Aligners</div>
            <div className="checkButton">
              {
                selected.step3.active
                ? <i className="fas fa-check-square"></i>
                : <i className="far fa-square"></i>
              }
            </div>
          </div>
          <div className="alignerMakeStep" 
            onClick={()=>{
              if(!selected.step4.active) this.APIUpdateLogs("step4");
            }}>
            <div className="stepName">Step 4 - Polish Aligners</div>
            <div className="checkButton">
              {
                selected.step4.active
                ? <i className="fas fa-check-square"></i>
                : <i className="far fa-square"></i>
              }
            </div>
          </div>
          <div className="alignerMakeStep" 
            onClick={()=>{
              if(!selected.step5.active) this.APIUpdateLogs("step5");
            }}>
            <div className="stepName">Step 5 - Label and Move to Shipping</div>
            <div className="checkButton">
              {
                selected.step5.active
                ? <i className="fas fa-check-square"></i>
                : <i className="far fa-square"></i>
              }
            </div>
          </div>
        </Column>
      </Row>
    )
  }
}

export default Index;