import React from 'react';
import {NavLink, Link} from 'react-router-dom';
import PAGES from 'user/doctor/pages/pages.json'; //ADD NEW PAGES HERE

const Component = (props)=>{
  var {onClickLink=()=>{}, MenuToShow, inline} = props;
  if(!MenuToShow) return null;
  const CleanLinks = PAGES.filter(Page=>Page.Menu.indexOf(MenuToShow) !== -1);        
  return(
    <div className={`menu ${inline ? "nav" : ""}`}>
      <div className="menuTitle">Menu</div>
      {
        CleanLinks.map((Link, index)=>{
          return(
            <div className="menu-item" key={index}>
              <NavLink className={"nav-link"} exact activeClassName="active" onClick={onClickLink} to={Link.Paths[0]}>
                <div className="inline-nav-link">
                  <div className="icon"><i className={Link.Icon}></i></div>
                  <div className="text">{Link.PageName}</div>
                </div>
              </NavLink>
            </div>
          )
        })
      }
    </div>
  )
}

export default Component;