import React from 'react';
import API_CALLS from 'API_CALLS/index';
import TemplatePage from "global/templates/templatePage/index";
import ActionPreview from "./ActionPreview";
import EmailSender from 'admin/components/EmailSender/index';



const QUERY = API_CALLS.EMAILS.QUERIES.allAndCount();
const QUERY_SINGLE = API_CALLS.EMAILS.QUERIES.single();
const QUERY_COUNT = API_CALLS.EMAILS.QUERIES.count();
const MUTATION_ADD = API_CALLS.EMAILS.MUTATIONS.sendEmail();
const QUERY_GET_USERS = API_CALLS.USERS.QUERIES.all(`email`);
const FORM_FIELDS = API_CALLS.EMAILS.FORM_FIELDS;
const LIST_HEADER_FIELDS = API_CALLS.EMAILS.LIST_HEADER_FIELDS;
const PREVIEW = API_CALLS.EMAILS.PREVIEW;

const API_CALLS_FULL = {
  QUERY,
  QUERY_SINGLE,
  QUERY_COUNT,
  MUTATION_ADD,
  FORM_FIELDS,
  QUERY_GET_USERS,
  PREVIEW
};






const ValidateObject = (newObject, onSuccess, onFail)=>{
  if(newObject._id && !newObject.id) newObject.id = newObject._id;      
  //console.log("newObject");
  //console.log(newObject,"\n\n");
        
  Object.keys(newObject).map((key, index)=>{
    if(!newObject[key]) delete newObject[key];
  })  
  onSuccess(newObject);
  //onFail();      
};








const PAGES = () => [
  {
    "id": "EMAILS01",
    "Name":"Active",
    "PageName": "Active",
    "Paths": ["/admin/configure/emails"],
    "TITLE": "Emails Sent History",
    "DATA_FIELD": "emails",
    "GlobalStateVar": ["emailCountActive"],
    "ActionSlideIn": ActionPreview,
    "API_CALLS": API_CALLS_FULL,
    "LIST_HEADER_FIELDS": LIST_HEADER_FIELDS,
    "VARIABLES": {
      //"status": "ACTIVE",
      "sortBy": "meta.createAt",
      "descending": false, 
      "limit": 20,
      "offset": 0
    },
    "NEWBUTTON": {
      NAME: "EMAILS",
      TITLE: "Email",
      FIELD: "email",
      CUSTOM: <EmailSender
        asButton={true}
        className={'sideButton button button1'}
        customLinkText={<span><span>New Email </span><i className="fas fa-paper-plane"/></span>}
        onClose={()=>{
          if(window.CurRefetchList) window.CurRefetchList(true); //RELOAD LIST
          if(window.CurRefetchSideNavNumbers) window.CurRefetchSideNavNumbers(); //RELOAD SIDE MENU NUMBERS
        }}
        curObj={{
          whatEmail: "WELCOME",
          emailData: {
            whoToEmail: "",
            whoToCC: "",
            language: "ENGLISH",
            UID: "",
            OID: "",
            TPID: "",
          }
        }}
      />
    },
    "DELETEBUTTON": {
      NAME: "TRACKING"
    },
    "BUTTONS": [
      // {
      //   width: "110px",
      //   category: "Preview",
      //   buttonText: "View",
      //   className: "btn-color3",
      //   iconClass: "fas fa-eye",
      //   onClick: (obj)=>{
      //     window.GlobalUtil.triggerEvent("TemplateDBEvent", {
      //       "TYPE": "PREVIEW",
      //       "BUTTON_TITLE": "Tracking",
      //       "TITLE": "Tracking", //TITLE OF SLIDE OUT
      //       "APINAME": "TRACKING",
      //       "NAME": "Tracking",
      //       "ID": obj._id
      //     }); 
      //   }
      // }
    ],
    SHOWSELECTBOXES:true
  }
];








const Index = (props) => {
  //var {data} = props;
  var today = new Date();
  return (
     <TemplatePage
      ID={"AdminTracking"}
      // REPORTS={
      //   [
      //     {
      //       QUERY: QUERY_COUNT,
      //       VARIABLES: {
      //         "status": "NOTINACTIVE",
      //         "dates": [{
      //           field: "meta.createAt",
      //           gte: `${today.getStartOfDay().getTime()}`
      //         }]
      //       },
      //       NAME: "emailCount",
      //       TITLE: "New Tracking Today"
      //     },
      //     {
      //       QUERY: QUERY_COUNT,
      //       VARIABLES: {
      //         "status": "NOTINACTIVE",
      //         "dates": [{
      //           field: "meta.createAt",
      //           gte: `${today.getStartOfWeek().getTime()}`
      //         }]
      //       },
      //       NAME: "emailCount",
      //       TITLE: "Tracking this Week"
      //     },
      //     {
      //       QUERY: QUERY_COUNT,
      //       VARIABLES: {
      //         "status": "NOTINACTIVE",
      //         "dates": [{
      //           field: "meta.createAt",
      //           gte: `${today.getStartOfMonth().getTime()}`
      //         }]
      //       },
      //       NAME: "emailCount",
      //       TITLE: "Tracking this Month"
      //     },
      //     {
      //       QUERY: QUERY_COUNT,
      //       VARIABLES: {
      //         "status": "NOTINACTIVE",
      //         "dates": [{
      //           field: "meta.createAt",
      //           gte: `${today.getStartOfYear().getTime()}`
      //         }]
      //       },
      //       NAME: "emailCount",
      //       TITLE: "Tracking this Year"
      //     }
      //   ]
      // }
      REDIRECT={{
        "path": "/admin/configure/email",
        "to": "/admin/configure/emails"
      }}
      PAGES={PAGES()}
      ValidateObject={ValidateObject}
    />
  );
}





export default Index;

export {PAGES};   

      
