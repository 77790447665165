import React, { useState, useRef } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import TemplateAction from './templateAction';
import API_CALLS from 'API_CALLS/index';
import ImageUtils from "global/utils/image-utils";
import TemplateSlideInRight from "global/templates/templateSlideInRight/index";
import FormBuilder from 'global/components/FormBuilder/index';
import EmailSender from 'admin/components/EmailSender/index';
import DialogProgressLogs from 'admin/components/DialogProgressLogs/index';
const IMAGE_UTILS =  new ImageUtils();
const {Container, Row, Column} = window.LayoutBuilder;
/*

//GET AND SHOW CURRENT USER
//CREATE NEW OBJECT FOR NEW TREATMENT PLAN
//SAVE NEW PLAN
//SEND DOCTOR AN EMAIL WITH A LINK
//CC DAD ON THE EMAIL WITH THE LINK
//CLOSE CURRENT TASK
//CREATE NEW TASK WITH (UPLOAD_TREATMENT_PLAN)
  //USER ID (UID)
  //ALIGNMENT PLAN ID (TPID)
  //


*/









class Index extends TemplateAction {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state
    }; 
    this.validate = this.validate.bind(this);
    this.APIGet = this.APIGet.bind(this);
    this.APIUpdate = this.APIUpdate.bind(this);
    this.APIRejectPlan = this.APIRejectPlan.bind(this);
    this.APISendTaskBack = this.APISendTaskBack.bind(this);
  }


  validate(){
    this.APIUpdate();
  }



  APIGet(){               
    var inputObj = {
      query: API_CALLS.TREATMENT_PLANS.QUERIES.singleFull(),
      variables: {
        id: this.props.curObj._id,
        include: [
          {
            name: "user"
          }
        ]
      },
      fetchPolicy: "no-cache"
    }
    window.Client.query(inputObj)
    .then((obj) => {
      var {data, loading} = obj;            
      var treatmentPlan = window.GlobalUtil.stripOutFromObj(data.treatmentPlan, "__typename");
      this.curObj = {
        id: treatmentPlan._id
      };
      window.CurObj = {...treatmentPlan, id: treatmentPlan._id};
      if(!this) return;            
      this.setState({
        Plan: treatmentPlan,
        User: window.GlobalUtil.deepGetFromString(window.CurObj, "user", false),
        hideForm: true
      }, ()=>{
        this.setState({hideForm: false})
      })
    })
    .catch((error)=>{
      console.log("query plan failed", error);
    });
  }


  APIUpdate(){
    var inputObj = {
      mutation: this.props.API_CALLS.MUTATION_APPROVEPLAN,
      variables: {
        id: this.curObj.id,
        status: "ACTIVE",
        rejectStepNotes: "",//THIS SHOULD BE REMOVED OR ADDED EVERY STEP
        changeStep: (this.state.User.purchasedPlan ? 2 : 1),//IF THEY'VE PURCHASED THE PLAN THEN SKIP 2 STEPS ELSE MOVE TO THE STEP FOR THEM TO PURCHASE THE PLAN
        HISTORY: {...this.state.Plan.HISTORY}
      }
    }

    window.GlobalUtil.deepSetFromString(inputObj, "variables,HISTORY,DOCTOR,APPROVED_PLAN", `${Date.now()}`); //SET THE DATE THE DR APPROVED THE PLAN

    window.Client.mutate(inputObj)
    .then((obj) => {
      var {data, loading} = obj;
      window.CurRefetchList(true);
      if(this.state.User.purchasedPlan){
        window.FlashMenuItem("/admin/plans/requestFull");
      } else window.FlashMenuItem("/admin/plans/userpurchase");
      this.onClose(this.CurRef)
    })
    .catch((error)=>{
      console.log("onCloneCatch catch", error);
      this.onClose(this.CurRef);
    });
  }


  APIRejectPlan(rejectionAndNotes, callback=()=>{}){
    var {rejectionNotes, rejectionObj} = rejectionAndNotes;
    rejectionObj.to = this.state.Plan.UID;
    const RejectPlan = (NotesID)=>{
      window.Client.mutate({
        mutation: this.props.API_CALLS.MUTATION_REJECT,
        variables: {
          id: this.curObj.id,
          rejectionNotes: `${rejectionNotes}\n See Com: ${NotesID}`
        }
      })
      .then((obj) => {
        var {data, loading} = obj;
        window.CurRefetchList(true);
        callback();
        window.FlashMenuItem("/admin/plans/upload");
        this.onClose(this.CurRef);
      })
      .catch((error)=>{
        console.log("onCloneCatch catch", error);
        this.onClose(this.CurRef);
      });
    }

    //SHOW DIALOG TO GET REASON WHY
    //CONTACT THE USER
    //UPDATE THE USER WITH NEW DATA
    window.Client.mutate({
      mutation: API_CALLS.COMS.MUTATIONS.create(),
      variables: rejectionObj
    })
    .then((obj) => {
      var {data, loading} = obj;
      console.log("data","\n\n",data,"\n\n");
      RejectPlan(data.createCom._id);
    })
    .catch((error)=>{
      console.log("onCloneCatch catch", error);
      this.onClose(this.CurRef);
    });
  }


  APISendTaskBack(rejectStepNotes, callback=()=>{}){
    var newObj = {
      id: this.props.curObj._id,
      rejectStepNotes: rejectStepNotes,//THIS SHOULD BE REMOVED OR ADDED EVERY STEP
      changeStep:  -1//MOVE TO NEXT STEP WHEN DONE
    }

    var inputObj = {
      mutation: this.props.API_CALLS.MUTATION_UPDATE,
      variables: newObj
    }
    window.Client.mutate(inputObj)
    .then((obj) => {
      var {data, loading} = obj;
        callback();
        window.FlashMenuItem("/admin/plans/upload");
        this.onClose(this.CurRef);
    })
    .catch((error)=>{
      console.log("Reject APISendTaskBack catch", error);
      this.onClose(this.CurRef);
    });
  }




  render(){
    var PREVIEWPLAN = this.PREVIEWPLAN;
    var {isValid, Plan={}, User={}, hideForm, ShowPreview, showRejectTask, showRejectUser} = this.state;          
    return (
      this.SlideInRight(
        "Approve or Reject Treatment Plan", 
        (curRef)=>{
          this.CurRef = curRef;
          return(
            <React.Fragment>
              <div className="buttonGroup">
                <button className="button button2 btn-color6" onClick={()=>{
                  if(curRef) curRef.onClose()
                }}>Close</button>
                <button className={`button btn-color8`} onClick={()=>this.setState({showRejectUser: true})}>
                  Reject Plan <i className="fas fa-skull" />
                </button>
                <button className={`button btn-color5`} onClick={()=>this.setState({showRejectTask: true})}>
                  Send Back <i className="fas fa-undo" />
                </button>
              </div>
              <div className="buttonGroup">
                <button className={`button button1`} onClick={()=>{ 
                  this.validate();
                }}>Approve <i className="fas fa-clipboard-check" />
                </button>
                <DialogProgressLogs
                  ID={this.props.curObj._id}
                  API_NAME={'TREATMENT_PLANS'}
                  FIELD={'treatmentPlan'}
                  asButton={true}
                  className={'button button1 btn-color4'}
                  style={{"width":"100px","minWidth":"0"}}
                  customLinkText={<span><span>Logs</span>&nbsp;&nbsp;<i className="fas fa-clipboard-list"/></span>}
                />
              </div>
            </React.Fragment>
          )
        }, 
        ()=>{
          return(
            <div className="">
              <Row>
                <Column 
                  className={""}>
                    {
                      Plan._id &&
                      <PREVIEWPLAN
                        VARIABLES={{
                          id: Plan._id
                        }}
                      />
                    }
                </Column>
              </Row>
              <div className="PreviewForm">
                {
                  User &&
                  this.previewUser(User)
                }
              </div>
              <SlideOutRejectTask
                showRejectTask={showRejectTask}
                onCancel={()=>this.setState({showRejectTask: false})}
                onReject={this.APISendTaskBack}
              />
              <SlideOutRejectPlan
                showRejectUser={showRejectUser}
                onCancel={()=>this.setState({showRejectUser: false})}
                onReject={this.APIRejectPlan}
                previewUser={()=>{
                  if(User) this.previewUser(User)
                }}
              />
            </div>
          )
        }
      )
    );
  }
}





class SlideOutRejectTask extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }; 
  }

  render(){
    var {rejectStepNotes="", isValid} = this.state;
    return(
      <div>
        {
          this.props.showRejectTask &&
          <TemplateSlideInRight
            ref={e=>this.slideInRightRef=e}
            title={"Reject Task"}
            onClose={this.props.onCancel}
            onSave={()=>{}}
            customButtons={(curRef)=>{
              return(
                <React.Fragment>
                  <button className="button button2" onClick={()=>{
                    if(this.slideInRightRef) this.slideInRightRef.slideInRightRef.onClose();
                  }}>Cancel</button>
                  <button className={`button btn-color5 ${isValid ? "" : "disabled"}`} onClick={()=>{ 
                    this.props.onReject(rejectStepNotes, ()=>{
                      if(this.slideInRightRef) this.slideInRightRef.slideInRightRef.onClose();
                    });
                  }}>Send Back <i className="fas fa-undo"></i></button>
                </React.Fragment>
              )
            }}>
            <div>
              <Container>
                <Row>
                  <Column 
                    className={""}>
                      <div >
                        <Container className="" style={{"padding": "30px 10px"}}>
                          <FormBuilder
                            disableKeySubmit={true}
                            initialValues={{}}
                            listOfFields={[
                              {
                                title: <div className="formTitle"><span className="">Reason for Rejection</span></div>,
                                subTitle: <div className="formSubTitle"><span className="">Tell the doctor why are we rejecting this task.</span></div>,
                                type: "textArea",
                                name: "rejectStepNotes",
                                rows: 6,
                                required: true,
                                defaultValue: "",
                                defaultValuePath: "rejectStepNotes",
                                errorMessage: "Rejection reason is required"
                              }
                            ]}
                            onSubmit={()=>{
                            }}
                            onChange={newObj => {
                              this.setState({rejectStepNotes: newObj.rejectStepNotes}) 
                            }}
                            onValidate={(isValid)=>{
                              this.setState({isValid})
                            }}
                          />
                        </Container>
                      </div>
                  </Column>
                </Row>
              </Container>
            </div>
          </TemplateSlideInRight>
        }
      </div>
    )
  }
}



class SlideOutRejectPlan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }; 
    this.newObj = {};
  }

  render(){
    var {isValid} = this.state;
    return(
      <div>
        {
          this.props.showRejectUser &&
          <TemplateSlideInRight
            ref={e=>this.slideInRightRef=e}
            title={"Reject User"}
            onClose={this.props.onCancel}
            onSave={()=>{}}
            customButtons={(curRef)=>{
              return(
                <React.Fragment>
                  <button className="button button2" onClick={()=>{
                    if(this.slideInRightRef) this.slideInRightRef.slideInRightRef.onClose();
                  }}>Cancel</button>
                  <button className={`button btn-color4 ${isValid ? "" : "disabled"}`} onClick={()=>{ 
                    this.props.onReject(this.newObj, ()=>{
                      if(this.slideInRightRef) this.slideInRightRef.slideInRightRef.onClose();
                    });
                  }}>Reject Plan <i className="fas fa-skull"></i></button>
                </React.Fragment>
              )
            }}>
            <div>
              {
                this.props.previewUser()
              }
              <Container>
                <Row>
                  <Column 
                    className={""}>
                      <div style={{"padding": "30px 10px"}}>
                        <div>
                          <h2>Attention:</h2>
                          <div style={{"fontSize":"16px","fontWeight":"500","marginTop":"-15px"}}>Please contact by phone and/or Email and explain why we are rejecting them. <strong>THEN</strong> please fill out the form below to save the reason to the DB.</div>
                        </div>
                        <div>
                          <Container className="" style={{"padding": "30px 0px"}}>
                            <FormBuilder
                              disableKeySubmit={true}
                              initialValues={{}}
                              listOfFields={[
                                {
                                  title: <div className="formTitle"><span className="">Reason for Plan Rejection</span></div>,
                                  subTitle: <div className="formSubTitle"><span className="">Leave notes as to why we are rejecting this plan. Be as specific as possible.</span></div>,
                                  type: "textArea",
                                  name: "rejectionNotes",
                                  rows: 6,
                                  required: true,
                                  defaultValue: "",
                                  defaultValuePath: "rejectionNotes",
                                  errorMessage: "Rejection reason is required"
                                },
                                {
                                  title: <div style={{"fontSize":"28px","fontWeight":"500","padding":"25px 0 15px"}}>Contact Report</div>,
                                  type: "sectionDivider",
                                  name: "sectionDivider",
                                  required: false
                                },
                                {
                                  title: <div className="formTitle"><span className="">Who contacted them?</span></div>,
                                  subTitle: <div className="formSubTitle"><span className="">Write how you contacted the user with their rejection. And anthing else that happned. This maybe used for leagle reasons in the future.</span></div>,
                                  type: "text",
                                  name: "rejectionObj,from",
                                  required: true,
                                  defaultValue: "",
                                  defaultValuePath: "rejectionObj,from",
                                  errorMessage: "Rejection reason is required"
                                },
                                {
                                  title: <div className="formTitle"><span className="">Type</span></div>,
                                  subTitle: <div className="formSubTitle"><span className="">What sort of communication was it?</span></div>,
                                  type: "selectAPIWithAdd",
                                  onMount: (callBack) => {
                                    window.Client.query({
                                      query: API_CALLS.COMS.QUERIES.distinct(),
                                      variables: {
                                        field: "type" //FIELD TO GET ALL OPTIONS
                                      },
                                      fetchPolicy: "no-cache"
                                    })
                                    .then(result => {
                                      //console.log("result.data","\n\n",result.data,"\n\n");
                                      var fullOptionList = result.data.comDistinct.map((DisOption, index)=>{
                                        return({
                                          "value": DisOption,
                                          "title": DisOption
                                        })
                                      });
                                      var DefaultValues = [
                                        {
                                          "value": "",
                                          "title": "Choose one",
                                          "disabled": true
                                        },
                                        {
                                          "value": "EMAIL",
                                          "title": "EMAIL"
                                        },
                                        {
                                          "value": "PHONE",
                                          "title": "PHONE"
                                        },
                                        {
                                          "value": "TEXT_MESSAGE",
                                          "title": "TEXT_MESSAGE"
                                        },
                                        {
                                          "value": "IN_PERSON",
                                          "title": "IN_PERSON"
                                        },
                                      ];
                                      const uniqueArray = window.GlobalUtil.arrayOfObjectsOnlyUnique([...DefaultValues, ...fullOptionList], "value");
                                      callBack("options", uniqueArray)
                                    });
                                  },
                                  name: "rejectionObj,type",
                                  required: true,
                                  defaultValue: "",
                                  defaultValuePath: "rejectionObj,type",
                                  errorMessage: "This is required"
                                },
                                {
                                  title: <div className="formTitle"><span className="">Rejection Message</span></div>,
                                  subTitle: <div className="formSubTitle"><span className="">Write how you contacted the user with their rejection. What you told them, and what they said.</span></div>,
                                  type: "textArea",
                                  name: "rejectionObj,message",
                                  rows: 6,
                                  required: true,
                                  defaultValue: "",
                                  defaultValuePath: "rejectionObj,message",
                                  errorMessage: "Rejection reason is required"
                                },
                                {
                                  title: <div className="formTitle"><span className="">Any additional notes about the communication</span></div>,
                                  subTitle: <div className="formSubTitle"><span className="">Keep in mind any of this information may be useful in future legal battles. So be as specific and accurate as possible.</span></div>,
                                  type: "textArea",
                                  name: "rejectionObj,notes",
                                  rows: 6,
                                  required: true,
                                  defaultValue: "",
                                  defaultValuePath: "rejectionObj,notes",
                                  errorMessage: "Rejection reason is required"
                                }
                              ]}
                              onSubmit={()=>{
                              }}
                              onChange={newObj => {                                      
                                this.newObj = newObj;
                              }}
                              onValidate={(isValid)=>{
                                this.setState({isValid})
                              }}
                            />
                          </Container>
                        </div>
                      </div>
                  </Column>
                </Row>
              </Container>
            </div>
          </TemplateSlideInRight>
        }
      </div>
    )
  }
}





export default Index;