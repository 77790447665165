import React from 'react';
//import Step4 from 'global/img/infoVectors/Step4.jpg';
//<div className="image" style={{"maxWidth": "300px", "margin":"auto"}}><img src={Step4} alt=""/></div>
// const {Container, Row, Column} = window.LayoutBuilder;




const Index = (props) => {
  var {byCoupons, CONTENT} = props;
  return (
    <div id="UnpaidEarnings" className="component fullHeight overflowRightScroll tableComponent">
      <div className="itemHeader">
        <div className="itemHeaderTextZone">
          <div className="itemHeaderText withIcon itemHeaderTextCombo">
            <div className="icon"><i className="fas fa-file-invoice-dollar"></i></div>
            <div className="text">{CONTENT.UnpaidEarnings[0]}</div>
          </div>
        </div>
      </div>
      <div className="componentSummary">
        {CONTENT.UnpaidEarnings[1]}
      </div>
      <div id="UnpaidEarningsTable" className="tableWrapper">
        {
           (byCoupons && byCoupons.length)
           ?  <table className='table'>
                <thead>
                  <tr>
                    <th>{CONTENT.UnpaidEarningsTable[0]}</th>
                    <th className="text-center">{CONTENT.UnpaidEarningsTable[1]}</th>
                    <th style={{"width": "50px", "textAlign":"right"}}>{CONTENT.UnpaidEarningsTable[2]}</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    (byCoupons && byCoupons.length)
                    ? byCoupons.map((coupon, index) => {
                        var couponCode = coupon[0].CODE;
                        var EARNED = coupon.reduce((cur, newVal)=>{return(cur+Number(newVal.EARNED ? newVal.EARNED : 0))},0);
                        return(
                          <tr key={index}>
                            <td>
                              <span>{couponCode}</span>
                            </td>
                            <td className="text-center"><span className="">{coupon.length}</span></td>
                            <td><span className="">{window.GlobalUtil.convertToMoney(EARNED)}</span></td>
                          </tr>
                        )
                      })
                    : null
                  }
                </tbody>
              </table>
           : <p className="nothingFoundMessage">{CONTENT.UnpaidEarningsTable[3]}</p>
        }
      </div>
    </div>
  );
}


export default Index;
      