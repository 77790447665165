import React, { useState, useRef } from 'react';
import {ExtenderClass, TITLE, SUBTITLE, INVALID_CLASS_CHECK} from "./helperClasses";
import EditNote from "global/components/EditNote/index";
///Users/danielezaldivar/Sites/sonrisas/src/styles/global/NotesList.scss


//FILTER BY: [CREATED ▽] [ASC ▽]
//FILTER BY: [CATEGORY ▽] [EMAIL ▽]
// const FILTER_DIC = {
//   "CREATED": ["ASC","DESC"],
//   "UPDATED": ["ASC","DESC"],
//   "STATUS": [],
//   "CATEGORY": [],
// };

const SortObjByUpdated = (items)=>{
  items.sort(function(a, b) {
    var nameA = a.updateAt; // ignore upper and lowercase
    var nameB = b.updateAt; // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  });
  return items;
}

class NotesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      zIndex: 1
    }
    //this.extraCheck = (newValue)=>{return((!newValue || !newValue.name || !newValue.url) ? false : true)}
    this.Validate = this.Validate.bind(this);
  }

  componentDidMount(){
    this.Validate(this.props.value);
  }


  Validate(newValue){
    var {field={}} = this.props;
    var {required, name=""} = field;
    if(!required){
      this.props.onChangeValid(name, true);
      return;
    }

    var valid = true;
    if(!newValue) valid = false;
    if(valid && this.extraCheck) valid = this.extraCheck(newValue)
    this.props.onChangeValid(name, valid);
  }

  onSaveNote = (index, newNote) => {
    var {field={}, value, onChange, onTouch, meta} = this.props;
    var curNotes = (value ? value.slice() : []);

    //IF -1 THEN ADD AS NEW, ELSE REPLACE WITH NEW
    if(index === -1) curNotes.push(newNote);
    else curNotes[index] = newNote;

    curNotes = curNotes.map((note, index)=>{
      if(!note.createAt) note.createAt = `${Date.now()}`;
      if(!note.updateAt) note.updateAt = `${Date.now()}`;
      return note;
    })

    curNotes = SortObjByUpdated(curNotes); //SORT BY updateAt
    curNotes = curNotes.reverse(); //BECAUSE updateAt IS BIGGER THE NEWER THEN REVERSE LIST

    onChange(field.name, curNotes);
    if(field.required) this.Validate(curNotes);    
  }


  getCategories = (value) => {
    var {field={}} = this.props;
    var {defaultCategoriesProps=[]} = field;
    if(!value || (value.length < 1)) return defaultCategoriesProps;
    var defaultCategories = value.map(note=>note.category);
    defaultCategories = window.GlobalUtil.arrayOnlyUnique(defaultCategories);
    var defaultCategoriesArray = defaultCategories.map(category=>{
      return({
        "value": category,
        "title": category  
      })
    });
    defaultCategoriesArray = [...defaultCategoriesProps, ...defaultCategoriesArray];
    defaultCategoriesArray = window.GlobalUtil.arrayOfObjectsOnlyUnique(defaultCategoriesArray, "value");
    defaultCategoriesArray = defaultCategoriesArray.filter(obj=>(obj.value !== "SYSTEM_LOG")); //REMOVE SYSTEM LOG FROM THE OPTIONS. THIS ONE ONLY EXIST FOR THE SYSTEM TO USE
    //console.log(`defaultCategoriesArray`,"\n\n",defaultCategoriesArray,"\n\n");    
    return defaultCategoriesArray 
  }

  render(){
    var {zIndex, filter} = this.state;
    var {field={}, id, value, onChange, onTouch, meta} = this.props;
    var {name="", required, title, options=[], add, accept, def, showTimeSelect} = field;
    var defaultCategories = this.getCategories(value);
    if(value.length) value = SortObjByUpdated(value).reverse();
    return(
      <div className="noteList">
        <div className="noteListTitle">
          {TITLE(field.title, field.required, field.requiredMessage)}
          {SUBTITLE(field.subTitle)}
        </div>
        <div className="notesListInputWrapper" style={{...STYLES.datePickerWrapper, "zIndex":zIndex}}>
          {
            (value && (value.length))
            ? <div className="notesListForm">
                {
                  value.map((curNote, index)=>{
                    return(
                      <Note 
                        key={curNote.createAt}
                        curNote={curNote}
                        defaultCategories={defaultCategories}
                        onSave={(newNote)=>{
                          this.onSaveNote(index, newNote)
                        }}
                      />
                    )
                  })
                }  
              </div>
            : <div className="noNoteFound">
                <EditNote
                  triggerButton={<span>No Notes Found</span>}
                  defaultCategories={defaultCategories}
                  onSave={(newNote)=>{
                    this.onSaveNote(-1, newNote)
                  }}
                  //currentNote={note}
                />  
              </div>
          }
        </div>
        <EditNote
          triggerButton={(<button className="button newNoteButton btn-color4">New Note&nbsp;&nbsp;<i className="fas fa-plus"></i></button>)}
          defaultCategories={defaultCategories}
          onSave={(newNote)=>{
            this.onSaveNote(-1, newNote)
          }}
          //currentNote={note}
        />
        {INVALID_CLASS_CHECK(meta, field.required, (field.errorMessage && <small className="form-text red-text errorMessage">{field.errorMessage}</small>), null)}
      </div>
    )
  }
}

const Note = (props) => {
  var {curNote={}, defaultCategories} = props;
  var {note, createAt, updateAt, status, category, title} = curNote;
  createAt = `${window.GlobalUtil.convertToDate(createAt, `n/d/Y @ H:mA`)}`
  updateAt = `${window.GlobalUtil.convertToDate(updateAt, `n/d/Y @ H:mA`)}`

  return(
    <React.Fragment>
      <div className={`noteForm ${category === 'SYSTEM_LOG' ? "redBorder" : ""} ${status}`}>
        <div className="noteTitle">
          {title}
        </div>
        <div className="noteMessage">
          {note}
        </div>
        <hr/>
        <div className="noteDates">
          <div className="noteDate noteDateFirst">
            <span className="emph">Update At:</span>&nbsp;&nbsp;{updateAt}
          </div>
          <div className="noteDate">
            <span className="emph">Create At:</span>&nbsp;&nbsp;{createAt}
          </div>
        </div>
        <div className="noteDates">
          <div className="noteStatus noteDateFirst">
            <span className="emph">Status:</span>&nbsp;&nbsp;{status}
          </div>
          <div className="noteDate">
            <span className="emph">Category:</span>&nbsp;&nbsp;{category}
          </div>
        </div>
        <div className="noteEdit">
          <EditNote
            defaultCategories={defaultCategories}
            onSave={props.onSave}
            currentNote={curNote}
          />
        </div>
      </div>
      <hr/>
    </React.Fragment>
  )
}





const STYLES = {
  imageWrapper: {
    "display": "flex", 
    "alignItems": "center", 
    "margin": "0 -1%",
    "flexWrap": "wrap"
  },
  tempStyles: {
    "height": "auto",
    "display": "block",
    "padding": "15px 10px",
    "margin": "0 0 15px 0",
    "borderColor": "#ddd"
  },
  imageDiv: {
    "maxWidth":"100%",
    "minWidth":"100%",
    "border":"1px solid #ddd",
    "margin":"0 1% 30px",
    "position":"relative",
  },
  deleteDiv: {
    "background": "red",
    "width": "30px",
    "height": "30px",
    "display": "flex",
    "alignItems": "center",
    "justifyContent": "center",
    "position": "absolute",
    "right": "0px",
    "top": "0px",
    "cursor": "pointer"
  },
  deleteIcon: {
    "color": "#FFF"
  },
  datePickerWrapper: {
    "position": "relative"
  },
  nothingSelected: {
  }
}

export default NotesList;