import React, { useState, useRef } from 'react';
import FormBuilder from "global/components/FormBuilder/index";
import Loading from 'global/components/Loading/index';
import {ExtenderClass, TITLE, SUBTITLE, INVALID_CLASS_CHECK} from "./helperClasses";
import API_CALLS from 'API_CALLS/index';
//CSS /Users/danielezaldivar/Sites/sonrisas/src/styles/scss/global/FormBuilder.scss


class UpdateCouponUserCode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      curStatus: false,
      status: 'start',
      curObj: {...(this.props.curObj ? this.props.curObj : {})}
    };    
  }

  componentDidMount(){          
  }

  componentWillUnmount(){
  }

  onFormChange = newObj => {
    if(newObj.whatEmail && (this.state.curObj.whatEmail != newObj.whatEmail)){
      this.setState({
        curObj: newObj,
        status: 'noForm'
      }, ()=>{
        this.setState({status: 'start'});
      });
    } else {
      this.setState({
        curObj: newObj
      });
    }
  }


  APIUpdateCouponAndUser = (newObj) => {  
    return window.Client.mutate({
      mutation: API_CALLS.COUPONS.MUTATIONS.updateCouponUserCode(),
      variables: {
        id: newObj.CPID,
        CODE: newObj.CODE,
        UID: newObj.UID
      }
    });
  }


  onSubmitContact = () => {
    var {curObj} = this.state;
    this.setState({status: 'loading'});
    console.log('curObj', curObj);
    
    const newVar = JSON.parse(JSON.stringify(curObj));
    console.log('newVar', newVar);
    
    this.APIUpdateCouponAndUser(newVar)
      .then((result)=>{
        console.log('result', result);
        
        this.setState({status: 'success'});
        window.GlobalUtil.triggerEvent("ShowConfetti", true);
        window.GlobalUtil.DialogStack.closeAll();
        if(this.props.onSuccess) this.props.onSuccess(true); //UPDATE THE DATABASE PAGE AREA 
      })
      .catch((error)=>{
        this.setState({status: 'failed', errorMessage: error.toString()});
        console.log(`error`,"\n\n",error,"\n\n");    
      });
        
  }

  render(){
    var {status, curObj, curStatus, errorMessage} = this.state;             
    console.log(`curObj`,"\n\n",curObj,"\n\n");
          
    return (
      <div id="AdminUpdateCouponCode" className=''>
        {
          status === 'loading' && 
          <Loading
            header={(window.Session.Language === "ESPAÑOL") ? "Enviando" : "Sending"}
            body={"Email Sending Please wait"}
            floating={false}
            overlay={false}
            inlineStyles={{
              LoadingScreen: {
                "minHeight": "190px",
                //"border": "1px solid rgb(221, 221, 221)",
                "borderRadius": "10px",
                "position": "relative",
                "paddingTop": "50px"
              },
              loadingIcon: {
                "maxWidth": "80px"
              },
              loadingHeader: {
                "fontSize":"18px",
                "marginBottom":"0",
                "paddingTop":"20px"
              }
            }}
          />
        }
        {
          status === 'start' &&  
          <div className="startText">
            <div className="text">
              Change Users Coupon Code
            </div>
            <br/>
            <FormBuilder
              ref={e => this.formRef = e}
              disableKeySubmit={true}
              initialValues={curObj}
              listOfFields={[
                {
                  title: <div className="formTitle"><span className="">USER</span></div>,
                  subTitle: <div className="formSubTitle"><span className="">Chose a User.</span></div>,
                  type: "selectAPI",
                  onMount: (callBack) => {
                    window.Client.query({
                      query: API_CALLS.USERS.QUERIES.all(`email`),
                      variables: {
                        "status": "ACTIVE",
                        // "manual": [
                        //   {
                        //     path: "role",
                        //     val: "PATIENT",
                        //     not: true
                        //   }
                        // ]
                      }
                    })
                    .then(result => {
                      //console.log(`result.data`,"\n\n",result.data,"\n\n");    
                      var Options = result.data.users.map((user, index)=>{
                        return({
                          "value": user._id,
                          "title": user.email
                        })
                      });
                      callBack("options", [{
                        "value": "",
                        "title": "Choose one",
                        "disabled": true
                      }, ...Options])
                      // console.log("Options");
                      // console.log(Options,"\n\n");
                            
                    });
                  },
                  name: "UID",
                  required: true,
                  defaultValue: "",
                  errorMessage: "User ID is required"
                },
                {
                  title: <div className="formTitle"><span className="">COUPON</span></div>,
                  subTitle: <div className="formSubTitle"><span className="">Chose a Coupon.</span></div>,
                  type: "selectAPI",
                  onMount: (callBack) => {
                    window.Client.query({
                      query: API_CALLS.COUPONS.QUERIES.all(`name`),
                      variables: {
                        "status": "ACTIVE",
                        "manual": [
                          {
                            path: "category",
                            val: "PROMO"
                          }
                        ]
                      }
                    })
                    .then(result => {
                      //console.log(`result.data`,"\n\n",result.data,"\n\n");    
                      var Options = result.data.coupons.map((coupon, index)=>{
                        return({
                          "value": coupon._id,
                          "title": coupon.name
                        })
                      });
                      callBack("options", [{
                        "value": "",
                        "title": "Choose one",
                        "disabled": true
                      }, ...Options])
                      // console.log("Options");
                      // console.log(Options,"\n\n");
                            
                    });
                  },
                  name: "CPID",
                  required: true,
                  defaultValue: "",
                  errorMessage: "Coupon ID is required"
                },
                {
                  title: <div className="formTitle"><span className="">CODE</span></div>,
                  subTitle: <div className="formSubTitle"><span className="">Pick a code</span></div>,
                  type: "text",
                  name: "CODE",
                  formatInput: (inputValue)=>{ //MAKE ALL INPUT UPPERCASE
                    if(!inputValue) return inputValue;
                    return inputValue.toUpperCase();
                  },
                  onBlur: ({newValue, currentObject, onChangeValid=()=>{}, newErrorFunction=()=>{}})=>{  
                    window.Client.mutate({
                      mutation: API_CALLS.COUPONS.QUERIES.couponFinder(),
                      variables: {
                        code: newValue
                      }
                    })
                    .then(result => {
                      var coupon = result.data.couponFinder;
                      console.log('coupon', coupon);
                      if(coupon){ //IF CODE EXIST                    
                        if(coupon.ID){ //IF CODE IS NOT CURRENT CODE
                          onChangeValid("CODE", false);
                          newErrorFunction("THIS CODE IS BEING USED");
                        } else {
                          onChangeValid("CODE", true);
                          newErrorFunction("");
                        }
                      }
                      //console.log(`result.data`,"\n\n",result.data,"\n\n");
                    })
                    .catch((error)=>{
                      console.log("API_CALLS.REFERRALS.QUERIES.all FAIL catch", error);
                    })
                  },
                  required: true,
                  defaultValue: "",
                  defaultValuePath: "CODE"
                }
              ]}
              onSubmit={()=>{
                console.log("Enter Pushed but will not send email until button pushed")
              }}
              onChange={this.onFormChange}
              onValidate={(isValid)=>{
                this.setState({curStatus: isValid});
              }}
            />
            <button className={`button button1 ${(curStatus) ? '' : 'disabled'}`} onClick={this.onSubmitContact} disabled={!curStatus}><i className="fas fa-paper-plane" style={{"padding": "0 10px 0 0 "}}/> Save</button>
          </div>
        }

        {
          status === 'success' && 
          <div className="successText">
            <div className="finishedMessage">
              <div style={{"color":"#4caf50","fontWeight":"700","fontSize":"30px"}}>SUCCESS!</div>
              <div className="title">Your email has been sent!</div>
              <div className="text">A log of the email being created and sent has also been saved.</div>
              <div className="last">You're good to go!</div>
              <br/>
              <button className="button button1" onClick={window.GlobalUtil.DialogStack.closeTop}>Close</button>
            </div>
          </div>
        }


        {
          status === 'failed' && 
          <div className="failedText">
            <div className="finishedMessage">
              <div style={{"color":"#e91e63","fontWeight":"700","fontSize":"30px"}}>FAILED!</div>
              <div className="title">Oh no, somthing went wrong</div>
              <div className="text">Something is wrong with the server, please try again later.</div>
              <div className="last">{errorMessage}</div>
              <br/>
              <button className="button button1" onClick={window.GlobalUtil.DialogStack.closeTop}>Close</button>
            </div>
          </div>
        }
      </div>
    );
  }
}






class APIUpdateCouponUserCode extends ExtenderClass { 
  constructor(props) {
    super(props);
    this.state={
      options: [],
      products: []
    };

  }

  onMount = ()=>{
  }


  render(){
    var {field={}, id, value='', onTouch, meta} = this.props;
    var {options=[], products=[], selectedProduct=[]} = this.state;
    var {name="", required, title, add, isUser=false} = field;
                              
    return(
      <div id="UpdateCouponUserCode">
        {TITLE(field.title, field.required, field.requiredMessage)}
        {SUBTITLE(field.subTitle)}
        <div className="subTitle">Current Products</div>
        <div id="InlineCodeList">
          {
            value
            ? value.map((obj, index)=>{              
              return(
                <div key={index} className="codeItem">
                  <div className="codeItemContainer">
                    <div className="codeItemCODE codeItemText">
                      <div className="title">CODE:</div>
                      <div className="value">{obj.CODE}</div>
                    </div>
                    {
                      obj.UID
                      ? <div className="codeItemUID codeItemText">
                          <div className="title">UID:</div>
                          <div className="value">
                            <a className="" onClick={()=>{
                              window.GlobalUtil.triggerEvent("TemplateDBEvent", {
                                "PATH": "UID",
                                "NAME":"USER ID",
                                "TITLE": "User", //TITLE OF SLIDE OUT
                                "APINAME": "USERS",
                                "ID_PATH": "UID",
                                "EDITABLE": true,
                                TYPE: "PREVIEW",
                                ID: obj.UID
                              });
                            }}>
                              {obj.UID}
                            </a>
                          </div>
                        </div>
                      : <div className="codeItemUID codeItemText">
                          <div className="title">CPID:</div>
                          <div className="value">
                            <a className="" onClick={()=>{
                              window.GlobalUtil.triggerEvent("TemplateDBEvent", {
                                "PATH": "CPID",
                                "NAME":"COUPON ID",
                                "TITLE": "Coupon", //TITLE OF SLIDE OUT
                                "APINAME": "COUPONS",
                                "ID_PATH": "CPID",
                                "EDITABLE": true,
                                TYPE: "PREVIEW",
                                ID: obj.CPID
                              });
                            }}>
                              {obj.CPID}
                            </a>
                          </div>
                        </div>
                    }
                  </div>
                  <div className="codeItemLink">
                    <button className="button button1" onClick={(e)=>{              
                      e.preventDefault();
                      window.GlobalUtil.triggerEvent("SlideInRightEvent", {
                        TITLE: <span>Edit Code</span>,
                        customClass: "frontEndSlideInRight shortSlideInRight",
                        width: " ",
                        noFooter: true,
                        onClose: ()=>{
                        },
                        children: (childrenProps)=>{                                      
                          return(
                            <UpdateCouponUserCode 
                              curObj={{
                                CODE: obj.CODE, 
                                UID: (obj.UID ? obj.UID : this.props.currentObject.ID), 
                                CPID: (obj.CPID ? obj.CPID : this.props.currentObject.ID)
                              }}
                            />
                          )
                        }
                      });
                    }}>
                      Edit
                    </button>
                  </div>
                </div>
              )
            })
            : null
          }
          <div className="codeItem">
            <div className="codeItemLink">
                <button className="button button1" onClick={(e)=>{              
                  e.preventDefault();
                  window.GlobalUtil.triggerEvent("SlideInRightEvent", {
                    TITLE: <span>Edit Code</span>,
                    customClass: "frontEndSlideInRight shortSlideInRight",
                    width: " ",
                    noFooter: true,
                    onClose: ()=>{
                    },
                    children: (childrenProps)=>{                                      
                      return(
                        <UpdateCouponUserCode 
                          curObj={{
                            CODE: undefined, 
                            UID: (isUser ? this.props.currentObject.ID : undefined), 
                            CPID: (!isUser ? this.props.currentObject.ID : undefined)
                          }}
                        />
                      )
                    }
                  });
                }}>
                  Add Code and Coupon
                </button>
              </div>
          </div>
        </div>
        {INVALID_CLASS_CHECK(meta, field.required, (field.errorMessage && <small className="form-text red-text errorMessage">{field.errorMessage}</small>), null)}
      </div>
    )
  }
}


export default APIUpdateCouponUserCode;