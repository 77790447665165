import React from 'react';
import {Helmet} from "react-helmet-async";
import 'styles/scss/website/pages/promo.scss';
import TemplatePage from 'website/templates/templatePage/index.js';
import HeaderImage from 'website/components/HeaderImage/index';
import GiveawayImage1 from 'global/img/giveaway1/prizeImage_4_1x1_500.jpg';
import TreatmentPlanBar from 'website/components/TreatmentPlanBar/index';
import ReferralCodeSearch from './ReferralCodeSearch';
import SimpleStepsList from './SimpleStepsList';
import CONTENTS from "./CONTENTS";
const {Container, Row, Column} = window.LayoutBuilder;

//?CODE=VAL-PAC-001
class Index extends TemplatePage {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state
    };    
  }

  didMount(){
    this.getURLCode();
    document.querySelector("#Content").classList.add("noMinHeight")
  }

  willUnmount(){
    document.querySelector("#Content").classList.remove("noMinHeight")
  }

  formatRefCode = (inputValue)=>{
    if(!inputValue) return "";
    return inputValue.split("-").join("");
    
    inputValue = inputValue.split("-");
    inputValue = inputValue.join("");
    if(!inputValue) return "";
    if(inputValue.length > 9) inputValue = inputValue.slice(0,9);
    inputValue = inputValue.toUpperCase();
    if(inputValue.length > 6){
      inputValue = `${inputValue.slice(0,3)}-${inputValue.slice(3,6)}-${inputValue.slice(6)}`
    } else if(inputValue.length > 3){
      inputValue = `${inputValue.slice(0,3)}-${inputValue.slice(3,6)}`
    } 
    return inputValue;
  }


  
  getURLCode = () => {
    var queryString = window.location.search;
    var urlParams = new URLSearchParams(queryString);
    var CODE = urlParams.get('CODE');
    if(CODE){
      //if(CODE.slice(0,1) !== "/") CODE = "/"+CODE;
      //console.log(`CODE`,"\n\n",CODE,"\n\n");
      var newRefCode = this.formatRefCode(CODE);
      console.log('newRefCode', newRefCode);
      
      this.setState({
        refCode: newRefCode,
        resetRef: true
      }, ()=>this.setState({
          resetRef: false
      }))
    } 
  }



  render(){
    var {showWhatsNext, refCode="", resetRef} = this.state;
    const CONTENT = window.GlobalUtil.dangerSetGlobalObjList(CONTENTS[window.Session.Language].CONTENT); 
    const HEADER = CONTENTS[window.Session.Language].HEADER; 
    //showWhatsNext = true;
    return (
      <div className="contentPage" id="PagePromo">
        <Helmet>
            <meta charSet="utf-8" />
            <title>{HEADER.TITLE}</title>
            <meta name="description" content={HEADER.DESCRIPTION}/>
            <link  href={`${this.props.location.pathname}`} />
            <link rel="canonical" href={`${HEADER.BASEURL}${this.props.location.pathname}`} />
            <meta name="keywords" content={HEADER.KEYWORDS} />

            {"<!-- Google / Search Engine Tags -->"}
            <meta itemprop="name" content={HEADER.TITLE} />
            <meta itemprop="description" content={HEADER.DESCRIPTION}/>
            <meta itemprop="image" content="https://Smiles.Club/SocialImage_500.jpg" />

            {/*<!-- Facebook Meta Tags -->*/}
            <meta property="og:url" content={`${HEADER.BASEURL}${this.props.location.pathname}`} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={HEADER.TITLE} />
            <meta property="og:description" content={HEADER.DESCRIPTION}/>
            <meta property="og:image" content="https://Smiles.Club/SocialImage_500.jpg" />

            {/*<!-- Twitter Meta Tags -->*/}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={HEADER.TITLE} />
            <meta name="twitter:description" content={HEADER.DESCRIPTION}/>
            <meta name="twitter:image" content="https://Smiles.Club/SocialImage_500.jpg" />
        </Helmet>
        <div id="TopMainSection" className="containerSection ">
          <Container className="">
            <Row className="">
              <Column className="" >
                <div className="mainImage">
                  <HeaderImage
                    img={GiveawayImage1}
                    alt={CONTENT.HeaderImage[0].alt}
                    //right={true}
                  />
                </div>
              </Column>
              
              <Column className="contentCenter topTextZone" col="12" xs="12" sm="12" md="12" lg="8" xl="">
                <div id="CodeZone" className="codeZone">
                  {CONTENT.CodeZone[0]}
                  {
                    !resetRef && 
                    <ReferralCodeSearch
                      refCode={refCode}
                      CONTENT={CONTENT.ReferralCodeSearch}
                      onSuccess={()=>{
                        this.setState({showWhatsNext: true})
                      }}
                    />
                  }
                </div>
              </Column>
            </Row>
          </Container>
          {
            showWhatsNext &&
            <React.Fragment>
              <div id="WhatsNextHeader"></div>
              <Container id="ImpressionKitContainer" className="animated slideInLeft">
                
                <TreatmentPlanBar 
                  showCouponInTreatmentPlanBar={true} //THIS TELLS THE TREATMENT PLAN BAR PRODUCT TO INCLUDE PRODUCT PRICE WITH COUPON.
                  className="TreatmentPlanUnlocked"
                  productTitleAdd={<div className="PreHeaderText"><small>{CONTENT.ImpressionKitContainer[0]}</small>!</div>}
                />
                {/*<div className="orSpacer">OR</div>
                <ImpressionKitBar />*/}
              </Container>
            </React.Fragment>
          }
          <Container id="StepsZone">
            <Row>
              <Column className="" col="12" xs="12" sm="12" md="12" lg="12" xl="">
                {CONTENT.StepsZone[0]}
                <SimpleStepsList 
                  CONTENT={CONTENT.SimpleStepsList}
                />
              </Column>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}




export default Index;

      
