import React from 'react';
// import {NavLink, Link} from 'react-router-dom';
//import PAGES from '../pages/pages.js'; //ADD NEW PAGES HERE
import API_CALLS from 'API_CALLS/index';
//import  'styles/scss/website/components/ImpressionKitBar.scss'; ///Users/danielezaldivar/Sites/sonrisas/src/styles/scss/website/components/ImpressionKitBar.scss
const {Container, Row, Column} = window.LayoutBuilder;
const CONTENTS = {
  ESPAÑOL: [
    {
      "type": "div",
      "content": `<div class="preHeader">COMPRA AHORA</div>`
    },
    {
      "type": "div",
      "content": `<h2 class="sectionTitle2">¡Compra tu kit de impresión por <strong style="font-weight: 900;color: #eb008b;">$1!</strong></h2>`
    },
    {
      "type": "div",
      "content": `<div class="subHeader"><strong style="font-weight: 600;">¡Por tiempo limitado, nuestros kits de impresión se han reducido a </strong><strong style="font-weight: 700;color:#eb008b;">UN DÓLAR!</strong> Compra tu kit de impresión ahora para obtener <strong style="font-weight: 600;">una oferta de plan de tratamiento gratuito.</strong> Una vez que hayas hecho tu impresión dental y nos la hayas enviado, un profesional licenciado creará un tratamiento personalizado para ti.</div>`
    },
    {
      "type": "span",
      "content": `Contáctanos`
    },
    {
      "type": "span",
      "content": `Comprar`
    },
    {
      "content": "Compra Ahora"
    },
    {
      "type": "div",
      "className": "riskFree",
      "content": `<div class="award"><i class="fas fa-award"></i></div>
                  <div class="text">
                    <div class="header">¡Completamente libre de riesgos!</div>
                    <div class="guarantee">Le reembolsaremos su dinero si no califica. <span class="emph">Y</span> si lo hace, ¡descontaremos lo que pagó por su kit de impresión <span class="emph">100%</span> del costo del plan de tratamiento completo! <span class="emph2">¡ES COMO OBTENER EL KIT DE IMPRESIÓN GRATIS!</span></div>
                  </div>`
    }
  ],
  ENGLISH: [
    {
      "type": "div",
      "content": `<div class="preHeader">Purchase Now</div>`
    },
    {
      "type": "div",
      "content": `<h2 class="sectionTitle2">Buy your impression kit for <strong style="font-weight: 900;color: #eb008b;">$1!</strong></h2>`
    },
    {
      "type": "div",
      "content": `<div class="subHeader"><strong style="font-weight: 600;">For a limited time our impression kits have gone down to </strong><strong style="font-weight: 700;color:#eb008b;">ONE DOLLAR!</strong> Order your impression kit now <strong style="font-weight: 600;">to get your free treatment plan offer.</strong> Once you've taken your impression and sent it back our licenced profesionals will be able to create a custom treatment plan for you.</div>`
    },
    {
      "type": "span",
      "content": `Contact Us`
    },
    {
      "type": "span",
      "content": `Order Now`
    },
    {
      "content": "Buy Now"
    },
    {
      "type": "div",
      "className": "riskFree",
      "content": `<div class="award"><i class="fas fa-award"></i></div>
                  <div class="text">
                    <div class="header">Completely Risk Free!</div>
                    <div class="guarantee">We‘ll refund you your money if you do not qualify. <span class="emph">AND</span> if you do, we'll take what you paid for your impression kit <span class="emph">OFF</span> the cost of the full treatment plan! <span class="emph2">IT'S LIKE GETTING THE IMPRESSION KIT FOR FREE!</span></div>
                  </div>`
    }
  ]
}




class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      loading: true
    };    
    this.ClientEvent = window.GlobalUtil.subscribeEvent("ClientEvent", (newValue)=>{ //WAIT UNTIL window.Client EXIST
      if(this.LoopRef) this.LoopRef.abort();
      this.LoopRef = window.GlobalUtil.retryLoop(this.getProducts, 6, 500, this.onFailed);
    });
    this.onFailed = this.onFailed.bind(this);
    this.getProducts = this.getProducts.bind(this);
  }

  componentDidMount(){                         
    if(window.Client && window.Client.query){
      if(this.LoopRef) this.LoopRef.abort();
      this.LoopRef = window.GlobalUtil.retryLoop(this.getProducts, 6, 500, this.onFailed);
    }
  }

  componentWillUnmount(){
    this.LoopRef.abort();
    this.ClientEvent.unsubscribe();
  }

  onFailed(){
    this.setState({curStatus: "failed", loading: false});
  }



  getProducts = () => {
    //return true; //FOR NOW DON'T GET PRODUCTS JUST HAVE THEM CONTACT US
    if(!window.Client || !window.Client.query) return false;          
    if(this.gettingProductsRef) return true;
    this.gettingProductsRef = true;
    this.setState({loading: true});
    return window.Client.query({
      query: API_CALLS.PRODUCTS.QUERIES.all(),
      variables: {
        type: "NORMAL",
        manual: [
          // {
          //   path: "language",
          //   val: window.Session.Language
          // },
          {
            path: "type",
            val: "NORMAL"
          },
          {
            path: "ref",
            val: "IMPRESSION_KIT"
          },
          {
            path: "website",
            val: (window.Session.Language === "ENGLISH") ? "SMILES" : "SONRISAS"
          }
        ],
        status: "ACTIVE",
      }
    })
    .then(result => {
      //console.log("result");
      //console.log(result,"\n\n");
      //if(!this._ismounted) return;
      //this.setState({products: result.data.products, loading: false});
      var products = window.GlobalUtil.productCouponDisCal(window.GlobalUtil.APICleanObj(result.data.products), window.GlobalUtil.State.get("newOrder").coupon); //THIS SHOULD GET ALL PRODUCTS AND APPLY ANY COUPONS
      this.setState({products: products, loading: false});
      return true;      
    })
    .catch((error)=>{
      console.log("catch ", error);
      return false;     
    })
  }


  render(){
    var {products=[], loading} = this.state;
    if(loading) return <div id="ImpressionKitBar" className="containerSection "></div>;
    const CONTENT = window.GlobalUtil.dangerSetGlobal(CONTENTS[window.Session.Language]); 
    const CUR_PRODUCT = products[0];          
    if(!CUR_PRODUCT) return <div id="ImpressionKitBar" className="containerSection "></div>
    const ImagesURL = window.GlobalUtil.deepGetFromString(CUR_PRODUCT, "images", []);
    const ImageURL = window.GlobalUtil.deepGetFromString(CUR_PRODUCT, "images,0,url", null);
    var {discount, cost, originalCost} = window.GlobalUtil.productCostCal(CUR_PRODUCT);  
    return (
      <div id="ImpressionKitBar" className="containerSection ">
        <Container className="impressionKitContainer">
          <Row id="BuyNow" className="">
            <Column className="" col="" xs="" sm="" md="" lg="" xl="">
              <div id="product" className="productLayout1 d-flex">
                <ProductImage className="d-none d-lg-block d-x-block" Images={ImagesURL} />
                <div className="productText">
                  <ProductImage className="d-block d-lg-none" Images={ImagesURL} />
                  <div className="productTextName">
                    {CUR_PRODUCT.name}
                  </div>
                  <div className="productTextCost d-flex">
                    <div className="cost">{window.GlobalUtil.convertToMoney(cost-discount)}</div>
                    <div className="originalPrice">{window.GlobalUtil.convertToMoney(originalCost)}</div>
                  </div>
                  <div className="productTextDesc">
                    {window.GlobalUtil.dangerSet(CUR_PRODUCT.description)}
                  </div>
                  {/*<div className="">
                    Category: {product.category}
                  </div>*/}
                  <div className="buttonGroup">
                    <button className="button button2 btn-color5 dontCloseCart" onClick={()=>{
                      window.GlobalUtil.addToCart({product: {...CUR_PRODUCT}, replaceAll: true, onlyOne: true}, ()=>{
                        //window.GlobalUtil.triggerEvent("cartEvent", "show");
                        window.Router.history.push('/checkout')
                      });
                    }}>
                    {
                      CONTENT[5]
                    }
                    </button>
                  </div>
                  {
                    CONTENT[6]
                  }
                </div>
              </div>   
            </Column>
          </Row>
        </Container>
      </div>
    );
  }
}






class ProductImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeImage: (this.props.Images ? this.props.Images[0] : {})
    };    
  }

  componentDidMount(){    
  }

  componentWillUnmount(){
  }


  render(){
    var {activeImage} = this.state;
    var {Images, className} = this.props;
    return (
      <div className={"productImageWrapper " + className}>
        <div className="productImage">
          <div className="activeImage"><img src={activeImage.url} alt="Sonrisas Club Impression Kit"/></div>
          {
            Images && Images.length
            ? <div className="smollImages">
                {
                  Images.map((imgObj, index)=>{
                    return(
                      <div className="smollImage" key={index} onClick={()=>{
                        this.setState({activeImage: imgObj})
                       }}>
                        <img src={imgObj.url} alt="Sonrisas Club Impression Kit"/>
                      </div>
                    )
                  })
                }
              </div>
            : null
          }
        </div>
      </div>
    );
  }
}
export default Index;
