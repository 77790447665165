import React from 'react';
//import { gql, useQuery, useMutation } from '@apollo/client';
import QUERIES from "./QUERIES";
import MUTATIONS from "./MUTATIONS";
import ImageUtils from "global/utils/image-utils";
const IMAGE_UTILS =  new ImageUtils();

const FORM_FIELDS = (obj={}) => {
  const QUERY_GET_DISTINCT = QUERIES.distinct();
  const MUTATION_EDIT = MUTATIONS.update();
  return(
    [ //defaultValuePath used to set default value form object
      {
        title: <div className="formTitle"><span className="">Ref</span></div>,
        subTitle: <div className="formSubTitle"><span className="">This is used to differentiate several examples of the same products. So maybe Version1, Version2, Etc.</span></div>,
        type: "text",
        name: "ref",
        required: false,
        defaultValue: "",
        defaultValuePath: "ref",
      },
      {
        title: <div className="formTitle"><span className="">Product Name</span></div>,
        subTitle: <div className="formSubTitle"><span className="">Name that shows up for the product.</span></div>,
        type: "text",
        name: "name",
        required: true,
        defaultValue: "",
        defaultValuePath: "name",
        errorMessage: "Each product needs a name"
      },
      {
        title: <div className="formTitle"><span className="">Description</span></div>,
        subTitle: <div className="formSubTitle"><span className="">Descrition that shows up for this product.</span></div>,
        type: "textArea",
        name: "description",
        rows: 6,
        required: true,
        defaultValue: "",
        defaultValuePath: "description",
        errorMessage: "Description is required"
      },
      {
        title: <div className="formTitle"><span className="">Short Description</span></div>,
        subTitle: <div className="formSubTitle"><span className="">A short description for products in store page. NO HTML will show up here on store page.</span></div>,
        type: "textArea",
        name: "shortDesc",
        showNumChar: true,
        maxNumChar: 200,
        rows: 6,
        required: true,
        defaultValue: "",
        defaultValuePath: "shortDesc",
        errorMessage: "Description is required"
      },
      {
        title: <div className="formTitle"><span className="">Includes</span></div>,
        subTitle: <div className="formSubTitle"><span className="">A list of things included in this product.</span></div>,
        type: "wordList",
        name: "includes",
        // formatText: (newWord)=>{
        //   return newWord.toLowerCase();
        // },
        required: false,
        defaultValue: "",
        defaultValuePath: "includes",
        errorMessage: "Comments is required"
      },
      {
        title: <div className="formTitle"><span className="">URL</span></div>,
        subTitle: <div className="formSubTitle"><span className="">The url link where this post can be found. Should have no capital letters and dashes between words. Example: why-we-dont-go-shopping</span></div>,
        type: "urlPicker",
        name: "url",
        urlAPI: QUERIES.count, //API TO CALL TO FIND IF THIS URL ALREADY EXIST
        urlPATH: "url", //WHAT FIELD IN THE DB WILL WE BE LOOKING FOR THAT MATCHES THIS URL
        urlFIELD: "productCount", //WHEN THE QUERY RETURNS WHAT IS THE FILE CALLED
        required: true,
        defaultValue: "",
        defaultValuePath: "url"
      },
      {
        title: <div className="formTitle"><span className="">Cost</span></div>,
        subTitle: <div className="formSubTitle"><span className="">Initial Cost of the product. If subscription this should include the setup fee.</span></div>,
        type: "money",
        name: "cost",
        required: true,
        defaultValue: 0,
        defaultValuePath: "cost", //TOTAL COST OF WHOLE THING
        errorMessage: "Cost is required"
      },
      {
        title: <div className="formTitle"><span className="">Discount</span></div>,
        subTitle: <div className="formSubTitle"><span className="">How much you want to take off the inital cost.</span></div>,
        type: "money",
        name: "discount",
        required: false,
        defaultValue: 0,
        defaultValuePath: "discount",
      },
      {
        title: <div className="formTitle"><span className="">Type</span></div>,
        subTitle: <div className="formSubTitle"><span className="">Choose one of the product types in the dropdown.</span></div>,
        type: "select",
        name: "type",
        options: [
          {
            "value": "",
            "title": "Choose one",
            "disabled": true
          },
          {
            "value": "SUBSCRIPTION",
            "title": "SUBSCRIPTION"
          },
          {
            "value": "NORMAL",
            "title": "NORMAL"
          }
        ],
        required: true,
        defaultValue: "NORMAL",
        defaultValuePath: "type",
        errorMessage: "Type is required"
      },
      {
        title: <div className="formTitle"><span className="">Subscription Options</span></div>,
        subTitle: <div className="formSubTitle">Each Subscription Product Needs These Fields</div>,
        type: "conditional",
        condition: (curObj)=>{            
          if(curObj.type && curObj.type === "SUBSCRIPTION") return true;
          return false;
        },
        listOfFields: [
          {
            title: <div className="formTitle"><span className="">Paypal Subscription ID</span></div>,
            subTitle: <div className="formSubTitle">This is used to tell paypal what subscription to sign them up for.</div>,
            type: "text",
            name: "subscription,paypalID",
            placeholder: "This is needed if you are using paypal subscriptions.",
            required: false,
            defaultValue: "",
            defaultValuePath: "subscription,paypalID"
          },
          {
            title: <div className="formTitle"><span className="">Setup Fee</span></div>,
            subTitle: <div className="formSubTitle"><span className="">How much we charge MORE the first time. (This will be charged in addition to the 1st months charge right away.)</span></div>,
            type: "money",
            name: "subscription,setupFee",
            required: false,
            defaultValue: 0,
            defaultValuePath: "subscription,setupFee",
            errorMessage: "Cost is required"
          },
          {
            title: <div className="formTitle"><span className="">Billing Cycle Charge</span></div>,
            subTitle: <div className="formSubTitle"><span className="">How much we charge each billing cycle. Like each month, or whatever we choose in billing cycle.</span></div>,
            type: "money",
            name: "subscription,billingCycleCharge",
            required: false,
            defaultValue: 0,
            defaultValuePath: "subscription,billingCycleCharge",
            errorMessage: "Cost is required"
          },
          {
            title: <div className="formTitle"><span className="">Billing Cycle</span></div>,
            subTitle: <div className="formSubTitle">How often do we charge them?</div>,
            type: "select",
            name: "subscription,billingCycle",
            options: [
              {
                "value": "",
                "title": "Choose one",
                "disabled": true
              },
              {
                "value": "1 Week",
                "title": "Every 1 week"
              },
              {
                "value": "2 Weeks",
                "title": "Every 2 week"
              },
              {
                "value": "1 Month",
                "title": "Every 1 month"
              },
              {
                "value": "2 Months",
                "title": "Every 2 month"
              },
              {
                "value": "3 Months",
                "title": "Every 3 month"
              },
              {
                "value": "4 Months",
                "title": "Every 4 month"
              },
              {
                "value": "6 Months",
                "title": "Every 6 month"
              },
              {
                "value": "1 Year",
                "title": "Every 1 year"
              }
            ],
            required: false,
            defaultValue: "",
            defaultValuePath: "subscription,billingCycle"
          },
          {
            type: "customText",
            customFunction: (curObj)=>{
              if(!curObj.subscription || !curObj.subscription.setupFee || !curObj.subscription.billingCycleCharge) return null;
              var NumberOfCycles = Math.round(((Number(curObj.cost) - Number((curObj.discount ? curObj.discount : 0)) - Number(curObj.subscription.setupFee)) / Number(curObj.subscription.billingCycleCharge)));
              var BillingCycleDic = {"W": "Weeks", "M": "Months", "Y": "Years"};
              return(<div className="">
                <div className="">Number of Billing Cycles: {NumberOfCycles}</div>
              </div>)
            }
          }
        ]
      },
      {
        title: <div className="formTitle"><span className="">Number In Stock</span></div>,
        subTitle: <div className="formSubTitle"><span className="">How many before we don't let them buy any more.</span></div>,
        type: "number",
        name: "numberInStock",
        required: false,
        defaultValue: 100000,
        defaultValuePath: "numberInStock"
      },
      {
        title: <div className="formTitle"><span className="">Website</span></div>,
        subTitle: <div className="formSubTitle"><span className="">Which website will show this product.</span></div>,
        type: "selectAPIWithAdd",
        onMount: (callBack) => {
          window.Client.query({
            query: QUERY_GET_DISTINCT,
            variables: {
              field: "website", //FIELD TO GET ALL OPTIONS
            },
            fetchPolicy: "no-cache"
          })
          .then(result => {
            console.log("result.data","\n\n",result.data,"\n\n");
                  
            var fullOptionList = result.data.productDistinct.map((DisOption, index)=>{
              return({
                "value": DisOption,
                "title": DisOption
              })
            });
            var DefaultValues = [
              {
                "value": "",
                "title": "Choose one",
                "disabled": true
              },
            ];
            const uniqueArray = window.GlobalUtil.arrayOfObjectsOnlyUnique([...DefaultValues, ...fullOptionList], "value");
            window.GlobalUtil.consoleLog("uniqueArray", [uniqueArray]);
            callBack("options", uniqueArray)
          });
        },
        name: "website",
        required: false,
        defaultValue: "",
        defaultValuePath: "website",
        errorMessage: "This is required"
      },
      {
        title: <div className="formTitle"><span className="">Language</span></div>,
        subTitle: <div className="formSubTitle"><span className="">This is to help filter by language.</span></div>,
        type: "selectAPIWithAdd",
        onMount: (callBack) => {
          window.Client.query({
            query: QUERY_GET_DISTINCT,
            variables: {
              field: "language", //FIELD TO GET ALL OPTIONS
            },
            fetchPolicy: "no-cache"
          })
          .then(result => {
            var fullOptionList = result.data.productDistinct.map((DisOption, index)=>{
              return({
                "value": DisOption,
                "title": DisOption
              })
            });
            var DefaultValues = [
              {
                "value": "",
                "title": "Choose one",
                "disabled": true
              },
            ];
            const uniqueArray = window.GlobalUtil.arrayOfObjectsOnlyUnique([...DefaultValues, ...fullOptionList], "value");
            window.GlobalUtil.consoleLog("uniqueArray", [uniqueArray]);
            callBack("options", uniqueArray)
          });
        },
        name: "language",
        required: false,
        defaultValue: "",
        defaultValuePath: "language",
        errorMessage: "This is required"
      },
      {
        title: <div className="formTitle"><span className="">Category</span></div>,
        subTitle: <div className="formSubTitle"><span className="">This can be used to group products in the future.</span></div>,
        type: "selectAPIWithAdd",
        onMount: (callBack) => {
          window.Client.query({
            query: QUERY_GET_DISTINCT,
            variables: {
              field: "category" //FIELD TO GET ALL OPTIONS
            },
            fetchPolicy: "no-cache"
          })
          .then(result => {                  
            var fullOptionList = result.data.productDistinct.map((DisOption, index)=>{
              return({
                "value": DisOption,
                "title": DisOption
              })
            });
            var DefaultValues = [
              {
                "value": "",
                "title": "Choose one",
                "disabled": true
              },
              {
                "value": "RETAINERS",
                "title": "RETAINERS"
              },
              {
                "value": "IMPRESSION_KITS",
                "title": "IMPRESSION_KITS"
              },
              {
                "value": "TREATMENT_PLANS",
                "title": "TREATMENT_PLANS"
              }
            ];
            const uniqueArray = window.GlobalUtil.arrayOfObjectsOnlyUnique([...DefaultValues, ...fullOptionList], "value");
            window.GlobalUtil.consoleLog("uniqueArray", [uniqueArray]);
            callBack("options", uniqueArray)
          });
        },
        name: "category",
        required: false,
        defaultValue: "",
        defaultValuePath: "category",
        errorMessage: "This is required"
      },
      {
        title: <div className="formTitle"><span className="">Where to show</span></div>,
        subTitle: <div className="formSubTitle"><span className="">Where should this product be pulled? Maybe ["STORE"]</span></div>,
        type: "wordList",
        name: "showWhere",
        formatText: (newWord)=>{
          return newWord.toUpperCase();
        },
        required: true,
        defaultValue: "",
        defaultValuePath: "showWhere",
        errorMessage: "Comments is required"
      },
      {
        title: <div className="formTitle"><span className="">Status</span></div>,
        subTitle: <div className="formSubTitle"><span className="">Choose a Status for your Product. Active means it'll go live.</span></div>,
        type: "select",
        name: "status",
        options: [
          {
            "value": "",
            "title": "Choose one",
            "disabled": true
          },
          {
            "value": "ACTIVE",
            "title": "ACTIVE"
          },
          {
            "value": "PENDING",
            "title": "PENDING"
          },
          {
            "value": "INACTIVE",
            "title": "INACTIVE"
          }
        ],
        required: true,
        defaultValue: "ACTIVE",
        defaultValuePath: "status",
        errorMessage: "Status is required"
      }
    ]
  )
};






export default FORM_FIELDS;