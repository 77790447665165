import React from 'react';
import 'styles/scss/website/pages/checkout.scss';
import {Helmet} from "react-helmet-async";
import API_CALLS from 'API_CALLS/index';
import CheckoutPage from 'global/components/CheckoutPage/index';
import 'styles/scss/website/pages/checkout.scss';
import CONTENTS from "./CONTENTS";
const {Container, Row, Column} = window.LayoutBuilder;

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      curStatus: "loading"
    };    
    this.getProduct = this.getProduct.bind(this);
    this.ClientEventRef = window.GlobalUtil.subscribeEventOnce("ClientEvent", this.getProduct);
  }

  componentDidMount(){
    //console.log(`this.props.match.params.PID`,"\n\n",this.props.match.params.PID,"\n\n");
    if(window.Client) this.getProduct()
  }

  componentWillUnmount(){
    this.ClientEventRef.unsubscribe();
  }
//http://localhost:3000/rd/?REDIR=/checkout/6137bf2032779f47383d2c83
  getProduct = async () => {
    if(!this.props.match || !this.props.match.params || !this.props.match.params.PID){
      return;
    }
    //console.log(`getProduct`,"\n\n","\n\n");
    if(!window.Client || !window.Client.query) return false;
    // console.log("this.props.match.params.PID");
    // console.log(this.props.match.params.PID,"\n\n");
    return await window.Client.query({
      query: API_CALLS.PRODUCTS.QUERIES.single(),
      variables: {id: this.props.match.params.PID}
    })
    .then(result => {
      //console.log("result.data.product");
      //console.log(result.data.product,"\n\n");
      window.GlobalUtil.addToCart({product: result.data.product, replaceAll: true}, ()=>{
        if(result.data.product.language) window.GlobalUtil.triggerEvent("LanguageEvent", result.data.product.language)
        else if(this.state) this.setState({date: new Date()});
      })     
      return true;
    })
    .catch((error)=>{
      console.log("catch", error);
      return false;
    })
  }


  onSuccessCheckout=(order)=>{
    //window.GlobalUtil.consoleLog("onSuccessCheckout RAN", [order]);
    //const NewOrderID = order.id;
  }

  onFailCheckout=(failed)=>{
    //window.GlobalUtil.consoleLog("onFailCheckout RAN", [failed]);
  }

  render(){
    var {curStatus} = this.state;          
    const CONTENT = window.GlobalUtil.dangerSetGlobalObjList(CONTENTS[window.Session.Language].CONTENT); 
    const HEADER = CONTENTS[window.Session.Language].HEADER; 
    //var {showSideMenu} = this.state;
    return (
      <div className="contentPage" id="PageCheckout">
        <Helmet>
            <meta charSet="utf-8" />
            <title>{HEADER.TITLE}</title>
            <meta name="description" content={HEADER.DESCRIPTION}/>
            <link  href={`${this.props.location.pathname}`} />
            <link rel="canonical" href={`${HEADER.BASEURL}${this.props.location.pathname}`} />
            <meta name="keywords" content={HEADER.KEYWORDS} />

            {"<!-- Google / Search Engine Tags -->"}
            <meta itemprop="name" content={HEADER.TITLE} />
            <meta itemprop="description" content={HEADER.DESCRIPTION}/>
            <meta itemprop="image" content="https://Smiles.Club/SocialImage_500.jpg" />

            {/*<!-- Facebook Meta Tags -->*/}
            <meta property="og:url" content={`${HEADER.BASEURL}${this.props.location.pathname}`} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={HEADER.TITLE} />
            <meta property="og:description" content={HEADER.DESCRIPTION}/>
            <meta property="og:image" content="https://Smiles.Club/SocialImage_500.jpg" />

            {/*<!-- Twitter Meta Tags -->*/}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={HEADER.TITLE} />
            <meta name="twitter:description" content={HEADER.DESCRIPTION}/>
            <meta name="twitter:image" content="https://Smiles.Club/SocialImage_500.jpg" />
            
            <meta name="robots" content="noindex" /> {/*<!-- This will prevent indexing -->*/}
        </Helmet>
        <div className="pageCheckoutHeader">
          <Container className="">
            <Row className="title">
              <Column className="" col="" xs="" sm="" md="" lg="" xl="">
                {CONTENT.PageCheckout[0]}
              </Column>
            </Row>
          </Container>
        </div>
        <CheckoutPage
          onSuccess={this.onSuccessCheckout}
          onFail={this.onFailCheckout}
        />
      </div>
    );
  }
}











export default Index;
      
