import React, { useState, useRef } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import {NavLink, Link} from 'react-router-dom';
import FormChiefComplaint from "./Form_ChiefComplaint";
import FormMouthPicture from "./Form_MouthPicture";
import InitialPics1 from "global/img/initialPics/initial_1.jpg";
import InitialPics2 from "global/img/initialPics/initial_2.jpg";
import InitialPics3 from "global/img/initialPics/initial_3.jpg";
import InitialPics4 from "global/img/initialPics/initial_4.jpg";
import InitialPics5 from "global/img/initialPics/initial_5.jpg";
import InitialPics6 from "global/img/initialPics/initial_6.jpg";
import InitialPics7 from "global/img/initialPics/initial_7.jpg";
import InitialPics8 from "global/img/initialPics/initial_8.jpg";
import API_CALLS from 'API_CALLS/index';
import ESPAÑOL from "./ESPAÑOL";
import ENGLISH from "./ENGLISH";
const CONTENTS = {
  ESPAÑOL,
  ENGLISH
}
const MUTATION_EDIT = API_CALLS.TREATMENT_PLANS.MUTATIONS.update();
const {Container, Row, Column} = window.LayoutBuilder;









class TreatementPlan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      testCurrentUser: {},
      curObj: {
        id: window.GlobalUtil.deepGetFromString(window, 'Session,user,treatmentPlan,_id', null),
        offerRequest: window.GlobalUtil.deepGetFromString(window, 'Session,user,treatmentPlan,offerRequest', {}),
        //smouthPictures: window.GlobalUtil.deepGetFromString(window, 'Session,user,treatmentPlan,offerRequest,mouthPictures', ''),
        //schiefComplaint: window.GlobalUtil.deepGetFromString(window, 'Session,user,treatmentPlan,offerRequest,chiefComplaint', ''),
        UID: window.GlobalUtil.deepGetFromString(window, 'Session,user,_id', null),
      }
    }

    if(!this.state.curObj.offerRequest.mouthPictures || (this.state.curObj.offerRequest.mouthPictures.length < 8)){
      var newMouthPlaceholder = new Array(8).fill({
        url: '',
        name: '',
        type: '',
        order: '',
        customName: ''
      });
      this.state.curObj.offerRequest.mouthPictures = [];
            
      this.state.curObj.offerRequest.mouthPictures = newMouthPlaceholder.slice().map((object, index)=>{
        var newObj = {...object};
        if(this.state.curObj.offerRequest.mouthPictures[index]){
          newObj = {...this.state.curObj.offerRequest.mouthPictures[index]};
        }
        var customName = `initialPic ${index+1}`;
        newObj.customName = customName;
        newObj.order = `${index}`;
        return newObj;
      })
    }

  }

  componentDidMount(){

  }

  componentWillUnmount(){
  }

  GetIndexByName = (mouthPictures, customName)=>{
    var curIndex = -1;
    mouthPictures.map((object, index)=>{
      if(object.customName === customName) curIndex = index;
    })
    return curIndex;
  }


  APIUpdateCurTreatmentPlan = (newPlanObj, callBack=()=>{}) => {
    window.Client.mutate({ //API UPDATE
      mutation: MUTATION_EDIT,
      variables: newPlanObj
    }) 
    .then((obj)=>{
      //window.GlobalUtil.consoleLog("onNewImageSelected window.CurObj.images", []);
      //if(window.setCurObj) window.setCurObj(window.CurObj);
      //if(window.CurRefetchEdit) window.CurRefetchEdit(); //RELOAD API FROM CACHE WITH NEW VALUES
      var cleanTreatmentPlan = window.GlobalUtil.APICleanObj(obj.data.updateTreatmentPlan);
      this.setState({curObj: {
        ...this.state.curObj,
        offerRequest: cleanTreatmentPlan.offerRequest
      }}, ()=>{
        window.ForceUserReload();
        callBack();
      })
    })
    .catch((error)=>{
      console.log("APIUpdateCurTreatmentPlan error saving image");
      console.log(error,"\n\n");  
    });
  }

  onSubmitContact = (newObj) => {
    var {curObj={}} = this.state;
    clearTimeout(this.onSubmitContactTimeout);
    this.onSubmitContactTimeout = setTimeout(()=>{
      //console.log(`TreatementPlan onSubmitContact newObj`,"\n\n",newObj,"\n\n");
      curObj.offerRequest.chiefComplaint = newObj;
      this.APIUpdateCurTreatmentPlan(curObj);
    }, 600);
  }//customName


  updateImages = (newImageObjTemp, index) => {
    //console.log(`updateImages`,"\n\n",newImageObjTemp,"\n\n");
    var {curObj={}} = this.state;
    var {offerRequest} = curObj;
    //ADD IMAGES TO ARRAY, OR REMOVE FROM ARRAY, OR REPLACE WITH NEW IMAGE
    if(!newImageObjTemp){ //IF NO IMAGE OBJ THEN DELETE IT FROM THE LIST OF IMAGES
      //GET INDEX OF CURRENT IMAGE AND DELETE FROM LIST
      offerRequest.mouthPictures[index].url = '';
      offerRequest.mouthPictures[index].name = '';
      offerRequest.mouthPictures[index].type = '';
    } else {
      //GET INDEX OF CURRENT IMAGE AND REPLACE WITH NEW ONE
      offerRequest.mouthPictures[index] = {...offerRequest.mouthPictures[index], ...newImageObjTemp};
    }


    curObj = {...curObj, offerRequest: offerRequest};
    //this.setState({curObj});
    //API UPDATE CURRENT OBJ
          
    //GET API AGAIN
    this.APIUpdateCurTreatmentPlan(curObj, ()=>{
      //THIS RELOADS THE IMAGES SO IT CAN HAVE THE DELETE X. IT'S NOT NEEDED FOR ANYTHING IMPORTANT.
      this.setState({loadNothing: true}, ()=>{
        this.setState({loadNothing: false});
      });
    });
  }


  render(){
    var {testCurrentUser={}, curObj={}, loadNothing} = this.state;          
    var {data={}} = this.props;
    if(!curObj.id || loadNothing) return null;
    const CONTENT = window.GlobalUtil.dangerSetGlobal(CONTENTS[window.Session.Language]);           
    return (
      <div id="RequestPlan">
        <Row className="planSummary">
          <Column className="matchHeight" col="" xs="" sm="" md="" lg="" xl="">
            <div className="fullHeight component">
              <div className="itemHeader d-flex">
                <div className="itemHeaderTextZone">
                  <div className="itemHeaderText">{CONTENT[0]}</div>
                  <div className="itemHeaderSubText">{CONTENT[1]}</div>
                </div>
              </div>
              <div className="" style={{"padding":"0px 20px"}}>
                <div className="initialPictures">
                  {/*<FormMouthPicture
                    placeHolderText={<PlaceHolderText/>}
                    curObj={curObj}
                    CONTENT={{
                      "title": CONTENT[2],
                      "desc": CONTENT[3],
                    }}
                    index={0}
                    onChangeImage={(newImageObj)=>this.updateImages(newImageObj, 0)}
                    placeHolderImage={<img src={InitialPics1}/>}
                  />
                  <FormMouthPicture
                    placeHolderText={<PlaceHolderText/>}
                    curObj={curObj}
                    CONTENT={{
                      "title": CONTENT[4],
                      "desc": CONTENT[5], 
                    }}
                    index={1}
                    onChangeImage={(newImageObj)=>this.updateImages(newImageObj, 1)}
                    placeHolderImage={<img src={InitialPics2}/>}
                  />
                  <FormMouthPicture
                    placeHolderText={<PlaceHolderText/>}
                    curObj={curObj}
                    CONTENT={{
                      "title": CONTENT[6],
                      "desc": CONTENT[7], 
                    }}
                    index={2}
                    onChangeImage={(newImageObj)=>this.updateImages(newImageObj, 2)}
                    placeHolderImage={<img src={InitialPics3}/>}
                  />*/}
                  <FormMouthPicture
                    placeHolderText={<PlaceHolderText/>}
                    curObj={curObj}
                    CONTENT={{
                      "title": CONTENT[8],
                      "desc": CONTENT[9], 
                    }}
                    index={3}
                    onChangeImage={(newImageObj)=>this.updateImages(newImageObj, 3)}
                    placeHolderImage={<img src={InitialPics4}/>}
                  />
                  <FormMouthPicture
                    placeHolderText={<PlaceHolderText/>}
                    curObj={curObj}
                    CONTENT={{
                      "title": CONTENT[10],
                      "desc": CONTENT[11], 
                    }}
                    index={4}
                    onChangeImage={(newImageObj)=>this.updateImages(newImageObj, 4)}
                    placeHolderImage={<img src={InitialPics5}/>}
                  />
                  <FormMouthPicture
                    placeHolderText={<PlaceHolderText/>}
                    curObj={curObj}
                    CONTENT={{
                      "title": CONTENT[12],
                      "desc": CONTENT[13], 
                    }}
                    index={5}
                    onChangeImage={(newImageObj)=>this.updateImages(newImageObj, 5)}
                    placeHolderImage={<img src={InitialPics6}/>}
                  />
                  <FormMouthPicture
                    placeHolderText={<PlaceHolderText/>}
                    curObj={curObj}
                    CONTENT={{
                      "title": CONTENT[14],
                      "desc": CONTENT[15], 
                    }}
                    index={6}
                    onChangeImage={(newImageObj)=>this.updateImages(newImageObj, 6)}
                    placeHolderImage={<img src={InitialPics7}/>}
                  />
                  <FormMouthPicture
                    placeHolderText={<PlaceHolderText/>}
                    curObj={curObj}
                    CONTENT={{
                      "title": CONTENT[16],
                      "desc": CONTENT[17], 
                    }}
                    index={7}
                    onChangeImage={(newImageObj)=>this.updateImages(newImageObj, 7)}
                    placeHolderImage={<img src={InitialPics8}/>}
                  />
                </div>

                <FormChiefComplaint
                  curObj={curObj}
                  onChange={this.onSubmitContact}
                  CONTENT={{
                    "title": CONTENT[18],
                    "desc": CONTENT[19], 
                  }}
                />
              </div>
            </div>
          </Column>
        </Row>
      </div>
    );
  }
}






const PlaceHolderText = ()=>{
  return(
    <div>Click to upload Selfie</div>
  )
}






export default TreatementPlan;
      




