//import React from 'react';
const PAGE = {
  	"ENGLISH": {
		"HEADER": {
			"BASEURL": "https://smiles.club",
			"TITLE": "Smiles Club | Let us answer all your Clear and Invisible Aligner Questions",
			"DESCRIPTION": "Are clear aligners worth it? Can clear aligners fix an underbite, overbite, or crowded teeth? Smiles Club Answers questions about invisible orthodontics.",
			"KEYWORDS": "Teeth straightening at home, affordable, clear brace price, invisible braces cost, best aligners, safest aligners, clear aligners safe, BPA, How Invisible Braces work"
		},
		"CONTENT": {
			"Section1": [
				{
					"type": "div",
					"content": `<div class="preHeader">Q and A</div>
							<h1 class="sectionTitle">Frequently asked questions</h1>
							<div class="">Check out some of our most common frequently asked questions. And check out our video to learn more about us, the process, and even how we came to be.</div>
							<br/>`
				}
			],
			"Accordion": [
				{
					"title": "How do Smiles Club's clear aligners straighten teeth?",
					"text": "Our clear aligners come as a set of multiple aligners. Each aligner will make slight adjustments to tooth position, a process that is mapped out in advance by a state licensed dentist or orthodontist who reviews your case. You'll begin wearing a new set of aligners as prescribed, advancing you to the next stage of your new smile. We make it even easier by shipping your aligners directly to you."
				},
				{
					"title": "Are clear aligners worth it?",
					"text": <div>
						<p>YES! Clear aligners can be highly effective in treating mild to moderate teeth misalignments, crowding, and spacing issues. They have successfully helped many people achieve straighter teeth and improved dental alignment. </p>
						<p>They are also discreate which people love. One of the significant advantages of clear aligners is their nearly invisible appearance. Unlike traditional braces, clear aligners are made from transparent material, making them a preferred choice for individuals who prefer a more discreet orthodontic treatment option.</p>
					</div>
				},
				{
					"title": "How much are clear aligners? How much do clear aligners cost?",
					"text": <div>
						<p>Our aligners are $2,000 or less while other companies charge between $2,000-$4,000 dollars. Many Invisible Aligner companies will offer an Impression Kit at a cheap price, and not show the full price until the user's taken the impression and sent it back. Others show a "Cheap" front cost but surprise the customers with hidden fees, and hidden charges.</p>
						<p>Hidden costs can be an unwelcome surprise, lurking beneath the surface of various transactions and services. These concealed expenses, not immediately apparent at the onset, can add up and significantly impact one's overall budget and financial planning.</p>
						<p><strong>SMILES CLUB IS PROUD TO HAVE NO HIDDEN FEES OR SURCHARGES AND OUR PRICES ARE TRANSPARENT!</strong> Plus, we offer a money back guarantee if the user does not qualify.</p>
					</div>
				},
				{
					"title": "Which clear aligners cheapest?",
					"text": <div>
						<p>Clear aligners are FAR LESS EXPENSIVE than traditional braces. The cost can vary depending on the complexity of the case and the provider. As Smiles Club we charge the same for every case regardless of how complex it may be. We also offer pay later with paypal for those who could use help financing.</p>
						<p>As for the best and cheapest clear aligners, it's essential to remember that different aligner brands and providers offer varying features, treatment quality, and pricing. What may be the best option for one person might not be the same for another, and the cheapest option might not always provide the desired outcomes. It's crucial to research different clear aligner companies, read reviews, and consult with professionals to find the option that suits your needs and budget best.</p>
						<p>Just make sure whatever company you chose uses only Non-Toxic plastics and is USA Quality.</p>
					</div>
				},
				{
					"title": "Which clear aligners are the best?",
					"text": <div>
						<p>Smiles Club is the best! Without a doubt. Well, okay we might be bias in saying that.</p>
						<p>Most big brands use the same process to create clear aligners for customers. However, there are cases where certain companies may use cheaper materials that may cause harm. This is why it is important to know that whoever you're working with is not using any toxic cheap plastics, and is a high quality American Company.</p>
						<p>Smiles Club is a USA company that only uses the same high quality products used by Orthodontics and Dentist in the USA.</p>
					</div>
				},
				{
					"title": "Benefits of clear aligners?",
					"text": <div>
						<p>Benefits of clear aligners include their nearly invisible appearance, which offers a more discreet treatment option compared to traditional braces. They are also removable, making oral hygiene easier, and allow you to eat and drink without restrictions during treatment.</p>
						<p>Clear aligners are custom-made for a comfortable fit and can be effective in correcting mild to moderate teeth misalignments. They can be more convenient than traditional braces, because you can take them off when needed. Because Clear aligners are removable they allowing you to eat and drink without any restrictions. They also make oral hygiene routines easier as you can brush and floss your teeth as usual.</p>
						<p>Another advantage is Invisible Aligners tend to be discreet. One of the significant advantages of clear aligners is their nearly invisible appearance. Unlike traditional braces, clear aligners are made from transparent material, making them a preferred choice for individuals who prefer a more discreet orthodontic treatment option.</p>
						<p>There are so many advantages to clear aligners, its hard to list them all.</p>
					</div>
				},
				{
					"title": "Can clear aligners fix an underbite?",
					"text": <div>
						<p><strong>YES!</strong></p>
						<p>Clear aligners can be effective in addressing mild underbites. HOWEVER, if a case is TOO serious, or our doctors decide it would be better for you to have personal supervision from a local physician, this could be a reason why someone wouldn't qualifying. In that case we will explain what our doctors decided and return their money in full.</p>
						<p>Some other companies may take on these cases at the expense of a customer in order to make the sale. But here at Smiles Club we will gladly give up a sale if it means keeping our customers safe and giving them the best service possible.</p>
					</div>
				},
				{
					"title": "Can clear aligners fix overbite?",
					"text": <div>
						<p><strong>YES!</strong></p>
						<p>Clear aligners can be effective in correcting mild overbite. HOWEVER, for severe orthodontic issues, traditional braces or other treatments may be recommended. If our doctors decide it would be better for you to have personal supervision from a local physician, this could be a reason why someone wouldn't qualifying. In that case we will explain what our doctors decided and return their money in full.</p>
						<p>Some other companies may take on these cases at the expense of a customer in order to make the sale. But here at Smiles Club we will gladly give up a sale if it means keeping our customers safe and giving them the best service possible.</p>
					</div>
				},
				{
					"title": "Can clear aligners fix crowded teeth?",
					"text": <div>
						<p><strong>YES!</strong></p>
						<p>Clear aligners have proven to be an effective solution for fixing crowded teeth, offering a discreet and comfortable alternative to traditional braces. Crowded teeth occur when there is insufficient space in the jaw for all teeth to align properly, causing them to overlap or become misaligned. Clear aligners work by using a series of custom-made, transparent trays that gently shift the teeth into their desired positions over time.</p>
						<p>Each aligner in the series applies precise and gradual pressure to guide the teeth into alignment. As the treatment progresses, the crowded teeth gradually move into their optimal positions, creating a harmonious and straighter smile. Invisible aligners can address crowded teeth effectively and deliver remarkable results, giving individuals the confidence of a beautiful and properly aligned smile.</p>
						<p>The success of clear aligners depends on wearing them consistently as prescribed by the orthodontist. If you can commit to wearing them for the recommended duration each day, you are more likely to achieve the desired results</p>
					</div>
				},
				{
					"title": "Are clear aligners safe?",
					"text": <div>
						<p><strong>YES!</strong></p>
						<p>Clear aligners are considered safe when used under the guidance of a qualified orthodontist or dental professional. However, like any dental treatment, there can be risks and side effects.</p>
						<p>It's essential to follow the instructions provided by the orthodontist, wear the aligners as prescribed.</p>
					</div>
				},
				{
					"title": "Are attachments or buttons required to move teeth with Smiles Club's clear aligners?",
					"text": "Unlike some other brands, our aligners straighten your teeth without the need for attachments or buttons, which are generally considered unsightly or uncomfortable."
				},
				{
					"title": "Can I use Smiles Club's clear aligners if I have missing teeth?",
					"text": "Our aligners can work for some customers who have missing teeth. The licensed dentist or orthodontist assigned to you will assess your smile and determine if Smiles Club's aligners are the best fit for you. If they determine they're not the best fit for your needs, the cost of your impression kit is fully refundable."
				},
				{
					"title": "Is the cost of my impression kit refundable?",
					"text": "Remote clear aligner therapy isn't right for everyone. That's why we'll refund the cost of your impression kit if the dentist or orthodontist determines our clear aligners aren't a fit for you."
				},
				{
					"title": "What if I make a mistake on my impressions?",
					"text": "If our lab receives distorted impressions that prevent them from making accurate aligners, we will contact you regarding sending another impression kit. We'll send you a retake kit. First retake kit is free."
				},
				{
					"title": "Does a licensed dentist or orthodontist review my case?",
					"text": "Yes. Your new smile will be reviewed, modified (if necessary), and approved by a state licensed dentist or orthodontist."
				},
				{
					"title": "Can I use Smiles Club's clear aligners on only my upper or lower teeth?",
					"text": "It's possible. The state-licensed dentist or orthodontist assigned to your case will decide what the safest and best treatment option is for you."
				},
				{
					"title": "How often should I wear my aligners?",
					"text": "Wear your aligners as directed by your prescribed treatment plan. Take them out for eating and drinking."
				},
				{
					"title": "Will my teeth hurt during treatment?",
					"text": "Much like the days after a good workout at the gym, most people experience some tooth soreness for a few days after wearing new aligners. This is perfectly normal. It is a sign that your aligners are working, gently moving your teeth to their final destination. This soreness should gradually go away after the first few days of wearing new aligners. If the pain doesn't go away, contact our customer care group."
				},
				{
					"title": "What happens if I lose an aligner?",
					"text": "We understand that mistakes happen. For starters, give our customer care group a call. Your treating doctor and our dental care group may advise you to wear your last set of aligners or to move on to the next set. If we determine you need a replacement aligner, we will send the first set to you for free. Subsequent replacement aligners may be subject to a $99 surcharge depending on the circumstances. "
				},
				{
					"title": "What happens after my clear aligner treatment is complete?",
					"text": "We strongly recommend you safeguard your new smile with our custom-fit Smiles Club's clear retainers. Without retainers, teeth have a tendency to shift back to their original positions. Keep them from moving back with our custom-fit retainers. Call our customer care about 4-5 weeks before you finish your treatment plan to place your order."
				},
				{
				  "title": `Can I use my HSA or FSA account to pay for clear aligners?`,
				  "text": <div>Invisible Aligners for teeth straightening are an orthodontic service, which is covered by most of HSA & FSA accounts.
					  <br/><br/>You can use your HSA & FSA to pay for a wide range of treatments and procedures, including preventative care, basic dental care, and orthodontics. So, your semi-annual cleanings, fillings, root canals, braces, and clear aligners are typically fair game.
					  <br/><br/>Every HAS & FSA plan is different, so some might have a more comprehensive list of qualifying expenses than others. You should check your HSA’s or FSA’s policy before beginning treatment to make sure that you can use it.
					</div>
				},
				{
				  "title": `Can I finance my treatment?`,
				  "text": `Yes! Smiles Club is integrated with PayPay "Buy now, pay later" program. You can choose to split the cost into 4 bi-weekly payments or a 6, 12 or 24-month payment plan with $0 down.`
				}
			]
		}
	},
	"ESPAÑOL": {
		"HEADER": {
			"BASEURL": "https://sonrisas.club",
			"TITLE": "Sonrisas Club | Respondamos a todas sus preguntas sobre alineadores transparentes e invisibles",
			"DESCRIPTION": "¿Valen la pena los alineadores transparentes? ¿Pueden los alineadores transparentes corregir una mordida inferior, una sobremordida o dientes apiñados? Club de Sonrisas Responde preguntas sobre ortodoncia invisible.",
			"KEYWORDS": "Enderezamiento de dientes en casa, asequible, precio de ortodoncia transparente, costo de ortodoncia invisible, mejores alineadores, alineadores más seguros, alineadores transparentes seguros, BPA, Cómo funcionan las ortodoncias invisibles"
		},
		"CONTENT": {
			"Section1": [
				{
					"type": "div",
					"content": `<h1 class="sectionTitle">Preguntas más frecuentes</h1>
							<h2 class="sectionTitle2">Algunas de nuestras preguntas frecuentes más comunes</h2>`
				}
			],
			"Accordion": [
				{
					"title": "¿Cómo enderezan los dientes los alineadores transparentes de Smiles Club?",
					"text": "Nuestros alineadores transparentes vienen como un conjunto de alineadores múltiples. Cada alineador realizará pequeños ajustes en la posición de los dientes, un proceso que un dentista u Ortodoncista autorizado por el estado traza con anticipación. Comenzarás a usar un nuevo juego de alineadores según lo prescrito, lo que te llevará a la siguiente etapa de tu nueva sonrisa. Hacemos que sea aún más fácil enviándote tus alineadores directamente a tu domicilio."
				},
				,
				{
					"title": "¿Valen la pena los alineadores transparentes?",
					"text": <div>
						<p>¡SÍ! Los alineadores transparentes pueden ser muy efectivos en el tratamiento de problemas de alineación, apiñamiento y espacio entre dientes de leves a moderados. Han ayudado con éxito a muchas personas a lograr dientes más rectos y una mejor alineación dental.</p>
						<p>También son discretos lo que la gente ama. Una de las ventajas significativas de los alineadores transparentes es su apariencia casi invisible. A diferencia de los frenos tradicionales, los alineadores transparentes están hechos de material transparente, lo que los convierte en la opción preferida para las personas que prefieren una opción de tratamiento de ortodoncia más discreta.</p>
					</div>
				},
				{
					"title": "¿Cuánto cuestan los alineadores transparentes? ¿Cuánto cuestan los alineadores transparentes?",
					"text": <div>
						<p>En promedio, los Clear Aligners le costarán entre $2,000 y $4,000 dólares. Muchas empresas de Invisible Aligner ofrecerán un kit de impresión a un precio económico y no mostrarán el precio completo hasta que el usuario haya tomado la impresión y la haya devuelto. Otros muestran un costo inicial "económico" pero sorprenden a los clientes con tarifas ocultas y cargos ocultos.</p>
						<p>Los costos ocultos pueden ser una sorpresa desagradable, al acecho debajo de la superficie de varias transacciones y servicios. Estos gastos ocultos, que no son evidentes de inmediato al principio, pueden acumularse y tener un impacto significativo en el presupuesto general y la planificación financiera de una persona.</p>
						<p><strong>¡SMILES CLUB SE ORGULLECE DE NO TENER CARGOS O RECARGOS OCULTOS Y NUESTROS PRECIOS SON TRANSPARENTES!</strong> Además, ofrecemos una garantía de devolución de dinero si el usuario no califica.</p>
					</div>
				},
				{
					"title": "¿Qué alineadores transparentes son los más baratos?",
					"text": <div>
						<p>Los alineadores transparentes son MUCHO MENOS CAROS que los frenos tradicionales. El costo puede variar dependiendo de la complejidad del caso y del proveedor. Como Club Smiles cobramos lo mismo para cada caso sin importar cuán complejo sea. También ofrecemos pagar más tarde con paypal para aquellos que necesitan ayuda financiera.</p>
						<p>En cuanto a los mejores y más baratos alineadores transparentes, es esencial recordar que las diferentes marcas y proveedores de alineadores ofrecen diferentes características, calidad de tratamiento y precios. Lo que puede ser la mejor opción para una persona puede no ser lo mismo para otra, y la opción más barata puede no proporcionar siempre los resultados deseados. Es crucial investigar diferentes compañías de alineadores transparentes, leer reseñas y consultar con profesionales para encontrar la opción que mejor se adapte a sus necesidades y presupuesto.</p>
						<p>Solo asegúrese de que la compañía que elija use solo plásticos no tóxicos y sea de calidad estadounidense.</p>
					</div>
				},
				{
					"title": "¿Qué alineadores transparentes son los mejores?",
					"text": <div>
						<p>Sonrisas Club es lo mejor! Sin duda. Bueno, está bien, podríamos ser parciales al decir eso.</p>
						<p>La mayoría de las grandes marcas utilizan el mismo proceso para crear alineadores transparentes para los clientes. Sin embargo, hay casos en los que ciertas empresas pueden utilizar materiales más baratos que pueden causar daños. Por eso es importante saber que cualquiera que sea la persona con la que esté trabajando no utiliza plásticos tóxicos baratos y es una empresa estadounidense de alta calidad.</p>
						<p>Sonrisas Club es una empresa de USA. que solo utiliza los mismos productos de alta calidad que utiliza Ortodoncia y Dentista en USA.</p>
					</div>
				},
				{
					"title": "¿Beneficios de los alineadores transparentes?",
					"text": <div>
						<p>Los beneficios de los alineadores transparentes incluyen su apariencia casi invisible, que ofrece una opción de tratamiento más discreta en comparación con los frenos tradicionales. Además son removibles, facilitando la higiene bucal, y te permiten comer y beber sin restricciones durante el tratamiento.</p>
						<p>Los alineadores transparentes están hechos a medida para un ajuste cómodo y pueden ser efectivos para corregir desalineaciones de dientes leves a moderadas. Pueden ser más convenientes que los aparatos ortopédicos tradicionales, porque puede quitárselos cuando sea necesario. Debido a que los alineadores transparentes son removibles, le permiten comer y beber sin restricciones. También facilitan las rutinas de higiene bucal, ya que puede cepillarse los dientes y usar hilo dental como de costumbre.</p>
						<p>Otra ventaja es que los alineadores invisibles tienden a ser discretos. Una de las ventajas significativas de los alineadores transparentes es su apariencia casi invisible. A diferencia de los aparatos ortopédicos tradicionales, los alineadores transparentes están hechos de material transparente, lo que los convierte en la opción preferida para las personas que prefieren una opción de tratamiento de ortodoncia más discreta.</p>
						<p>Los alineadores transparentes tienen tantas ventajas que es difícil enumerarlas todas.</p>
					</div>
				},
				{
					"title": "¿Pueden los alineadores transparentes corregir una mordida inferior?",
					"text": <div>
						<p><strong>¡SÍ!</strong></p>
						<p>Los alineadores transparentes pueden ser efectivos para tratar las mordidas leves. SIN EMBARGO, si un caso es DEMASIADO grave, o si nuestros médicos deciden que sería mejor para usted tener la supervisión personal de un médico local, esta podría ser una razón por la cual alguien no calificaría. En ese caso, le explicaremos lo que decidieron nuestros médicos y le devolveremos el dinero en su totalidad.</p>
						<p>Algunas otras empresas pueden hacerse cargo de estos casos a expensas de un cliente para realizar la venta. Pero aquí en Smiles Club con gusto renunciaremos a una venta si eso significa mantener seguros a nuestros clientes y brindarles el mejor servicio posible.</p>
					</div>
				},
				{
					"title": "¿Pueden los alineadores transparentes corregir la sobremordida?",
					"text": <div>
						<p><strong>¡SÍ!</strong></p>
						<p>Los alineadores transparentes pueden ser eficaces para corregir la sobremordida leve. SIN EMBARGO, para problemas de ortodoncia severos, se pueden recomendar aparatos ortopédicos tradicionales u otros tratamientos. Si nuestros médicos deciden que sería mejor para usted contar con la supervisión personal de un médico local, esta podría ser una razón por la cual alguien no calificaría. En ese caso, le explicaremos lo que decidieron nuestros médicos y le devolveremos el dinero en su totalidad.</p>
						<p>Algunas otras empresas pueden hacerse cargo de estos casos a expensas de un cliente para realizar la venta. Pero aquí en Smiles Club con gusto renunciaremos a una venta si eso significa mantener seguros a nuestros clientes y brindarles el mejor servicio posible.</p>
					</div>
				},
				{
					"title": "¿Pueden los alineadores transparentes arreglar los dientes apiñados?",
					"text": <div>
						<p><strong>¡SÍ!</strong></p>
						<p>Los alineadores transparentes han demostrado ser una solución eficaz para reparar los dientes apiñados y ofrecen una alternativa discreta y cómoda a los aparatos ortopédicos tradicionales. Los dientes apiñados ocurren cuando no hay suficiente espacio en la mandíbula para que todos los dientes se alineen correctamente, lo que hace que se superpongan o se desalineen. Los alineadores transparentes funcionan mediante el uso de una serie de cubetas transparentes hechas a medida que desplazan suavemente los dientes a las posiciones deseadas con el tiempo.</p>
						<p>Cada alineador de la serie aplica una presión precisa y gradual para guiar los dientes hacia la alineación. A medida que avanza el tratamiento, los dientes apiñados se mueven gradualmente a sus posiciones óptimas, creando una sonrisa armoniosa y más recta. Los alineadores invisibles pueden tratar los dientes apiñados de manera efectiva y brindar resultados notables, brindando a las personas la confianza de una sonrisa hermosa y correctamente alineada.</p>
						<p>El éxito de los alineadores transparentes depende de su uso constante según lo prescrito por el ortodoncista. Si puede comprometerse a usarlos durante el tiempo recomendado cada día, es más probable que logre los resultados deseados</p>
					</div>
				},
				{
					"title": "¿Son seguros los alineadores transparentes?",
					"text": <div>
						<p><strong>¡SÍ!</strong></p>
						<p>Los alineadores transparentes se consideran seguros cuando se usan bajo la supervisión de un ortodoncista calificado o un profesional dental. Sin embargo, como cualquier tratamiento dental, puede haber riesgos y efectos secundarios.</p>
						<p>Imprescindible seguir las indicaciones del ortodoncista, llevar los alineadores según prescripción médica.</p>
					</div>
				},
				{
					"title": "¿Se requieren ataches o botones para mover los dientes con los alineadores transparentes de Smiles Club?",
					"text": "A diferencia de otras marcas, nuestros alineadores enderezan los dientes sin necesidad de ataches o botones, que generalmente se consideran antiestéticos o incómodos."
				},
				{
					"title": "¿Puedo usar los alineadores transparentes de Smiles Club si me faltan dientes?",
					"text": "Nuestros alineadores pueden funcionar para algunos clientes a los que les faltan dientes. El dentista u Ortodoncista con licencia que se te asigne evaluará yu sonrisa y determinará si los alineadores de Smiles Club son los más adecuados para ti. Si determinan que no son los más adecuados para tus necesidades, el costo de tu kit de impresión es totalmente reembolsable."
				},
				{
					"title": "¿Es reembolsable el costo de mi kit de impresión?",
					"text": "Si, la terapia con alineador transparente remoto no es adecuada para todos. Es por eso que te reembolsaremos el costo de tu kit de impresión si el dentista u Ortodoncista determina que nuestros alineadores transparentes no son adecuados para ti."
				},
				{
					"title": "¿Qué pasa si me equivoco en mis impresiones?",
					"text": "Si nuestro laboratorio recibe impresiones distorsionadas que les impiden hacer alineadores precisos, nos comunicaremos contigo para enviarte otro kit de impresión. El primer kit adicional es gratis."
				},
				{
					"title": "¿Un dentista u Ortodoncista con licencia revisa mi caso?",
					"text": "Si. Su nueva sonrisa será revisada, modificada (si es necesario) y aprobada por un dentista u Ortodoncista con licencia estatal."
				},
				{
					"title": "¿Puedo usar los alineadores transparentes de Smiles Club solo en mis dientes superiores o inferiores?",
					"text": "Es posible. El dentista u Ortodoncista con licencia estatal asignado a su caso decidirá cuál es la mejor y más segura opción de tratamiento para ti."
				},
				{
					"title": "¿Con qué frecuencia debo usar mis alineadores?",
					"text": "Use sus alineadores según las indicaciones de su plan de tratamiento prescrito. Sácatelos para comer y beber."
				},
				{
					"title": "¿Me dolerán los dientes durante el tratamiento?",
					"text": "Al igual que los días posteriores a un buen entrenamiento en el gimnasio, la mayoría de las personas experimentan dolor en los dientes durante unos días después de usar alineadores nuevos. Esto es perfectamente normal. Es una señal de que los alineadores están funcionando, moviendo suavemente tus dientes hacia su destino final. Este dolor debe desaparecer gradualmente después de los primeros días de usar alineadores nuevos. Si el dolor no desaparece, comunícate con nuestro grupo de atención al cliente."
				},
				{
					"title": "¿Qué pasa si pierdo un alineador?",
					"text": "Entendemos que ocurren errores. Para empezar, llama a nuestro grupo de atención al cliente. Tu dentista o nuestro grupo de atención dental pueden recomendarte que uses tu último juego de alineadores o que pases al siguiente juego. Si determinamos que necesitas un alineador de reemplazo, te enviaremos el primer juego de forma gratuita. Los alineadores de reemplazo posteriores pueden estar sujetos a un recargo de $99 según las circunstancias."
				},
				{
					"title": `¿Puedo usar mi cuenta HSA o FSA para pagar alineadores transparentes?`,
					"text": <div>Los alineadores invisibles para enderezar los dientes son un servicio de ortodoncia que está cubierto por la mayoría de las cuentas HSA y FSA.
						<br/><br/>Puede usar su HSA y FSA para pagar una amplia gama de tratamientos y procedimientos, incluida la atención preventiva, la atención dental básica y la ortodoncia. Por lo tanto, sus limpiezas semestrales, empastes, endodoncias, aparatos ortopédicos y alineadores transparentes suelen ser un juego justo.
						<br/><br/>Cada plan HAS y FSA es diferente, por lo que algunos pueden tener una lista más completa de gastos calificados que otros. Debe consultar la política de su HSA o FSA antes de comenzar el tratamiento para asegurarse de que puede usarlo.
					</div>
				},
				{
					"title": `¿Puedo financiar mi tratamiento?`,
					"text": `¡Sí! Smiles Club está integrado con el programa de PayPay "Buy now, pay later". Puede elegir dividir el costo en 4 pagos quincenales o un plan de pago de 6, 12 o 24 meses con $0 de pago inicial.`
				}
			]
		}
	}
};


	
export default PAGE;