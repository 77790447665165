import React, { useState, useRef } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';

const {Container, Row, Column} = window.LayoutBuilder;


export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };    
    this.getAPI = this.getAPI.bind(this);
  }

  componentDidMount(){
    Promise.all(this.props.reports.map((reportObj, index)=>{
      return this.getAPI(reportObj.QUERY, reportObj.VARIABLES, reportObj.NAME, index)
    }));
  }

  componentWillUnmount(){
  }


  getAPI(QUERY, VARIABLES, NAME, index){
    return window.Client.query({
      query: QUERY,
      variables: VARIABLES
    })
    .then(result => {      
      if(!this) return;      
      this.setState({
        [NAME+index]: result.data[NAME]
      });
    })
    .catch(()=>{
      console.log("catch");
      this.setState({
        [NAME+index]: 0
      });
    })
  }


  render(){

    return (
      <Container className="templateReports" fluid="true">
        <Row className="">
          {
            this.props.reports.map((reportObj, index)=>{
              return(
                <Column key={index} className="" col="" xs="" sm="" md="" lg="" xl="">
                  <div className={"reportTop report"+(index+1)}>
                    <div className="reportIcon">
                      {(index===0) && <i className="fas fa-calendar-day"></i>}
                      {(index===1) && <i className="fas fa-calendar-week"></i>}
                      {(index===2) && <i className="fas fa-calendar-alt"></i>}
                      {(index===3) && <i className="fas fa-calendar"></i>}
                    </div>
                    <div className="reportText">
                      <div className="">
                        <div className="reportTitle">{reportObj.TITLE}</div>
                        <div className="reportValue" >
                            {this.state[reportObj.NAME+index]}
                        </div>
                      </div>
                    </div>
                  </div>
                </Column>
              )
            })
          }
        </Row>
      </Container>
    );
  }
}


