import React from 'react';
import {Helmet} from "react-helmet-async";
import 'styles/scss/website/pages/impressionKit.scss';
import TemplatePage from 'website/templates/templatePage/index.js';
import {NavLink, Link} from 'react-router-dom';


import Step1 from 'global/img/impressionKitPage/ImpressionCompairEng.jpg';
import Step1ESP from 'global/img/impressionKitPage/ImpressionCompairEsp.jpg';

import Step2A from 'global/img/impressionKitPage/Gloves1.jpg';
import Step2B from 'global/img/impressionKitPage/Gloves2.jpg';
import Step2C from 'global/img/impressionKitPage/20SecTimer.png';

import Step3A from 'global/img/impressionKitPage/Gloves4.jpg';
import Step3B from 'global/img/impressionKitPage/Gloves5.jpg';

import Step4A from 'global/img/impressionKitPage/Mouth2.jpg';
import Step4B from 'global/img/impressionKitPage/Mouth1.jpg';
import Step4C from 'global/img/impressionKitPage/3MinTimer.png';

import Step5A from 'global/img/impressionKitPage/Mouth3.jpg';
import Step5B from 'global/img/impressionKitPage/Mouth4.jpg';

import Step6 from 'global/img/impressionKitPage/FinishedAligners.jpg';
import Step7 from 'global/img/impressionKitPage/ShippingIcon.png';
import Step8 from 'global/img/impressionKitPage/CameraIcon.png';

// import initialPics1 from 'global/img/initialPics/initial_4.jpg';÷
// import initialPics2 from 'global/img/initialPics/initial_5.jpg';÷
// import initialPics3 from 'global/img/initialPics/initial_6.jpg';÷
// import initialPics4 from 'global/img/initialPics/initial_7.jpg';÷
// import initialPics5 from 'global/img/initialPics/initial_8.jpg';÷



//import API_CALLS from 'API_CALLS/index';
//import ImpressionKitBar from 'website/components/ImpressionKitBar/index';
import TreatmentPlanBar from 'website/components/TreatmentPlanBar/index';
import CONTENTS from "./CONTENTS";
const {Container, Row, Column} = window.LayoutBuilder;
class Index extends TemplatePage {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      products: []
    };    
  }

  didMount(){          
  }

  willUnmount(){
  }

  onFailed(){
  }



  render(){
    var {products=[]} = this.state;
    const CONTENT = window.GlobalUtil.dangerSetGlobalObjList(CONTENTS[window.Session.Language].CONTENT); 
    const HEADER = CONTENTS[window.Session.Language].HEADER; 
          
    return (
      <div className="contentPage" id="PageImpressionKit">
        <Helmet>
            <meta charSet="utf-8" />
            <title>{HEADER.TITLE}</title>
            <meta name="description" content={HEADER.DESCRIPTION}/>
            <link  href={`${this.props.location.pathname}`} />
            <link rel="canonical" href={`${HEADER.BASEURL}${this.props.location.pathname}`} />
            <meta name="keywords" content={HEADER.KEYWORDS} />

            {"<!-- Google / Search Engine Tags -->"}
            <meta itemprop="name" content={HEADER.TITLE} />
            <meta itemprop="description" content={HEADER.DESCRIPTION}/>
            <meta itemprop="image" content="https://Smiles.Club/SocialImage_500.jpg" />

            {/*<!-- Facebook Meta Tags -->*/}
            <meta property="og:url" content={`${HEADER.BASEURL}${this.props.location.pathname}`} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={HEADER.TITLE} />
            <meta property="og:description" content={HEADER.DESCRIPTION}/>
            <meta property="og:image" content="https://Smiles.Club/SocialImage_500.jpg" />

            {/*<!-- Twitter Meta Tags -->*/}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={HEADER.TITLE} />
            <meta name="twitter:description" content={HEADER.DESCRIPTION}/>
            <meta name="twitter:image" content="https://Smiles.Club/SocialImage_500.jpg" />
        </Helmet>
        <div id="TopMainSection" className="containerSection backgroundImage3">
          <Container className="">
            <Row className="">
              <Column className="" col="" xs="" sm="" md="" lg="" xl="">
                <div className="">
                  <IframeVideo />
                </div>
              </Column>
              <Column className="contentCenter topTextZone" col="12" xs="12" sm="12" md="12" lg="6" xl="">
                  {CONTENT.Section1[0]}
              </Column>
            </Row>
          </Container>
        </div>
        
        {/* <ImpressionKitBar
        /> */}
        <TreatmentPlanBar  />

        
        <div id="FullSteps" className="containerSection ">
          <Container>
            <Row className="">
              <Column className="text-center" col="" xs="" sm="" md="" lg="" xl="">
                {CONTENT.Section2[0]}
              </Column>
            </Row>
            {/*STEP 1*/}
            <Row className="">
              <Column className="contentCenter" col="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <div className="fullSizeImage">
                  <img 
                    src={((window.Session.Language === "ENGLISH") ? Step1 : Step1ESP)} 
                    alt={CONTENT.Step1Images[0].alt}
                  />
                  <br/>  
                  <br/> 
                </div>
              </Column>
              <Column className="contentCenter" col="12" xs="12" sm="12" md="6">
                <div className="step">
                  {CONTENT.Step1[0]}
                  <div className="buttonGroup"><button className={"button button1"} onClick={()=>window.GlobalUtil.triggerEvent("ShowContactForm", true)}>{CONTENT.Step1[1]}</button></div>
                </div>
              </Column>
            </Row>
            <br/> 
            <br/>

            {/*STEP 2*/}
            <Row className="">
              <Column className="contentCenter" col="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <div className="imageGroup">
                  <div className="image">
                    <img 
                      src={Step2A} 
                      alt={CONTENT.Step2Images[0].alt}
                    />
                  </div>
                  <div className="image">
                    <img 
                      src={Step2B} 
                      alt={CONTENT.Step2Images[1].alt}
                    />
                  </div>
                  <div className="floatingImage">
                    <img 
                      src={Step2C} 
                      alt={CONTENT.Step2Images[2].alt}
                    />
                  </div>
                </div>
              </Column>
              <Column className="contentCenter" col="12" xs="12" sm="12" md="6">
                <div className="step">
                  {CONTENT.Step2[0]}
                </div>
              </Column>
            </Row>
            <br/> 
            <br/>

            {/*STEP 3*/}
            <Row className="">
              <Column className="contentCenter" col="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <div className="imageGroup">
                  <div className="image">
                    <img 
                      src={Step3A} 
                      alt={CONTENT.Step3Images[0].alt}
                    />
                  </div>
                  <div className="image">
                    <img 
                      src={Step3B} 
                      alt={CONTENT.Step3Images[1].alt}
                    />
                  </div>
                </div>
              </Column>
              <Column className="contentCenter" col="12" xs="12" sm="12" md="6">
                <div className="step">
                  {CONTENT.Step3[0]}
                </div>
              </Column>
            </Row>
            <br/> 
            <br/>

            {/*STEP 4*/}
            <Row className="">
              <Column className="contentCenter" col="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <div className="imageGroup">
                  <div className="image">
                    <img 
                      src={Step4A} 
                      alt={CONTENT.Step4Images[0].alt}
                    />
                  </div>
                  <div className="image">
                    <img 
                      src={Step4B} 
                      alt={CONTENT.Step4Images[1].alt}
                    />
                  </div>
                  <div className="floatingImage">
                    <img 
                      src={Step4C} 
                      alt={CONTENT.Step4Images[2].alt}
                    />
                  </div>
                </div>
              </Column>
              <Column className="contentCenter" col="12" xs="12" sm="12" md="6">
                <div className="step">
                  {CONTENT.Step4[0]}
                </div>
              </Column>
            </Row>
            <br/> 
            <br/>

            {/*STEP 5*/}
            <Row className="">
              <Column className="contentCenter" col="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <div className="imageGroup">
                  <div className="image">
                    <img 
                      src={Step5A} 
                      alt={CONTENT.Step5Images[0].alt}
                    />
                  </div>
                  <div className="image">
                    <img 
                      src={Step5B} 
                      alt={CONTENT.Step5Images[1].alt}
                    />
                  </div>
                </div>
              </Column>
              <Column className="contentCenter" col="12" xs="12" sm="12" md="6">
                <div className="step">
                  {CONTENT.Step5[0]}
                </div>
              </Column>
            </Row>
            <br/> 
            <br/>

            {/*STEP 6*/}
            <Row className="">
              <Column className="contentCenter" col="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <div className="fullSizeImage">
                  <img 
                    src={Step6} 
                    alt={CONTENT.Step6Images[0].alt}
                  />
                  <br/>  
                  <br/> 
                </div>
              </Column>
              <Column className="contentCenter" col="12" xs="12" sm="12" md="6">
                <div className="step">
                  {CONTENT.Step6[0]}
                </div>
              </Column>
            </Row>

            <br/> 
            <br/>
            <hr/>
            <br/>
            <br/>
            

            <Row className="">
              <Column className="text-center" col="" xs="" sm="" md="" lg="" xl="">
                {CONTENT.Section3[0]}
                <br/>  
                <br/>  
              </Column>
            </Row>


            {/*STEP 7*/}
            <Row className="">
              <Column className="iconImage">
                <img 
                  src={Step7} 
                  alt={CONTENT.Section4Images[0].alt}
                />
              </Column>
              <Column className="contentCenter iconImageContent" >
                <div className="">
                  {CONTENT.Section4[0]}
                </div>
              </Column>
            </Row>
            <br/> 
            <br/>




            {/*STEP 8*/}
            <Row className="">
              <Column className="iconImage">
                <img 
                  src={Step8} 
                  alt={CONTENT.Section5Images[0].alt}
                />
              </Column>
              <Column className="contentCenter iconImageContent" >
                <div className="">
                  {CONTENT.Section5[0]}
                  <NavLink className={"button button2"} style={{"margin": "20px 5px 20px"}}  exact to={CONTENT.Section5[2]}>{CONTENT.Section5[1]}</NavLink>
                </div>
              </Column>
            </Row>
            <br/> 
            <br/>
          </Container>
        </div>
      </div>
    );
  }
}







class IframeVideo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true, 
      width: 0,
      height: 0
    };    
  }
  
  componentDidMount(){
    var width = this.containerSize.parentElement.offsetWidth;
    this.setState({
      loading: false,
      width: width,
      height: (width * (480/853))
    });
  }


  render(){
    const {loading, width, height} = this.state;
    if(loading) return(
      <div ref={e=> this.containerSize = e}></div>
    )
    return(
      <iframe
        width="100%"
        height={height}
        src={(window.Session.Language === "ENGLISH") ? `https://www.youtube.com/embed/Pg8ky3uhhhA` : `https://www.youtube.com/embed/PrF0A_lQPHE`}
        frameBorder="0"
        //allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    )
  }
}


// const Product = (props)=>{
//   var {product={}} = props;
//   return(
//     <div className="product productLayout1">
//       <div className="productImages d-none d-lg-block">
//         <div className="productImage">
//           <img src={window.GlobalUtil.deepGetFromString(product, "images,0,url", null)} alt=""/>
//         </div>
//       </div>
//       <div className="productText">
//         <div className="productImage d-block d-lg-none">
//           <img src={window.GlobalUtil.deepGetFromString(product, "images,0,url", null)} alt=""/>
//           <br/>
//         </div>
//         <div className="productTextName">
//           {product.name}
//         </div>
//         <div className="productTextCost d-flex">
//           <div className="cost">{window.GlobalUtil.convertToMoney(product.cost-product.discount)}</div>
//           <div className="originalPrice">{window.GlobalUtil.convertToMoney(product.cost)}</div>
//         </div>
//         <div className="productTextDesc">
//           {window.GlobalUtil.dangerSet(product.description)}
//         </div>
//         {/*<div className="">
//           Category: {product.category}
//         </div>*/}
//         <div className="buttonGroup">
//           <button className="button button2 btn-color5 dontCloseCart" onClick={()=>{
//             window.GlobalUtil.addToCart({product: product, replaceAll: true, onlyOne: true}, ()=>{
//               window.GlobalUtil.triggerEvent("cartEvent", "show");
//             });
//           }}>
//           {
//             (window.Session.Language === "ENGLISH")
//             ? "Buy Now"
//             : "Compra Ahora"
//           }
//           </button>
//         </div>
//       </div>
//     </div>
//   )
// }  

export default Index;
