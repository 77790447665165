import React from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import API_CALLS from 'API_CALLS/index';
import TemplateList from "global/templates/templateLists/index";
import TemplateSlideInRight from "global/templates/templateSlideInRight/index";
import CONTENTS from "./CONTENTS";

//import SlideInRight from 'global/components/SlideInRight/index';
//import FullPreview from 'user/normal/components/FullPreview/index';


const {Container, Row, Column} = window.LayoutBuilder;
const QUERY_LIST = API_CALLS.ORDERS.QUERIES.all();
const QUERY_SINGLE = API_CALLS.ORDERS.QUERIES.single();
const PREVIEW = API_CALLS.ORDERS.PREVIEW;
const PROMO_PAYMENT_PREVIEW = API_CALLS.REPORTS.PROMO_PAYMENT_PREVIEW;

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };    
  }

  componentDidMount(){
  }

  componentWillUnmount(){
  }

  render(){
    var {PreviewObj, PreviewReportsObj, hasPendingPayments=false} = this.state;
    var getToday = new Date().getTime();
    var subscriptionID = window.GlobalUtil.deepGetFromString(window, "Session,user,treatmentPlan,subscriptionID", null);
    const CONTENT = window.GlobalUtil.dangerSetGlobalObjList(CONTENTS[window.Session.Language].CONTENT); 
    return(
      <div className="UserBillingPage" className="scrollZone">
        <Container className="" fluid="true">
          <Row className="">
            <Column className="" col="" xs="" sm="" md="" lg="" xl="">
              {CONTENT.Title[0]}
            </Column>
          </Row>
        </Container> 
        
        <Container className="" fluid="true">
          <Row className="">
            <Column className="matchHeight " col="" xs="" sm="" md="" lg="" xl="">
              <div className="component fullHeight">
                <div className="itemHeader">
                  <div className="itemHeaderTextZone">
                    <div className="itemHeaderText">{CONTENT.BillingHistory[0]}</div>
                  </div>
                </div>
                <div className="">
                  <TemplateList
                    PAGEID={"USERBILLING2"}
                    VARIABLES={{
                      "sortBy": "chargingAt",
                      "manual": [
                        {
                          path: "UID",
                          val: window.Session.user._id
                        }
                      ]
                    }}
                    LIST={{
                      "dataField": 'orders',
                      "QUERY": API_CALLS.ORDERS.QUERIES.allAndCount(),
                      "LIST_HEADER_FIELDS": [
                      {
                        path: "paidAt", 
                        type: "DATE", 
                        name: CONTENT.BillingHistory[1]
                      },
                      {
                        path: "_id", 
                        type: "VALUE", 
                        name: CONTENT.BillingHistory[2]
                      },
                      {
                        path: "cost.total", 
                        type: "MONEY", 
                        name: CONTENT.BillingHistory[3]
                      }
                    ]
                    }}

                    BUTTONS={[{
                      width: "110px",
                      category: CONTENT.BillingHistory[4],
                      buttonText: "",
                      className: "btn-color1 previewButtonIcon",
                      iconClass: "fas fa-search",
                      onClick: (curObj)=>{
                        this.setState({PreviewObj: curObj}) 
                      }
                    }]}
                  />
                </div>
              </div>
            </Column>
          </Row>
          {
            (window.Session.user && window.Session.user.AFFILIATE && window.Session.user.AFFILIATE.isAffiliate)
            ? <Row className="">
                <Column className="matchHeight " col="" xs="" sm="" md="" lg="" xl="">
                  <div className="component fullHeight">
                    <div className="itemHeader">
                      <div className="itemHeaderTextZone">
                        <div className="itemHeaderText">{CONTENT.Commissions[0]}</div>
                      </div>
                    </div>
                    <div className="">
                      <TemplateList
                        PAGEID={"USERPAYMENTS1"}
                        VARIABLES={{
                          "UID": window.Session.user._id
                        }}
                        LIST={{
                          "dataField": 'promoPaid',
                          "QUERY": API_CALLS.REPORTS.QUERIES.promoPaidReport(),
                          "LIST_HEADER_FIELDS": [
                            {
                              path: "ADMIN_PAID_DATE", 
                              type: "DATE", 
                              name: CONTENT.Commissions[1]
                            },
                            {
                              path: "ADMIN_CHECK_NUMBER", 
                              type: "VALUE", 
                              name: CONTENT.Commissions[2]
                            },
                            {
                              path: "TOTAL", 
                              type: "MONEY", 
                              name: CONTENT.Commissions[3]
                            }
                          ]
                        }}

                        BUTTONS={[{
                          width: "110px",
                          category: CONTENT.Commissions[4],
                          buttonText: "",
                          className: "btn-color1 previewButtonIcon",
                          iconClass: "fas fa-search",
                          onClick: (curObj)=>{
                            this.setState({PreviewReportsObj: window.GlobalUtil.stripOutFromObj(curObj, "__typename")}) 
                          }
                        }]}
                      />
                    </div>
                  </div>
                </Column>
              </Row>
            : null
          }
        </Container>
        {
          PreviewObj &&
          <TemplateSlideInRight
            ref={e=>this.slideInRightRef=e}
            title={"Order Preview"}
            isValid={false}
            onClose={()=>{
              this.setState({PreviewObj: false});
            }}
            hideSaveButton={true}
            customButtons={undefined}>
            <PREVIEW
              VARIABLES={{
                id: PreviewObj._id
              }}
              TITLE=""
              SUB_TITLE=""
            />
          </TemplateSlideInRight>
        }
        {
          PreviewReportsObj &&
          <TemplateSlideInRight
            ref={e=>this.slideInRightRef=e}
            title={"Commission Payment Preview"}
            isValid={false}
            onClose={()=>{
              this.setState({PreviewReportsObj: false});
            }}
            width={"500px"}
            hideSaveButton={true}
            customButtons={undefined}>
            <PROMO_PAYMENT_PREVIEW
              VARIABLES={PreviewReportsObj}
              TITLE=""
              SUB_TITLE=""
            />
          </TemplateSlideInRight>
        }
      </div>
    )
  }
}







  // hasPendingPayments && 
  // <Container className="" fluid="true">
  //   <Row className="">
  //     <Column className="matchHeight " col="" xs="" sm="" md="" lg="" xl="">
  //       <div className="component fullHeight">
  //         <div className="itemHeader">
  //           <div className="itemHeaderTextZone">
  //             <div className="itemHeaderText">{CONTENT.Pending[0]}</div>
  //           </div>
  //         </div>
  //         <div className="">
  //           <TemplateList
  //             PAGEID={"USERBILLING1"}
  //             VARIABLES={{
  //               "sortBy": "chargingAt",
  //               "manual": [
  //                 {
  //                   path: "UID",
  //                   val: window.Session.user._id
  //                 }
  //               ],
  //               "status": "PENDING"
  //             }}
  //             LIST={{
  //               "dataField": 'orders',
  //               "QUERY": API_CALLS.ORDERS.QUERIES.allAndCount(),
  //               "LIST_HEADER_FIELDS": [
  //                 {
  //                   path: "chargingAt", 
  //                   type: "DATE", 
  //                   name: CONTENT.Pending[1]
  //                 },
  //                 {
  //                   path: "_id", 
  //                   type: "VALUE", 
  //                   name: CONTENT.Pending[2]
  //                 },
  //                 {
  //                   path: "cost.total", 
  //                   type: "MONEY", 
  //                   name: CONTENT.Pending[3]
  //                 }
  //               ]
  //             }}

  //             BUTTONS={[{
  //               width: "110px",
  //               category: CONTENT.Pending[4],
  //               buttonText: "",
  //               className: "btn-color1 previewButtonIcon",
  //               iconClass: "fas fa-search",
  //               onClick: (curObj)=>{
  //                 this.setState({PreviewObj: curObj}) 
  //               }
  //             }]}
  //           />
  //         </div>
  //       </div>
  //     </Column>
  //   </Row>
  // </Container>

  // false && //subscriptionID && 
  // <Container className="" fluid="true">
  //   <Row className="">
  //     <Column className="matchHeight " col="" xs="" sm="" md="" lg="" xl="">
  //       <div className="component fullHeight">
  //         <div className="itemHeader">
  //           <div className="itemHeaderTextZone">
  //             <div className="itemHeaderText">{CONTENT.Subscription[0]}</div>
  //           </div>
  //         </div>
  //         <div className="">
  //           <TemplateList
  //             PAGEID={"USERBILLING2"}
  //             VARIABLES={{
  //               "sortBy": "chargingAt",
  //               "descending": true,
  //               "dates": [{
  //                 "field": "chargingAt",
  //                 "gte": `${getToday}`,
  //               }],
  //               "manual": [
  //                 {
  //                   path: "UID",
  //                   val: window.Session.user._id
  //                 }
  //               ]
  //             }}
  //             LIST={{
  //               "dataField": 'orders',
  //               "QUERY": API_CALLS.ORDERS.QUERIES.allAndCount(),
  //               "LIST_HEADER_FIELDS": [
  //                 {
  //                   path: "chargingAt", 
  //                   type: "DATE", 
  //                   name: CONTENT.Subscription[1]
  //                 },
  //                 {
  //                   path: "_id", 
  //                   type: "VALUE", 
  //                   name: CONTENT.Subscription[2]
  //                 },
  //                 {
  //                   path: "cost.total", 
  //                   type: "MONEY", 
  //                   name: CONTENT.Subscription[3]
  //                 },
  //               ]
  //             }}

  //             BUTTONS={[{
  //               width: "110px",
  //               category: CONTENT.Subscription[4],
  //               buttonText: "",
  //               className: "btn-color1 previewButtonIcon",
  //               iconClass: "fas fa-search",
  //               onClick: (curObj)=>{
  //                 this.setState({PreviewObj: curObj}) 
  //               }
  //             }]}
  //           />
  //         </div>
  //       </div>
  //     </Column>
  //   </Row>
  // </Container>










export default Index;