import React from 'react';
import 'styles/scss/website/pages/404.scss';
import {Helmet} from "react-helmet-async";
import TemplatePage from 'website/templates/templatePage/index.js';
import MainFooter_500 from 'global/img/squareGirlAligner_500.jpg';

import CONTENTS from "./CONTENTS";
const {Container, Row, Column} = window.LayoutBuilder;
class Index extends TemplatePage {
  constructor(props) {
    super(props);
    //this.state = {...this.state,
    //};    
  }

  didMount(){
  }

  willUnmount(){
  }

  render(){
    //console.log('this.props', this.props);
    const CONTENT = window.GlobalUtil.dangerSetGlobalObjList(CONTENTS[window.Session.Language].CONTENT); 
    const HEADER = CONTENTS[window.Session.Language].HEADER; 
    return (
      <div className="contentPage" id="Page404">
        <Helmet>
            <meta charSet="utf-8" />
            <title>{HEADER.TITLE}</title>
            <meta name="description" content={HEADER.DESCRIPTION}/>
            <link  href={`${this.props.location.pathname}`} />
            <link rel="canonical" href={`${HEADER.BASEURL}${this.props.location.pathname}`} />
            <meta name="keywords" content={HEADER.KEYWORDS} />

            {"<!-- Google / Search Engine Tags -->"}
            <meta itemprop="name" content={HEADER.TITLE} />
            <meta itemprop="description" content={HEADER.DESCRIPTION}/>
            <meta itemprop="image" content="https://Smiles.Club/SocialImage_500.jpg" />

            {/*<!-- Facebook Meta Tags -->*/}
            <meta property="og:url" content={`${HEADER.BASEURL}${this.props.location.pathname}`} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={HEADER.TITLE} />
            <meta property="og:description" content={HEADER.DESCRIPTION}/>
            <meta property="og:image" content="https://Smiles.Club/SocialImage_500.jpg" />

            {/*<!-- Twitter Meta Tags -->*/}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={HEADER.TITLE} />
            <meta name="twitter:description" content={HEADER.DESCRIPTION}/>
            <meta name="twitter:image" content="https://Smiles.Club/SocialImage_500.jpg" />
        </Helmet>
        <div id="Section1" className=''>
          <Container >
            <Row id="Row1" className="sectionRows title">
              <Column className="" col="12" xs="12" sm="12" md="12">
                <div id="MainText">
                  <div className="icon"><i className="fas fa-exclamation-triangle"></i></div>
                  <div className="message">
                    {CONTENT.Section1[0]}
                  </div>
                </div>
              </Column>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}


export default Index;
      