import React from 'react';
import API_CALLS from 'API_CALLS/index';
import TemplatePage from "global/templates/templatePage/index";



const QUERY = API_CALLS.COMS.QUERIES.allAndCount();
const QUERY_SINGLE = API_CALLS.COMS.QUERIES.single();
const QUERY_COUNT = API_CALLS.COMS.QUERIES.count();
const MUTATION_EDIT = API_CALLS.COMS.MUTATIONS.update();
const MUTATION_ADD = API_CALLS.COMS.MUTATIONS.create();
const QUERY_GET_USERS = API_CALLS.USERS.QUERIES.all(`com`);

const FORM_FIELDS = API_CALLS.COMS.FORM_FIELDS;
const LIST_HEADER_FIELDS = API_CALLS.COMS.LIST_HEADER_FIELDS;

const PREVIEW = API_CALLS.COMS.PREVIEW;

const API_CALLS_FULL = {
  QUERY,
  QUERY_SINGLE,
  QUERY_COUNT,
  MUTATION_EDIT,
  MUTATION_ADD,
  FORM_FIELDS,
  QUERY_GET_USERS,
  PREVIEW
};






const ValidateObject = (newObject, onSuccess, onFail)=>{
  if(newObject._id && !newObject.id) newObject.id = newObject._id;      
  //console.log("newObject");
  //console.log(newObject,"\n\n");
        
  Object.keys(newObject).map((key, index)=>{
    if(!newObject[key]) delete newObject[key];
  })  
  onSuccess(newObject);
  //onFail();      
};







const PAGES = () => [
  {
    "id": "COM01",
    "Name":"New",
    "PageName": "New",
    "Paths": ["/admin/coms/new"],
    "TITLE": "New Communications",
    "DATA_FIELD": "coms",
    "GlobalStateVar": ["comsCountNew"],
    "API_CALLS": API_CALLS_FULL,
    "LIST_HEADER_FIELDS": LIST_HEADER_FIELDS,
    "VARIABLES": {
      "status": "ACTIVE",
      "sortBy": "meta.createAt",
      "descending": false,
      "limit": 20,
      "offset": 0
    },
    "NEWBUTTON": {
      NAME: "COMS",
      TITLE: "Com",
      FIELD: "com"
    },
    "EDITBUTTON": {
      NAME: "COMS",
      TITLE: "Com",
      FIELD: "com"
    },
    "DELETEBUTTON": {
      NAME: "COMS"
    },
    "CLONEBUTTON": {
      NAME: "COMS",
      CONFIRMMESSAGE: "Are you sure you want to clone this? If so it be cloned into PENDING.",
      ONSUCCESS: ()=>{
        window.FlashMenuItem("/admin/coms/pending");
      }
    },
    "BUTTONS": [
      {
        category: "Preview",
        buttonText: "View",
        className: "btn-color3",
        iconClass: "fas fa-eye",
        onClick: (obj)=>{
          window.GlobalUtil.triggerEvent("TemplateDBEvent", {
            "TYPE": "PREVIEW",
            "BUTTON_TITLE": "Com",
            "TITLE": "Com", //TITLE OF SLIDE OUT
            "APINAME": "COMS",
            "NAME": "Com",
            "ID": obj._id
          }); 
        }
      }
    ],
    SHOWSELECTBOXES:true
  },
  {
    "id": "COM02",
    "Name":"Pending",
    "PageName": "Pending",
    "Paths": ["/admin/coms/pending"],
    "TITLE": "Pending Communications",
    "DATA_FIELD": "coms",
    "API_CALLS": API_CALLS_FULL,
    "LIST_HEADER_FIELDS": LIST_HEADER_FIELDS,
    "VARIABLES": {
      "status": "PENDING",
      "sortBy": "meta.createAt",
      "descending": false,
      "limit": 20,
      "offset": 0
    },
    "NEWBUTTON": {
      NAME: "COMS",
      TITLE: "Com",
      FIELD: "com"
    },
    "EDITBUTTON": {
      NAME: "COMS",
      TITLE: "Com",
      FIELD: "com"
    },
    "DELETEBUTTON": {
      NAME: "COMS"
    },
    "CLONEBUTTON": {
      NAME: "COMS",
      CONFIRMMESSAGE: "Are you sure you want to clone this? If so it be cloned into PENDING.",
      ONSUCCESS: ()=>{
        window.FlashMenuItem("/admin/coms/pending");
      }
    },
    "BUTTONS": [
      {
        width: "110px",
        category: "Preview",
        buttonText: "View",
        className: "btn-color3",
        iconClass: "fas fa-eye",
        onClick: (obj)=>{
          window.GlobalUtil.triggerEvent("TemplateDBEvent", {
            "TYPE": "PREVIEW",
            "BUTTON_TITLE": "Com",
            "TITLE": "Com", //TITLE OF SLIDE OUT
            "APINAME": "COMS",
            "NAME": "Com",
            "ID": obj._id
          }); 
        }
      }
    ],
    SHOWSELECTBOXES:true
  },
  {
    "id": "COM03",
    "Name":"History",
    "PageName": "History",
    "Paths": ["/admin/coms/history"],
    "TITLE": "History of Communications",
    "DATA_FIELD": "coms",
    "API_CALLS": API_CALLS_FULL,
    "LIST_HEADER_FIELDS": LIST_HEADER_FIELDS,
    "VARIABLES": {
      "status": "NOTINACTIVE",
      "sortBy": "meta.createAt",
      "descending": false,
      "limit": 20,
      "offset": 0
    },
    "NEWBUTTON": {
      NAME: "COMS",
      TITLE: "Com",
      FIELD: "com"
    },
    "EDITBUTTON": {
      NAME: "COMS",
      TITLE: "Com",
      FIELD: "com"
    },
    "DELETEBUTTON": {
      NAME: "COMS"
    },
    "CLONEBUTTON": {
      NAME: "COMS",
      CONFIRMMESSAGE: "Are you sure you want to clone this? If so it be cloned into PENDING.",
      ONSUCCESS: ()=>{
        window.FlashMenuItem("/admin/coms/pending");
      }
    },
    "BUTTONS": [
      {
        width: "110px",
        category: "Preview",
        buttonText: "View",
        className: "btn-color3",
        iconClass: "fas fa-eye",
        onClick: (obj)=>{
          window.GlobalUtil.triggerEvent("TemplateDBEvent", {
            "TYPE": "PREVIEW",
            "BUTTON_TITLE": "Com",
            "TITLE": "Com", //TITLE OF SLIDE OUT
            "APINAME": "COMS",
            "NAME": "Com",
            "ID": obj._id
          }); 
        }
      }
    ],
    SHOWSELECTBOXES:true
  }
];





const Index = (props) => {
  //var {data} = props;
  var today = new Date();
  return (
     <TemplatePage
      ID={"AdminComs"}
      REPORTS={
        [
          {
            QUERY: QUERY_COUNT,
            VARIABLES: {
              "status": "NOTINACTIVE",
              "dates": [{
                field: "meta.createAt",
                gte: `${today.getStartOfDay().getTime()}`
              }]
            },
            NAME: "comCount",
            TITLE: "New Coms Today"
          },
          {
            QUERY: QUERY_COUNT,
            VARIABLES: {
              "status": "NOTINACTIVE",
              "dates": [{
                field: "meta.createAt",
                gte: `${today.getStartOfWeek().getTime()}`
              }]
            },
            NAME: "comCount",
            TITLE: "Coms this Week"
          },
          {
            QUERY: QUERY_COUNT,
            VARIABLES: {
              "status": "NOTINACTIVE",
              "dates": [{
                field: "meta.createAt",
                gte: `${today.getStartOfMonth().getTime()}`
              }]
            },
            NAME: "comCount",
            TITLE: "Coms this Month"
          },
          {
            QUERY: QUERY_COUNT,
            VARIABLES: {
              "status": "NOTINACTIVE",
              "dates": [{
                field: "meta.createAt",
                gte: `${today.getStartOfYear().getTime()}`
              }]
            },
            NAME: "comCount",
            TITLE: "Coms this Year"
          }
        ]
      }
      REDIRECT={{
        "path": "/admin/coms",
        "to": "/admin/coms/new"
      }}
      PAGES={PAGES()}
      ValidateObject={ValidateObject}
    />
  );
}





export default Index;

export {PAGES};   

      
