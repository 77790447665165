import React from 'react';
import 'styles/scss/doctor/components/navMenu.scss';
import Nav from 'user/doctor/components/Nav/index';
import {NavLink, Link} from 'react-router-dom';
import LogoDark from 'global/img/logo-dark.svg';
const {Container, Row, Column} = window.LayoutBuilder;
const Component = (props) => {
  var today = new Date();
  return (
    <div id="NavMenu">     
      <NavLink className="siteLogo" to={"/doctor"}>
        <img src={LogoDark} alt=""/>
      </NavLink>
    	<Nav MenuToShow={(window.Session.Language === "ENGLISH") ? "Header" : "HeaderESP"} />
    	<div className="menu otherButtons">
    		<div className="menu-item">
          <a className={"nav-link"}  onClick={()=>window.GlobalUtil.logout(true)}>
            <div className="inline-nav-link">
              <div className="icon"><i className="fas fa-sign-out-alt"></i></div>
              <div className="text">Logout</div>
            </div>
          </a>
        </div>
    	</div>
    </div>
  );
}

export default Component;