import React from 'react';
import API_CALLS from 'API_CALLS/index';
import ESPAÑOL from "./ESPAÑOL";
import ENGLISH from "./ENGLISH";
const CONTENTS = {
  ESPAÑOL,
  ENGLISH
}
const MUTATION_USER_EDIT = API_CALLS.USERS.MUTATIONS.update();

const {Container, Row, Column} = window.LayoutBuilder;

const Index = (props) => {
  var {
    alignersSchedule={}, 
    alignerActiveEvent,
    alignerScheduleEventsPast,
    alignerScheduleEventsUpcomming,
    onStartTreatmentPlan
  } = props;
  const CONTENT = window.GlobalUtil.dangerSetGlobalObjList(CONTENTS[window.Session.Language]); 
  return(
    <Row className="alignerScheduleZone">
      { //IF HAVE ASIGNED UPPER NUMBER AND LOWER NUMBER THEN ALLOW THEM TO START THEIR PLAN
        !alignersSchedule.started
        ? (alignersSchedule.numOfAlignersBottom || alignersSchedule.numOfAlignersTop)
          ? <div id="StartTreatment" className='startTreatment'>
              <div className="startTreatmentContainer">
                <i className="fas fa-laugh-wink"></i>
                <h2 className='startTreatmentHeader'>{CONTENT.StartTreatment[0]}</h2>
                <p className='startTreatmentTitle'>{CONTENT.StartTreatment[1]}</p>
                <button className="button button1 btn-color5" onClick={onStartTreatmentPlan}>{CONTENT.StartTreatment[2]}</button>
              </div>
            </div>
          : <div id="StartTreatment" className='startTreatment preStart'>
              <div className="startTreatmentContainer">
                <i className="fas fa-user-md"></i>
                <h2 className='startTreatmentHeader'>{CONTENT.StartTreatment[3]}</h2>
                <p className='startTreatmentTitle'>{CONTENT.StartTreatment[4]}</p>
              </div>
            </div>
        : null
      }
      <Column id="CurAlignerColumn" className="" style={{"minWidth": "250px"}} col="" xs="" sm="" md="" lg="" xl="4">
        <div id="CurAlignerComponent1" className="component curAlignerWrapper">
          <div className="curAligner">
            <div className="curAlignerNum">
              <div className="step">
                {CONTENT.CurAlignerComponent1[0]}
              </div>
              <div className="stepNum">
                {
                  !alignerActiveEvent
                  ? "0"
                  : alignerActiveEvent.number//+1
                }
              </div>
            </div>
            <div className="curAlignerDescWrapper">
              <div className='curAlignerText'>
                <div className="curAlignerDesc">
                  {CONTENT.CurAlignerComponent1[1]}
                </div>
                <div className="curAlignerDate">
                  {
                    !alignerActiveEvent
                    ? CONTENT.CurAlignerComponent1[2]
                    : `${CONTENT.CurAlignerComponent1[3]}: ${alignerActiveEvent.dateString}`
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="CurAlignerComponent2" className="component">
          <div className="itemHeader d-flex">
            <div className="itemHeaderTextZone text-center">
              <div className="itemHeaderText">{CONTENT.CurAlignerComponent2[0]}</div>
              <div className="itemHeaderSubText">{CONTENT.CurAlignerComponent2[1]}</div>
            </div>
          </div>
          <div className="alignerScheduleNext" >
            {
              !alignerActiveEvent
              ? <div className='text-center'>{CONTENT.CurAlignerComponent2[2]}</div>
              : !alignerScheduleEventsUpcomming
                ? <div className='text-center noMoreAligners'>
                    {CONTENT.CurAlignerComponent2[3]}
                  </div>
                : <div className='countdown'>
                    <CountDown 
                      ending={Number(alignerScheduleEventsUpcomming[0].dateTime)}
                      CONTENT={CONTENT.CountDown}
                    />
                  </div>
            }
          </div>
        </div>
      </Column>
      <Column className=" alignerScheduleColumn" col="" xs="" sm="" md="" lg="" xl="">
        <div id="AlignerScheduleComponent" className='component'>
          <div className="itemHeader d-flex">
            <div className="calendarIcon">
              <i className="fas fa-calendar-alt"></i>
            </div>
            <div className="itemHeaderTextZone">
              <div className="itemHeaderText">{CONTENT.AlignerScheduleComponent[0]}</div>
              <div className="itemHeaderSubText">{CONTENT.AlignerScheduleComponent[1]}</div>
            </div>
          </div>
          <div className="alignerSchedule">
            {
              !alignerScheduleEventsUpcomming && !alignerScheduleEventsPast &&
              <div className="text-center">{CONTENT.AlignerScheduleComponent[2]}</div>
            }
            {
              alignerActiveEvent
              ? <div className={`eventWrapper next`}>
                  <div className='event'>
                    <div className='eventDateWrapper'>
                      <div className='eventDate'>
                        <div className='eventDateMonth'>
                          {alignerActiveEvent.month}
                        </div>
                        <div className='eventDateNumber'>
                          {alignerActiveEvent.date}
                        </div>
                      </div>
                    </div>
                    <div className='eventText'>
                      {
                          alignerActiveEvent.alignerTop
                          ? <div className='eventTextTitle'>
                              {CONTENT.AlignerScheduleComponent[3]} {CONTENT.AlignerScheduleComponent[4]} <span style={{fontWeight: "600"}}>#{alignerActiveEvent.alignerTop}</span>
                            </div>
                          : null
                        }
                        {
                          (alignerActiveEvent.alignerTop && alignerActiveEvent.alignerBottom)
                          ? <span>&nbsp;{CONTENT.AlignerScheduleComponent[5]}&nbsp;</span>
                          : null
                        }
                        {
                          alignerActiveEvent.alignerBottom
                          ? <div className='eventTextTitle'>
                            {CONTENT.AlignerScheduleComponent[6]} <span style={{fontWeight: "600"}}>#{alignerActiveEvent.alignerBottom}</span>
                            </div>
                          : null
                        }
                    </div>
                  </div>
                </div>
              : null
            }
            {
              alignerScheduleEventsUpcomming && alignerScheduleEventsUpcomming.map((object, index)=>{
                return(
                  <div className={`eventWrapper`} key={index}>
                    <div className='event'>
                      <div className='eventDateWrapper'>
                        <div className='eventDate'>
                          <div className='eventDateMonth'>
                            {object.month}
                          </div>
                          <div className='eventDateNumber'>
                            {object.date}
                          </div>
                        </div>
                      </div>
                      <div className='eventText'>
                        {
                          object.alignerTop
                          ? <div className='eventTextTitle'>
                              {CONTENT.AlignerScheduleComponent[3]} {CONTENT.AlignerScheduleComponent[4]} <span style={{fontWeight: "600"}}>#{object.alignerTop}</span>
                            </div>
                          : null
                        }
                        {
                          (object.alignerTop && object.alignerBottom)
                          ? <span>&nbsp;{CONTENT.AlignerScheduleComponent[5]}&nbsp;</span>
                          : null
                        }
                        {
                          object.alignerBottom
                          ? <div className='eventTextTitle'>
                            {CONTENT.AlignerScheduleComponent[6]} <span style={{fontWeight: "600"}}>#{object.alignerBottom}</span>
                            </div>
                          : null
                        }
                      </div>
                    </div>
                  </div>
                )
              })
            }
            {
              alignerScheduleEventsPast
              ? <React.Fragment>
                  {alignerScheduleEventsUpcomming && <React.Fragment><br/><hr/><br/></React.Fragment>}
                  <div className='pastTitle'>{CONTENT.AlignerScheduleComponent[7]}</div>
                  {
                    alignerScheduleEventsPast.map((object, index)=>{
                      return(
                        <div className={`eventWrapper done`} key={index}>
                          <div className='event'>
                            <div className='eventDateWrapper'>
                              <div className='eventDate'>
                                <div className='eventDateMonth'>
                                  {object.month}
                                </div>
                                <div className='eventDateNumber'>
                                  {object.date}
                                </div>
                              </div>
                            </div>
                            <div className='eventText'>
                              <div className='eventTextTitle'>
                                {CONTENT.AlignerScheduleComponent[8]} {object.number}
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })
                  }
                </React.Fragment>
              : null
            }
          </div>
        </div>
      </Column>
    </Row>
  )
}
//




class CountDown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ending: new Date(this.props.ending),
      countdownTime: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      }
    };
  }

  componentDidMount(){
    this.GetCountdownTime();
  }

  componentWillUnmount(){
    clearTimeout(this.GetCountdownTimeRef);
  }

  GetCountdownTime(){
    var countdown = this.state.ending.getDateCountDown()
    this.setState({
      countdownTime: {
        days: countdown.slice(0,2),
        hours: countdown.slice(3,5),
        minutes: countdown.slice(6,8),
        seconds: countdown.slice(9),
      }
    }, ()=>{
      this.GetCountdownTimeRef = setTimeout(()=>{
        this.GetCountdownTime();
      }, 1000);
    });
  }

  render(){
    //var {showNav, offtop, HeaderStyles={}} = this.state;
    var {countdownTime} = this.state;
    var {CONTENT} = this.props;
    return (
      <div className="countdownCards">
        <div className="cardZone">
          <div className="card">
            <div className="number">{countdownTime.days}</div>
            <div className="line"></div>
          </div>
          <div className="title">
            {CONTENT[0]}
          </div>
        </div>
        <div className="cardZone">
          <div className="card">
            <div className="number">{countdownTime.hours}</div>
            <div className="line"></div>
          </div>
          <div className="title">
            {CONTENT[1]}
          </div>
        </div>
        <div className="cardZone">
          <div className="card">
            <div className="number">{countdownTime.minutes}</div>
            <div className="line"></div>
          </div>
          <div className="title">
           {CONTENT[2]}
          </div>
        </div>
        <div className="cardZone">
          <div className="card">
            <div className="number">{countdownTime.seconds}</div>
            <div className="line"></div>
          </div>
          <div className="title">
            {CONTENT[3]}
          </div>
        </div>
      </div>
    );
  }
}

export default Index;