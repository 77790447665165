import React, { useState, useRef } from 'react';
import {
    BrowserRouter as Router, StaticRouter, Route, NavLink, Link, Redirect, Switch
    // etc.
} from 'react-router-dom';
import TemplateHeader from "../templateHeader/index";
import TemplateList from "../templateLists/index";
import TemplateReports from "../templateReports1/index";
import API_CALLS from 'API_CALLS/index';
const {Container, Row, Column} = window.LayoutBuilder;

class Index extends React.Component {
  constructor(props) {
    super(props); 
  }

  componentDidMount(){
  }

  componentWillUnmount(){
  }

  render(){
    var {ID, data, API_CALLS_FULL, REDIRECT, REDIRECT_ARRAY, REPORTS, PAGES, ValidateObject} = this.props;
    return (
      <div id={ID}>
        {
          REPORTS && 
          <TemplateReports
            reports={REPORTS}
          />
        }
        <Container className="templateListWrapper" fluid="true">
          <Row className="">
            <Column className="">
              <Switch> 
                {
                  PAGES.map((page, index)=>{
                    if(page.SubPaths) return (
                      page.SubPaths.map((subPage, index2)=>{
                        return (
                          <Route 
                            key={index2}
                            path={[`${subPage.Paths[0]}/:ACTIVEITEM`, ...subPage.Paths]} 
                            render={props=>{
                              window.GlobalUtil.triggerEvent("PageChangedEvent", subPage);
                              return(
                                <TemplatePageLayout
                                  {...{...props, ...subPage}}
                                  ValidateObject={ValidateObject}
                                />
                              )
                            }} 
                          />
                        )
                      })
                    )
                    return(
                      <Route 
                        key={index}
                        path={[`${page.Paths[0]}/:ACTIVEITEM`, ...page.Paths]} 
                        render={props=>{
                          window.GlobalUtil.triggerEvent("PageChangedEvent", page);
                          return(
                            <TemplatePageLayout
                              {...{...props, ...page}}
                              ValidateObject={ValidateObject}
                            />
                          )
                        }} 
                      />
                    );
                  })
                }
                {
                  (REDIRECT_ARRAY && (REDIRECT_ARRAY.length > 0)) && 
                  REDIRECT_ARRAY.map((REDIRECT_OBJ, index)=>{
                    return <Route key={index} path={REDIRECT_OBJ.path}>
                      <Redirect
                        to={REDIRECT_OBJ.to}
                      />
                    </Route>
                  })
                }
                {
                  REDIRECT && 
                  <Route path={REDIRECT.path}>
                    <Redirect
                      to={REDIRECT.to}
                    />
                  </Route>
                }
              </Switch>
            </Column>
          </Row>
        </Container>
      </div>
    );
  }
}





class TemplatePageLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSlideIn: false,
      query: "",
      argument: ""
    };    
    this.standardButtons = this.standardButtons.bind(this);
    this.onSearch = this.onSearch.bind(this);
  }

  componentDidMount(){
  }

  componentWillUnmount(){
  }

  standardButtons(){
    var {BUTTONS=[], EDITBUTTON} = this.props;
    var NewButton = [];
    if(EDITBUTTON){
      NewButton.push({       
        category: "Edit",
        width: "40px",
        buttonText: "",//"Edit",
        className: "button",
        iconClass: "fas fa-edit",
        onClick: (curObj)=>{
          window.GlobalUtil.triggerEvent("TemplateDBEvent", {
            ...EDITBUTTON, 
            TYPE: "EDIT",
            ID: curObj._id
          });
        }
      });
    }
    return([...NewButton, ...BUTTONS]);
  }

  onSearch(argument, query){
    this.setState({query: query, argument: argument});
    if(this.TemplateListRef) this.TemplateListRef.updateQuery(argument, query)
  }

  render(){
    var {showSlideIn, argument, query} = this.state;
    var {LIST_HEADER_FIELDS, ValidateObject, ActionSlideIn, VARIABLES, TITLE, DATA_FIELD, BUTTONS=[], NEWBUTTON, id, SHOWSELECTBOXES, SUBTITLE} = this.props;
    var BUTTONS = this.standardButtons();
          
    //FORM_FIELDS,
    return (
      <div className="">
        <TemplateHeader
          TITLE={TITLE}
          SUBTITLE={SUBTITLE}
          NEWBUTTON={NEWBUTTON}
          DELETEBUTTON={this.props.DELETEBUTTON}
          INACTIVEBUTTON={this.props.INACTIVEBUTTON}
          CLONEBUTTON={this.props.CLONEBUTTON}
          SearchBox={()=><SearchBox
            LIST_HEADER_FIELDS={LIST_HEADER_FIELDS.filter(obj=>obj.searchable)}
            query={query}
            argument={argument}
            onSearch={this.onSearch}
          />}
        />
        <TemplateList
          ref={e=>this.TemplateListRef = e}
          PAGEID={id}
          VARIABLES={VARIABLES}
          ACTIVEITEM={window.GlobalUtil.deepGetFromString(this, 'props,match,params,ACTIVEITEM', undefined)}
          LIST={{
            "dataField": DATA_FIELD,
            "QUERY": (this.props.API_CALLS ? this.props.API_CALLS.QUERY : null),
            "LIST_HEADER_FIELDS": LIST_HEADER_FIELDS
          }}
          SHOWSELECTBOXES={SHOWSELECTBOXES}
          BUTTONS={BUTTONS.map(obj=>{
            if(obj.onClick) return obj;
            obj.onClick=(planToMake)=>{
              this.setState({showSlideIn: planToMake})
            }
            return obj;
          })}
          query={query}
        />
        {
          showSlideIn && 
          <ActionSlideIn
            curObj={showSlideIn}
            API_CALLS={this.props.API_CALLS}
            onClose={()=>{
              this.setState({showSlideIn: false});
            }}
          />
        }
      </div>
    );
  }
}





class SearchBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: (this.props.query ? this.props.query : ""),
      argument: (this.props.argument ? this.props.argument : "")
    };    
    this.onChangeQuery = this.onChangeQuery.bind(this);
    this.onChangeArg = this.onChangeArg.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount(){
  }

  componentWillUnmount(){          
  }

  onChangeQuery(e){
    this.setState({query: e.target.value})
  }

  onChangeArg(e){
    this.setState({argument: e.target.value})
  }

  onSubmit(){
    this.props.onSearch(this.state.argument, this.state.query) 
  }

  render(){
    var {LIST_HEADER_FIELDS} = this.props;
    var {query, argument} = this.state;
    if(!LIST_HEADER_FIELDS.length) return null;
    return (
      <div id="SearchBox">
        <form action="" className="SearchBoxForm" onSubmit={(e)=>{
          e.preventDefault();
          this.onSubmit()
        }} >{/*onKeyPress={(event)=>{if(!disableKeySubmit && event.key === 'Enter') this.onSubmit()}}*/}
          <select 
            type="select" 
            className={`searchArg`}
            value={argument}
            name={"argument"}
            onChange={this.onChangeArg}>
            <option value={""} disabled>Search By</option>
            {
              LIST_HEADER_FIELDS.length > 0 && 
              LIST_HEADER_FIELDS.map((object, index)=>{
                return(
                  <option key={index} value={object.path}>{object.name}</option>
                )
              })
            }
          </select>
          <input className={`searchQuery`} type="text" value={query} onChange={this.onChangeQuery} />
          <button className={`searchButton`} type={"submit"}><i className="fas fa-search" /></button>
        </form>
      </div>
    );
  }
}



export default Index;