import React from 'react';
import "styles/scss/doctor/pages/help.scss";
import ESPAÑOL from "./ESPAÑOL";
import ENGLISH from "./ENGLISH";
import ContactForm from 'global/components/ContactForm/index';
const CONTENTS = {
  ESPAÑOL,
  ENGLISH
}
const {Container, Row, Column} = window.LayoutBuilder;

const Index = (props) => {
  //var {showSideMenu} = this.state;
  const CONTENT = window.GlobalUtil.dangerSetGlobal(CONTENTS[window.Session.Language]); 
  var phoneString = window.GlobalUtil.getPhoneString(true);
  var curUser = ((window.Session && window.Session.user) ? window.Session.user : {});
  //<i className="fas fa-info-circle"></i>
  return (
    <div id="DoctorHelp"  className="scrollZone">
      <Container className="" fluid="true">
        <Row className="">
          <Column className="" col="" xs="" sm="" md="" lg="" xl="">
            <h1 className="pageHeader"><i className="fas fa-question-circle"></i> {CONTENT[0]}</h1>
          </Column>
        </Row>
      </Container>  
      <Container className="" fluid="true">
        <Row className="">
          <Column className="matchHeight" col="" xs="" sm="" md="" lg="" xl="">
            <div className="component fullHeight">
              <div className="itemHeader">
                <div className="itemHeaderTextZone">
                  <div className="itemHeaderText">{CONTENT[1]}</div>
                </div>
              </div>
              <ContactForm 
                noDialog={true} 
                showButton={true} 
                hideLogo={true}
                defaultObj={{
                  name: `${curUser.firstName} ${curUser.lastName}`,
                  phone: curUser.phone,
                  email: curUser.email,
                }}
              />
            </div>
          </Column>
          <Column className="infoColumn matchHeight" col="" xs="12" sm="12" md="12" lg="12" xl="2">
            <div className="component fullHeight">
              <div className="infoSection">
                <div className="itemHeader">
                  <div className="itemHeaderTextZone">
                    <div className="itemHeaderText withIcon">
                      {CONTENT[2]}
                    </div>
                  </div>
                </div>
                <div className="infoItemsWrapper">
                  <div className="infoItems">
                    <div className="infoItem">
                      <div className="icon"><i className="fas fa-building" /></div>
                      <div className="infoContent">
                        {window.SiteSettings.companyName}
                      </div>
                    </div>
                    <div className="infoItem">
                      <div className="icon"><i className="fas fa-envelope" /></div>
                      <div className="infoContent">
                        {window.SiteSettings.publicEmailAddress}
                      </div>
                    </div>
                    <div className="infoItem">
                      <div className="icon"><i className="fas fa-phone" /></div>
                      <div className="infoContent">
                        <a className="phoneNumber" href={`tel:${window.GlobalUtil.getPhoneNumber()}`}>{phoneString}</a>
                      </div>
                    </div>
                    <div className="infoItem">
                      <div className="icon"><i className="fas fa-map-pin" /></div>
                      <div className="infoContent">
                        {window.GlobalUtil.formatAddress(window.SiteSettings.address)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Column>
        </Row>
      </Container>
    </div>
  );
}


export default Index;
      
