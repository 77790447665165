import React from 'react';
import {CartContent} from "global/components/Cart/index";
//import Loading from 'global/components/Loading/index';


class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };    
    
  }

  componentDidMount(){
  }

  componentWillUnmount(){
  }


  render(){
    var {newOrder} = this.props;  
    return (
      <div id="CheckoutPageComponentRightSide">
        <CartContent newOrder={newOrder}/>
      </div>
    );
  }
}








export default Index;
      
