import React, { useState, useRef } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import {NavLink, Link} from 'react-router-dom';
import API_CALLS from 'API_CALLS/index';
import SectionValue from 'user/normal/components/SectionValue/index';
import Step4 from 'global/img/infoVectors/Step4.jpg';
import ESPAÑOL from "./ESPAÑOL";
import ENGLISH from "./ENGLISH";
const CONTENTS = {
  ESPAÑOL,
  ENGLISH
}
const QUERY_SINGLE_PLAN = API_CALLS.TREATMENT_PLANS.QUERIES.single();
const {Container, Row, Column} = window.LayoutBuilder;





class TreatementPlan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      testCurrentUser: {}
    }
  }

  componentDidMount(){
    window.UpdateTestCurrentUser = (NewTestCurrentUser)=>{
      if(this.state.testCurrentUser._id) this.setState({testCurrentUser: {}});
      else this.setState({testCurrentUser: NewTestCurrentUser});
    };
  }

  componentWillUnmount(){
  }


  render(){
    var {testCurrentUser={}} = this.state;
    var {data={}} = this.props;
    var currentUser = (window.Session.user ? window.GlobalUtil.stripOutFromObj(window.Session.user, "__typename") : {});
    if(testCurrentUser._id) currentUser = testCurrentUser; 
    currentUser.id = currentUser._id;
    var treatmentPlan = (currentUser.treatmentPlan ? currentUser.treatmentPlan : {});
    var TP_STEP = treatmentPlan.step;
    var createdAt = window.GlobalUtil.deepGetFromString(treatmentPlan, "meta,createAt", null);
    if(createdAt) createdAt = `${window.GlobalUtil.dateBasic(createdAt, "DAY")}`
    var PlanPDF = ((treatmentPlan && treatmentPlan.planFiles) ? treatmentPlan.planFiles.filter(obj=>obj.type === "application/pdf")[0] : {});
    if(!PlanPDF) PlanPDF = {};
    //console.log(`treatmentPlan.planFiles`,"\n\n",treatmentPlan.planFiles,"\n\n");
            
    const CONTENT = window.GlobalUtil.dangerSetGlobal(CONTENTS[window.Session.Language]); 
    const confirmDic = {
      "ENGLISH": "Yes",
      "ESPAÑOL": "Si"
    }
    return (
      <div id="ViewPlan">
        <Container className="planSummary" fluid="true">
          <Row className="">
            <Column className="matchHeight" col="" xs="" sm="" md="" lg="" xl="">
              <div className="fullHeight component overflowRightScroll">
                <div className="itemHeader">
                  <div className="itemHeaderTextZone">
                    <div className="itemHeaderText">{CONTENT[1]}</div>
                    <div className="itemHeaderSubText">{CONTENT[2]}</div>
                  </div>
                </div>
                <div className="">
                  <Row className="">
                    <Column className="" col="" xs="" sm="" md="" lg="" xl="">
                      <div className="sectionValueZone">
                        <SectionValue
                          section={CONTENT[3]}
                          value={treatmentPlan._id}
                        />
                        <SectionValue
                          section={CONTENT[4]}
                          value={createdAt}
                        />
                        <SectionValue
                          section={CONTENT[5]}
                          value={`${currentUser.firstName} ${currentUser.lastName}`}
                        />
                        {
                          treatmentPlan.approvedByName && <SectionValue
                            section={CONTENT[7]}
                            value={treatmentPlan.approvedByName}
                          />
                        }
                        {
                            treatmentPlan.alignersSchedule.numOfAlignersTop
                            ? <SectionValue
                                section={CONTENT[8]}
                                value={treatmentPlan.alignersSchedule.numOfAlignersTop}
                              />
                            : null
                        }
                        {
                            treatmentPlan.alignersSchedule.numOfAlignersBottom
                            ? <SectionValue
                                section={CONTENT[9]}
                                value={treatmentPlan.alignersSchedule.numOfAlignersBottom}
                              />
                            : null
                        }
                        {
                            treatmentPlan.totalNumOfAligners && <SectionValue
                            section={CONTENT[10]}
                            value={treatmentPlan.totalNumOfAligners ? `${treatmentPlan.totalNumOfAligners/4} Months` : ""}
                          />
                        }
                        {
                          treatmentPlan.notes && <SectionValue
                            section={CONTENT[11]}
                            value={treatmentPlan.notes}
                          />
                        }
                        {/*<SectionValue
                          section={CONTENT[11]}
                          value={treatmentPlan.status}
                        />*/}
                        <SectionValue
                          section={CONTENT[12]}
                          value={<span className="">{(currentUser.purchasedPlan ? confirmDic[window.Session.Language] : "No")}</span>}
                        />
                        {
                          !currentUser.purchasedPlan && <SectionValue
                            section={"Status"}
                            value={<span className="">Creating</span>}
                          />
                        }
                        
                        {/*(!treatmentPlan.OID && (treatmentPlan.planFiles && (treatmentPlan.planFiles.length > 0))) && <NavLink style={{marginLeft: "30px"}} className={"button button1"} to={`/checkout/${}`}>{CONTENT[13]}</NavLink>*/}
                      </div>
                    </Column>
                  </Row>
                </div>
              </div>
            </Column>
          </Row>
          <Row>
            {
              (TP_STEP === "UPLOAD")
              ? <Column className="matchHeight" style={{"minWidth": "300px"}} col="" xs="" sm="" md="" lg="" xl="">
                  <div className="fullHeight component" style={{"border": "none", "boxShadow": "none"}}>
                    <div className="itemHeader d-flex">
                      <div className="itemHeaderTextZone text-center">
                        <div className="itemHeaderText">{CONTENT[17]}</div>
                        <div className="itemHeaderSubText">{CONTENT[18]}</div>
                      </div>
                    </div>
                    <div className="image" style={{"maxWidth": "300px", "margin":"auto"}}>
                      <img src={Step4} alt=""/>
                    </div>
                  </div>
                </Column>
              :  null
            } 
          </Row>
        </Container>

        {
          (treatmentPlan.planFiles && (treatmentPlan.planFiles.length > 0))
          ? <React.Fragment>
              <Container className="" fluid="true">
                <Row className="">
                  <Column className="matchHeight" col="" xs="" sm="" md="" lg="" xl="">
                    <div className="component fullHeight">
                      <div className="itemHeader">
                        <div className="itemHeaderTextZone">
                          <div className="itemHeaderText">{CONTENT[14]}</div>
                        </div>
                      </div>
                      <div className="planFiles">
                        {
                          treatmentPlan.planFiles.map((object, index)=>{
                            return(
                              <div className="planFile" key={index}>
                                <div className="icon">
                                  {
                                    TYPES[object.type]
                                    ? TYPES[object.type]
                                    : <i className="far fa-folder"></i>
                                  }
                                </div>
                                <div className="name">
                                  <span>{object.name}</span>
                                </div>
                                <div className="type">
                                  Type: {object.type}
                                </div>
                                <div className="buttonGroup">
                                  <a href={object.url} className="button button1" target="_blank">{CONTENT[15]}</a>
                                </div>
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                  </Column>
                </Row>
              </Container> 
              <Container className="" fluid="true">
                <Row className="">
                  <Column className="matchHeight" col="" xs="" sm="" md="" lg="" xl="">
                    <div className="component fullHeight">
                      <div className="itemHeader">
                        <div className="itemHeaderTextZone">
                          <div className="itemHeaderText">{CONTENT[16]}</div>
                        </div>
                      </div>
                      <iframe width="100%" height="1000px" src={PlanPDF.url}></iframe>
                    </div>
                  </Column>
                </Row>
              </Container>
            </React.Fragment>
          : null
        }
      </div>
    );
  }
}


const TYPES = {
  "application/pdf": <i className="far fa-file-pdf"></i>,
  "image/png": <i className="far fa-file-image"></i>,
  "image/jpeg": <i className="far fa-file-image"></i>,
  "image/jpg": <i className="far fa-file-image"></i>,
  "image/svg": <i className="far fa-file-image"></i>,
  "video/mp4": <i className="far fa-file-video"></i>,
}
export default TreatementPlan;
      




