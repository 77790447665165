import React, { useState, useRef } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import TemplateAction from './templateAction';
import EmailSender from 'admin/components/EmailSender/index';
import DialogProgressLogs from 'admin/components/DialogProgressLogs/index';
import API_CALLS from 'API_CALLS/index';
import ImageUtils from "global/utils/image-utils";
const IMAGE_UTILS =  new ImageUtils();
const {Container, Row, Column} = window.LayoutBuilder;
const MUTATION_EDIT = API_CALLS.TREATMENT_PLANS.MUTATIONS.update();
/*

//GET AND SHOW CURRENT USER
//CREATE NEW OBJECT FOR NEW TREATMENT PLAN
//SAVE NEW PLAN
//SEND DOCTOR AN EMAIL WITH A LINK
//CC DAD ON THE EMAIL WITH THE LINK
//CLOSE CURRENT TASK
//CREATE NEW TASK WITH (UPLOAD_TREATMENT_PLAN)
  //USER ID (UID)
  //ALIGNMENT PLAN ID (TPID)
  //


*/


//UPLOAD_IMAGES

function GetDREmail(props) {     
  var {doctorID} = props;        
  const { loading, error, data, refetch } = useQuery(API_CALLS.REPORTS.QUERIES.getDoctorEmail(), {
    variables: {id: doctorID},
    fetchPolicy: "no-cache"
  });
  if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;
  //console.log(`data`,"\n\n",data,"\n\n");
  return (
    <div className="" style={{"marginTop":"-15px","padding":"0 0 5px 10px"}}>
      <strong>Email:</strong> {data.getDoctorEmail}
    </div>
  );
}



const FORM_FIELDS = (obj)=>{
  return [
    {
      title: <div className="formTitle"><span className="">Initial Mouth Pictures</span></div>,
      type: "fileUploadList",
      name: "offerRequest,mouthPictures",
      maxNumber: 100,
      required: false,
      defaultValue: "",
      defaultValuePath: "offerRequest,mouthPictures",
      maxWidth: "150px",
      filePath: [
        "USERS",
        obj.UID,
        "PLAN_REQUEST",
        "INITAL_MOUTH"
      ],
      hideBlanks: true,
      showDownload: true,
      showDownloadAllButton: true,
      onChange: (newFileList, callBack=()=>{})=>IMAGE_UTILS.onChangeFile(
        newFileList, 
        obj.id,
        "offerRequest,mouthPictures", 
        MUTATION_EDIT,
        window.CurObj,
        ()=>{
          window.APIGetAgain()
          callBack();
        })
    },
    {
      title: <div className="formTitle"><span className="">Mouth Scan Files</span></div>,
      type: "fileUploadList",
      name: "offerRequest,otherFiles",
      maxNumber: 100,
      required: false,
      defaultValue: "",
      defaultValuePath: "offerRequest,otherFiles",
      maxWidth: "150px",
      filePath: [
        "USERS",
        obj.UID,
        "PLAN_REQUEST",
        "INITAL_SCANS"
      ],
      hideBlanks: true,
      showDownload: true,
      showDownloadAllButton: true,
      onChange: (newFileList, callBack=()=>{})=>IMAGE_UTILS.onChangeFile(
        newFileList, 
        obj.id,
        "offerRequest,otherFiles", 
        MUTATION_EDIT,
        window.CurObj,
        ()=>{
          window.APIGetAgain()
          callBack();
        })
    },
    {
      title: <div className="formTitle"><span className="">Chief Complaint</span></div>,
      subTitle: <div className="formSubTitle"><span className="">Chief Complaint for this user.</span></div>,
      type: "textArea",
      name: "offerRequest,chiefComplaint",
      rows: 6,
      required: true,
      defaultValue: "",
      defaultValuePath: "offerRequest,chiefComplaint",
      errorMessage: "Chief Complaint is required"
    },
    {
      title: <div className="formTitle"><span className="">Notes</span></div>,
      subTitle: <div className="formSubTitle"><span className="">Additional Notes for this alignment plan request. These are only seeen by the admins and doctors. The user CANNOT see these notes.</span></div>,
      type: "textArea",
      name: "offerRequest,notes",
      rows: 6,
      required: false,
      defaultValue: "",
      defaultValuePath: "offerRequest,notes",
      errorMessage: "Notes is required"
    },
    {
      title: <div className="formTitle"><span className="">Laboratory</span></div>,
      subTitle: <div className="formSubTitle"><span className="">Who is creating this treatment plan?</span></div>,
      type: "selectAPI",
      onMount: (callBack) => {
        window.Client.query({
          query: API_CALLS.USERS.QUERIES.all("email firstName lastName"),
          variables: {
            "status": "ACTIVE",
            "manual": [
              {
                path: "role",
                listValues: JSON.stringify(["LABORATORY", "ADMIN"])
              }
            ]
          }
        })
        .then(result => {
          var doctors = result.data.users.map((doc, index)=>{
            return({
              "value": doc._id,
              "title": `${doc.firstName} ${doc.lastName} - ${doc.email}`
            })
          });
          callBack("options", [{
            "value": "",
            "title": "Choose one",
            "disabled": true
          }, ...doctors])                
        });
      },
      name: "LABORATORY",
      required: false,
      defaultValue: "",
      defaultValuePath: "LABORATORY",
      errorMessage: "Who to email is required"
    },
    {
      title: <div className="formTitle"><span className="">Approving Doctor</span></div>,
      subTitle: <div className="formSubTitle"><span className="">What doctor will approve this once it's been uploaded by the lab?</span></div>,
      type: "selectAPI",
      onMount: (callBack) => {
        window.Client.query({
          query: API_CALLS.USERS.QUERIES.all("email firstName lastName"),
          variables: {
            "status": "ACTIVE",
            "manual": [
              {
                path: "role",
                listValues: JSON.stringify(["DOCTOR", "ADMIN"])
              }
            ]
          }
        })
        .then(result => {
          var doctors = result.data.users.map((doc, index)=>{
            return({
              "value": doc._id,
              "title": `${doc.firstName} ${doc.lastName} - ${doc.email}`
            })
          });
          callBack("options", [{
            "value": "",
            "title": "Choose one",
            "disabled": true
          }, ...doctors])                
        });
      },
      name: "DOCTOR",
      required: false,
      defaultValue: "",
      defaultValuePath: "DOCTOR",
      errorMessage: "Who to email is required"
    }
    // {
    //   title: <div className="formTitle"><span className="">Progress Logs</span></div>,
    //   subTitle: <div className="formSubTitle"><span className="">Please list off every action taken. From contacting the user, to sending emails, to contacting doctors and more. ANY AND EVERY action taken for this treatment plan.</span></div>,
    //   type: "notesList",
    //   name: "progressLogs",
    //   defaultValue: "",
    //   required: false,
    //   defaultValuePath: "progressLogs",
    //   errorMessage: "This is required"
    // }
  ]
};
/*
this.APIUpdate({
  TPID: curObj.id, 
  groupList: groupListClean,
  rejectStepNotes: "", //THIS SHOULD BE REMOVED OR ADDED EVERY STEP
  changeStep: 1 //MOVE TO NEXT STEP WHEN DONE
}, dontFinish);
*/

class Index extends TemplateAction {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
    };    
    this.validate = this.validate.bind(this);
    this.APIGet = this.APIGet.bind(this);
    this.APIUpdate = this.APIUpdate.bind(this);
    this.APICreate = this.APICreate.bind(this);
  }


  validate(dontFinish){
    var curObj = {...this.curObj};
    Object.keys(curObj).map((key, index)=>{
      if((curObj[key]  === undefined) || (curObj[key]  === "")) delete curObj[key] 
    })
    curObj = window.GlobalUtil.stripOutFromObj(curObj, "__typename")
    //console.log("Saved","\n\n",curObj,"\n\n");
    /*
      SHOULD HAVE
        _id
        UID
        status
        offerRequest: {
          chiefComplaint String
          notes String
          createAt Date
        }
    */
    //return false;
    if(dontFinish) this.APIUpdate(curObj);
    else this.APICreate(curObj);

    //this.APICompleteTask(curObj);
  }




  APIGet(){       
    //console.log(`IDDic`,"\n\n",IDDic,"\n\n");          
    var inputObj = {
      query: API_CALLS.TREATMENT_PLANS.QUERIES.singleFull(),
      variables: {
        id: this.props.curObj._id,
        include: [
          {
            name: "user"
          }
        ]
      },
      fetchPolicy: "no-cache"
    }
    window.Client.query(inputObj)
    .then((obj) => {
      var {data, loading} = obj;            
      var treatmentPlan = window.GlobalUtil.stripOutFromObj(data.treatmentPlan, "__typename");
            
      //console.log("window.CurObj","\n\n",window.CurObj,"\n\n");
      this.curObj = {
        ...this.curObj, 
        ...treatmentPlan,
        id: treatmentPlan._id,
        offerRequest: (treatmentPlan.offerRequest ? treatmentPlan.offerRequest : {
          mouthPictures: [],
          otherFiles: []
        })
      };
      window.CurObj = {...this.curObj};
      this.setState({
        Plan: treatmentPlan,
        User: window.GlobalUtil.deepGetFromString(treatmentPlan, "user", false),
        hideForm: true
      }, ()=>{
        this.setState({hideForm: false});
      })
    })
    .catch((error)=>{
      console.log("query plan failed", error);
    });
  }



  APIUpdate(newObj){
    var inputObj = {
      mutation: this.props.API_CALLS.MUTATION_UPDATE,
      variables: newObj
    }
    //console.log(`newObj`,"\n\n",newObj,"\n\n");
    window.Client.mutate(inputObj)
    .then((obj) => {
      var {data, loading} = obj;
    })
    .catch((error)=>{
      console.log("onCloneCatch catch", error);
    });
  }



  APICreate(newObj){
    //console.log("this.props.API_CALLS","\n\n",this.props.API_CALLS,"\n\n");
    //console.log("APICreate newObj","\n\n",newObj,"\n\n");
    newObj.rejectStepNotes = "";//THIS SHOULD BE REMOVED OR ADDED EVERY STEP
    newObj.changeStep = 1;//MOVE TO NEXT STEP WHEN DONE
    var inputObj = {
      mutation: this.props.API_CALLS.MUTATION_PLAN_REQUEST,
      variables: newObj
    }
    window.Client.mutate(inputObj)
    .then((obj) => {
      var {data, loading} = obj;
      window.FlashMenuItem("/admin/plans/upload");
      this.onClose(this.CurRef);
    })
    .catch((error)=>{
      console.log("APICreate Request TP failed catch", error);
      this.onClose(this.CurRef);
    });
  }



  render(){
    var {isValid, Plan={}, User, hideForm, ShowPreview, isLoading} = this.state;        
    return (
      this.SlideInRight(
        "Request Treatment Plan", 
        (curRef)=>{
          this.CurRef = curRef;
          return(
            <React.Fragment>
              <div className="buttonGroup" style={{"flex":1}}>
                <button className="button button2 btn-color6" onClick={()=>{
                  if(curRef) curRef.onClose()
                }}>Close</button>
              </div>
              <div className="buttonGroup">
                <button 
                  className={`button button1 ${(isValid && !isLoading) ? "" : "disabled"}`} 
                  onClick={()=>{ 
                  this.validate();
                  }}>
                  Request Complete
                </button>
                <DialogProgressLogs
                  ID={this.props.curObj._id}
                  API_NAME={'TREATMENT_PLANS'}
                  FIELD={'treatmentPlan'}
                  asButton={true}
                  className={'button button1 btn-color4'}
                  style={{"width":"100px","minWidth":"0"}}
                  customLinkText={<span><span>Logs</span>&nbsp;&nbsp;<i className="fas fa-clipboard-list"/></span>}
                />
              </div>
            </React.Fragment>
          )
        }, 
        ()=>{
          return(
            <div className="">
              <div className="PreviewForm">
                {
                  Plan.rejectStepNotes &&
                  <div id="ErrorNotes">
                    <h2>REJECTED REASON:</h2>
                    {Plan.rejectStepNotes}
                  </div>
                }
                {
                  User &&
                  this.previewUser(User)
                }
                <Row>
                  <Column 
                    className={"borderColor5"}
                    style={{"paddingBottom":"0px"}}
                  >
                  <div className="previewFormFieldTitles2">
                    <div className="previewFormFieldTitle">Needed Input</div>
                    <div className="previewFormFieldSubTitle">Fill out as much of the fields below as possible</div>
                  </div>
                  </Column>
                </Row>
              </div>
              {
                !hideForm &&
                this.FormBuilderZone(FORM_FIELDS)
              }              
            </div>
          )
        }
      )
    );
  }
}




export default Index;