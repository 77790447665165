import React from 'react';
//import { gql, useQuery, useMutation } from '@apollo/client';
import MUTATIONS from "./MUTATIONS";
import QUERIES from "./QUERIES";
import COUPONS from "../COUPONS";
import REFERRALS from "../REFERRALS/index";
import ImageUtils from "global/utils/image-utils";
const IMAGE_UTILS =  new ImageUtils();


const FORM_FIELDS = (obj={})=>{
  const MUTATION_EDIT = MUTATIONS.update();        
  const QUERY_GET_DISTINCT = QUERIES.distinct();
  const QUERY_GET_USERS = QUERIES.all(`firstName lastName email`);
  const QUERY_GET_COUPONS = COUPONS.QUERIES.all(`name`);
  const QUERY_GET_REFERRALS = REFERRALS.QUERIES.all(`CODE name`);
  return(
    [ //defaultValuePath used to set default value form object
      {
        title: <div className="formTitle"><span className="">ID</span></div>,
        subTitle: <div className="formSubTitle"><span className="">Product ID CANT BE CHANGED</span></div>,
        type: "text",
        name: "id",
        required: false,
        editable: false,
        defaultValue: "",
        defaultValuePath: "_id",
        hide: true
      },
      {
        title: <div className="formTitle"><span className="">User Picture</span></div>,
        type: "editableImages",
        name: "userPicture",
        maxNumber: 1,
        required: false,
        defaultValue: "",
        defaultValuePath: "userPicture",
        maxWidth: "150px",
        filePath: [
          "USERS",
          obj.id,
          "PICTURE"
        ],
        onChangeImage: (index, newImageObj)=>IMAGE_UTILS.onChangeImage(
          index, 
          obj.id,
          newImageObj, 
          "userPicture", 
          MUTATION_EDIT,
          window.CurObj,
          ()=>{
            window.APIGetAgain()
          })
      },
      {
        title: <div className="formTitle"><span className="">Progress Pictures</span></div>,
        type: "fileUploadList",
        name: "progressPictures",
        maxNumber: 10,
        required: false,
        defaultValue: "",
        defaultValuePath: "progressPictures",
        maxWidth: "150px",
        filePath: [
          "USERS",
          obj.id,
          "PROGRESS_IMAGES"
        ],
        onChange: (newFileList, callBack=()=>{})=>IMAGE_UTILS.onChangeFile(
          newFileList, 
          obj.id,
          "progressPictures", 
          MUTATION_EDIT,
          window.CurObj,
          ()=>{
            window.APIGetAgain()
          }
        )
      },
      {
        title: <div className="formTitle"><span className="">First Name</span></div>,
        subTitle: <div className="formSubTitle"><span className="">Every user needs a first name</span></div>,
        type: "text",
        name: "firstName",
        required: true,
        defaultValue: "",
        defaultValuePath: "firstName",
        errorMessage: "Each user needs a First Name"
      },
      {
        title: <div className="formTitle"><span className="">Last Name</span></div>,
        subTitle: <div className="formSubTitle"><span className="">Every user needs a last name</span></div>,
        type: "text",
        name: "lastName",
        required: true,
        defaultValue: "",
        defaultValuePath: "lastName",
        errorMessage: "Each user needs a Last Name"
      },
      {
        title: <div className="formTitle"><span className="">Email Address</span></div>,
        subTitle: <div className="formSubTitle"><span className="">Every user needs a last name</span></div>,
        type: "email",
        name: "email",
        required: true,
        defaultValue: "",
        defaultValuePath: "email",
        errorMessage: "Each user needs an Email"
      },
      {
        title: <div className="formTitle"><span className="">Phone Number</span></div>,
        subTitle: <div className="formSubTitle"><span className="">Every user needs a phone number</span></div>,
        type: "phone",
        name: "phone",
        required: false,
        defaultValue: "",
        defaultValuePath: "phone",
        errorMessage: "Each user needs a Phone Number"
      },
      {
        title: <div className="formTitle"><span className="">New Password</span></div>,
        subTitle: <div className="formSubTitle"><span className="">Use this to force change the password for a user</span></div>,
        type: "password",
        name: "newPassword",
        required: false,
        defaultValue: "",
        defaultValuePath: ""
      },
      {
        title: <div className="formTitle"><span className="">Address</span></div>,
        subTitle: <div className="formSubTitle">User Shipping Address</div>,
        type: "conditional",
        condition: (curObj)=>{            
          //if(curObj.type && curObj.type === "SUBSCRIPTION") return true;
          return true;
        },
        listOfFields: [
          {
            title: <div className="formTitle"><span className="">Street</span></div>,
            subTitle: <div className="formSubTitle"><span className="">Street address where things will be shipped</span></div>,
            type: "text",
            name: "address,street",
            required: false,
            defaultValue: "",
            defaultValuePath: "address,street",
            errorMessage: "Each user needs this for shipping"
          },
          {
            title: <div className="formTitle"><span className="">Street 2</span></div>,
            subTitle: <div className="formSubTitle"><span className="">You can use this for floor, suite, and unit numbers, etc.</span></div>,
            type: "text",
            name: "address,street2",
            required: false,
            defaultValue: "",
            defaultValuePath: "address,street2",
            errorMessage: "Each user needs this for shipping"
          },
          {
            title: <div className="formTitle"><span className="">City</span></div>,
            subTitle: <div className="formSubTitle"><span className="">What is the locations city</span></div>,
            type: "text",
            name: "address,city",
            required: false,
            defaultValue: "",
            defaultValuePath: "address,city",
            errorMessage: "Each user needs this for shipping"
          },
          {
            title: <div className="formTitle"><span className="">State</span></div>,
            subTitle: <div className="formSubTitle"><span className="">Please choose a state</span></div>,
            type: "selectState",
            name: "address,state",
            required: false,
            defaultValue: "",
            defaultValuePath: "address,state",
            errorMessage: "Each user needs this for shipping"
          },
          {
            title: <div className="formTitle"><span className="">Country</span></div>,
            subTitle: <div className="formSubTitle"><span className="">What country is this going to</span></div>,
            type: "text",
            name: "address,country",
            required: false,
            defaultValue: "",
            defaultValuePath: "address,country",
            errorMessage: "Each user needs this for shipping"
          },
          {
            title: <div className="formTitle"><span className="">Zipcode</span></div>,
            subTitle: <div className="formSubTitle"><span className="">Please include your zipcode too</span></div>,
            type: "text",
            name: "address,zipcode",
            required: false,
            defaultValue: "",
            defaultValuePath: "address,zipcode",
            errorMessage: "Each user needs this for shipping"
          }
        ]
      },
      {
        title: <div className="formTitle"><span className="">Role</span></div>,
        subTitle: <div className="formSubTitle"><span className="">Must be one of the following.</span></div>,
        type: "select",
        name: "role",
        options: [
          {
            "value": "",
            "title": "Choose one",
            "disabled": true
          },
          {
            "value": "USER",
            "title": "USER"
          },
          {
            "value": "PATIENT",
            "title": "PATIENT"
          },
          {
            "value": "DOCTOR",
            "title": "DOCTOR"
          },
          {
            "value": "LABORATORY",
            "title": "LABORATORY"
          },
          {
            "value": "ADMIN",
            "title": "ADMIN"
          }
        ],
        required: true,
        defaultValue: "USER",
        defaultValuePath: "role",
        errorMessage: "Role is required"
      },
      {
        condition: obj=>obj.role === "PATIENT",
        title: <div className="formTitle"><span className="">Assign to Doctor</span></div>,
        subTitle: <div className="formSubTitle"><span className="">Must have a doctor assigned to each patient</span></div>,
        type: "selectAPI",
        onMount: (callBack) => {
          window.Client.query({
            query: QUERY_GET_USERS,
            variables: {
              "manual": [
                {
                  path: "role",
                  val: "DOCTOR"
                }
              ]
            }
          })
          .then(result => {
            var usersOptions = result.data.users.map((doctor, index)=>{
              return({
                "value": doctor._id,
                "title": `${doctor.firstName} ${doctor.lastName}`
              })
            });
            callBack("options", [{
              "value": "",
              "title": "Choose one",
              "disabled": true
            }, ...usersOptions])
            // console.log("usersOptions");
            // console.log(usersOptions,"\n\n");
                  
          });
        },
        name: "DOCID",
        required: false,
        defaultValue: "",
        defaultValuePath: "DOCID",
        errorMessage: "DOCID is required for all PATIENTS"
      },
      {
        title: <div className="formTitle"><span className="">Plan ID</span></div>,
        subTitle: <div className="formSubTitle"><span className="">This will connect the user to their plan</span></div>,
        type: "text",
        name: "TPID",
        required: false,
        defaultValue: "",
        defaultValuePath: "TPID",
        errorMessage: "Each user needs this for shipping"
      },
      {
        title: <div className="formTitle"><span className="">Treatment Plan Approved?</span></div>,
        subTitle: <div className="formSubTitle"><span className="">This should be posivtive when the treatment plan is approved.</span></div>,
        type: "toggle",
        name: "approved",
        required: false,
        defaultValue: "",
        defaultValuePath: "approved",
        errorMessage: "Each user needs this for shipping"
      },
      {
        condition: (curObj)=>{
          return curObj.approved;
        },
        title: <div className="formTitle"><span className="">Date this was approved.</span></div>,
        subTitle: <div className="formSubTitle">We want to know when it was approved to know when to pay the sales men.</div>,
        type: "datePicker",
        name: "approvedAt",
        placeholder: 0,
        required: false,
        defaultValue: 0
      },
      {
        title: <div className="formTitle"><span className="">Website</span></div>,
        subTitle: <div className="formSubTitle"><span className="">What website is this user registered with?</span></div>,
        type: "selectAPIWithAdd",
        onMount: (callBack) => {
          window.Client.query({
            query: QUERY_GET_DISTINCT,
            variables: {
              field: "website" //FIELD TO GET ALL OPTIONS
            },
            fetchPolicy: "no-cache"
          })
          .then(result => {                  
            var fullOptionList = result.data.userDistinct.map((DisOption, index)=>{
              return({
                "value": DisOption,
                "title": DisOption
              })
            });
            var DefaultValues = [
              {
                "value": "",
                "title": "Choose one",
                "disabled": true
              },
              {
                "value": "SMILESCLUB",
                "title": "SMILESCLUB"
              },
              {
                "value": "SONRISASCLUB",
                "title": "SONRISASCLUB"
              }
            ];
            const uniqueArray = window.GlobalUtil.arrayOfObjectsOnlyUnique([...DefaultValues, ...fullOptionList], "value");
            //window.GlobalUtil.consoleLog("uniqueArray", [uniqueArray]);
            callBack("options", uniqueArray)
          });
        },
        name: "website",
        required: false
      },
      {
        title: <div className="formTitle"><span className="">Preferred Language</span></div>,
        subTitle: <div className="formSubTitle"><span className="">What language does this user perfer their emails.</span></div>,
        type: "selectAPIWithAdd",
        onMount: (callBack) => {
          window.Client.query({
            query: QUERY_GET_DISTINCT,
            variables: {
              field: "preferredLanguage" //FIELD TO GET ALL OPTIONS
            },
            fetchPolicy: "no-cache"
          })
          .then(result => {                  
            var fullOptionList = result.data.userDistinct.map((DisOption, index)=>{
              return({
                "value": DisOption,
                "title": DisOption
              })
            });
            var DefaultValues = [
              {
                "value": "",
                "title": "Choose one",
                "disabled": true
              },
              {
                "value": "ENGLISH",
                "title": "ENGLISH"
              },
              {
                "value": "ESPAÑOL",
                "title": "ESPAÑOL"
              }
            ];
            const uniqueArray = window.GlobalUtil.arrayOfObjectsOnlyUnique([...DefaultValues, ...fullOptionList], "value");
            //window.GlobalUtil.consoleLog("uniqueArray", [uniqueArray]);
            callBack("options", uniqueArray)
          });
        },
        name: "preferredLanguage",
        required: false
      },
      {
        title: <div className="formTitle"><span className="">Status</span></div>,
        subTitle: <div className="formSubTitle"><span className="">Choose one of the following status. Active means its live.</span></div>,
        type: "select",
        name: "status",
        options: [
          {
            "value": "",
            "title": "Choose one",
            "disabled": true
          },
          {
            "value": "ACTIVE",
            "title": "ACTIVE"
          },
          {
            "value": "PENDING",
            "title": "PENDING"
          },
          {
            "value": "INACTIVE",
            "title": "INACTIVE"
          }
        ],
        required: true,
        defaultValue: "ACTIVE",
        defaultValuePath: "status",
        errorMessage: "Status is required"
      },
      {
        title: <div><br/><hr/><br/></div>,
        type: "sectionDivider",
        name: "",
        defaultValue: "",
        defaultValuePath: ""
      },
      {
        title: <div className="formTitle"><span className="">Codes and Coupons</span></div>,
        subTitle: <div className="formSubTitle"><span className="">See and edit every code by this user.</span></div>,
        type: "updateCouponUserCode",
        name: "AFFILIATE,codes",
        isUser: true,
        required: true,
        defaultValue: "",
        defaultValuePath: "AFFILIATE,codes"
      }
      // {
      //   title: <div className="formTitle"><span className="">Images</span></div>,
      //   type: "text",
      //   name: "images",
      //   placeholder: "Images",
      //   required: false
      // }
    ]
  )
};





export default FORM_FIELDS;