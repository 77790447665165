import React from 'react';
import LogoDark from 'global/img/logo-dark.svg';
import LogoLight from 'global/img/logo-light.svg';
import {NavLink, Link} from 'react-router-dom';

const Component = (props)=>{
  var {type="dark", noLink} = props;
  var Logos = {
  	dark: LogoDark,
  	light: LogoLight
  }
  if(window.SiteSettings && window.SiteSettings.logosObj) {
  	var TempDark = window.GlobalUtil.deepGetFromString(window, "SiteSettings,logosObj,logoDark,url", false);
    if(TempDark) Logos.dark = TempDark;
  	var TempLight = window.GlobalUtil.deepGetFromString(window, "SiteSettings,logosObj,logoLight,url", false);
    if(TempLight) Logos.light = TempLight;
  }
  if(noLink) return(
    <div className="logo-image">
      <img src={type=="dark" ? Logos.dark : Logos.light } alt=""/>
    </div>
  )
  return(
    <div className="logo-image">
      <NavLink className="nav-link" to={{"pathname": "/"}}>
        <img src={type=="dark" ? Logos.dark : Logos.light } alt=""/>
      </NavLink>
    </div>
  )
}

export default Component;