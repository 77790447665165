import React, { useState, useRef } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import axios from "axios";
//import LogoImages from "./logoImages";
//import SocialLinks from "./socialLinks";
//<LogoImages onUpdateSiteSettings={this.props.onUpdateSiteSettings} />
import API_CALLS from 'API_CALLS/index';
import {PAGES as FailedLinks} from '../failed/index';
import Emails from '../emails/index';
import {
    BrowserRouter as Router, StaticRouter, Route, NavLink, Link, Redirect, Switch
    // etc.
} from 'react-router-dom';
import {GetSiteSettings} from 'global/utils/helper-functions';
import Loading from 'global/components/Loading/index';
import FormBuilder from 'global/components/FormBuilder/index';
import EditableImage from 'global/components/EditableImage/index';
const {Container, Row, Column} = window.LayoutBuilder;
const QUERY_SINGLE = API_CALLS.SITE_SETTINGS.QUERIES.single();
const MUTATION_EDIT = API_CALLS.SITE_SETTINGS.MUTATIONS.update();



const ValidateObject = (newObject)=>{
  if(!newObject) return;
  var newObj = {...newObject, id: newObject._id};
  // console.log("newObject");
  // console.log(newObject,"\n\n");
  // console.log("window.CurObj");
  // console.log(window.CurObj,"\n\n");

  Object.keys(newObj).map((key, index)=>{
    if(newObj[key] === undefined || newObj[key] === "" || newObj[key] === null || (Array.isArray(newObj[key]) && newObj[key].length < 1)) delete newObj[key];
  })  
  delete newObj.meta;

  return newObj;      
};




class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentObj: {},
      //loading: true
    };    
  }

  componentDidMount(){
  }

  componentWillUnmount(){
    clearTimeout(this.TimeoutUpdate);
  }

  handleChangeObj = (newObj) => {
    var {currentObj} = this.state;
    this.setState({
      currentObj: {...currentObj, ...newObj}
    })
    //console.log("newObj");
    //console.log(newObj,"\n\n");
    if(this.TimeoutUpdate) clearTimeout(this.TimeoutUpdate);
    this.TimeoutUpdate = setTimeout(()=>{
      this.onSubmitUpdate(newObj);
    }, 500);
  }

  handleOnValidate = (newValid) => {

  }


  onSubmitUpdate = (newObj) => {
    var cleanObj = ValidateObject(newObj);   
    if(!cleanObj) return;
    cleanObj = window.GlobalUtil.stripOutFromObj(cleanObj, "__typename");
          
    window.Client.mutate({
      mutation: MUTATION_EDIT,
      variables: cleanObj
    })
    .then(result => {
      //console.log("result");
      //console.log(result,"\n\n");
      console.log("SUCCESS UPDATE SETTINGS", result);  
      this.setState({addNewDoctor: false});
      this.onGetSettings()  
    })
    .catch((error)=>{
      console.log("onSubmitUpdate catch", error);
      this.setState({addNewDoctor: false});
    })
  }


  onGetSettings = (fullReload=false) => {
    if(fullReload) this.setState({loading: true});
    GetSiteSettings({
      forceUpdate: true, 
      callBack: (cleanObj)=>{
        //console.log("cleanObj","\n\n",cleanObj,"\n\n");
        window.SiteSettings = cleanObj;
        if(!this) return;
        if(fullReload) this.setState({currentObj: cleanObj, loading: false})
        else this.setState({currentObj: cleanObj})
      }, 
      //triggerEvent: true
    });
  }


  onUpdateDB = () => {
    // window.Client.mutate({
    //   mutation: API_CALLS.TREATMENT_PLANS.MUTATIONS.approve(),
    //   variables: {"id":"62e18c16ec8981104ae900ca"}
    // })
    // .then(result => {
    //   console.log(`DB Updated - result`,"\n\n",result,"\n\n");
              
    // })
    // .catch((error)=>{
    //   console.log("Update DB ERROR \n", error);
    // });
    window.Client.query({
      query: API_CALLS.REPORTS.QUERIES.updateDB(),
      variables: {}
    })
    .then(result => {
      console.log(`DB Updated - result`,"\n\n",result,"\n\n");
              
    })
    .catch((error)=>{
      console.log("Update DB ERROR \n", error);
    });
  }

  render(){
    var {loading, addNewDoctor} = this.state;
    var currentObj = {...window.SiteSettings};
    if(loading) return <div className="contentRestrains">
      <Loading 
        header={undefined}
        body={undefined}
        floating={true}
        overlay={true}
        inlineStyles={{
          loadingIcon: {
            "maxWidth": "250px"
          }
        }}
      />
    </div>

    return (
      <div id="Settings" className="">
        <Container className="">
          <Row className="">
            <Column className="" col="12" xs="12" sm="12" md="" lg="" xl="">
              <div className="" onClick={this.onUpdateDB}>
                <h1>Site Settings</h1>
              </div>
            </Column>
          </Row>
          <Row className="">
            <Column className="" col="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <div className="">
                <FormBuilder
                  initialValues={currentObj}
                  listOfFields={[ //defaultValuePath used to set default value form object
                    {
                      title: <div style={{"fontSize":"28px","fontWeight":"500","padding":"25px 0 15px"}}>Contact Emails</div>,
                      type: "sectionDivider",
                      name: "sectionDivider",
                      required: false
                    },
                    {
                      title: <div className="formTitle"><span className="">Send Contact Emails To:</span></div>,
                      subTitle: <div className="formSubTitle"><span className="">Addresses to forward any emails for forms</span></div>,
                      type: "wordList",
                      name: "toEmailList",
                      required: true,
                      defaultValue: "",
                      defaultValuePath: "toEmailList",
                      errorMessage: "Comments is required"
                    },
                    {
                      title: <div className="formTitle"><span className="">CC Contact Emails To:</span></div>,
                      subTitle: <div className="formSubTitle"><span className="">Addresses to CC any emails for forms</span></div>,
                      type: "wordList",
                      name: "ccEmailList",
                      required: true,
                      defaultValue: "",
                      defaultValuePath: "ccEmailList",
                      errorMessage: "Comments is required"
                    }
                  ]}
                  onChange={newObj => this.handleChangeObj(newObj)}
                  onValidate={newObj => this.handleOnValidate(newObj)}
                />
              </div>
            </Column>
          </Row>
          <br/>
          <br/>


          {/*<Row className="">
            <Column className="" col="12" xs="12" sm="12" md="" lg="" xl="">
              <div className="">
                <h2>Partner Doctors</h2>
              </div>
            </Column>
            <Column id="PartnerDoctors" col="12" xs="12" sm="12" md="12" lg="12" xl="12">
              {
                (currentObj.partnerDoctors && currentObj.partnerDoctors.length > 0) &&
                currentObj.partnerDoctors.map((object, index)=>{
                  return(
                    <div className="partnerDoctorWrapper d-flex" key={index}>
                      <div className="partnerDoctor">
                        <div className="PDName field">
                          <div className="title">Name</div> <div className="value">{object.name}</div>
                        </div>
                        <div className="PDEmail field">
                          <div className="title">Email</div> <div className="value">{object.email}</div>
                        </div>
                        <div className="PDCompany field">
                          <div className="title">Company</div> <div className="value">{object.company}</div>
                        </div>
                      </div>
                      <i className="fas fa-trash-alt"
                        onClick={()=>{
                          var r = window.confirm("Are you sure you want to delete this?!");
                          if (r == true) {
                            currentObj.partnerDoctors.splice(index, 1);
                            this.handleChangeObj(currentObj);
                          } 
                        }}
                      />
                    </div>
                  )
                })
              }
              {
                !addNewDoctor &&
                <div id="ANDButtonWrapper">
                  <button className="button button1" onClick={()=>this.setState({addNewDoctor: true})}>
                    Add New Doctor
                  </button>
                </div>
              }
            </Column>
            {
              addNewDoctor &&
              <Column id="AddNewDoctorWrapper" col="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <div className="animated fadeIn">
                  <div className="ANDHeader d-flex">
                    <div className="ANDText">
                      Add New Doctor
                    </div>
                    <div className="ANDClose" onClick={()=>this.setState({addNewDoctor: false})}>
                      <i className="fas fa-times"/>
                    </div>
                  </div>
                  <div className="ANDFormWrapper">
                    <NewDoctor
                      handleChangeObj={newObj => {
                        if(!currentObj.partnerDoctors) currentObj.partnerDoctors = [];
                        currentObj.partnerDoctors.push(newObj);
                        this.handleChangeObj(currentObj);
                      }}
                    />
                  </div>
                </div>
              </Column>
            }
            

          </Row>*/}
        </Container>
      </div>
    );
  }
}




class NewDoctor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };    
    this.currentObj = {};
  }
  render(){
    var {isValid} = this.state;
    return(
      <div className="">
        <FormBuilder
          initialValues={this.currentObj}
          listOfFields={[ 
            {
              title: <div className="formTitle"><span className="">Name</span></div>,
              subTitle: <div className="formSubTitle"><span className="">Add your new doctors Name</span></div>,
              type: "text",
              name: "name",
              required: true,
              defaultValue: "",
              defaultValuePath: "name"
            },
            {
              title: <div className="formTitle"><span className="">Email</span></div>,
              subTitle: <div className="formSubTitle"><span className="">Add your new doctors Email</span></div>,
              type: "email",
              name: "email",
              required: true,
              defaultValue: "",
              defaultValuePath: "email"
            },
            {
              title: <div className="formTitle"><span className="">Company</span></div>,
              subTitle: <div className="formSubTitle"><span className="">Add your new doctors Company</span></div>,
              type: "text",
              name: "company",
              required: false,
              defaultValue: "",
              defaultValuePath: "company"
            }
          ]}
          onChange={newObj => {
            this.currentObj=newObj;
          }}
          onValidate={isValid => this.setState({isValid})}
        />
        <button className={`button button1 ${isValid ? "" : "disabled"}`} onClick={()=>{
          this.props.handleChangeObj(this.currentObj)
        }}>Add New</button>
      </div>
    )
  }
}





const SOCIAL_ICONS = [
  {
    "title": "Facebook",
    "name": "Facebook",
    "baseURL": "www.facebook.com",
    "icon": <i className="fab fa-facebook-square"></i>
  },
  {
    "title": "Twitter",
    "name": "Twitter",
    "baseURL": "www.twitter.com",
    "icon": <i className="fab fa-twitter-square"></i>
  },
  {
    "title": "Instagram",
    "name": "Instagram",
    "baseURL": "www.instagram.com",
    "icon": <i className="fab fa-instagram-square"></i>
  },
  {
    "title": "Youtube",
    "name": "Youtube",
    "baseURL": "www.youtube.com",
    "icon": <i className="fab fa-youtube-square"></i>
  }
]





const LOGO_IMAGES = [
  {
    "name": "logoDark",
    "title": "Logo Dark"
  },
  {
    "name": "logoLight",
    "title": "Logo Light"
  },
  {
    "name": "logoIcon",
    "title": "Logo Icon"
  },
  {
    "name": "logoFavicon",
    "title": "Logo Favicon"
  }
];


const PAGES = () => [
  {
    "Name": "Settings",
    "PageName": "Site Settings",
    "Paths": ["/admin/configure/settings"]
  },
  {
    "Name": "Emails",
    "PageName": "Emails",
    "Paths": ["/admin/configure/emails"]
  },
  {
    "Name": "Failed",
    "PageName": "Failed",
    "Paths": ["/admin/configure/failed"],
    "SubPaths": FailedLinks()
  },
];



/* BOOL INPUT
<Row className="">
  <Column className="" col="12" xs="12" sm="12" md="6" lg="8" xl="8">
    <div className="">
      <h3>Email Settings</h3>
      <FormBuilder
        initialValues={currentObj}
        listOfFields={[ //defaultValuePath used to set default value form object
          {
            title: <div className="formTitle"><span className="">Show Field Name</span></div>,
            subTitle: <div className="formSubTitle"><span className="">ON means it will show in the email</span></div>,
            type: "toggle",
            name: "showOnEmail,Name",
            borderBottom: true,
            defaultValue: false,
            defaultValuePath: "showOnEmail,Name",
          },
          {
            title: <div className="formTitle"><span className="">Show Field Date</span></div>,
            subTitle: <div className="formSubTitle"><span className="">ON means it will show in the email</span></div>,
            type: "toggle",
            name: "showOnEmail,Date",
            borderBottom: true,
            defaultValue: false,
            defaultValuePath: "showOnEmail,Date",
          },
          {
            title: <div className="formTitle"><span className="">Show Field Phone</span></div>,
            subTitle: <div className="formSubTitle"><span className="">ON means it will show in the email</span></div>,
            type: "toggle",
            name: "showOnEmail,Phone",
            borderBottom: true,
            defaultValue: false,
            defaultValuePath: "showOnEmail,Phone",
          },
          {
            title: <div className="formTitle"><span className="">Show Field Email</span></div>,
            subTitle: <div className="formSubTitle"><span className="">ON means it will show in the email</span></div>,
            type: "toggle",
            name: "showOnEmail,Email",
            borderBottom: true,
            defaultValue: false,
            defaultValuePath: "showOnEmail,Email",
          },
          {
            title: <div className="formTitle"><span className="">Show Field Zipcode</span></div>,
            subTitle: <div className="formSubTitle"><span className="">ON means it will show in the email</span></div>,
            type: "toggle",
            name: "showOnEmail,Zipcode",
            borderBottom: true,
            defaultValue: false,
            defaultValuePath: "showOnEmail,Zipcode",
          },
          {
            title: <div className="formTitle"><span className="">Show Field Message</span></div>,
            subTitle: <div className="formSubTitle"><span className="">ON means it will show in the email</span></div>,
            type: "toggle",
            name: "showOnEmail,Message",
            borderBottom: true,
            defaultValue: false,
            defaultValuePath: "showOnEmail,Message",
          }
        ]}
        onChange={newObj => this.handleChangeObj(newObj)}
        onValidate={newObj => this.handleOnValidate(newObj)}
      />
    </div>
  </Column>
</Row>
*/


const PageSwitcher = (props) => {
  return(
    <Switch> 
      <Route path={'/admin/configure/failed'}>
        <Redirect
          to={'/admin/configure/failed/active'}
        />
      </Route>
      <Route 
        path={[`/admin/configure/emails`]} 
        render={props=>{
          return(
            <Emails
              {...props}
            />
          )
        }} 
      />
      <Route 
        path={[`/admin/configure/settings`]} 
        render={props=>{
          return(
            <Index
              {...props}
            />
          )
        }} 
      />
      <Route path={'/admin/configure'}>
        <Redirect
          to={'/admin/configure/settings'}
        />
      </Route>
    </Switch>
  )
}

export default PageSwitcher;
      
export {PAGES}