import React from 'react';
//import { gql, useQuery, useMutation } from '@apollo/client';
import FormBuilder from "global/components/FormBuilder/index";
import Loading from 'global/components/Loading/index';
import API_CALLS from 'API_CALLS/index';
import FieldsBasic from "./FieldsBasic";
import FieldsByType from "./FieldsByType";

/*
  <EmailSender
    curObj={{
      whatEmail: "",
      emailData: {
        whoToEmail: "",
        whoToCC: "",
        language: "",
        UID: "",
        OID: "",
        TPID: "",
      }
    }}
  />
*/

const Index = (props) => {  
  if(props.asButton) return(
      <button className={props.className} style={props.style ? props.style : {}} onClick={(e)=>{              
        e.preventDefault();
        window.GlobalUtil.triggerEvent("SlideInRightEvent", {
          TITLE: <span><i className="fas fa-paper-plane" style={{"padding": "0 10px 0 10px"}}/> Admin Email Sender</span>,
          customClass: "frontEndSlideInRight shortSlideInRight",
          width: " ",
          noFooter: true,
          onClose: (props.onClose ? props.onClose : ()=>{}),
          children: (childrenProps)=>{                                      
            return(
              <SendEmail {...{...props, ...childrenProps}}/>
            )
          }
        });
      }}>
        {props.customLinkText ? props.customLinkText : <i className="fas fa-paper-plane"></i>}
      </button>
  );


  return(
    <div className="resetPWButton">
      <a href="#" className={props.className} onClick={(e)=>{
        e.preventDefault();
        window.GlobalUtil.triggerEvent("SlideInRightEvent", {
          TITLE: <span><i className="fas fa-paper-plane" style={{"padding": "0 10px 0 10px"}}/> Admin Email Sender</span>,
          customClass: "frontEndSlideInRight shortSlideInRight",
          width: " ",
          noFooter: true,
          onClose: (props.onClose ? props.onClose : ()=>{}),
          children: (childrenProps)=>{                                      
            return(
              <SendEmail {...{...props, ...childrenProps}}/>
            )
          }
        });
      }}>
        {props.customLinkText ? props.customLinkText : <i className="fas fa-paper-plane"></i>}
      </a>
    </div>
  )
}






class SendEmail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      curStatus: false,
      status: 'start',
      curObj: {...(this.props.curObj ? this.props.curObj : {})}
    };    
  }

  componentDidMount(){          
  }

  componentWillUnmount(){
  }

  onFormChange = newObj => {
    if(newObj.whatEmail && (this.state.curObj.whatEmail != newObj.whatEmail)){
      this.setState({
        curObj: newObj,
        status: 'noForm'
      }, ()=>{
        this.setState({status: 'start'});
      });
    } else {
      this.setState({
        curObj: newObj
      });
    }
  }


  APISendEmail = (newObj) => {  
    var emailData = newObj.emailData;
    return window.Client.mutate({
      mutation: API_CALLS.EMAILS.MUTATIONS.sendEmail(),
      variables: {
        whatEmail: newObj.whatEmail,
        emailData: JSON.stringify(emailData)
      }
    });
  }


  onSubmitContact = () => {
    var {curObj} = this.state;
    this.setState({status: 'loading'});
    const newVar = JSON.parse(JSON.stringify(curObj));
    this.APISendEmail(newVar)
      .then((result)=>{
        window.GlobalUtil.delay(500).then(()=>{ //ADD A 2 SEC DELAY FOR MORE USER FRIENDLY
          var sendEmail = result.data.sendEmail;
          this.setState({status: 'success'});
          window.GlobalUtil.triggerEvent("ShowConfetti", true);
          if(this.props.onSuccess) this.props.onSuccess(true); //UPDATE THE DATABASE PAGE AREA 
        });
      })
      .catch((error)=>{
        this.setState({status: 'failed', errorMessage: error.toString()});
        console.log(`error`,"\n\n",error,"\n\n");    
      });
        
  }

  render(){
    var {status, curObj, curStatus, errorMessage} = this.state;       
    var Fields = FieldsBasic();
    if(curObj.whatEmail) Fields = [...Fields, ...FieldsByType(curObj.whatEmail)];          
    //console.log(`curObj`,"\n\n",curObj,"\n\n");
          
    return (
      <div id="AdminEmailSender" className=''>
        {
          status === 'loading' && 
          <Loading
            header={(window.Session.Language === "ESPAÑOL") ? "Enviando" : "Sending"}
            body={"Email Sending Please wait"}
            floating={false}
            overlay={false}
            inlineStyles={{
              LoadingScreen: {
                "minHeight": "190px",
                //"border": "1px solid rgb(221, 221, 221)",
                "borderRadius": "10px",
                "position": "relative",
                "paddingTop": "50px"
              },
              loadingIcon: {
                "maxWidth": "80px"
              },
              loadingHeader: {
                "fontSize":"18px",
                "marginBottom":"0",
                "paddingTop":"20px"
              }
            }}
          />
        }
        {
          status === 'start' && 
          <div className="startText">
            <div className="text">
              <i className="fas fa-envelope-open-text"></i> Fill out to send email
            </div>
            <br/>
            <FormBuilder
              ref={e => this.formRef = e}
              disableKeySubmit={true}
              initialValues={curObj}
              listOfFields={Fields}
              onSubmit={()=>{
                console.log("Enter Pushed but will not send email until button pushed")
                //this.onSubmitContact
              }}
              onChange={this.onFormChange}
              onValidate={(isValid)=>{
                this.setState({curStatus: isValid});
              }}
            />
            <button className={`button button1 ${(curStatus) ? '' : 'disabled'}`} onClick={this.onSubmitContact} disabled={!curStatus}><i className="fas fa-paper-plane" style={{"padding": "0 10px 0 0 "}}/> Send Email</button>
          </div>
        }

        {
          status === 'success' && 
          <div className="successText">
            <div className="finishedMessage">
              <div style={{"color":"#4caf50","fontWeight":"700","fontSize":"30px"}}>SUCCESS!</div>
              <div className="title">Your email has been sent!</div>
              <div className="text">A log of the email being created and sent has also been saved.</div>
              <div className="last">You're good to go!</div>
              <br/>
              <button className="button button1" onClick={window.GlobalUtil.DialogStack.closeTop}>Close</button>
            </div>
          </div>
        }


        {
          status === 'failed' && 
          <div className="failedText">
            <div className="finishedMessage">
              <div style={{"color":"#e91e63","fontWeight":"700","fontSize":"30px"}}>FAILED!</div>
              <div className="title">Oh no, somthing went wrong</div>
              <div className="text">Something is wrong with the server, please try again later.</div>
              <div className="last">{errorMessage}</div>
              <br/>
              <button className="button button1" onClick={window.GlobalUtil.DialogStack.closeTop}>Close</button>
            </div>
          </div>
        }
      </div>
    );
  }
}


export default Index;