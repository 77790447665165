import React, { useState, useRef } from 'react';
import ImageUploading from 'react-images-uploading';
import Loading from 'global/components/Loading/index';
import { gql, useQuery, useMutation } from '@apollo/client';
import axios from "axios";
import FileUploads from "global/utils/file-uploads";
const FILE_UPLOAD_UTILS =  new FileUploads();



/*
<EditableImage
  images={[]}
  onChangeImage={(imageList, callBack)=>{
    callBack();
  }}
  placeHolderImage={}
  showDeleteButton={false}
/>
*/

class EditableImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      images: this.props.images ? this.props.images : [],
      loading: true
    }

    this.handleOnChange = this.handleOnChange.bind(this);
  }

  componentDidMount(){
    this.setState({loading: false})
  }



  handleOnChange(imageList, addUpdateIndex){   
    var {category="images"} = this.props;   
    if(this.props.disable) return;    
    //if(imageList && imageList.length > 0 && addUpdateIndex !== undefined && this.props.field.onNewImageSelected) this.props.field.onNewImageSelected(imageList[imageList.length-1])
    this.setState({loading: true});
    //window.GlobalUtil.consoleLog("this.state.images", [this.state.images]);
    var CurURL = window.GlobalUtil.deepGetFromString(this.state, "images,0,url", undefined);
    //console.log("FILE_UPLOAD_UTILS.DeleteFile","\n\n",CurURL,"\n\n");
          
    FILE_UPLOAD_UTILS.DeleteFile(CurURL, category, (condition, reason, val)=>{
      // if(condition === "failed"){ //IF FAILED TO DELETE
      //   console.log(reason,"\n\n",val,"\n\n");
      // } 
      // console.log(`imageList`,"\n\n",imageList,"\n\n");
            

      if(imageList[0] && imageList[0].file) FILE_UPLOAD_UTILS.UploadFile([...this.props.filePath, imageList[0].file.name], imageList[0].file, (newImageObj)=>{  
        if(this.props.onChangeImage) this.props.onChangeImage(newImageObj, () => this.setState({images: [newImageObj], loading: false}))                
      }); //END OF UPLOAD NEW IMAGE AND SAVE TO DB
      else { //if no new image exsit
        if(this.props.onChangeImage) this.props.onChangeImage(null, () => this.setState({images: [], loading: false}))     
      }
    }); //END OF DELETE EXISTING IMAGE
    //else this.setState({images: imageList, loading: false});
  }

  render(){
    var {images=[], loading} = this.state;          
    var {id, maxNumber, maxWidth, placeHolderImage, placeHolderText, showDeleteButton} = this.props;
    var imageDiv = {...STYLES.imageDiv, ...(maxWidth ? {"maxWidth": maxWidth, "minWidth": maxWidth} : {})};

    if(loading) return (
      <div className={`editableImage`}>
        <Loading
          header={undefined}
          body={undefined}
          floating={false}
          overlay={false}
          inlineStyles={{
            LoadingScreen: {
              "minHeight": "190px",
              "border": "1px solid rgb(221, 221, 221)",
              "borderRadius": "10px",
              "position": "relative",
              "paddingTop": "50px"
            },
            loadingIcon: {
              "maxWidth": "80px"
            },
            loadingHeader: {
              "fontSize":"18px",
              "marginBottom":"0",
              "paddingTop":"20px"
            }
          }}
        />
      </div>
    )
    if(!placeHolderImage) placeHolderImage = (
      <div className="newPicture">
        <div>
          <div className="newImageIcon"><i className="far fa-image"></i></div>
          <div className="newImageText">Add New Image</div>
        </div>
      </div>
    ); 

    return(
      <div className={`editableImage`}>
        <ImageUploading
          value={images}
          onChange={this.handleOnChange}
          maxNumber={2} //REALY IT'S ONLY 1 BUT THE DRAG DOES NOT WORK IF THEY DON'T HAVE 2 HERE
          dataURLKey="data_url"
        >
        {
          ({
            imageList,
            onImageUpload,
            onImageRemoveAll,
            onImageUpdate,
            onImageRemove,
            isDragging,
            dragProps,
            errors
          }) => {                 
            return(
              // write your building UI
              <div className={"upload__image-wrapper "+(this.props.disable ? "disabled" : '')}>
                {
                  ((imageList && imageList.length) && showDeleteButton)
                  ? <i className="fas fa-trash-alt" onClick={()=>{if(!this.props.disable) onImageRemove(0)}}></i>
                  : null
                }
                <div {...dragProps}>
                  <div className="dropZone" style={STYLES.dropZone}>
                    {(!imageList || !imageList[0] || !imageList[0].url) && <div onClick={onImageUpload} style={{"cursor":"pointer"}}>{placeHolderImage}</div>}
                    {
                      imageList.map((image, index) => {
                        if(!image) return;//placeHolderImage;
                        return(
                          <CurImage
                            key={index} 
                            image={image} 
                            showDeleteButton={showDeleteButton}
                            onClick={onImageUpload}
                            onImageUpdate={()=>onImageUpdate(index)} 
                          />
                        )
                      })
                    }
                    {(!imageList || !imageList[0] || !imageList[0].url) && placeHolderText && <div className="placeholderText">{placeHolderText}</div>}
                    {isDragging && <div className="dropZoneText" style={STYLES.dropZoneText}>Drop image here</div>}
                  </div>
                </div>
              </div>
            )
          } 
        }
        </ImageUploading>        
        <div className="underLay"></div>
      </div>
    )
  }
}





const CurImage = (props) => {
  var {image, onImageUpdate, showDeleteButton, onClick} = props;
  return(
    <div className="image-item">
      {
        image['data_url']
        ? <img src={image['data_url']} alt="" onClick={onClick} className="image" style={STYLES.image}/>
        : <img src={image.url} alt="" onClick={onClick} className="image" style={STYLES.image}/>
      }
    </div>
  )
}


const STYLES = {
  image:{
    "maxWidth": "100%",
    "cursor": "pointer"
  },
  dropZone: {
    "position":'relative',
    //"pointerEvents": "none"
  },
  dropZoneText: {
   
  },
  imageWrapper: {
    "display": "flex", 
    "alignItems": "center", 
    "margin": "0 -1%",
    "flexWrap": "wrap"
  },
  tempStyles: {
    "height": "auto",
    "display": "block",
    "padding": "15px 10px",
    "margin": "0 0 15px 0",
    "borderColor": "#ddd"
  },
  imageDiv: {
    "maxWidth":"100%",
    "minWidth":"100%",
    "border":"1px solid #ddd",
    "margin":"0 1% 30px",
    "position":"relative",
  },
  deleteDiv: {
    "background": "red",
    "width": "30px",
    "height": "30px",
    "display": "flex",
    "alignItems": "center",
    "justifyContent": "center",
    "position": "absolute",
    "right": "0px",
    "top": "0px",
    "cursor": "pointer"
  },
  deleteIcon: {
    "color": "#FFF"
  }
}

export default EditableImage;