import React, { useRef, useEffect } from "react";
//import { gql, useQuery, useMutation } from '@apollo/client';
import API_CALLS from 'API_CALLS/index';
  
/*
SECURITY IDEA:
RIGHT BEFORE I OPEN THE PAYPAL BUTTON, I SHOULD QUERY THE CART PRODUCT AGAIN AND GET THEM FRESH
THEN I SHOULD GET THE COUPON FRESH TOO
THEN I SHOULD RUN THE COUPON PRODUCT GLOBAL UTIL TO CALCULATE THE COST
THIS SHOULD NOT CHANGE ANYTHING UNLESS THEY'VE MESSED WITH THE GLOBAL WINDOW VAR
IF THEY HAVE MODIFIED THE GLOBAL WINDOW VAR (POSSIBLY TO REDUCE OR CHANGE THE PRODUCT PRICE) THEN THIS WILL RESET IT TO IT'S CORRECT PRICE
~MAYBE THERE'S AN EASIER WAY 
*/
function AddOrderToUser ({orderID, subscriptionID, onSuccess=()=>{}, onFail=()=>{}}) {
  var today = new Date();
  var todayTime = today.getTime();
  var curOrders = window.GlobalUtil.State.get("newOrder");
  var {products=[], coupon} = curOrders;
  const CHECKOUT_TOTAL = window.GlobalUtil.checkoutCalculatorNew(products, coupon);
  

  var orderItems = products.map((product)=>{ //THIS WILL BE AN ARRAY OF ORDER ITEM OBJECTS TO MAKE
    var APPROVED = "TRUE";
    if(coupon && (coupon.category === "PROMO") && (product.category === "TREATMENT_PLANS")) APPROVED = "PENDING"; //ONLY PUT APPROVED AS FALSE IF THEY USED A PROMO COUPON CODE AND THEY ARE BUYING A TREATMENT PLAN. IF THEY DIDN'T USE A PROMO CODE WHO CARES.
    var orderItem = {
      //UID, //THESE WILL BE ADDED ON THE SERVER SIDE AFTER THE ORDER IS MADE
      //OID, //THESE WILL BE ADDED ON THE SERVER SIDE AFTER THE ORDER IS MADE
      "name": product.ref,
      "ship": (product.category === "TREATMENT_PLANS" ? false : true),
      "PROMO": { //THIS WILL REPLACE THE REF CODE. WHEN THEY BUY 
        "CODE": ((coupon && coupon.PROMO && coupon.PROMO.CODE) ? coupon.PROMO.CODE : null),
        "UID": ((coupon && coupon.PROMO && coupon.PROMO.UID) ? coupon.PROMO.UID : null), //ID OF PERSON WE'RE GOING TO PAY
        "CPID": ((coupon && coupon._id) ? coupon._id : null), //ID OF COUPON USED
        "DATE": `${todayTime}`,
        "EARNED": ((coupon && coupon.commission) ? coupon.commission : null), //HOW MUCH THE AFFILIATE GETS FOR EACH SALE
        "APPROVED": APPROVED, //YOU CAN PAY THE AFFILIATE RIGHT AWAY UNLESS IT'S A TREATMENT PLAN. THEN WE NEED TO APPROVE IT BEFORE WE PAY THE AFFILIATE.
        "ADMIN_PAID": false,
        "ADMIN_PAID_DATE": "",
        "ADMIN_CHECK_NUMBER": ""
      },
      "product": {
        "ID": product.ID,
        "ref": product.ref,
        "type": product.type,
        "name": product.name,
        "quantity": product.quantity,
        "description": product.description,
        "cost": product.cost,
        "shipping": product.shipping,
        "discount": product.discount,
        "language": product.language,
        "website": product.website,
        "category": product.category
      }
    }
    // if(product.subscription) {
    //   orderItem.product.subscription = {
    //     setupFee: product.setupFee, //COST CHARGED ONCE FOR SETUP. THIS WILL BE CHARGED WITH FIRST MONTH CHARGE RIGHT AWAY
    //     billingCycleCharge: product.billingCycleCharge, //HOW MUCH WE CHARGE EACH BILLING CYCLE
    //     paypalID: product.paypalID, //IF THIS EXSIT THEN USE IT TO FIND PRODUCT ON PAYPAL WHEN CHECKING OUT
    //     billingCycle: product.billingCycle //HOW OFTEN THEY ARE CHARGED. EVERY 1 MONTH, EVERY 2 MONTHS
    //   }
    //}
    return orderItem;
  }); 


  var variables = {
    UID: window.Session.user._id,
    couponID: (coupon ? coupon._id : null),
    orderItemsStringArray: JSON.stringify(orderItems),
    paymentOrderID: orderID,
    status: "ACTIVE",
    productsStringArray: JSON.stringify(products),
    cost: {
      subtotal: (CHECKOUT_TOTAL.subtotal ? Number(Number(CHECKOUT_TOTAL.subtotal).toFixed(2)) : 0), //TOTAL PRICE BEFORE THE DISCOUNTS AND TAXES AND SHIPPING ETC.
      discount: (CHECKOUT_TOTAL.discount ? Number(Number(CHECKOUT_TOTAL.discount).toFixed(2)) : 0), //TOTAL DISCOUNT, INCLUDING COUPONS AND PRODUCT PRICE REDUCTION.
      subtotalMinusDis: (CHECKOUT_TOTAL.subtotalMinusDis ? Number(Number(CHECKOUT_TOTAL.subtotalMinusDis).toFixed(2)) : 0),
      tax: (CHECKOUT_TOTAL.tax ? Number(Number(CHECKOUT_TOTAL.tax).toFixed(2)) : 0),
      setupFee: 0,
      shipping: (CHECKOUT_TOTAL.shipping ? Number(Number(CHECKOUT_TOTAL.shipping).toFixed(2)) : 0),
      total: (CHECKOUT_TOTAL.total ? Number(Number(CHECKOUT_TOTAL.total).toFixed(2)) : 0)
    },
    website: window.SiteSettings.siteNameAllCaps,
    paidAt: `${todayTime}`,
    chargingAt: `${todayTime}`,
    sendEmail: true
  }
  if(subscriptionID) variables.paymentSubscriptionID = subscriptionID;
  // var variables = {
  //   UID: "5f9dbef56dd69d3477b3787d",
  //   chargingAt: "1614725393658",
  //   couponID: "603813df1c0396323ec0070e",
  //   discounts: 12.15,
  //   paidAt: "1614725393658",
  //   paymentOrderID: "32K18727DW111204Y",
  //   products: [
  //     {
  //       costWithDiscount: "80.99",
  //       productID: "5fb063bdddcf613ba677e534",
  //       quantity: "1"

  //     }
  //   ],
  //   shipping: 0.00,
  //   status: "ACTIVE",
  //   subTotalWithDis: 68.8415,
  //   tax: 3.442,
  //   total: 72.28
  // }

  //window.GlobalUtil.consoleLog("variables", [variables]);
  
  window.Client.mutate({
    mutation: API_CALLS.ORDERS.MUTATIONS.create(),
    variables: variables
  })
  .then((obj) => {
    window.GlobalUtil.consoleLog("Success obj", [obj]);
    onSuccess(obj.data.createOrder)
  })
  .catch((error)=>{
    console.log("Paypal Button ORDERS.MUTATIONS.create Filed", error);
    onFail()
  });
}

//<script src="https://www.paypal.com/sdk/js?client-id=AePM7Mx2qN1TSQo4ERTanQTTa2UTZpr6peCJ2f6phvQ8ky5cIwP_2trh27vkFxgGF5JQQgSu_If5NALH&vault=true&intent=subscription" 





class Paypal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: undefined
    };    

  }

  componentDidMount(){
    //console.log(`Paypal loaded`,"\n\n","\n\n");
          
    var {checkoutCalculator, onSuccess=()=>{}, onFail=()=>{}} = this.props;
    //enable-funding=paylater
    window.paypal
      .Buttons({
        style: {
          shape: 'rect',
          color: 'gold',
          layout: 'vertical',
          label: 'paypal'
        },
        createOrder: (data, actions, err) => {
          return actions.order.create({
            intent: "CAPTURE",
            //enable-funding: "paylater",
            purchase_units: [
              {
                description: checkoutCalculator.orderSummary,
                amount: {
                  currency_code: "USD",
                  value: checkoutCalculator.total.toFixed(2),
                },
              },
            ],
          });
        },
        onApprove: async (data, actions) => {
          const order = await actions.order.capture();
          console.log("Paypal onApprove order", order);
          window.GlobalUtil.consoleLog("Paypal data", [data]);
          AddOrderToUser({orderID: order.id, subscriptionID: null, onSuccess, onFail})
        },
        onError: (err) => {
          console.log(err);
          if(this.props.onFail) this.props.onFail(err);
        },
      })
      .render(this.PaypalRef);
  }


  render(){
    return (
      <div ref={e=>this.PaypalRef=e}></div>
    );
  }
}


export default Paypal;

export {AddOrderToUser};