import React from 'react';
import TemplatePage from 'website/templates/templatePage/index.js';
import HeaderImage from 'website/components/HeaderImage/index';
import Main4 from 'global/img/main_pngs/main4.png';
import Main4_500 from 'global/img/main_pngs/main4_500.png';
import Main4_400 from 'global/img/main_pngs/main4_400.png';
import Main4_250 from 'global/img/main_pngs/main4_250.png';
import PrizeImage1 from 'global/img/giveaway1/prizeImage_5_1x1_1000.jpg';
import ESPAÑOL from "./ESPAÑOL";
import ENGLISH from "./ENGLISH";
const CONTENTS = {
  ESPAÑOL,
  ENGLISH
}

const {Container, Row, Column} = window.LayoutBuilder;
class Index extends TemplatePage {
  // constructor(props) {
  //   super(props);
  //   //this.state = {
  //    ...this.state
  //   //};    
  // }

  didMount(){
  }

  willUnmount(){
  }

  render(){
    //var {showSideMenu} = this.state;
    const CONTENT = window.GlobalUtil.dangerSetGlobal(CONTENTS[window.Session.Language]); 
    var Total1 = GIVEAWAY_FEB.reduce(function(accumulator, value) {
      return Number(accumulator)+Number(value.savings);
    }, 0);
    return (
      <div id="PageWinners">
        <div id="TopMainSection" className="containerSection backgroundImage3">
          <Container className="">
            <Row className="">
              <Column className="" col="" xs="" sm="" md="" lg="" xl="">
                <div className="headerImageSmall">
                  <HeaderImage
                    img={PrizeImage1}
                    alt="Sonrisas Club Winners"
                    //right={true}
                  />
                </div>
              </Column>
              <Column className="contentCenter topTextZone" col="12" xs="12" sm="12" md="12" lg="9" xl="9">
                <div className="">
                  <div className="preHeader">{CONTENT[0]}</div>
                  <h1 className="sectionTitle">{CONTENT[1]}</h1>
                  <div className="">{CONTENT[2]}</div>
                </div>
              </Column>
            </Row>
          </Container>
        </div>

        <div id="WinnersResults" className="containerSection backgroundColor">
          <Container>
            <Row className="">
              <Column className="winners" col="12">
                <div className="text-center">
                  {/*<h2 className="sectionTitle2">Winners</h2>*/}
                </div>
              </Column>
              <Column className="giveawayResults" col="12" xs="12" sm="12" md="6">
                <div className="headerSize3">
                  February 2022 Giveaway
                </div>
                <div className="small">This list will may be updated to reflect any additional winners and/or remove winners who did not accept their winnings.</div>
                <br/>
                <div className="giveawayList">
                  <div className="giveawayPerson header">
                    <div className="won">Place</div>
                    <div className="name">Name</div>
                    <div className="prize">Prize</div>
                    <div className="savings">Savings</div>
                  </div>
                  {
                    GIVEAWAY_FEB.map((object, index)=>{
                      return(
                        <div className="giveawayPerson" key={index}>
                          <div className="won">{object.won}</div>
                          <div className="name">{object.name}</div>
                          <div className="prize">{object.prize}</div>
                          <div className="savings">{window.GlobalUtil.convertToMoney(object.savings).slice(0,-3)}</div>
                        </div>
                      )
                    })
                  }
                  <div className="giveawayTotal">
                    <div className="desc">Total Given Away</div>
                    <div className="total">{window.GlobalUtil.convertToMoney(Total1).slice(0,-3)}</div>
                  </div>
                </div>
              </Column>   
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}




const GIVEAWAY_FEB = [
  {
    "won": "1st",
    "name": "Ashlee Clark",
    "prize": "100% OFF",
    "savings": "1550"
  },
  {
    "won": "2nd",
    "name": "Lucia Hernández",
    "prize": "50% OFF",
    "savings": "775"
  },
  {
    "won": "2nd",
    "name": "Alejandro Izarraraz",
    "prize": "50% OFF",
    "savings": "775"
  },
  {
    "won": "3rd",
    "name": "Adam Ross",
    "prize": "$500 OFF",
    "savings": "500"
  },
  {
    "won": "3rd",
    "name": "Monica Howard",
    "prize": "$500 OFF",
    "savings": "500"
  },
  {
    "won": "3rd",
    "name": "Elena Putman",
    "prize": "$500 OFF",
    "savings": "500"
  },
  {
    "won": "3rd",
    "name": "Dora Nunez",
    "prize": "$500 OFF",
    "savings": "500"
  },
  {
    "won": "3rd",
    "name": "Karina",
    "prize": "$500 OFF",
    "savings": "500"
  },
];



export default Index;
      