import React, { useState, useRef } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import ReactTooltip from 'react-tooltip';
const {Container, Row, Column} = window.LayoutBuilder;
///Users/danielezaldivar/Sites/sonrisas/src/styles/admin/pages/templateList.scss


class ListComp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reloadAPI: false,
      showNextButton: false,
      showPrevButton: false,
      currentPage: 1,
      totalPages: 1,
      selectedList: []
    };    
    this.onChangeSort = this.onChangeSort.bind(this);
    this.APIGetList = this.APIGetList.bind(this);
    this.getNavigationVar = this.getNavigationVar.bind(this);
    this.navigateTo = this.navigateTo.bind(this);
    this.SelectedListEventKey = window.GlobalUtil.subscribeEvent("SelectedListEvent", selectedList=>{
      this.setState({
        selectedList: selectedList
      });
    })

    window.CurRefetchList = this.APIGetList; //RUN THIS FUNCTION TO RELOAD THE LIST WITHOUT CACHE
  } 

  componentDidMount(){
    this.APIGetList(true);
  }

  componentWillUnmount(){
    this.SelectedListEventKey.unsubscribe();
  }


  onChangeSort(SortPath){
    var {filterVariables={}} = this.props;
    if(filterVariables.sortBy === SortPath){
      filterVariables.descending = !filterVariables.descending;
    } else {
      filterVariables.sortBy = SortPath;
      filterVariables.descending = false;
    }
    this.props.onUpdateFilter(filterVariables); //this auto calls window.CurRefetchList which is APIGetList()
  }

  cleanFilterVar = (filterVariables) => {
    const deleteIfMissing = ["status","sortBy","limit","offset","fbd,field","fbd,field","fbd,gt","fbd,gte","fbd,lt","fbd,lte"];
    const typeBoolean = ["descending"];
    const typeNumber = ["limit", "offset"];
    typeBoolean.map((pathToValue, index)=>{
      var curVal = window.GlobalUtil.deepGetFromString(filterVariables, pathToValue, "false");
      if(typeof curVal === "string") window.GlobalUtil.deepSetFromString(filterVariables, pathToValue, (curVal === "false" ? false : true));
    });
    typeNumber.map((pathToValue, index)=>{
      var curVal = window.GlobalUtil.deepGetFromString(filterVariables, pathToValue, "false");
      window.GlobalUtil.deepSetFromString(filterVariables, pathToValue, Number(curVal));
    });
    deleteIfMissing.map((pathToValue, index)=>{
      var curVal = window.GlobalUtil.deepGetFromString(filterVariables, pathToValue, undefined);
      if(curVal===undefined){
        var pathListArray = pathToValue.split(",");
        if(pathListArray.length === 1){ //REMOVE FROM BASE OBJECT IF AT BASE
          delete filterVariables[pathToValue];
        } else { //DEEP REMOVE FIELD FROM OBJ
          delete filterVariables[pathToValue]; //DELETE ANY WRONG LONG VALUES LIKE "fbd,active" 
          var lastPathValue = pathListArray.slice(-1).join(",");
          var pathWithoutLastValue = pathListArray.slice(0,-1).join(",");
          var tempObj = window.GlobalUtil.deepGetFromString(filterVariables, pathWithoutLastValue, {});
          delete tempObj[lastPathValue];
          window.GlobalUtil.deepSetFromString(filterVariables, pathWithoutLastValue, tempObj);
        }
      }
    });
    // if(!filterVariables.fbd.active){
    //   delete filterVariables.dates;
    // }
    delete filterVariables.fbd;
    delete filterVariables["fbd,active"];
    return filterVariables;
  }



  APIGetList(noCache){
    if(!this) return;
    var cleanFilter = this.cleanFilterVar({...this.props.filterVariables});
    //console.log(`cleanFilter`,"\n\n",cleanFilter,"\n\n");  
    var inputObj = {
      query: this.props.QUERY,
      variables: this.cleanFilterVar({...this.props.filterVariables})
    }
    //console.log(`inputObj`,"\n\n",inputObj,"\n\n");
          
    if(noCache) inputObj.fetchPolicy = "no-cache";
    window.Client.query(inputObj)
    .then((obj) => {
      var {data, loading} = obj;
      //console.log("SUCCESS LIST API");    
      //window.GlobalUtil.consoleLog("data", [data]);
      console.log(`data`,"\n\n",data,"\n\n");
            
      Object.keys(data).map((key, index)=>{
        if(key.search("Count") > -1) window.GlobalUtil.triggerEvent("TotalListEvent", ((data[key]) ? data[key] : 0)); //UPDATE HEADER TEMPLATE IF UP
      })
      if(this) this.setState({data}, this.getNavigationVar)
    })
    .catch((error)=>{
      console.log(`inputObj`,"\n\n",inputObj,"\n\n");
            
      console.log("API GET LIST catch", error);
    });
  }


  getNavigationVar(){
    var {data} = this.state;
    var {dataField, filterVariables} = this.props;
    var CountName = (dataField ? (dataField.slice(0, -1)+"Count") : null);
          
    if(!data || !dataField || !data[CountName]) return; //IF FOUND NOTHING THEN NO NAVIGATION
    window.TotalCount = data[CountName];
    var maxItems = Number(data[CountName]);
    if(!maxItems) return; //IF FOUND NOTHING THEN NO NAVIGATION
    var limit = Number(filterVariables.limit);
    var offset = Number(filterVariables.offset);
    var {hasNextPage, hasPrevPage, totalPages, currentPage} = window.GlobalUtil.paginationCalculator(maxItems, limit, offset);
          
    //window.GlobalUtil.consoleLog("maxItems, limit, offset", [maxItems, limit, offset]);
    this.setState({
      showPrevButton: hasPrevPage,
      showNextButton: hasNextPage,
      currentPage: currentPage,
      totalPages: totalPages,
    })
    
  }

  navigateTo(direction){
    var {filterVariables} = this.props;
    if(direction == 'next'){
      filterVariables.offset = Number(filterVariables.offset)+Number(filterVariables.limit);
    } else {
      filterVariables.offset = Number(filterVariables.offset)-Number(filterVariables.limit);
    }
    this.props.onUpdateFilter(filterVariables);
  }

  render(){
    var {reloadAPI, data, showNextButton, showPrevButton, currentPage, totalPages, selectedList} = this.state;
    var {dataField, LIST_HEADER_FIELDS, filterVariables, Buttons, ACTIVEITEM, SHOWSELECTBOXES} = this.props;
    return(
      <div className="templateList">
        <Container className="" fluid="true">
          {
            data && data[dataField] && 
            <DataItems
              LIST_HEADER_FIELDS={LIST_HEADER_FIELDS}
              items={data[dataField]}
              ACTIVEITEM={ACTIVEITEM}
              Buttons={Buttons}
              changeSort={this.onChangeSort}
              filterVariables={filterVariables}
              selectedList={selectedList}
              SHOWSELECTBOXES={SHOWSELECTBOXES}
            />
          }
        </Container>
        <Container className="navigationList" fluid="true">
          <Row className="">
            <Column className="navLeft" col="" xs="" sm="" md="" lg="" xl="">
              <div className="navButtons prevButton">
                  <button className={`button button2 ${!showPrevButton ? "disabled" : ""}`} disabled={!showPrevButton} onClick={()=>{
                    if(showPrevButton) this.navigateTo("back");
                  }}><i className="fas fa-chevron-left"></i> Back</button>
              </div>
            </Column>
            <Column className="curNumber" col="" xs="" sm="" md="" lg="" xl="">
              <div className="navigationTracker">
                <div className="">Page {currentPage} of {totalPages}</div>
              </div>
            </Column>
            <Column className="navRight" col="" xs="" sm="" md="" lg="" xl="">
              <div className="navButtons nextButton">
                <button className={`button button2 ${!showNextButton ? "disabled" : ""}`} disabled={!showNextButton} onClick={()=>{
                  if(showNextButton) this.navigateTo("next");
                }}>Next <i className="fas fa-chevron-right"></i></button>
              </div>
            </Column>
          </Row>
        </Container>
      </div>
    )
  }
}




class DataItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      makeTableResponsive: false
    };    
    this.checkTableResponsive = this.checkTableResponsive.bind(this);
    this.onResizeScreen = this.onResizeScreen.bind(this);
    this.getHeaderValue = this.getHeaderValue.bind(this);
    this.toggleActive = this.toggleActive.bind(this);
    this.getListIdsFromTo = this.getListIdsFromTo.bind(this);
  } 

  componentDidMount(){
    window.addEventListener("resize", this.onResizeScreen);
    this.checkTableResponsive(300);
  }

  componentWillUnmount(){
    window.removeEventListener("resize", this.onResizeScreen);
    clearTimeout(this.TimeoutPointer);
    clearTimeout(this.ResizePointer);
  }

  onResizeScreen(e){
    if(this.ResizePointer) clearTimeout(this.ResizePointer);
    this.ResizePointer = setTimeout(()=>{
      this.checkTableResponsive(0);
      clearTimeout(this.ResizePointer);
    }, 10);
  }

  checkTableResponsive(timeOutDelay=300){ //THIS NEEDS A DELAY BECAUSE THE COLUMNS ARE RESIZING BELOW.
    if(this.TimeoutPointer) clearTimeout(this.TimeoutPointer);
    this.TimeoutPointer = setTimeout(()=>{
      if(this.TableOutterRef && this.TableRef){
        var makeTableResponsive = false;
        if(this.TableRef.offsetWidth > this.TableOutterRef.offsetWidth) makeTableResponsive = true;               
        if(this.TableRef.offsetWidth === this.TableOutterRef.offsetWidth) makeTableResponsive = false;
        this.setState({makeTableResponsive})
      }
      clearTimeout(this.TimeoutPointer);
    }, timeOutDelay);
  }

  getHeaderValue(item, FIELDOBJ){
    var value = "";
    var valueArray = FIELDOBJ.path.split(",").map((splitString, index)=>{
      return window.GlobalUtil.deepGetFromString(item, splitString.split('.').join(), FIELDOBJ.defaultValue);
    })
    value = valueArray.join(" ")
    return value;
  }

  getListIdsFromTo(from, to){
    if(!from || !to) return [];
    var arrayOfIDS = this.props.items.slice().map((item, index)=>item._id);
    var first = arrayOfIDS.indexOf(from), 
    last = arrayOfIDS.indexOf(to)+1;
    if(first > last){ //IF SELECTING UPWARDS THEN REVERSE
      first = arrayOfIDS.indexOf(to); 
      last = arrayOfIDS.indexOf(from);
    }
    return arrayOfIDS.slice(first, last);
  }

  toggleActive(id){
    var {selectedList=[]} = this.props;
    window.GlobalUtil.clearSelection(); //REMOVE ANY SELECTED POSSIBLY DUE TO SHIFT HELD DOWN

    if(window.event.shiftKey){ //IF THEY HAVE SHIFT DOWN GET THE LIST FROM TO
      var fromToList = this.getListIdsFromTo(selectedList.slice(-1)[0], id);
      //console.log(`fromToList`,"\n\n",fromToList,"\n\n");
      selectedList = window.GlobalUtil.arrayOnlyUnique([...selectedList.slice(), ...fromToList]);
      //console.log(`selectedList`,"\n\n",selectedList,"\n\n");
    } else {
      if(selectedList.indexOf(id) > -1) selectedList.splice(selectedList.indexOf(id), 1);
      else selectedList.push(id);
    }

    window.GlobalUtil.triggerEvent("SelectedListEvent", selectedList);
  }


  render(){
    var {items, LIST_HEADER_FIELDS, Buttons, filterVariables={}, ACTIVEITEM, selectedList, SHOWSELECTBOXES} = this.props;     
    var {makeTableResponsive} = this.state;    
    if(!items || items.length < 1) return <div className="TableWrapper" ref={e=> this.TableOutterRef = e} >No items found</div>
    return (
      <div className="TableWrapper" className={`${makeTableResponsive ? "table-responsive" : ""}`} ref={e=> this.TableOutterRef = e} >
        <table className={`table`} >
          <thead className="thead-dark" ref={e=> this.TableRef = e}>
            <tr>
            {
              SHOWSELECTBOXES &&
              <th className="tableCheckBoxHeader" scope="col">
                <i className="far fa-square" onClick={()=>{
                  if(selectedList.length === items.length) window.GlobalUtil.triggerEvent("SelectedListEvent", []);
                  else {
                    var newSelectedList = items.map((item, index)=>item._id)
                    window.GlobalUtil.triggerEvent("SelectedListEvent", newSelectedList);
                  }
                }}/>
              </th>
            }
            {
              LIST_HEADER_FIELDS &&
              LIST_HEADER_FIELDS.map((FIELDOBJ, index)=>{
                var SmallBoxes = ["Status", "Create At", "Total", "Last Update", "Create", "Image"];
                var isActive = (FIELDOBJ.path === filterVariables.sortBy);
                return(
                  <TH
                    key={index}
                    obj={FIELDOBJ}
                    SmallBoxes={SmallBoxes}
                    isActive={isActive}
                    changeSort={this.props.changeSort}
                    filterVariables={filterVariables}
                  />
                )
              })
            }
            {
              Buttons &&
              Buttons.map((buttonObj, index)=>{
                return(
                  <th className="text-center buttonCategory" scope="col" key={index}  style={buttonObj.width ? {width: buttonObj.width} : {}}>
                    {buttonObj.category}
                  </th>
                )
              })
            }
            </tr>
          </thead>
          <tbody>
          {
            items.map((item, index)=>{    
              var isActive = false;
              if(ACTIVEITEM && (ACTIVEITEM === item._id)) isActive = true;
              if(selectedList && (selectedList.indexOf(item._id) > -1)) isActive = true;
              return(
                <tr key={index} className={`animated fadeIn ${isActive ? "active" : ""}`}>
                  {
                    SHOWSELECTBOXES &&
                    <CheckboxButtons
                      id={item._id}
                      isActive={isActive}
                      onClick={()=>this.toggleActive(item._id)}
                    />
                  }
                  {
                    LIST_HEADER_FIELDS &&
                    LIST_HEADER_FIELDS.map((FIELDOBJ, index)=>{
                      if(FIELDOBJ.type === "CUSTOM") return(<th key={index} scope="row"><div className="rowText">{FIELDOBJ.CUSTOM(item)}</div></th>)
                      var value = this.getHeaderValue(item, FIELDOBJ);
                      if(FIELDOBJ.subtype === "IDArray"){
                        var IDDic = window.GlobalUtil.IDArrayToObj(item.IDArray);
                        value = this.getHeaderValue(IDDic, FIELDOBJ);
                      }
                      var field = null;
                      if(value === undefined || value === "NONE"){
                        return(
                          <th key={index} scope="row">
                            <div className="NONE">
                              <small className="">NONE</small>
                            </div>
                          </th>
                        )
                      }
                      
                      if(FIELDOBJ.type === "DATE") {                            
                        if(!value || value === undefined || value === "NONE" || value === "NA"){
                          field = <div className="light">NONE</div>;
                        } else {
                          const newDate = new Date(Number(value))
                          field = <div className="">{newDate.formatDate("n/d/y")}</div>
                        }
                      }

                      if(FIELDOBJ.type === "IMAGE"){
                        return(
                          <th key={index} scope="row">
                            <div className="rowImage">
                              <div className="tableListImage"><img src={value} alt="" /></div>
                            </div>
                          </th>
                        )
                      }
                      
                      if(FIELDOBJ.type === "ID"){
                        field = <div className=""><small className="">{value}</small></div>
                      }

                      if(FIELDOBJ.type === "STRINGARRAY"){
                        var valTemp = (Array.isArray(value) ? value : value.split(","));
                        field = <div className="">{window.GlobalUtil.ulify((valTemp ? valTemp.join(", ") : ""), 35)}</div>
                      }

                      if(FIELDOBJ.type === "VALUE"){
                        if(FIELDOBJ.toolTip) field = <div className="value" data-tip={value}>{window.GlobalUtil.ulify((value ? value : ""), 50)}</div>
                        else field = <div className="value">{window.GlobalUtil.ulify((value ? value : ""), 50)}</div>
                      }

                      if(FIELDOBJ.type === "MONEY"){
                        field = <div className="">{window.GlobalUtil.convertToMoney(value)}</div>
                      }


                      if(FIELDOBJ.type === "ARRAY") field = <div style={{"display": "flex"}}>{
                        value.map((arrayValue, index2)=>{     
                          if(index2 > 4) return;                         
                          return <div key={index2} style={{"width": "30px", "height": "20px", "paddingRight": "10px"}}>{FIELDOBJ.handleArray(arrayValue)}</div>;
                        })
                      }</div>
                      if(FIELDOBJ.type === "EMAIL") field = <div className=""><a href={`mailto:${value}`}>{value}</a></div>
                      ;

                      if(FIELDOBJ.type === "PHONE") {
                        field = <div className=""><a href={`tel:${value}`}>({value.slice(0,3)}) {value.slice(3,6)} - {value.slice(6)}</a></div>
                      }

                      if(FIELDOBJ.type === "BOOLEAN"){
                        field = <div className="value"><strong style={{"textTransform":"uppercase", "fontSize":"11px", "color": (window.GlobalUtil.inputToBool(value) ? "#1a8d1f" : "#e91e63")}}>{`${window.GlobalUtil.inputToBool(value)}`}</strong></div>
                      }
                    
                      return(
                        <th key={index} scope="row">
                          <div className="rowText">
                            {field}
                          </div>
                        </th>
                      )
                    })
                  }
                  {
                    Buttons &&
                    Buttons.map((buttonObj, index)=>{
                      if(buttonObj.CUSTOMBUTTON) return(<th key={index+"a"} scope="row">{buttonObj.CUSTOM(item)}</th>)
                      return(
                        <th key={index+"a"} scope="row" onClick={()=>buttonObj.onClick(item)}>
                          <div className="text-center buttonColumn" style={(index+1) < Buttons.length ? {"alignItems":"center","justifyContent":"center"} : {}}>
                            <div className="rowText">
                              <div className={`button button1 d-flex ${buttonObj.className}`} style={buttonObj.width ? {maxWidth: buttonObj.width, minWidth: buttonObj.width} : {}}>
                                {buttonObj.buttonText && <div className="buttonText">{buttonObj.buttonText}</div>}
                                {buttonObj.iconClass && <div className="buttonIcon"><i className={`${buttonObj.iconClass}`} style={{"cursor":"pointer"}}></i></div>}
                              </div>
                            </div>
                          </div>
                        </th>
                      )
                    })
                  }
                </tr>
              )
            })
          }
          </tbody>
        </table>
        <ReactTooltip />
      </div>
    );
  }
}






//ADD sortable: false TO PREVENT SORTING
class TH extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      minWidth: 400
    };    
  } 

  componentDidMount(){
    if(this.THRef) this.setState({minWidth: this.THRef.children[0].children[0].offsetWidth})
  }

  componentWillUnmount(){
  }


  render(){
    var {minWidth} = this.state;
    var {obj, SmallBoxes, isActive, filterVariables} = this.props;
    var style = {"minWidth": (obj.minWidth ? obj.minWidth : `${minWidth+20}px`)};
    if(obj.width) style.width = obj.width;
    return(
      <th 
        ref={e=> this.THRef = e} 
        scope="col" 
        style={style}
        width={(SmallBoxes.indexOf(obj.name) > -1) ? "110px" : null}
        onClick={()=>{
          //if(obj.searchable === undefined) return;
          if(obj.type === "CUSTOM") return; //IF CUSTOM NO SORT
          if((obj.sortable === undefined) || (obj.sortable === true)) this.props.changeSort(obj.path)
        }}>
        <div className={`headerColWrapper ${isActive ? "active" : ""}`}>
          <div className="headerColText">
            <div className="text">{obj.name}</div>
            {
              isActive &&
              <div className="icon">
                {
                  filterVariables.descending
                  ? <i className={`fas fa-sort-up`}></i>
                  : <i className={`fas fa-sort-down`}></i>
                }
              </div>
            }
          </div>
        </div>
      </th>
    )
  }
}




class CheckboxButtons extends React.Component {
  constructor(props) {
    super(props);
  } 

  componentDidMount(){
  }

  componentWillUnmount(){
  }

  render(){
    var {id, isActive, onClick} = this.props;
    return(
      <th className={`tableCheckBox ${isActive ? 'active' : ''}`} onClick={this.props.onClick}>
        <div className="tableCheckBoxDiv">
          {
            isActive
            ? <i className="fas fa-check-square"></i>
            : <i className="far fa-square" />
          }
        </div>
      </th>
    )
  }
}

export default ListComp;
      

