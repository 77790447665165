import React, { useState, useRef } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import API_CALLS from 'API_CALLS/index';
import TemplateListBasic from 'global/templates/templateListBasic/index';
import ViewTreatmentPlanRequest from 'user/doctor/components/OrderList/ViewTreatmentPlanRequest/index';
import ESPAÑOL from "./ESPAÑOL";
import ENGLISH from "./ENGLISH";
const CONTENTS = {
  ESPAÑOL,
  ENGLISH
}
const {Table} = window.LayoutBuilder;


class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hideList: false,
      variables: {
        "status": "NOTINACTIVE",
        "sortBy": "meta.createAt",
        "descending": false,
        "limit": 10,
        "offset": 0,
        "manual": [
          {
            path: "DOCTOR",
            val: window.Session.user._id
          },
          {
            path: "step",
            listValues: JSON.stringify(["PURCHASE"])
          }
        ],
        "include": [
          {
            name: "user"
          }
        ]
      }
    }; 
    //window.CurRefetchList
    // window.APIListGetAgain = ()=>{
    //   this.setState({hideList: true}, ()=>{
    //     this.setState({hideList: false})
    //   })
    // };
  }

  componentDidMount(){
  }

  componentWillUnmount(){
  }

  onUpdateFilter = (newVariables)=>{
    this.setState({
      variables: newVariables,
    }, ()=>window[this.props.CurRefetchListVar](true))
  }


  render(){
    var {hideList, variables} = this.state;
    var {TPList=[], noActions, noStep} = this.props;
    const CONTENT = window.GlobalUtil.dangerSetGlobal(CONTENTS[window.Session.Language]); 
    return(
      <div className="orderList">
        <div className="template1">
          <TemplateListBasic 
            CurRefetchListVar={this.props.CurRefetchListVar}
            QUERY={API_CALLS.TREATMENT_PLANS.QUERIES.allAndCountListFull()}
            VARIABLES={variables}
            dataField="treatmentPlans"
            onUpdateFilter={this.onUpdateFilter}
            CreateList={(data)=>{
              return(
                <div className="">
                  <OfferListFull
                    CONTENT={CONTENT}
                    fullList={data.treatmentPlans}
                    noActions={noActions}
                    noStep={noStep}
                    reloadList={()=>{
                      window[this.props.CurRefetchListVar](true)
                    }}
                  />
                </div>
              )
            }}
            //changeSort={this.onChangeSort}
          /> 
        </div>
      </div>
    )
  }
}




function OfferListFull(props) {     
  var {fullList=[], CONTENT=[], noActions, noStep} = props;
  return (
    <Table
      className="table"
      thead={{
        className:"thead-dark",
        tr: {
          th: [
            {
              //style: {"width": "210px"},
              value: (
                <div className="headerColWrapper ">
                  <div className="headerColText">
                    <div className="text">{CONTENT[0]}</div>
                  </div>
                </div>
              )
            },
            {
              style: {"width": "210px"},
              value: (
                <div className="headerColWrapper ">
                  <div className="headerColText">
                    <div className="text">{CONTENT[1]}</div>
                  </div>
                </div>
              )
            },
            {
              style: {"width": "91px"},
              value: (
                <div className="headerColWrapper ">
                  <div className="headerColText">
                    <div className="text">{CONTENT[2]}</div>
                  </div>
                </div>
              )
            },
            {
              style: {"width": "50px"},
              className: "text-center buttonCategory",
              value: CONTENT[3]
            }
          ]
        }
      }}
      tbody={{
        tr: (
          fullList.length
          ? fullList.map((treatmentPlan, index)=>{
              var createdAt = new Date(Number(treatmentPlan.meta.createAt));
              var updateAt = new Date(Number(treatmentPlan.meta.updateAt));
              return(
                {
                  className: "animated fadeIn",
                  th: [
                    {
                      value: (
                        <div className="rowText">
                          <div className="">{treatmentPlan.user.firstName} {treatmentPlan.user.lastName}</div>
                        </div>
                      )
                    },
                    {
                      value: (
                        <div className="rowText">
                          <div className="value">{treatmentPlan._id}</div>
                        </div>
                      )
                    },
                    {
                      value: (
                        <div className="rowText">
                          <div className="value">{createdAt.formatDate("n/d/y")}</div>
                        </div>
                      )
                    },
                    {
                      value: (
                        <ViewTreatmentPlanRequest 
                          curTreatmentPlan={{...treatmentPlan}}
                          curUser={{
                            ...treatmentPlan.user,
                            TPID: treatmentPlan._id
                          }}
                        />
                      )
                    },
                  ]
                }
              )
            })
          : [{
              className: "animated fadeIn",
              th: [
                {
                  value: (
                    <div className="rowText">
                      <div className="">{CONTENT[4]}</div>
                    </div>
                  )
                },
                {},
                {},
                {},
                {},
                {}
              ] 
            }]
        )
      }}
    />
  );
}


export default Index;

