import React from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';

import MUTATIONS from "./MUTATIONS";
import QUERIES from "./QUERIES";
import FORM_FIELDS from "./FORM_FIELDS";
import FORM_FIELDS_NEW from "./FORM_FIELDS_NEW";
import {DEFAULT_QUERY_INPUT_VAR, DEFAULT_QUERY_INPUT} from "../QUERY_DEFAULTS";
import PREVIEW_FORM_TEMPLATES from "../PREVIEW_FORM_TEMPLATES";
import PREVIEW from "./PREVIEW";
import MUTATION_FIELDS_DEFAULT from "../MUTATION_FIELDS_DEFAULT";
import CLEAN_FIELDS_DEFAULT from "../CLEAN_FIELDS_DEFAULT";
const DEFAULT_MUTATION_FIELDS = `
  ID
  ref
  type
  name
  description
  shortDesc
  includes
  images{
    ${MUTATION_FIELDS_DEFAULT.FILE}
  }
  website
  language
  cost
  url
  discount
  numberInStock
  category
  status
  showWhere
  subscription{
    setupFee
    billingCycleCharge
    paypalID
    billingCycle
  }
  meta{
    ${MUTATION_FIELDS_DEFAULT.META}
  } 
`;


const DEFAULT_QUERY_FIELDS = DEFAULT_MUTATION_FIELDS+``;



const DEFAULT_MUTATION_INPUT_VAR = ` 
  $id: String,
  $ref: String,
  $type: String,
  $name: String,
  $language: String,
  $description: String,
  $shortDesc: String,
  $includes: [String],
  $showWhere: [String],
  $url: String,
  $website: String,
  $status: String,
  $images: [FileObjectInput],
  $cost: Float,
  $discount: Float,
  $numberInStock: Int,
  $subscription: SubscriptionObjInput,
  $category: String,
  
`;


const DEFAULT_MUTATION_INPUT = ` 
  id: $id,
  ref: $ref,
  type: $type,
  name: $name,
  description: $description,
  shortDesc: $shortDesc,
  includes: $includes,
  showWhere: $showWhere,
  url: $url,
  website: $website,
  language: $language,
  status: $status,
  images: $images,
  cost: $cost,
  discount: $discount,
  numberInStock: $numberInStock,
  subscription: $subscription,
  category: $category,
  
`;


const CLEAN_FIELDS = { 
  "id": "string",
  "ref": "string",
  "type": "string",
  "name": "string",
  "language": "string",
  "description": "string",
  "website": "string",
  "shortDesc": "string",
  "includes": ["string"],
  "showWhere": ["string"],
  "url": "string",
  "status": "string",
  "images": [
    CLEAN_FIELDS_DEFAULT.FILE
  ],
  "cost": "number",
  "discount": "number",
  "numberInStock": "number",
  "subscription,setupFee": "number",
  "subscription,billingCycleCharge": "number",
  "subscription,paypalID": "string",
  "subscription,billingCycle": "string",
  "category": "string",
  "meta": CLEAN_FIELDS_DEFAULT.META
}




const LIST_HEADER_FIELDS = [
  {
    type: "IMAGE",
    name: "Image",
    defaultValue: "",
    path: "images.0.url"
  },
  {
    path: "ID",
    type: "ID",
    name: "ID",
    searchable: true
  },
  {
    type: "VALUE",
    name: "Name",
    defaultValue: "",
    path: "name",
    searchable: true
  },
  {
    path: "language",
    type: "VALUE",
    name: "Language",
    defaultValue: "NONE",
    searchable: true
  },
  {
    type: "VALUE",
    name: "Status",
    defaultValue: "ACTIVE",
    path: "status"
  },
  {
    type: "VALUE",
    name: "Type",
    defaultValue: "",
    path: "type",
    searchable: true
  },
  {
    type: "VALUE",
    name: "URL",
    defaultValue: "",
    path: "url",
    searchable: true
  },
  {
    type: "VALUE",
    name: "Website",
    defaultValue: "",
    path: "website",
    searchable: true
  },
  {
    type: "VALUE",
    name: "Show Where",
    defaultValue: "",
    path: "showWhere",
    searchable: false
  },
  {
    type: "MONEY",
    name: "Cost",
    defaultValue: 0,
    path: "cost",
    searchable: true
  },
  {
    type: "MONEY",
    name: "Discount",
    defaultValue: 0,
    path: "discount",
    searchable: true
  },
  {
    type: "CUSTOM",
    name: "Total",
    path: "total", //THIS IS NEEDED TO ALLOW TABLE SETTINGS TO SAVE IT
    CUSTOM: val=>{
      return(<strong>{window.GlobalUtil.convertToMoney(Number(val.cost)-Number(val.discount))}</strong>)
    }
  },
  {
    path: "meta.createAt",
    type: "DATE",
    name: "Create At",
    defaultValue: "1606607634571"
  },
  {
    path: "meta.updateAt",
    type: "DATE",
    name: "Last Update",
    defaultValue: "1606607634571"
  }
];



  

  
  
  
  
  
  


const DEFAULT_PREVIEW_FORM = () => [
  {
    "TYPE": "ROW",
    "FIELDS": [
      {
        "TYPE": "COLUMN",
        "FIELDS": [
          {
            "PATH": "_id",
            "TYPE": "STRING",
            "NAME": "ID"
          },
          {
            "PATH": "ref",
            "TYPE": "STRING",
            "NAME": "Ref"
          },
          {
            "PATH": "type",
            "TYPE": "STRING",
            "NAME": "Type"
          },
          {
            "PATH": "name",
            "TYPE": "STRING",
            "NAME": "Name"
          },
          {
            "PATH": "description",
            "TYPE": "STRING",
            "NAME": "Description"
          },
          {
            "PATH": "language",
            "TYPE": "STRING",
            "NAME": "Language"
          },
          {
            "PATH": "numberInStock",
            "TYPE": "STRING",
            "NAME": "NumberInStock"
          },
          {
            "PATH": "status",
            "TYPE": "STRING",
            "NAME": "Status"
          },
          {
            "PATH": "paypalID",
            "TYPE": "STRING",
            "NAME": "PaypalID"
          },
          {
            "PATH": "category",
            "TYPE": "STRING",
            "NAME": "Category"
          },

          {
            "PATH": "cost",
            "TYPE": "MONEY",
            "NAME": "Cost"
          },
          {
            "PATH": "discount",
            "TYPE": "MONEY",
            "NAME": "Discount"
          },
          {
            "PATH": "shortDesc",
            "TYPE": "STRING",
            "NAME": "Short Desc"
          },
          {
            "PATH": "includes",
            "TYPE": "ARRAY_STRINGS",
            "NAME": "Includes"
          },
          {
            "PATH": "showWhere",
            "TYPE": "ARRAY_STRINGS",
            "NAME": "Show Where"
          },
          {
            "PATH": "url",
            "TYPE": "STRING",
            "NAME": "URL"
          }
        ]
      }
    ]
  },
  {
    "TYPE": "ROW",
    "FIELDS": [
      {
        "TYPE": "COLUMN",
        "TITLE":"Images",
        //"SUB_TITLE":"Current user mouth ",
        "FIELDS": [
          {
            "PATH": "images",
            "TYPE": "ARRAY_OBJECT",
            "FIELDS": [
              {
                "PATH": "url",
                "TYPE": "IMAGE",
                "NAME": "Image"
              },
              {
                "PATH": "id",
                "TYPE": "STRING",
                "NAME": "Id"
              },
              {
                "PATH": "type",
                "TYPE": "STRING",
                "NAME": "Type"
              },
              {
                "PATH": "name",
                "TYPE": "STRING",
                "NAME": "Name"
              }
            ]
          }
        ]
      }
    ]
  },
  PREVIEW_FORM_TEMPLATES.META()
];


const FINAL_MOD_NEW = (curCleanObj)=>{
  delete curCleanObj.ID;  if(!curCleanObj.images) delete curCleanObj.images;
  return curCleanObj;
}

const FINAL_MOD_EDIT = (curCleanObj)=>{
  delete curCleanObj.ID;  if(!curCleanObj.images) delete curCleanObj.images;
  return curCleanObj;
}

const PRODUCTS = {
  MUTATIONS: MUTATIONS,
  QUERIES: QUERIES,
  FORM_FIELDS: FORM_FIELDS,
  FORM_FIELDS_NEW: FORM_FIELDS_NEW,
  CLEAN_FIELDS: CLEAN_FIELDS,
  LIST_HEADER_FIELDS: LIST_HEADER_FIELDS,
  PREVIEW: PREVIEW,
  FINAL_MOD_NEW: FINAL_MOD_NEW,
  FINAL_MOD_EDIT: FINAL_MOD_EDIT
}

export default PRODUCTS;
export {
  DEFAULT_MUTATION_FIELDS,
  DEFAULT_QUERY_FIELDS,
  DEFAULT_QUERY_INPUT_VAR,
  DEFAULT_QUERY_INPUT,
  DEFAULT_MUTATION_INPUT_VAR,
  DEFAULT_MUTATION_INPUT,
  DEFAULT_PREVIEW_FORM
};