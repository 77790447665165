import React from 'react';
import {
    BrowserRouter as Router, StaticRouter, Route, Link, Redirect, Switch
    // etc.
} from 'react-router-dom';
import Confetti from 'react-confetti';
import Lightbox from "react-awesome-lightbox";
// You need to import the CSS only once
import "react-awesome-lightbox/build/style.css";
import API_CALLS from 'API_CALLS/index';


import {ChangeLang, GetLan} from './NavLanConverter';
import Website from 'website/index';
// import Promotions from 'promotions/index';


//FOR SCHOOLS
//import Schools from 'schools/index';

import Checkout from 'website/pages/checkout/index';
import BackendWrapper from './BackendWrapper';
import TemplateSlideInRight from "global/templates/templateSlideInRight/index";
import Cart from 'global/components/Cart/index';
import Loading from 'global/components/Loading/index';
import {GetBasicUserInfo, GetSiteSettings} from 'global/utils/helper-functions';
const {Container, Row, Column} = window.LayoutBuilder;



// const httpLink = createHttpLink({
//   uri: 'http://localhost:3001/graphql',
//   credentials: 'same-origin'
// });

// const authLink = setContext((_, { headers }) => {
//   // get the authentication token from local storage if it exists
//   const token = localStorage.getItem('token');
//   // return the headers to the context so httpLink can read them
//   return {
//     headers: {
//       ...headers,
//       authorization: token ? `Bearer ${token}` : "",
//     }
//   }
// });


// const client = new ApolloClient({
//   link: authLink.concat(httpLink),
//   cache: new InMemoryCache()
// });
// const client = new ApolloClient({
//   uri: 'http://localhost:3001/graphql',
//   // batchInterval: 10,
//   // opts: {
//   //   credentials: 'same-origin',
//   // },

//   cache: new InMemoryCache()
// });

//


// if(loading) return (
//       <Loading
//         header={undefined}
//         body={undefined}
//         floating={true}
//         overlay={true}
//         inlineStyles={{
//           loadingIcon: {
//             "maxWidth": "250px"
//           }
//         }}
//       />
//     )


//(window.DefaultLang == "ESPAÑOL")

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reload: false,
      loading: false
    };
    this.HandleCartOrdersKey = window.GlobalUtil.State.subscribe("newOrder", newOrd=>{
      window.GlobalUtil.setCartToLocalStorage(newOrd); //UPDATE TEMP CACHE
    });
    this.LanguageEventKey = window.GlobalUtil.subscribeEvent("LanguageEvent", newLang=>{       
      //console.log(`LanguageEvent`,"\n\n",newLang,"\n\n");
      if(!newLang) return; //IF NO CHANGE THEN RETURN
      window.Session.Language = window.DefaultLang;
      if(window.Session && window.Session.Language && (window.Session.Language !== newLang)){
        //FIND CURRENT PAGE
        //GO TO CORRECT LENGUAGE PAGE VERSION OF THIS PAGE
        //window.GlobalUtil.LocalStorage.set("language", newLang);
        //window.Session.Language = newLang;
        ChangeLang(newLang)

        // ENGLISH
        this.setState({
          date: new Date(),
          reload: true
        }, ()=>this.setState({
          reload: false
        }));
      }
    });

    this.SiteSettingsEventKey = window.GlobalUtil.subscribeEvent("SiteSettingsEvent", cleanObj=>{
      window.SiteSettings = {...window.SiteSettings, ...window.GlobalUtil.APICleanObj(cleanObj)};
      this.setState({date: new Date()});
    });
    this.PageChangeEventKey = window.GlobalUtil.subscribeEvent("PageChangedEvent", newPage=>{ //RUNS EVERY TIME THE PAGE CHANGES
      //console.log(`PageChangeEvent`,"\n\n",newPage,"\n\n");
      //GetLan(); //ON PAGE CHANGE CHECK IF LAN CHANGED. IF SO THEN UPDATE TO NEW LANG. ELSE SKIP    

      if(window.NoScroll){ //IF WE DO NOT WANT TO SCROLL THEN STOP HERE THIS ONE TIME
        window.NoScroll = false;
        return true;
      }

      if(!window.Router){ //IF NO ROUTER THEN LOAD WHEN ROUTER EXIST AND STOP HERE
        window.GlobalUtil.subscribeEventOnce("RouterEvent", ()=>{
          window.GlobalUtil.triggerEvent("PageChangedEvent", newPage);
        });
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'instant'
        });
        return;
      }
      
      var hash = window.GlobalUtil.deepGetFromString(window, 'Router,history,location,hash', false);  
      //console.log(`hash`,"\n\n",hash,"\n\n");
      if (!hash || (hash === '')) {
        window.scrollTo({
          top: 0,
          left: 0,
          //behavior: 'instant'
        });
      } else {
        clearTimeout(this.scrollTimeout);
        this.scrollTimeout = setTimeout(() => {
          const id = hash.replace('#', '');
          const element = document.getElementById(id);
          if (element) {
            element.scrollIntoView();
          }
        }, 0);
      }
      // setTimeout(()=>{
      //   window.scrollTo(0,0);
      // }, 10);
    });


    this.SlideInRightEventKey = window.GlobalUtil.subscribeEvent("SlideInRightEvent", newTemplateDBObj=>{
      var {SlideInRightEvn} = this.state;
      if(!SlideInRightEvn) SlideInRightEvn = {};
      SlideInRightEvn[window.GlobalUtil.getRandomId()] = newTemplateDBObj;
      this.setState({SlideInRightEvn});            
    });

    this.showConfettiTimeoutKey = window.GlobalUtil.subscribeEvent("ShowConfetti", showOrNot=>{
      console.log(`this.showConfettiTimeout`,"\n\n",this.showConfettiTimeout,"\n\n");
      if(this.showConfettiTimeout) return;
      this.showConfettiTimeout = true; //this is running
      this.setState({showConfetti: true});
    });


    this.showLightboxImagesTimeoutKey = window.GlobalUtil.subscribeEvent("ShowLightboxImages", images=>{
      //if(this.showLightboxImagesTimeout) return;
      //this.showLightboxImagesTimeout = true; //this is running
      this.setState({showLightboxImages: images});
    });


    this.ClientEventKey = window.GlobalUtil.subscribeEventOnce("ClientEvent", readyToGo=>{            
      //ONCE THE CLIENT EVEN IS READY THEN LOAD ANY BASIC USER THAT COULD EXIST
      GetBasicUserInfo(true);
      GetSiteSettings({
        forceUpdate: true, 
        //callBack:()=>{}, 
        //triggerEvent:true
      });
    });

  }



  componentDidMount(){
    //console.log(`app componentDidMount`,"\n\n","\n\n");
    if(this.history){
      window.Router = this.history;
      window.GlobalUtil.triggerEvent("RouterEvent", this.history); //ROUTER IS READY THEN RUN THIS EVENT
    }
    //GetLan(); //GET WHAT LENG THIS PAGE IS
    if(window.Client){
      //window.GlobalUtil.consoleLog("client", [client]);
      //window.Client = client;
      window.GlobalUtil.triggerEvent("ClientEvent", window.client); //ROUTER IS READY
    }
    //window.GlobalUtil.triggerEvent("ClientEvent", window.Client);
    this.setState({WindowClientReady: true});
    this.setupCart();
  }

  componentWillUnmount(){
    this.HandleCartOrdersKey.unsubscribe();
    this.LanguageEventKey.unsubscribe();
    this.SiteSettingsEventKey.unsubscribe();
    this.PageChangeEventKey.unsubscribe();
    this.SlideInRightEventKey.unsubscribe();
    this.showConfettiTimeoutKey.unsubscribe();
    this.showLightboxImagesTimeoutKey.unsubscribe();
    if(this.ClientEventKey) this.ClientEventKey.unsubscribe();
  }



  setupCart = () => {
    //GET ANY PRODUCTS THAT WHERE IN THE CART AND LEFT IN THE TEMP MEMORY
    window.GlobalUtil.State.set("newOrder", window.GlobalUtil.getCartFromLocalStorage());
  }

  render(){
    var {WindowClientReady, reload, loading, SlideInRightEvn, showConfetti, showLightboxImages} = this.state;          
    if(window.Router){
      window.Session.Language = window.DefaultLang;
    }
    if(loading) return (
      <div className="">
        <Loading 
          floating="true"
          header="Loading"
          body="Loading you session"
          inlineStyles={{
            "loadingIcon": {
              "paddingBottom": "20px"
            },
            "loadingHeader": {
              "marginBottom": "5px",
              "fontSize": "40px"
            },
            "loadingBody": {
              "marginBottom": "0px",
              "fontSize": "20px"
            }
          }}
        />
      </div>
    );

    return (
      <React.Fragment>
        <Router ref={ref=>this.history=ref} basename={((process.env.NODE_ENV === 'development') ? '/' : '/')}>
          {
            (!reload && !loading) && 
            <Switch> {/* A SWITCH IS USED TO IF THEY ARE IN BACKEND THEY WILL GO TO BACKEND ELSE FOR EVERYTHING ELSE THEY WILL GO TO FRONTEND*/}
              <Route path={["/user", "/doctor", "/admin", "/lab"]} render={props=>
                (WindowClientReady ? <BackendWrapper {...props}/> : null)} />
              <Route path={["/checkout/:PID", "/checkout"]} render={props=>{
                window.GlobalUtil.triggerEvent("PageChangedEvent", {
                  PageName: "Checkout",
                  Name: "Checkout",
                  Paths: ["/checkout"]
                });
                return<Checkout {...props}/>
              }} />
              {/*<Route path={["/promotion"]} render={props=><Promotions {...props}/>} />*/}
              {/*<Route path={["/escuelas", "/schools"]} render={props=> <Schools {...props} />} />*/}
              <Route path={["", "/"]} render={props=><Website {...props}/>} />
            </Switch>
          }
          {
            (SlideInRightEvn && Object.keys(SlideInRightEvn).map((key, index)=>{
                var TemplateObj = SlideInRightEvn[key];
                if(!this.SlideInRightRef) this.SlideInRightRef = {};
                return(
                  <TemplateSlideInRight
                    key={key}
                    ref={e=>this.SlideInRightRef[key]=e}
                    title={TemplateObj.TITLE}
                    isValid={(TemplateObj.ISVALID !== undefined) ? TemplateObj.ISVALID : true}
                    width={TemplateObj.width}
                    customClass={TemplateObj.customClass}
                    onClose={()=>{
                      if(TemplateObj.onClose) TemplateObj.onClose();
                      delete SlideInRightEvn[key];
                      this.setState({SlideInRightEvn});
                    }}
                    noHeader={TemplateObj.noHeader}
                    noFooter={TemplateObj.noFooter}
                    saveButtonText={TemplateObj.saveButtonText}
                    hideSaveButton={TemplateObj.hideSaveButton}
                    closeButtonText={TemplateObj.closeButtonText}
                    onSave={()=>{
                      var OnCloseFunction = (this.SlideInRightRef[key] && this.SlideInRightRef[key].onClose) ? this.SlideInRightRef[key].onClose : ()=>{};
                      if(TemplateObj.onSave){
                        TemplateObj.onSave().then(()=>{
                          OnCloseFunction();
                        });
                      } else OnCloseFunction();
                    }}
                    customButtons={
                      TemplateObj.CUSTOMBUTTONS 
                      ? TemplateObj.CUSTOMBUTTONS
                      : null
                    }>
                    {TemplateObj.children({
                      onChangeValid: (newValid)=>{
                        SlideInRightEvn[key].ISVALID = newValid;
                        this.setState({SlideInRightEvn})
                      },
                      onSetSubmitButton: (newSaveButton)=>{
                        TemplateObj.onSave = newSaveButton;
                        this.setState({date: new Date()})
                      }
                    })}
                  </TemplateSlideInRight>
                ) 
              })
            )
          }
          <Cart
          />
          {
            showConfetti && 
            <Confetti
              numberOfPieces={1000}
              width={window.innerWidth}
              height={window.innerHeight*3}
              recycle={false}
              tweenDuration={8000}
              onConfettiComplete={()=>{
                this.setState({showConfetti: false});
                this.showConfettiTimeout = false;
              }}
            />
          }


          { 
            showLightboxImages &&
            <Lightbox 
              images={showLightboxImages} 
              onClose={()=>{
                window.GlobalUtil.triggerEvent("ShowLightboxImages", []);
              }}
            />
          }
        </Router>   
      </React.Fragment>
    );
  }
}




export default App;