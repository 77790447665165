import React from 'react';
import API_CALLS from 'API_CALLS/index';
import TemplatePage from "global/templates/templatePage/index";


const QUERY = API_CALLS.ORDERS.QUERIES.allAndCount();
const QUERY_SINGLE = API_CALLS.ORDERS.QUERIES.single();
const QUERY_COUNT = API_CALLS.ORDERS.QUERIES.count();
const MUTATION_EDIT = API_CALLS.ORDERS.MUTATIONS.update();
const MUTATION_ADD = API_CALLS.ORDERS.MUTATIONS.create();
const QUERY_GET_USERS = API_CALLS.USERS.QUERIES.all(`email`);
const FORM_FIELDS = API_CALLS.ORDERS.FORM_FIELDS;
const LIST_HEADER_FIELDS = API_CALLS.ORDERS.LIST_HEADER_FIELDS;
const PREVIEW = API_CALLS.ORDERS.PREVIEW;

const API_CALLS_FULL = {
  QUERY,
  QUERY_SINGLE,
  QUERY_COUNT,
  MUTATION_EDIT,
  MUTATION_ADD,
  FORM_FIELDS,
  QUERY_GET_USERS,
  PREVIEW
};






const ValidateObject = (newObject, onSuccess, onFail)=>{
  if(newObject._id && !newObject.id) newObject.id = newObject._id;      
  console.log("newObject");
  console.log(newObject,"\n\n");
        
  Object.keys(newObject).map((key, index)=>{
    if(!newObject[key]) delete newObject[key];
  })  
  onSuccess(newObject);
  //onFail();      
};







const PAGES = () => [
  {
    "id": "PAYMENTS01",
    "Name":"Paid Order",
    "PageName": "Paid Order",
    "Paths": ["/admin/orders/payInvoice"],
    "GlobalStateVar": ["PAY_INVOICE_REQUEST_TREATMENT_PLAN"],
    "TITLE": "Paid Orders",
    "DATA_FIELD": "orders",
    "LIST_HEADER_FIELDS": LIST_HEADER_FIELDS,
    "API_CALLS": API_CALLS_FULL,
    "EDITBUTTON": {
      NAME: "ORDERS",
      TITLE: "Order",
      FIELD: "order"
    },
    "VARIABLES": {
      "status": "NOTINACTIVE",
      "sortBy": "meta.createAt",
      "descending": false,
      "limit": 20,
      "offset": 0,
      "include": [
        {
          name: "user"
        }
      ]
    },
    "NEWBUTTON": {
      NAME: "ORDERS",
      TITLE: "Order",
      FIELD: "order"
    },
    "BUTTONS": [
      {
        width: "110px",
        category: "Preview",
        buttonText: "View",
        className: "btn-color3",
        iconClass: "fas fa-eye",
        onClick: (obj)=>{
          window.GlobalUtil.triggerEvent("TemplateDBEvent", {
            "TYPE": "PREVIEW",
            "BUTTON_TITLE": "Order",
            "TITLE": "Order", //TITLE OF SLIDE OUT
            "APINAME": "ORDERS",
            "NAME": "Order",
            "ID": obj._id
          }); 
        }
      }
    ]
  },
  {
    "id": "PAYMENTS02",
    "Name":"Upcoming",
    "PageName": "Upcoming",
    "Paths": ["/admin/orders/upcoming"],
    "TITLE": "Upcoming Payments",
    "DATA_FIELD": "orders",
    "LIST_HEADER_FIELDS": LIST_HEADER_FIELDS,
    "API_CALLS": API_CALLS_FULL,
    "EDITBUTTON": {
      NAME: "ORDERS",
      TITLE: "Order",
      FIELD: "order"
    },
    "VARIABLES": {
      "status": "PENDING",
      "sortBy": "meta.createAt",
      "descending": false,
      "limit": 20,
      "offset": 0,
      "include": [
        {
          name: "user"
        }
      ]
    },
    "BUTTONS": [
      {
        width: "110px",
        category: "Preview",
        buttonText: "View",
        className: "btn-color3",
        iconClass: "fas fa-eye",
        onClick: (obj)=>{
          window.GlobalUtil.triggerEvent("TemplateDBEvent", {
            "TYPE": "PREVIEW",
            "BUTTON_TITLE": "Order",
            "TITLE": "Order", //TITLE OF SLIDE OUT
            "APINAME": "ORDERS",
            "NAME": "Order",
            "ID": obj._id
          }); 
        }
      }
    ],
    SHOWSELECTBOXES:true
  },
  {
    "id": "PAYMENTS03",
    "Name":"Failed Payments",
    "PageName": "Failed Payments",
    "Paths": ["/admin/orders/failed"],
    "TITLE": "Failed Payments",
    "DATA_FIELD": "orders",
    "API_CALLS": API_CALLS_FULL,
    "LIST_HEADER_FIELDS": LIST_HEADER_FIELDS,
    "VARIABLES": {
      "status": "PENDING",
      "sortBy": "meta.createAt",
      "descending": false,
      "limit": 20,
      "offset": 0,
      "include": [
        {
          name: "user"
        }
      ]
    },
    "EDITBUTTON": {
      NAME: "ORDERS",
      TITLE: "Order",
      FIELD: "order"
    },
    "DELETEBUTTON": {
      NAME: "ORDERS"
    },
    "BUTTONS": [
      {
        width: "110px",
        category: "Preview",
        buttonText: "View",
        className: "btn-color3",
        iconClass: "fas fa-eye",
        onClick: (obj)=>{
          window.GlobalUtil.triggerEvent("TemplateDBEvent", {
            "TYPE": "PREVIEW",
            "BUTTON_TITLE": "Order",
            "TITLE": "Order", //TITLE OF SLIDE OUT
            "APINAME": "ORDERS",
            "NAME": "Order",
            "ID": obj._id
          }); 
        }
      }
    ],
    SHOWSELECTBOXES:true
  },
  {
    "id": "PAYMENTS04",
    "Name":"History",
    "PageName": "History",
    "Paths": ["/admin/orders/history"],
    "TITLE": "Order History",
    "DATA_FIELD": "orders",
    "API_CALLS": API_CALLS_FULL,
    "LIST_HEADER_FIELDS": LIST_HEADER_FIELDS,
    "VARIABLES": {
      "status": "NOTINACTIVE",
      "sortBy": "meta.createAt",
      "descending": false,
      "limit": 20,
      "offset": 0,
      "include": [
        {
          name: "user"
        }
      ]
    },
    "EDITBUTTON": {
      NAME: "ORDERS",
      TITLE: "Order",
      FIELD: "order"
    },
    "DELETEBUTTON": {
      NAME: "ORDERS"
    },
    "CLONEBUTTON": {
      NAME: "ORDERS",
      CONFIRMMESSAGE: "Are you sure you want to clone this? If so it be cloned into PENDING.",
      ONSUCCESS: ()=>{
        window.FlashMenuItem("/admin/payment/pending");
      }
    },
    "BUTTONS": [
      {
        width: "110px",
        category: "Preview",
        buttonText: "View",
        className: "btn-color3",
        iconClass: "fas fa-eye",
        onClick: (obj)=>{
          window.GlobalUtil.triggerEvent("TemplateDBEvent", {
            "TYPE": "PREVIEW",
            "BUTTON_TITLE": "Order",
            "TITLE": "Order", //TITLE OF SLIDE OUT
            "APINAME": "ORDERS",
            "NAME": "Order",
            "ID": obj._id
          }); 
        }
      }
    ],
    SHOWSELECTBOXES:true
  }
];







const Index = (props) => {
  //var {data} = props;
  var today = new Date();
  return (
     <TemplatePage
      ID={"AdminPayments"}
      REPORTS={
        [
          {
            QUERY: QUERY_COUNT,
            VARIABLES: {
              "status": "NOTINACTIVE",
              "dates": [{
                field: "meta.createAt",
                gte: `${today.getStartOfDay().getTime()}`
              }]
            },
            NAME: "orderCount",
            TITLE: "New Payments Today"
          },
          {
            QUERY: QUERY_COUNT,
            VARIABLES: {
              "status": "NOTINACTIVE",
              "dates": [{
                field: "meta.createAt",
                gte: `${today.getStartOfWeek().getTime()}`
              }]
            },
            NAME: "orderCount",
            TITLE: "Payments this Week"
          },
          {
            QUERY: QUERY_COUNT,
            VARIABLES: {
              "status": "NOTINACTIVE",
              "dates": [{
                field: "meta.createAt",
                gte: `${today.getStartOfMonth().getTime()}`
              }]
            },
            NAME: "orderCount",
            TITLE: "Payments this Month"
          },
          {
            QUERY: QUERY_COUNT,
            VARIABLES: {
              "status": "NOTINACTIVE",
              "dates": [{
                field: "meta.createAt",
                gte: `${today.getStartOfYear().getTime()}`
              }]
            },
            NAME: "orderCount",
            TITLE: "Payments this Year"
          }
        ]
      }
      REDIRECT={{
        "path": "/admin/orders",
        "to": "/admin/orders/payInvoice"
      }}
      PAGES={PAGES()}
      ValidateObject={ValidateObject}
    />
  );
}





export default Index;

export {PAGES};   

      
