import React from 'react';
import API_CALLS from 'API_CALLS/index';
import PromoCodes from './promoCodes/index';
import Chart from "./chart/index";
import Join from "./join/index";
import UnpaidCommissions from "./unpaidCommissions/index";
import Loading from 'global/components/Loading/index';
import CONTENTS from "./CONTENTS";

//import SlideInRight from 'global/components/SlideInRight/index';
const MUTATION_USER_EDIT = API_CALLS.USERS.MUTATIONS.update();
const {Container, Row, Column} = window.LayoutBuilder;




/*
LATER
  TODO LATER:
  □ ADD CHART SHOWING THIS MONTH CONVERSIONS BY DATE OF MONTH
  □ ADD CHART COMPARING THIS MONTH TO LAST MONTH


*/

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      affiliateSales: undefined,
      EARNED: 0,
      commissions: null,
      commissionsCount: 0,
      loading: true 
    };    
  }

  componentDidMount = () => {
    //window.GlobalUtil.consoleLog("MUTATION_USER_EDIT", [MUTATION_USER_EDIT]);
    var date = new Date();
    var startOfMonth = date.getStartOfMonth().getTime()
    
    this.APIGetCouponsTimeout = setTimeout(async () => {
      await this.setState({loading: true});
      var affiliateSales = await this.APIGetCoupons({"startAt": `${startOfMonth}`});

      //await this.setState({loading: true});
      var commissionsByUser = await this.APIGetCommissions();
      if(!commissionsByUser) commissionsByUser = {};
      
      await this.setState({
        affiliateSales: affiliateSales,
        EARNED: commissionsByUser.EARNED,
        commissions: commissionsByUser.commissions,
        commissionsCount: commissionsByUser.commissionsCount,
        loading: false
      });
      return true;
      clearTimeout(this.APIGetCouponsTimeout)
    }, 10);
  }

  componentWillUnmount(){
    clearTimeout(this.APIGetCouponsTimeout)
  }

  APIGetCommissions = async () => {
    return await window.Client.query({ //API UPDATE
      query: API_CALLS.COMMISSIONS.QUERIES.commissionsByUser(),
      variables: {
        id: window.Session.user._id
      }
    })
    .then(({data})=>{
      window.GlobalUtil.consoleLog('data.commissionsByUser', data.commissionsByUser)
      return data.commissionsByUser;
    })
    .catch((error)=>{
      console.log("error APIGetCommissions");
      console.log(error,"\n\n");  
      return undefined;
    });
  }


  APIGetCoupons = async ({startAt}) => {
    return await window.Client.query({ //API UPDATE
      query: API_CALLS.REPORTS.QUERIES.getAffiliateSales(),
      variables: {
        UID: window.Session.user._id,
        //CODE: "",
        //CPID: "",
        //APPROVED: "",
        startAt: startAt,
        //endAt: "",
      }
    })
    .then(({data})=>{
      //console.log('data.affiliateSales', data.affiliateSales);
      return data.affiliateSales;
    })
    .catch((error)=>{
      console.log("error APIGetCoupons getAffiliateSales");
      console.log(error,"\n\n");  
      return undefined;
    });
  }



  APISendAffiliateRequest = () => {
    this.setState({loading: true});
    var currentUser = (window.Session.user ? window.GlobalUtil.APICleanObj(window.Session.user) : {});    
    const ISAFFILIATE = (currentUser.AFFILIATE && currentUser.AFFILIATE.isAffiliate);
    if(ISAFFILIATE){
      this.setState({loading: false});
      return true;
    }; ///IF THEY ARE ALREADY AFFILIATES THEN DON'T SEND ANOTHER REQUEST
    this.props.onUpdateUser({
      id: currentUser._id,
      "AFFILIATE": {
        isAffiliate: true,
        codes: ((currentUser.AFFILIATE && currentUser.AFFILIATE.codes) ? currentUser.AFFILIATE.codes : []) 
      },
    }, async ()=>{
      await window.ForceUserReload();
      window.GlobalUtil.delay(2000).then(()=>{
        this.setState({
          loading: false
        });
      })
    });
    
  }


  render(){
    var {affiliateSales={}, EARNED=0, commissions, commissionsCount=0, loading} = this.state;
    var {TOTALS={APPROVED: 0, PENDING: 0, EARNED: 0}} = affiliateSales;
    var currentUser = (window.Session.user ? window.GlobalUtil.APICleanObj(window.Session.user) : {});
    currentUser.id = currentUser._id;
    const CONTENT = window.GlobalUtil.dangerSetGlobalObjList(CONTENTS[window.Session.Language].CONTENT); 
    var byCoupons = ((commissions && commissions.length) ? window.GlobalUtil.groupArrayOfObj({"objArray":commissions, "pathToGroupBy":"CODE", "returnAsObj": false}) : []);
    if(loading) {
      return(
        <div id="UserAffiliate" className="scrollZone">
          <div className="">
            <Container className="" fluid="true">
              <Row className="">
                <Column className="" col="" xs="" sm="" md="" lg="" xl="">
                  {CONTENT.Title[0]}
                </Column>
              </Row>
            </Container> 
            <Container className="" fluid="">
              <Row className="">
                <Column className="" col="" xs="" sm="" md="" lg="" xl="">
                  <Loading 
                    floating="true"
                    header={"Loading"}
                    body={"Loading Changes"}
                  />
                </Column>
              </Row>
            </Container> 
          </div>
        </div>
      )
    }



    //IF NOT AFFILIATE THEN JUST SHOW THE JOIN AFFILIATES MESSAGE
    return (
      <div id="UserAffiliate" className="scrollZone">
        <Container className="" fluid="true">
          <Row className="">
            <Column className="" col="" xs="" sm="" md="" lg="" xl="">
              {CONTENT.Title[0]}
            </Column>
          </Row>
        </Container> 
        {
          (!currentUser.AFFILIATE || !currentUser.AFFILIATE.isAffiliate || !currentUser.AFFILIATE.codes.length)
          ? <Join 
              CONTENT={CONTENT}
              currentUser={currentUser}
              onUpdateUser={this.props.onUpdateUser}
              APISendAffiliateRequest={this.APISendAffiliateRequest}
            />
          : <Container className="" fluid="true">
              <Row className="">
                <Column className="matchHeight" col="12" xs="12" sm="" md="" lg="" xl="">
                  <div className="">
                    <Row className="fullHeight">
                      <Column className="" col="12" xs="12" sm="12">
                        <div id="HeaderPaymentInfo">
                          <div className="comWrapper comWrapper1">
                            <div className="component iconAndHeaderComp borderColor1">
                              <div className="iconAndHeader">
                                <div className="icon color1"><i className="fas fa-piggy-bank"></i></div>
                                <div className="text">{CONTENT.HeaderPaymentInfo[0]}</div>
                              </div>
                              <div className="number color1">{commissionsCount}</div>
                            </div>
                          </div>
                          <div className="comWrapper comWrapperLonger ">
                            <div className="component iconAndHeaderComp borderColor2">
                              <div className="iconAndHeader">
                                <div className="icon color2"><i className="fas fa-money-bill-wave"></i></div>
                                <div className="text">{CONTENT.HeaderPaymentInfo[1]}</div>
                              </div>
                              <div className="number color2">{window.GlobalUtil.convertToMoney(EARNED)}</div>
                            </div>
                          </div>
                        </div>
                      </Column>
                    </Row>
                  </div>
                </Column>
              </Row>

              <Row className="">
                <Column className="matchHeight" col="" xs="" sm="" md="" lg="" xl="">
                  <PromoCodes 
                    currentUser={currentUser}
                    CONTENT={CONTENT}
                  />
                </Column>
              </Row>


              <Row className="">
                <Column className="matchHeight" col="" xs="" sm="" md="" lg="" xl="">
                  <UnpaidCommissions
                    CONTENT={CONTENT}
                    byCoupons={byCoupons}
                  />
                </Column>
                <Column className="couponColumn" col="12" xs="12" sm="12" md="5" lg="5" xl="4" >
                  <div className="component">
                    { //THIS WILL USE byCoupons WHICH IS AN ARRAY OF ARRAIES OF SALES BY COUPONS
                      (byCoupons && byCoupons.length)
                      ? <Chart
                          labels={byCoupons.map(obj=>obj[0].CODE)}
                          dataArray={byCoupons.map(obj=>Number(obj.length))}
                          affiliateSales={byCoupons}
                          totalConversions={commissionsCount}
                          CONTENT={CONTENT}
                        />
                      : <Chart
                          labels={[]}
                          dataArray={[]}
                          CONTENT={CONTENT}
                          totalConversions={0}
                          affiliateSales={[]}
                        />
                    }
                    
                  </div>
                </Column>
              </Row>

              {/* 

              //place this in billing
              <Row className="">
                <Column className="matchHeight" col="" xs="" sm="" md="" lg="" xl="">
                  <div className="component fullHeight overflowRightScroll">
                    <div className="itemHeader">
                      <div className="itemHeaderTextZone">
                        <div className="itemHeaderText withIcon">
                          <div className="text">Payment History</div>
                        </div>
                      </div>
                    </div>
                    <Row className="">
                      <Column className="" col="12" xs="12" sm="12">
                        <div className="">
                          Get a list of times we've paid them and how much we paid.
                        </div>
                        <div className="">
                          When they expand it, a full payment recipt will show up. It'll state how much we've paid them, when, for how many of each sale, etc.
                        </div>
                        <div className="">
                          <table className='table'>
                            <thead>
                              <tr>
                                <th>Date</th>
                                <th># of Sales</th>
                                <th>Paid</th>
                                <th className="buttonSection">Preview</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td><span className="">JAN 2021</span></td>
                                <td><span className="">5</span></td>
                                <td><span className="">$2,000</span></td>
                                <td className="buttonSection"><i className="fas fa-search" /></td>
                              </tr>
                              <tr>
                                <td><span className="">FEB 2021</span></td>
                                <td><span className="">15</span></td>
                                <td><span className="">$5,000</span></td>
                                <td className="buttonSection"><i className="fas fa-search" /></td>
                              </tr>
                              <tr>
                                <td><span className="">MAR 2021</span></td>
                                <td><span className="">30</span></td>
                                <td><span className="">$12,000</span></td>
                                <td className="buttonSection"><i className="fas fa-search" /></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </Column>
                    </Row>
                  </div>
                </Column>
              </Row> 
              */}
            </Container>
        }
        
      </div>
    );
  }
}













export default Index;
      
