import React, { useState, useRef } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import TemplateSlideInRight from "global/templates/templateSlideInRight/index";
import $ from 'jquery';






class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isValid: true
    };    
    this.onClose = this.onClose.bind(this);
  }
  componentDidMount(){  
  }

  componentWillUnmount(){
  }


  onClose(){    
    if(this.slideInRightRef) this.slideInRightRef.slideInRightRef.onClose(); //ANIMATION SLIDE OUT
    if(this.props.onClose) this.props.onClose(); //CLOSE THIS IN PARENT
  }



  render(){
    var {showSlideIn, isValid} = this.state;
    var {curObj={}, FORM_FIELDS, API_CALLS} = this.props;   
    var PREVIEW = API_CALLS.PREVIEW;          
    return (
      <TemplateSlideInRight
        ref={e=>this.slideInRightRef=e}
        title={"Preview Tracking"}
        isValid={isValid}
        saveButtonText={"Done"}
        onClose={this.onClose}
        onSave={this.onClose}>
        <PREVIEW
          VARIABLES={{
            id: curObj._id
          }}
        />
      </TemplateSlideInRight>
    );
  }
}







export default Index;