import React from 'react';
import {Helmet} from "react-helmet-async";
import 'styles/scss/website/pages/guarantees.scss';
import TemplatePage from 'website/templates/templatePage/index.js';
import CONTENTS from "./CONTENTS";
const {Container, Row, Column} = window.LayoutBuilder;
class Index extends TemplatePage {
  // constructor(props) {
  //   super(props);
  //   //this.state = {
  //...this.state,
  //   //};    
  // }

  didMount(){
  }

  willUnmount(){
  }

  render(){
    //var {showSideMenu} = this.state;
    const CONTENT = window.GlobalUtil.dangerSetGlobalObjList(CONTENTS[window.Session.Language].CONTENT); 
    const HEADER = CONTENTS[window.Session.Language].HEADER; 
    return (
      <div className="contentPage" id="PageGuarantees">
        <Helmet>
            <meta charSet="utf-8" />
            <title>{HEADER.TITLE}</title>
            <meta name="description" content={HEADER.DESCRIPTION}/>
            <link  href={`${this.props.location.pathname}`} />
            <link rel="canonical" href={`${HEADER.BASEURL}${this.props.location.pathname}`} />
            <meta name="keywords" content={HEADER.KEYWORDS} />

            {"<!-- Google / Search Engine Tags -->"}
            <meta itemprop="name" content={HEADER.TITLE} />
            <meta itemprop="description" content={HEADER.DESCRIPTION}/>
            <meta itemprop="image" content="https://Smiles.Club/SocialImage_500.jpg" />

            {/*<!-- Facebook Meta Tags -->*/}
            <meta property="og:url" content={`${HEADER.BASEURL}${this.props.location.pathname}`} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={HEADER.TITLE} />
            <meta property="og:description" content={HEADER.DESCRIPTION}/>
            <meta property="og:image" content="https://Smiles.Club/SocialImage_500.jpg" />

            {/*<!-- Twitter Meta Tags -->*/}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={HEADER.TITLE} />
            <meta name="twitter:description" content={HEADER.DESCRIPTION}/>
            <meta name="twitter:image" content="https://Smiles.Club/SocialImage_500.jpg" />
        </Helmet>
        <Container className="">
          <Row className="title">
            <Column className="" col="" xs="" sm="" md="" lg="" xl="">
              <div className="">
                {CONTENT.Section1[0]}
              </div>
            </Column>
          </Row>
          <Row className="title">
            <Column className="" col="" xs="" sm="" md="" lg="" xl="">
              <div className="textTemplate">
                {CONTENT.Section1[1]}
              </div>
            </Column>
          </Row>
        </Container>
      </div>
    );
  }
}


export default Index;
      