import React from 'react';
import {NavLink} from 'react-router-dom';
import PAGES from 'website/pages/pages.js'; //ADD NEW PAGES HERE

const Component = (props)=>{
  var {onClickLink=()=>{}, MenuToShow, inline} = props;
  if(!MenuToShow) return null;
  const CleanLinks = PAGES(window).filter(Page=>Page.Menu.indexOf(MenuToShow) !== -1);        
        
  return(
    <div className={`menu ${inline ? "nav" : ""}`}>
      {
        CleanLinks.map((Link, index)=>{
          if((Link.PageName === "Home") || Link.PageName === "Inicio") return(
            <div className="menu-item" key={index}>
              <NavLink 
                className={"nav-link"} 
                exact 
                isActive={(match, location) => {                                                            
                  if(!location) return false;
                  var isHome = false;
                  if(Link.Paths[0] && (Link.Paths[0] == location.pathname)) isHome = true;
                  if(Link.Paths[1] && (Link.Paths[1] == location.pathname)) isHome = true;
                  return(isHome)      
                }}
                activeClassName="active" 
                onClick={onClickLink} 
                to={Link.Paths[0]}>
                  {Link.PageName}
              </NavLink>
            </div>
          )
          return(
            <div className="menu-item" key={index}>
              <NavLink className={"nav-link"} exact activeClassName="active" onClick={onClickLink} to={Link.Paths[0]}>{Link.PageName}</NavLink>
            </div>
          )
        })
      }
    </div>
  )
}

export default Component;