import React, { useState, useRef } from 'react';
import API_CALLS from 'API_CALLS/index';
import TemplateListBasic from 'global/templates/templateListBasic/index';
import NewPatient from 'user/doctor/components/NewPatient/index';
import RejectTreatmentPlan from './RejectTreatmentPlan/index';
import ApproveTreatmentPlan from './ApproveTreatmentPlan/index';
import ViewTreatmentPlanRequest from './ViewTreatmentPlanRequest/index';
import ViewTreatmentPlan from './ViewTreatmentPlan/index';
import ESPAÑOL from "./ESPAÑOL";
import ENGLISH from "./ENGLISH";
const CONTENTS = {
  ESPAÑOL,
  ENGLISH
}
const {Table} = window.LayoutBuilder;


class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hideList: false,
      variables: {
        "status": "NOTINACTIVE",
        "sortBy": "meta.createAt",
        "descending": false,
        "limit": 10,
        "offset": 0,
        "manual": [
          {
            path: "DOCTOR",
            val: window.Session.user._id
          },
          {
            path: "step",
            listValues: JSON.stringify(this.props.stepOptionsArray ? this.props.stepOptionsArray : ["REQUEST","UPLOAD", "APPROVE"]) //"PURCHASE", "REQUEST_FULL"
          }
        ],
        "include": [
          {
            name: "user"
          }
        ]
      }
    }; 
    //window.CurRefetchList
    // window.APIListGetAgain = ()=>{
    //   this.setState({hideList: true}, ()=>{
    //     this.setState({hideList: false})
    //   })
    // };
  }

  componentDidMount(){
  }

  componentWillUnmount(){
  }

  onUpdateFilter = (newVariables)=>{
    this.setState({
      variables: newVariables,
    }, ()=>window[this.props.CurRefetchListVar](true))
  }


  render(){
    var {hideList, variables} = this.state;
    var {TPList=[], noActions, noStep} = this.props;
    const CONTENT = window.GlobalUtil.dangerSetGlobal(CONTENTS[window.Session.Language]); 
    return(
      <div className="orderList">
        <div className="template1">
          <TemplateListBasic 
            CurRefetchListVar={this.props.CurRefetchListVar}
            QUERY={API_CALLS.TREATMENT_PLANS.QUERIES.allAndCountListFull()}
            VARIABLES={variables}
            dataField="treatmentPlans"
            onUpdateFilter={this.onUpdateFilter}
            CreateList={(data)=>{
              return(
                <div className="">
                  <OfferListFull
                    CONTENT={CONTENT}
                    fullList={data.treatmentPlans}
                    noActions={noActions}
                    noStep={noStep}
                    reloadList={()=>{
                      window[this.props.CurRefetchListVar](true)
                    }}
                  />
                </div>
              )
            }}
            //changeSort={this.onChangeSort}
          /> 
        </div>
      </div>
    )
  }
}




function OfferListFull(props) {     
  var {fullList=[], CONTENT=[], noActions, noStep} = props;       
  var TH = [
    {
      //style: {"width": "210px"},
      value: (
        <div className="headerColWrapper ">
          <div className="headerColText">
            <div className="text">{CONTENT[0]}</div>
          </div>
        </div>
      )
    },
    {
      style: {"width": "210px"},
      value: (
        <div className="headerColWrapper ">
          <div className="headerColText">
            <div className="text">{CONTENT[1]}</div>
          </div>
        </div>
      )
    },
    {
      style: {"width": "91px"},
      value: (
        <div className="headerColWrapper ">
          <div className="headerColText">
            <div className="text">{CONTENT[2]}</div>
          </div>
        </div>
      )
    },
    {
      style: {"width": "121px"},
      value: (
        <div className="headerColWrapper ">
          <div className="headerColText">
            <div className="text">{CONTENT[3]}</div>
          </div>
        </div>
      )
    },
    {
      style: {"width": "50px"},
      className: "text-center buttonCategory",
      value: CONTENT[4]
    },
    {
      style: {"width": "110px"},
      className: "text-center buttonCategory",
      value: CONTENT[5]
    }
  ];
  if(noStep) TH.splice(3, 1);
  if(noActions) TH.splice(-1, 1);
  return (
    <Table
      className="table"
      thead={{
        className:"thead-dark",
        tr: {
          th: TH
        }
      }}
      tbody={{
        tr: (
          fullList.length
          ? fullList.map((treatmentPlan, index)=>{
              var createdAt = new Date(Number(treatmentPlan.meta.createAt));
              var updateAt = new Date(Number(treatmentPlan.meta.updateAt));
              var TH_INNER = [
                {
                  value: (
                    <div className="rowText">
                      <div className="">{treatmentPlan.user.firstName} {treatmentPlan.user.lastName}</div>
                    </div>
                  )
                },
                {
                  value: (
                    <div className="rowText">
                      <div className="value">{treatmentPlan._id}</div>
                    </div>
                  )
                },
                {
                  value: (
                    <div className="rowText">
                      <div className="value">{createdAt.formatDate("n/d/y")}</div>
                    </div>
                  )
                },
                {
                  value: (
                    <div className="rowText">
                      <div className="value">{treatmentPlan.step}</div>
                    </div>
                  )
                },
                {
                  value: (
                    (treatmentPlan.step == "REQUEST") 
                    ? <ViewTreatmentPlanRequest 
                        curTreatmentPlan={{...treatmentPlan}}
                        curUser={{
                          ...treatmentPlan.user,
                          TPID: treatmentPlan._id
                        }}
                      />
                    : <ViewTreatmentPlan
                        curTreatmentPlan={{...treatmentPlan}}
                        curUser={{
                          ...treatmentPlan.user,
                          TPID: treatmentPlan._id
                        }}
                      />
                  )
                },
                {
                  value: (
                    <div className="text-center buttonColumn">
                      <div className="rowText">
                        <ActionButton
                          treatmentPlan={treatmentPlan}
                          reloadList={props.reloadList}
                          CONTENT={CONTENT}
                        />
                      </div>
                    </div>
                  )
                }
              ];
              if(noActions) TH_INNER.splice(-1, 1);
              if(noStep) TH_INNER.splice(3, 1);
              return(
                {
                  className: "animated fadeIn",
                  th: TH_INNER
                }
              )
            })
          : [{
              className: "animated fadeIn",
              th: [
                {
                  value: (
                    <div className="rowText">
                      <div className="">{CONTENT[6]}</div>
                    </div>
                  )
                },
                {},
                {},
                {},
                {},
                {}
              ] 
            }]
        )
      }}
    />
  );
}




const ActionButton = (props)=>{
  var {treatmentPlan, reloadList, CONTENT} = props;
        
  if(treatmentPlan.step === "REQUEST"){
    return(
      <NewPatient 
        DOCID={window.Session.user._id}
        curTreatmentPlan={treatmentPlan}
        curUser={{
          ...treatmentPlan.user,
          TPID: treatmentPlan._id,
          DOCID: window.Session.user._id
        }}
        customButton={(props)=>{
          return(
            <div className="button button1 btn-color5 actionButton" onClick={props.onClick}>
              <div className="text">{CONTENT[7]}</div>
              <div className="buttonIcon">
                <i className="fas fa-pencil-alt" style={{"cursor": "pointer"}}></i>
              </div>
            </div>
          )
        }}
      />
    )
  }
  if(treatmentPlan.step === "UPLOAD"){
    return(
      <div className="button button1 btn-color6 actionButton disabled">
        <div className="text">{CONTENT[8]}</div>
        <div className="buttonIcon">
          <i className="fas fa-hourglass-half" style={{"cursor": "pointer"}}></i>
        </div>
      </div>

    )
  }
  if(treatmentPlan.step === "APPROVE"){
    return(
      <ViewTreatmentPlanRequest 
        curTreatmentPlan={{...treatmentPlan}}
        curUser={{
          ...treatmentPlan.user,
          TPID: treatmentPlan._id
        }}
        reloadMainList={reloadList}
        customButtonText={(onClick)=>{
          return(
            <div 
              className="button button1 btn-color2 actionButton" 
              style={{"margin":"0px"}} 
              onClick={onClick}>
              <div className="text">{CONTENT[9]}&nbsp;</div>
              <div className="buttonIcon">
                <i className="fas fa-check" style={{"cursor": "pointer"}}></i>
              </div>
            </div>
          )
        }}
        customButton={()=>{
          return(
            <div className="buttonGroup">
              <RejectTreatmentPlan 
                curTreatmentPlan={{...treatmentPlan}}
                curUser={{
                  ...treatmentPlan.user,
                  TPID: treatmentPlan._id
                }}
              />
              <ApproveTreatmentPlan 
                curTreatmentPlan={{...treatmentPlan}}
                reloadMainList={reloadList}
                curUser={{
                  ...treatmentPlan.user,
                  TPID: treatmentPlan._id
                }}
              />
            </div>
          )
        }}
      />
    )
  }
  return null
}



export default Index;

