import React from 'react';
import SidePayment from "./SidePayment";
import SideSummary from "./SideSummary";
import PaypalButtons from "global/components/PaypalButtons/index";
import PaypalButtonSubscription from "global/components/PaypalButtonSubscription/index";
import TestCheckoutButton from "global/components/TestCheckoutButton/index";
import DialogWrapper from "global/components/DialogWrapper/index";
import {GetBasicUserInfo} from 'global/utils/helper-functions';
import CONTENTS from "./CONTENTS";
const {Container, Row, Column} = window.LayoutBuilder;
//import Loading from 'global/components/Loading/index';
//import CheckoutPage from 'global/components/CheckoutPage/index';


class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: {}, 
      finalCheckoutCalculator: {},
      newOrder: (window.GlobalUtil.State.get("newOrder") ? window.GlobalUtil.State.get("newOrder") : {products: [], coupon: {}})
    };    
    this.showPaypalErrorMessage = this.showPaypalErrorMessage.bind(this);
    this.NewOrderSubKey = window.GlobalUtil.State.subscribe("newOrder", newOrd=>{
      //window.GlobalUtil.consoleLog("newOrd", [newOrd]);            
      this.setState({newOrder: newOrd})
    })

    // this.TestSessionKey = window.GlobalUtil.subscribeEvent("testSession", newTest=>{
    //   //window.GlobalUtil.consoleLog("newOrd", [newOrd]);
    //   window.TestSession = newTest;
    //   this.setState({date: new Date()})
    // })
  }

  componentDidMount(){
    //var newOrder = window.GlobalUtil.getCartFromLocalStorage();
    //console.log(`componentDidMount newOrder`,"\n\n",newOrder,"\n\n");
          
    // if(window.GlobalUtil.State.get("newOrder")){
    //   window.GlobalUtil.setCartToLocalStorage(window.GlobalUtil.State.get("newOrder"))
    // }
  }

  componentWillUnmount(){
    this.NewOrderSubKey.unsubscribe()
    //this.TestSessionKey.unsubscribe();
  }


  showSuccessMessage = (order) => {
    this.setState({
      order: order
    });
    window.GlobalUtil.State.set("newOrder", {products: [], coupon: {}});
    window.GlobalUtil.clearLocalStorageCart(); //THIS CLEARS YOUR CART KEPT IN LOCAL STORAGE
    this.setState({resetChildren: true}, ()=>{
      this.setState({showSuccessMessage: true, resetChildren: false})
    });
  }
  showPaypalErrorMessage(){
    this.setState({showPaypalErrorMessage: true})
  }

  onCloseSuccess=()=>{
    GetBasicUserInfo(true);
    window.Router.history.push((window.Session.Language === "ENGLISH") ? '/' : '/inicio');
  }



  render(){
    var {curStatus, showSuccessMessage, showPaypalErrorMessage, resetChildren, order={}, finalCheckoutCalculator={}, newOrder} = this.state;          
    //var {showSideMenu} = this.state;          
    const CONTENT = window.GlobalUtil.dangerSetGlobalObjList(CONTENTS[window.Session.Language].CONTENT); 
    if(resetChildren) return null;
    return (
      <div id="CheckoutPageComponent">
        <Container className="">
          <Row className="">
            <Column className="" col="" xs="" sm="" md="" lg="" xl="">
              <SideSummary
                newOrder={newOrder}
              />
              
            </Column>
            <Column className="customCheckoutRight" col="" xs="" sm="" md="" lg="" xl="">
              <SidePayment 
                newOrder={newOrder}
                CONTENT={CONTENT}
                onShowSuccessMessage={this.showSuccessMessage}
                onShowPaypalErrorMessage={this.showPaypalErrorMessage}
                paypalButtons={()=>{
                  var newOrder = window.GlobalUtil.State.get("newOrder");
                  var paypalID = window.GlobalUtil.deepGetFromString(newOrder, "products,0,subscription,paypalID", false);
                  var basicType = window.GlobalUtil.deepGetFromString(newOrder, "products,0,type", false);
                  var checkoutCalculator = window.GlobalUtil.checkoutCalculator(newOrder.products, newOrder.coupon);
                  var PaypalButton = (((newOrder.products[0].type === "SUBSCRIPTION") && paypalID) ? PaypalButtonSubscription : PaypalButtons); //USE PAYPAL SUBSCRIPTION FOR SUBSCRIPTION PRODUCT, ELSE USE PAYPAL
                  if((basicType === "SUBSCRIPTION") && !paypalID) return(
                    <h5 className="">{CONTENT.CheckoutPageComponent[0]}</h5>
                  )    
                  //if(window.TestSession && (window.Session.user.role === "ADMIN")) 
                  if(process.env.NODE_ENV === 'development') PaypalButton = TestCheckoutButton;//IF ON TEST MODE THEN HAVE CHECKOUT BUTTON FOR EASY CHECKOUT TEST                        
                  return(
                    <PaypalButton
                      paypalID={paypalID}
                      checkoutCalculator={checkoutCalculator}
                      onSuccess={(order)=>{
                        //console.log('order', order)
                        if(!order) return;
                        order.id = order._id;
                        this.showSuccessMessage(order);
                        if(this.props.onSuccess) this.props.onSuccess(order);
                      }}
                      onFail={(fail)=>{
                        this.showPaypalErrorMessage();
                        if(this.props.onFail) this.props.onFail(fail);
                      }}
                    />
                  )
                }}
              />
            </Column>
          </Row>
        </Container>
        {
          showSuccessMessage &&
          <DialogWrapper
            ref={e => this.DialogWrapperRef = e}
            clickToCloseOverlay={true}
            renderToBody={true}
            containerId={"CheckoutDialogContent"}
            darkBackground={true}
            headerText={CONTENT.CheckoutPageComponent[1]}
            width="800"
            height="500"
            hideCancel={true}
            onCancel={this.onCloseSuccess}
            customSubmitButton={<button className={`button button1 button-accept`} onClick={this.onCloseSuccess}>{CONTENT.CheckoutPageComponent[2]}</button>}
            acceptButtonText="Submit"
          >
            <div className="BasicDialogContent">
              {CONTENT.CheckoutPageComponent[3]}
              <div className="text3">{CONTENT.CheckoutPageComponent[4]} <strong>#{order.id}</strong>. 
              {CONTENT.CheckoutPageComponent[5]}
                {/*
                  <br/>Total with shipping and discounts <strong>{window.GlobalUtil.convertToMoney(finalCheckoutCalculator.total)}</strong>.
                  <br/>You will received an order confirmation email with details of your order soon.
                */}
              </div>
            </div>
          </DialogWrapper>
        }
        {
          showPaypalErrorMessage &&
          <DialogWrapper
            ref={e => this.DialogWrapperRef2 = e}
            clickToCloseOverlay={true}
            renderToBody={true}
            containerId={"CheckoutDialogContent"}
            darkBackground={true}
            headerText={CONTENT.CheckoutPageComponent[6]}
            width="800"
            height="500"
            onCancel={()=>{
              this.setState({showPaypalErrorMessage: false});
            }}
            customSubmitButton={<button className={`button button1 button-accept`} onClick={()=>{
              window.Router.history.push('/checkout');
            }}>{CONTENT.CheckoutPageComponent[7]}</button>}
            acceptButtonText={CONTENT.CheckoutPageComponent[8]}
          >
            {CONTENT.CheckoutPageComponent[9]}
          </DialogWrapper>
        }
      </div>
    );
  }
}




export default Index;
      
