import React, { useState, useRef } from 'react';
import "styles/scss/doctor/pages/patients.scss";
import OrderList from 'user/doctor/components/OrderList/index';
import NewPatient from 'user/doctor/components/NewPatient/index';
import ESPAÑOL from "./ESPAÑOL";
import ENGLISH from "./ENGLISH";
const CONTENTS = {
  ESPAÑOL,
  ENGLISH
}
const {Container, Row, Column} = window.LayoutBuilder;


//"started": "1634851943362"


const Index = (props) => {
  var {data={}} = props;    
  const CONTENT = window.GlobalUtil.dangerSetGlobal(CONTENTS[window.Session.Language]); 
  return (
    <div id="DoctorTreatmentPlan" className="scrollZone">
      <div className="">
        <Container className="" fluid="true">
          <Row className="">
            <Column className="" col="" xs="" sm="" md="" lg="" xl="">
              <div className="headerButton d-flex">
                <h1 className="pageHeader"><i class="fas fa-file-contract"></i>{CONTENT[0]}</h1>
                <NewPatient DOCID={window.Session.user._id}/>
              </div>
            </Column>
          </Row>
        </Container> 
        <Container className="" fluid="true">
          <Row className="">
            <Column className="matchHeight" col="" xs="" sm="" md="12" lg="12" xl="12">
              <div className="component fullHeight overflowRightScroll">
                <div className="itemHeader d-flex">
                  <div className="itemHeaderTextZone">
                    <div className="itemHeaderText">{CONTENT[1]}</div>
                  </div>
                </div>
                <OrderList 
                  CurRefetchListVar={"CurRefetchListVar2"}
                  stepOptionsArray={["REQUEST","UPLOAD", "APPROVE"]}
                 />
              </div>
            </Column>
          </Row>
        </Container>
        <Container className="" fluid="true">
          <Row className="">
            <Column className="matchHeight" col="" xs="" sm="" md="12" lg="12" xl="12">
              <div className="component fullHeight overflowRightScroll">
                <div className="itemHeader d-flex">
                  <div className="itemHeaderTextZone">
                    <div className="itemHeaderText">{CONTENT[2]}</div>
                  </div>
                </div>
                <OrderList 
                  CurRefetchListVar={"CurRefetchListVar3"}
                  stepOptionsArray={["PURCHASE"]}
                  noActions={true}
                  noStep={true}
                 />
              </div>
            </Column>
          </Row>
        </Container>
        <Container className="" fluid="true">
          <Row className="">
            <Column className="matchHeight" col="" xs="" sm="" md="12" lg="12" xl="12">
              <div className="component fullHeight overflowRightScroll">
                <div className="itemHeader d-flex">
                  <div className="itemHeaderTextZone">
                    <div className="itemHeaderText">{CONTENT[3]}</div>
                  </div>
                </div>
                <OrderList 
                  CurRefetchListVar={"CurRefetchListVar3"}
                  stepOptionsArray={["REQUEST_FULL","UPLOAD_ALIGNERS","SCHEDULE_ALIGNERS","ACTIVE","FINISHED"]}
                  noActions={true}
                  noStep={true}
                 />
              </div>
            </Column>
          </Row>
        </Container>
      </div>
    </div>
  );
}






export default Index;
      




